import {Component, Input, OnChanges} from '@angular/core';
import {MetaField} from '../../core/definitions/meta-field';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {LoggerService} from "../../core/logger.service";

@Component({
  selector: 'app-edit-field-context',
  templateUrl: './edit-field-context.component.html',
  styleUrls: ['./edit-field-context.component.scss']
})
export class EditFieldContextComponent implements OnChanges {
  @Input() contexts: SuperObjectModel[];
  @Input() field: MetaField;

  currentConcept: { superobjectType: string, showItems: boolean, count: number, items: Array<SuperObjectModel> };
  contextTypes: { superobjectType: string, showItems: boolean, count: number, items: Array<any> }[] = [];

  constructor(private logger: LoggerService) {
  }

  ngOnChanges(): void {
    this.contextTypes = [];
    this.contexts.forEach(context => {
      let contextExisted = false;
      for (const contextType of this.contextTypes) {
        if (context.superobject_type_id_value === contextType.superobjectType) {
          contextType.showItems = false;
          if (context.context_id !== null) {
            contextType.items.push({uuid: context.context_id, identifier: this.getIdentifier(context)});
          }
          contextType.count++;
          contextExisted = true;
        }
      }
      if (!contextExisted) {
        this.contextTypes.push({
          count: 1,
          showItems: false,
          superobjectType: context.superobject_type_id_value,
          items: [{uuid: context.context_id, identifier: this.getIdentifier(context)}]
        });
      }
    });
  }

  toggleContext(contextType) {
    this.contextTypes.forEach(con => {
      if (con.superobjectType !== contextType.artifactType) {
        con.showItems = false;
      }
    });
    this.currentConcept = contextType;
    contextType.showItems = !contextType.showItems;
  }

  private getIdentifier(context: SuperObjectModel) {
    let res;
    if (context.identification_number && context.identification_number['identification_number']) {
      res = context.identification_number['identification_number'];
    } else {
      if (context.context_id_value) {
        const contextIdSplit = context.context_id_value.split(':');
        res = contextIdSplit[contextIdSplit.length - 1];
      } else {
        this.logger.warn(`Unable to obtain identification number due to missing value in context_id_value for
        object type ${context.object_type}`)
      }
    }
    return res;
  }
}
