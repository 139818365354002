import {Injectable} from '@angular/core';
import {SuperObjectModel} from './definitions/super-object-model';
import {SearchParameters} from './definitions/search-parameters';
import {AConst} from './a-const.enum';
import {SolrFilterService} from './solr-filter.service';
import {SearchService} from "./search.service";

export class ObjectNameResult {
  name = 'TRANS__OBJECT_NAME__MISSING';
  translate = true;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectNameService {
  promise;

  constructor(private searchService: SearchService,
              private solrFilter: SolrFilterService) {
  }


  public async getObjectName(params): Promise<ObjectNameResult> {
    const obj: SuperObjectModel = params.object;
    const objectNameResult = new ObjectNameResult();
    if (obj.artifact_name) {
      objectNameResult.name = obj.artifact_name;
      this.setName(params, objectNameResult);
    } else if (obj.artifact_id) {
      await this.searchForName(obj.artifact_id, objectNameResult);
      this.setName(params, objectNameResult);
    } else if (obj[AConst.CONTEXT_ID]) {
      const name = obj[AConst.CONTEXT_ID_VALUE];
      if (name) {
        objectNameResult.name = name;
      } else {
        await this.searchForName(obj[AConst.CONTEXT_ID], objectNameResult);
      }
      this.setName(params, objectNameResult);
    } else {
      console.warn('This object has no artifact_name: ' + JSON.stringify(obj));
    }
    return objectNameResult;
  }

  private setName(params, objectNameResult: ObjectNameResult) {
    let name = objectNameResult.name;
    objectNameResult.translate = name.indexOf('TRANS__') === 0;
    if (params.toLowerCase) {
      name = name.toLocaleLowerCase();
    }
    if (params.replace) {
      name = params.replace.replace('{name}', name);
    }
    objectNameResult.name = name;
  }

  private async searchForName(artifactId: string, objectNameResult: ObjectNameResult) {
    const params = {fl: ['artifact_name', 'artifact_id']} as SearchParameters;
    this.solrFilter.addFq(params, 'artifact_id', artifactId);
    try {
      const searchResult = await this.searchService.search(params);
      if (searchResult.artifacts.length) {
        objectNameResult.name = searchResult.artifacts[0].artifact_name;
      } else {
        console.warn('An object with id ' + artifactId + ' could not be found');
      }
    } catch (reason) {
      console.warn('Failed search for id ' + artifactId);
    }
  }
}
