<div class="edit-field-search-selector-multiple" [formGroup]="sectionsContainer.formGroup">

  <app-edit-field-search-selector-multiple-item-list
    *ngIf="!reference?.can_edit_context && itemsSet"
    [items]="items"
    [fieldParameters]="fieldParameters"
    [reference]="reference"
    (itemDelete)="deleteItemByIndex($event)"
    (itemEdit)="openEdit($event)"
    (itemDrop)="onItemDrop($event)"
  ></app-edit-field-search-selector-multiple-item-list>

  <app-edit-field-inline-array-item-list
    *ngIf="reference?.can_edit_context && itemsSet"
    [items]="items"
    [fieldParameters]="fieldParameters"
    (fieldOutsideTheScreen)="onFieldOutsideTheScreen()"
  ></app-edit-field-inline-array-item-list>

  <div  class="edit-field-search-selector-multiple__button-container"
        [matTooltip]="disabledReason"
        [matTooltipDisabled]="!isSelectorDisabled">
    <button mat-stroked-button
            color="primary"
            type="button"
            [disabled]="selectorIsDisabled"
            (click)="openCreateNew()"
            (keypress)="($event.key === 'Enter') && openCreateNew()">
      <i class="icon-create-new"></i>
      <ng-container *ngIf="!reference?.add_new_params?.add_button_title">
        <span class="bold uppercase">{{'TRANS__SEARCH_SELECTOR_MULTIPLE__CREATE_NEW' | translate}}</span>
        <span class="bold uppercase">{{(field.title || field.admin_title) | translate}}</span>
      </ng-container>
      <ng-container *ngIf="reference?.add_new_params?.add_button_title">
        <span class="bold uppercase">{{reference?.add_new_params?.add_button_title | translate}}</span>
      </ng-container>
    </button>
    <button *ngIf="reference?.selector && !sectionsContainer.isDialog"
            mat-stroked-button
            color="primary"
            type="button"
            [disabled]="selectorIsDisabled"
            (click)="clickEnableSelector()"
            (keypress)="($event.key === 'Enter') && clickEnableSelector()">
      <i class="icon-select1"></i>
      <span class="bold uppercase">{{'TRANS__FIELD__ARRAY_ADD_EXISTING' | translate}}</span>
      <span class="bold uppercase">{{(field.title || field.admin_title) | translate}}</span>
    </button>
  </div>
</div>
