import {Injectable} from '@angular/core';
import {InlineArrayItemService} from './inline-array-item.service';

@Injectable({
  providedIn: 'root'
})
export class UiToolsService {
  windowSizeData = {
    width: window.innerWidth,
    height: window.innerHeight
  };
  windowListeners = [];
  clickListeners = [];
  clickIgnoreList = [];
  scrollToOpenArrayItemTimeout;

  constructor(private inlineArrayItemService: InlineArrayItemService) {
  }

  public getClientType() {
    const ua = window.navigator.userAgent;
    const isAndroid = ua.toLowerCase().indexOf('android') > -1;
    const res = {
      isIphone: false,
      isIpad: false,
      isApple: false,
      isAndroid: false,
      isComputer: false
    };

    const vend = window.navigator.vendor;
    if (vend !== null && vend.match(/Apple Computer, Inc./) &&
      (ua.match(/iPhone/i) || ua.match(/iPod/i))) {
      res.isIphone = true;
    } else if (vend !== null &&
      vend.match(/Apple Computer, Inc./) && ua.match(/iPad/i)) {
      res.isIpad = true;
    } else if (vend !== null && vend.match(/Apple Computer, Inc./) &&
      ua.indexOf('Safari') !== -1) {
      res.isApple = true;
    } else if (isAndroid) {
      res.isAndroid = true;
    } else {
      res.isComputer = true;
    }
    return res;
  }

  public get windowSize() {
    return this.windowSizeData;
  }

  public setMediumScreenSizeOnLoad(mediumScreen) {
    const windowSize = this.windowSize;
    if (mediumScreen === undefined) {
      mediumScreen = windowSize.width < 1025;
    }
    return mediumScreen;
  }

  public setWindowSize() {
    const oldData = {width: this.windowSize.width, height: this.windowSize.height};
    this.windowSizeData.width = window.innerWidth;
    this.windowSizeData.height = window.innerHeight;
    this.runListenerCallbacks(this.windowListeners, [], this.windowSizeData, oldData);
  }

  public addWindowSizeListener(callback, source?) {
    return this.addListener(this.windowListeners, callback, source || 'ID');
  }

  public removeWindowSizeListener(id) {
    this.removeListener(this.windowListeners, id);
  }

  public addDocumentClickListener(callback, sourceName) {
    return this.addListener(this.clickListeners, callback, sourceName);
  }

  public removeDocumentClickListener(id) {
    this.removeListener(this.clickListeners, id);
  }

  public registerDocumentClick(event) {
    this.runListenerCallbacks(this.clickListeners, this.clickIgnoreList, event);
  }

  public ignoreNextDocumentClick(id) {
    this.clickIgnoreList.push(id);
  }

  public scrollTo(elementId) {
    let res = false;
    const scrollElement = document.getElementById(elementId);
    if (scrollElement) {
      const offset = 500;
      const scrollTop = scrollElement.getBoundingClientRect().top + window.scrollY;
      res = true;
      setTimeout(function () {
        window.scrollTo({top: scrollTop - offset, behavior: 'smooth'});
      }, 111);
    }
    return res;
  }

  public scrollToOpenArrayItem(idString, idIndexStart, rootObject) {
    const bracketEnd = idString.indexOf(']', idIndexStart);
    if (bracketEnd !== -1) {
      const arrayId = idString.substring(0, bracketEnd + 1);
      this.inlineArrayItemService.setOpenArrayItemIndexFromKey(rootObject, arrayId);
      this.scrollToOpenArrayItemTimeout = setTimeout(() => {
        if (this.scrollTo(arrayId)) {
          this.scrollToOpenArrayItem(idString, bracketEnd + 1, rootObject);
        } else {
          console.warn('Unable to find element to scroll to ' + arrayId);
        }
      }, 200);
    }
  }

  public clearScrollToArrayItemTimeout() {
    if (this.scrollToOpenArrayItemTimeout) {
      clearTimeout(this.scrollToOpenArrayItemTimeout);
    }
  }

  public findClassNameRecursively(nodeIn, className) {
    let node = nodeIn, found = false;
    if (node) {
      while (node.parentNode) {
        node = node.parentNode;
        if (this.hasClass(node, className)) {
          found = true;
        }
      }
    } else {
      console.warn('Node not set!');
    }
    return found;
  }

  private hasClass(el, class_to_match) {
    let c;
    if (el && el.className &&
      typeof class_to_match === 'string') {
      c = el.getAttribute('class');
      c = ' ' + c + ' ';
      return c.indexOf(' ' + class_to_match + ' ') > -1;
    } else {
      return false;
    }
  }


  private addListener(listenerList, callback, sourceName) {
    const id = sourceName + ':' + Math.random();
    listenerList.push({id: id, callback: callback});
    return id;
  }

  private removeListener(listenerList, id) {
    let index = null;
    listenerList.forEach((clickListener, ndx) => {
      if (clickListener.id === id) {
        index = ndx;
      }
    });
    if (index !== null) {
      listenerList.splice(index, 1);
    }
  }

  private runListenerCallbacks(listenerList, ignoreList, data?, oldData?) {
    listenerList.forEach(listener => {
      if (!this.ignoreCallback(listener, ignoreList)) {
        listener.callback(data, oldData);
      }
    });
  }

  private ignoreCallback(listener, ignoreList) {
    let res = false, ignoreIndex = 0;
    if (ignoreList.length) {
      ignoreList.forEach((ignoreId, index) => {
        if (ignoreId === listener.id) {
          ignoreIndex = index;
          res = true;
        }
      });
    }
    if (res) {
      this.clickIgnoreList.splice(ignoreIndex, 1);
    }
    return res;
  }
}
