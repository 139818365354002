<mat-tab-group [selectedIndex]="tabIndex.value"
               class="object-information-tabs"
               (selectedIndexChange)="tabIndex.setValue($event)"
               [ngClass]="{'noSelectedTab': tabIndex.value === 0}">
  <mat-tab></mat-tab>
  <mat-tab>
    <ng-template mat-tab-label class="bold">
      <span (click)="toggleTab(1)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__FOLDERS' | translate}}
       <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-object-folders [object]="object"></app-object-folders>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <span (click)="toggleTab(2)" class="object-information-tabs__tab bold">
        {{'TRANS__OBJECT_INFORMATION_TABS__CHANGES' | translate}}
        <mat-icon>expand_more</mat-icon></span>
    </ng-template>
    <app-object-update-log [contextId]="object.artifact_id" [getLogItems]="tabIndex.value === 2"></app-object-update-log>
  </mat-tab>
</mat-tab-group>
