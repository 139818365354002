import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchStateService} from '../../search-state.service';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-result-view-list-selector',
  templateUrl: './search-result-view-list-selector.component.html',
  styleUrls: ['./search-result-view-list-selector.component.scss']
})
export class SearchResultViewListSelectorComponent implements OnInit {

  @Input() searchContainer: SearchContainer;

  AConst = AConst;
  selectedItemId = null;

  constructor(private searchStateService: SearchStateService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnInit() {
    if (this.searchContainer.selections.singleSelect && this.searchContainer.selections.selectedItems.length) {
      this.selectedItemId = this.searchResultSelectionsService.getItemId(
          this.searchContainer.selections.selectedItems[0]);
    }
  }

  select(obj, event) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    this.searchStateService.setState(this.searchContainer).catch();
    if (this.searchContainer.selections.singleSelect) {
      this.selectedItemId = obj[AConst.ARTIFACT_ID];
    }
  }

}
