import {Injectable} from '@angular/core';
import {AnnotationContainer} from './annotation-container';
import {MediaHelperService} from '../core/media-helper.service';
import {AppNotification, NotificationService} from '../shared/notification.service';
import {Annotation} from './annotation';
import {ImageItem} from '../core/definitions/image-item';

@Injectable({
  providedIn: 'root'
})
export class AnnotationRendererService {

  private REG_ANN_COLOR = '#cccccc';
  private SEL_ANN_COLOR = '#ffffff';
  private tSize = 25; // "Thumb size"
  private htSize = this.tSize / 2; // "Half thumb size"
  private mvX = -4 - this.tSize;
  private mvY = 0;
  private clX = this.tSize + 4;
  private clY = 0;
  private proxyOffset = 3;

  constructor(private mediaHelper: MediaHelperService,
              private notificationService: NotificationService) {
  }

  drawImage(container: AnnotationContainer) {
    const cs = this.canvasSize(container);
    if (cs) {
      container.ctx.drawImage(container.imageObj, 0, 0, container.imageObj.width, container.imageObj.height, 0, 0,
        cs.width, cs.height);
    }
  }

  drawAnnotations(container: AnnotationContainer, p?) {
    const annotations = container.curAnn.getAnnotations();
    container.ctx = container.ctx || container.canvas.getContext('2d');

    if (container.imageObj) {
      this.drawImage(container);
      if (annotations) {
        annotations.forEach(ann => {
          this.drawAnnotation(container, ann);
          this.drawOrderNumber(container, ann);
        });
        this.checkDrawButtons(container, p);
      }
    } else if (!container.imageLoaded) {
      this.loadImage(container);
    }
  }

  cx2ax(container: AnnotationContainer, cx) {
    return cx / container.canvas.width;
  }

  cy2ay(container: AnnotationContainer, cy) {
    return cy / container.canvas.height;
  }

  setDrawAction(container: AnnotationContainer, mouseCoord) {
    let foundAction = false;
    const state = container.curAnn.state;
    let annotationUpdated = false;
    let ann = container.curAnn.selectedAnnotation;
    if (this.isCloseTo(container, mouseCoord, ann.x2, ann.y2)) {
      foundAction = true;
      state.draw.drawing = true;
      ann.$$$unfinished = true;
    } else if (this.isCloseTo(container, mouseCoord, ann.x1, ann.y1)) {
      foundAction = true;
      state.draw.drawing = true;
      ann.$$$unfinished = true;
      this.swapX(ann);
      this.swapY(ann);
    } else if (this.isCloseTo(container, mouseCoord, ann.x1, ann.y2)) {
      foundAction = true;
      state.draw.drawing = true;
      ann.$$$unfinished = true;
      this.swapX(ann);
    } else if (this.isCloseTo(container, mouseCoord, ann.x2, ann.y1)) {
      foundAction = true;
      state.draw.drawing = true;
      ann.$$$unfinished = true;
      this.swapY(ann);
    } else if (this.isCloseTo(container, mouseCoord, ann.x2, ann.y1, this.clX, this.clY)) {
      foundAction = true;
      container.curAnn.deleteAnnotation(ann);
      annotationUpdated = true;
      ann = null;
    } else if (this.isCloseTo(container, mouseCoord, ann.x1, ann.y1, this.mvX, this.mvY)) {
      foundAction = true;
      state.draw.moving = true;
      ann.$$$unfinished = true;
    }
    return {
      foundAction: foundAction,
      annotationUpdated: annotationUpdated
    };
  }

  getHoverAnnotation(container: AnnotationContainer, mouseCoord) {
    const hoverArr: Array<Annotation> = [];
    let res = null;
    if (mouseCoord) {
      const annotations = container.curAnn.getAnnotations();
      annotations.forEach(ann => {
        if (!ann.$$$unfinished &&
          this.isHovering(container, ann, mouseCoord)) {
          hoverArr.push(ann);
        }
      });
      if (hoverArr.length > 0) {
        res = this.getClosest(container, hoverArr, mouseCoord);
      }
    }
    return res;
  }

  setAnnotationEnd(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    let mouseX = mouseCoord.x;
    let mouseY = mouseCoord.y;

    if (mouseX > container.canvas.width) {
      mouseX = container.canvas.width - this.htSize;
    } else if (mouseX < 0) {
      mouseX = 0;
    }
    if (mouseY > container.canvas.height) {
      mouseY = container.canvas.height - this.htSize;
    } else if (mouseY < 0) {
      mouseY = 0;
    }
    ann.x2 = this.cx2ax(container, mouseX);
    ann.y2 = this.cy2ay(container, mouseY);
  }

  finishAnnotation(container: AnnotationContainer, ann: Annotation) {
    let deltaX, deltaY;
    if (ann.x2 < ann.x1) {
      this.swapX(ann);
    }
    if (ann.y2 < ann.y1) {
      this.swapY(ann);
    }
    const cc = this.a2c(container, ann);
    deltaX = cc.x2 - cc.x1;
    deltaY = cc.y2 - cc.y1;
    // "Small" annotation points are deleted
    if (deltaX < this.htSize && deltaY < this.htSize) {
      container.curAnn.deleteAnnotation(ann);
    } else {
      if (ann.a_type === 'circle') {
        if (deltaX > deltaY) {
          ann.y2 = this.cy2ay(container, cc.y1 + deltaX);
        } else {
          ann.x2 = this.cx2ax(container, cc.x1 + deltaY);
        }
      }
      ann.$$$unfinished = false;
    }
  }

  ax2cx(container: AnnotationContainer, aX, oX?) {
    let cX = aX * container.canvas.width;
    if (oX) {
      cX += oX;
    }
    return cX;
  }

  ay2cy(container: AnnotationContainer, aY, oY?) {
    let cY = aY * container.canvas.height;
    if (oY) {
      cY += oY;
    }
    return cY;
  }

  private loadImage(container: AnnotationContainer) {
    container.imageLoaded = true;
    container.imageObj = new Image();
    container.imageObj.onload = () => {
      const aPoints = container.curAnn.getAnnotations();
      if (aPoints.length > 0) {
        this.drawAnnotations(container);
      } else {
        this.drawImage(container);
      }
    };
    const imgArtifact = new ImageItem();
    imgArtifact.image_id = container.imageId;
    this.mediaHelper.getImageUrl(imgArtifact, container.imageSize).then(url => {
      container.imageObj.src = url;
    });
    container.imageObj.onerror = () => {
      this.notificationService.addNotification(new AppNotification(
        ['TRANS__ANNOTATION__IMAGE_NOT_FOUND'],
        'error',
        null,
        404
      ));
    };
  }

  private getClient(container: AnnotationContainer) {
    if (!container.client) {
      container.client = document.getElementById(container.parentContainerId);
      if (!container.client) {
        console.error('Parent container not found: ' + container.parentContainerId);
      }
    }
    return container.client;
  }

  private getDashboard(container: AnnotationContainer) {
    if (!container.dashboard && container.parentContainerId !== 'activeImage') {
      container.dashboard = document.getElementById(container.dashboardId);
      if (!container.dashboard) {
        console.warn('Dashboard container not found: ' + container.dashboardId);
      }
    }
    return container.dashboard;
  }

  private availableHeight(container: AnnotationContainer) {
    const rect = this.getClient(container).getBoundingClientRect();
    const dashboard = this.getDashboard(container);
    const dashboardHeight = dashboard ? dashboard.clientHeight : 0;
    return container.inDialog ?
      (window.innerHeight - (rect.top + dashboardHeight + 85)) :
      (window.innerHeight - (rect.top + dashboardHeight) - window.scrollY - 8);
  }

  private availableWidth(container: AnnotationContainer) {
    return this.getClient(container).clientWidth - container.offsets;
  }

  private canvasSize(container: AnnotationContainer) {
    let scale, width, height;
    let availHeight;
    const availWidth = this.availableWidth(container);

    width = container.imageObj.width;
    height = container.imageObj.height;

    if (!container.lockHeight) {
      availHeight = this.availableHeight(container);
    } else {
      let WY;
      if (window.innerHeight) {
        WY = container.inDialog ? window.innerHeight - 125 : window.innerHeight;
      } else {
        WY = container.inDialog ? Math.max(document.documentElement.clientHeight) - 125 : Math.max(document.documentElement.clientHeight);
      }
      const dashboard = this.getDashboard(container);
      const dashboardHeight = dashboard ? dashboard.clientHeight : 0;
      availHeight = WY - (Math.max(dashboardHeight) - 80);
    }
    if (container.heightIsSmallerThanContainer) {
      availHeight = height;
    }

    scale = availHeight / height;
    height = availHeight;
    width = width * scale;

    // For images wider than the canvas container
    // width, canvas size must be scaled down
    if (width > availWidth) {
      scale = availWidth / width;
      width = availWidth;
      height = height * scale;
    }

    container.canvas.width = width;
    container.canvas.height = height;

    return {
      width: width,
      height: height
    };
  }

  private swapX(ann: Annotation) {
    const newOX = ann.x1;
    ann.x1 = ann.x2;
    ann.x2 = newOX;
  }

  private swapY(ann: Annotation) {
    const newOY = ann.y1;
    ann.y1 = ann.y2;
    ann.y2 = newOY;
  }

  private checkDrawButtons(container: AnnotationContainer, p?) {
    let hoverAnn;
    const selectedAnnPoint = container.curAnn.selectedAnnotation;
    if (p) {
      if (selectedAnnPoint && !selectedAnnPoint.$$$unfinished && !selectedAnnPoint._destroy) {
        this.drawButtons(container, selectedAnnPoint, p.mouseCoord);
      }
      hoverAnn = this.getHoverAnnotation(container, p.mouseCoord);
      if (hoverAnn && !hoverAnn.$$selected) {
        this.drawButtons(container, hoverAnn, p.mouseCoord);
      }
    }
  }

  private drawButtons(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    const state = container.curAnn.state;
    this.drawMoveButton(container, ann, mouseCoord);
    if (state &&
      state.action === container.curAnn.actionStates.draw) {
      this.drawCloseButton(container, ann, mouseCoord);
      this.drawDragButtons(container, ann, mouseCoord);
    }
  }

  private selColor(ann: Annotation) {
    let res = this.REG_ANN_COLOR;
    if (ann.$$selected) {
      res = this.SEL_ANN_COLOR;
    }
    if (ann.color) {
      res = ann.color;
    }
    return res;
  }

  private a2c(container: AnnotationContainer, ann: Annotation) {
    return {
      x1: this.ax2cx(container, ann.x1, null),
      y1: this.ay2cy(container, ann.y1, null),
      x2: this.ax2cx(container, ann.x2, null),
      y2: this.ay2cy(container, ann.y2, null)
    };
  }

  private drawAnnotation(container: AnnotationContainer, ann: Annotation) {
    const cc = this.a2c(container, ann);
    let radius, x, y;
    const deltaX = Math.abs(cc.x2 - cc.x1);
    const deltaY = Math.abs(cc.y2 - cc.y1);

    container.ctx.beginPath();
    container.ctx.lineWidth = 1;
    container.ctx.strokeStyle = this.selColor(ann);
    container.ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
    container.ctx.shadowColor = '#000';
    container.ctx.shadowBlur = 3;
    container.ctx.shadowOffsetX = 2;
    container.ctx.shadowOffsetY = 2;
    if (ann.a_type === 'circle') {
      x = cc.x1;
      if (cc.x1 > cc.x2) {
        x = cc.x2;
      }
      y = cc.y1;
      if (cc.y1 > cc.y2) {
        y = cc.y2;
      }
      radius = deltaY;
      if (deltaX > deltaY) {
        radius = deltaX;
      }
      container.ctx.arc(x + radius / 2, y + radius / 2, radius / 2, 0, 2 * Math.PI, false);
    } else {
      container.ctx.rect(cc.x1, cc.y1, cc.x2 - cc.x1, cc.y2 - cc.y1);
    }
    container.ctx.stroke();
    container.ctx.fill();
  }

  private colorMidVal(color) {
    const r = parseInt('0x' + color.substring(1, 3), 16);
    const g = parseInt('0x' + color.substring(3, 5), 16);
    const b = parseInt('0x' + color.substring(5, 7), 16);
    return (r + g + b) / 3;
  }

  private drawOrderNumber(container: AnnotationContainer, ann: Annotation) {
    const cc = this.a2c(container, ann);
    let x, y, xOffset = this.mvX;
    const annColor = this.selColor(ann);
    if (!container.editable) {
      xOffset = 0;
    }
    x = this.ax2cx(container, ann.x1, xOffset);
    y = this.ay2cy(container, ann.y1, this.mvY);
    container.ctx.beginPath();
    container.ctx.arc(x, y, this.htSize - 1, 0, 2 * Math.PI, false);
    if (this.colorMidVal(annColor) > 96) {
      container.ctx.fillStyle = '#000000';
    } else {
      container.ctx.fillStyle = '#FFFFFF';
    }
    container.ctx.fill();
    container.ctx.stroke();

    container.ctx.font = '14px serif';
    container.ctx.fillStyle = annColor;

    const text = container.curAnn.getAnnotationIndex(ann).toString();

    container.ctx.fillText(text,
      cc.x1 + xOffset - 1 - text.length * 3,
      cc.y1 + 5);
  }

  private drawMoveButton(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    const x = this.ax2cx(container, ann.x1, this.mvX);
    const y = this.ay2cy(container, ann.y1, this.mvY);
    container.ctx.beginPath();
    container.ctx.strokeStyle = this.selColor(ann);
    container.ctx.lineWidth = 1;
    if (this.isCloseTo(container, mouseCoord, ann.x1, ann.y1, this.mvX, this.mvY)) {
      container.ctx.lineWidth = 2;
    }
    container.ctx.rect(x - this.htSize, y - this.htSize, this.tSize, this.tSize);
    container.ctx.stroke();
  }

  private drawCloseButton(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    const x = this.ax2cx(container, ann.x2, this.clX);
    const y = this.ay2cy(container, ann.y1, this.clY);
    container.ctx.beginPath();
    container.ctx.strokeStyle = this.selColor(ann);
    container.ctx.fillStyle = '#FFFFFF';
    container.ctx.lineWidth = 1;
    if (this.isCloseTo(container, mouseCoord, ann.x2, ann.y1, this.clX, this.clY)) {
      container.ctx.lineWidth = 2;
    }
    container.ctx.fillRect(x - this.htSize, y - this.htSize, this.tSize, this.tSize);
    container.ctx.rect(x - this.htSize, y - this.htSize, this.tSize, this.tSize);
    container.ctx.moveTo(x - this.htSize, y - this.htSize);
    container.ctx.lineTo(x + this.htSize, y + this.htSize);
    container.ctx.moveTo(x - this.htSize, y + this.htSize);
    container.ctx.lineTo(x + this.htSize, y - this.htSize);
    container.ctx.stroke();
  }

  private drawDragButton(container: AnnotationContainer, ann: Annotation, aX, aY, mouseCoord) {
    container.ctx.beginPath();
    container.ctx.lineWidth = 1;
    container.ctx.strokeStyle = this.selColor(ann);
    container.ctx.fillStyle = this.selColor(ann);
    if (this.isCloseTo(container, mouseCoord, aX, aY, null, null)) {
      container.ctx.lineWidth = 2;
    }

    container.ctx.rect(this.ax2cx(container, aX, -this.htSize), this.ay2cy(container, aY, -this.htSize), this.tSize, this.tSize);
    container.ctx.stroke();
    container.ctx.fillRect(this.ax2cx(container, aX, -this.htSize), this.ay2cy(container, aY, -this.htSize), this.tSize, this.tSize);
  }

  private drawDragButtons(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    this.drawDragButton(container, ann, ann.x1, ann.y1, mouseCoord);
    this.drawDragButton(container, ann, ann.x1, ann.y2, mouseCoord);
    this.drawDragButton(container, ann, ann.x2, ann.y1, mouseCoord);
    this.drawDragButton(container, ann, ann.x2, ann.y2, mouseCoord);
  }


  private isHovering(container: AnnotationContainer, ann: Annotation, mouseCoord) {
    const cc = this.a2c(container, ann);

    let res = false;
    if (mouseCoord.x > cc.x1 - this.htSize + this.mvX - this.proxyOffset &&
      mouseCoord.x < cc.x2 + this.clX + this.htSize - this.proxyOffset &&
      mouseCoord.y > cc.y1 - this.htSize - this.proxyOffset &&
      mouseCoord.y < cc.y2 + this.htSize - this.proxyOffset) {
      res = true;
    }
    return res;
  }

  // Return the annotation closest to the mouse
  private getClosest(container: AnnotationContainer, annotations: Array<Annotation>, mouseCoord) {
    const x = this.cx2ax(container, mouseCoord.x);
    const y = this.cy2ay(container, mouseCoord.y);
    let res = annotations[0], index, ann, dx1, dx2, dy1, dy2, dd1, dd2;
    if (annotations.length > 1) {
      for (index = 1; index < annotations.length; index++) {
        dx1 = this.middleDiffX(res, x);
        dy1 = this.middleDiffY(res, y);
        ann = annotations[index];
        dx2 = this.middleDiffX(ann, x);
        dy2 = this.middleDiffY(ann, y);
        if (dx2 > dx1 && dy2 > dy1) {
          // Keep previous as closest
        } else if (dx2 < dx1 && dy2 < dy1) {
          res = ann;
        } else {
          dd1 = dx1 + dy1;
          dd2 = dx2 + dx2;
          if (dd2 < dd1) {
            res = ann;
          }
        }
      }
    }
    return res;
  }

  private middleDiffX(ann: Annotation, x) {
    const delta = Math.abs(ann.x2 - ann.x1);
    const middle = ann.x1 + delta / 2;
    return Math.abs(x - middle);
  }

  private middleDiffY(ann: Annotation, y) {
    const delta = Math.abs(ann.y2 - ann.y1);
    const middle = ann.y1 + delta / 2;
    return Math.abs(y - middle);
  }

  // Check whether a coordinate is close to another size,
  // based on "thumb size"
  private isCloseTo(container: AnnotationContainer, mouseCoord, aX, aY, oX?, oY?) {
    let cX, cY, res = false;
    if (mouseCoord) {
      cX = this.ax2cx(container, aX, null);
      cY = this.ay2cy(container, aY, null);
      if (oX) {
        cX += oX;
      }
      if (oY) {
        cY += oY;
      }
      if (mouseCoord.x >= cX - this.htSize - this.proxyOffset && mouseCoord.x < cX + this.htSize - this.proxyOffset &&
        mouseCoord.y >= cY - this.htSize - this.proxyOffset && mouseCoord.y < cY + this.htSize - this.proxyOffset) {
        res = true;
      }
    }
    return res;
  }

}
