<div class="searchHeader">
  <div class="searchHeader-fixed">

    <app-side-menu-toggle
      *ngIf="mediumScreen"
      [sideMenuSmallScreen]="sideMenuSmallScreen"
      (filterSideMenuSmallScreenToggled)="toggleSideFilterMenuSmallScreen()"
      [searchContainer]="searchContainer"
    ></app-side-menu-toggle>

    <div class="searchHeader__search-query">
      <app-search-query
        class="searchHeader__search-query__content"
        *ngIf="searchContainer"
        [searchContainer]="searchContainer"
        [placeholder]="searchPlaceholder"
        [setFocus]="setSearchQueryFocus"
        [newLayout]="newLayout"
        [noBorder]="true"
      ></app-search-query>

      <div class="searchHeader__search-query__template-button-container">
        <div class="searchHeader__search-query__template-button-content">
          <app-template-group-select-button
            [object]="searchContainer.object"
            [collapseOnSmallScreen]="true"
            [templateGroupId]="searchContainer.templateGroupId"
            (templateSelectorCreated)="onTemplateSelectorCreated($event)"
            (changeTemplateGroup)="onChangeTemplateGroup($event)"
          ></app-template-group-select-button>
        </div>
      </div>
    </div>
  </div>

  <app-search-view-selector
    *ngIf="isSearchViewSelectorEnabled()"
    [selectorContainer]="curSelectorContainer"
  ></app-search-view-selector>

</div>
<div class="overlay"
     *ngIf="isSearchViewSelectorEnabled()"
     (click)="selectorOverlay()"></div>
