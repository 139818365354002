import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  SearchFocusEditDialogComponent,
  SearchFocusEditDialogData
} from '../search-focus-edit-dialog/search-focus-edit-dialog.component';
import {FocusServiceImplementation} from '../../search-focus.service';
import {CommonsService} from '../../../core/commons.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SearchHandlerService} from '../../search-handler.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {Focus} from '../../../core/definitions/focus';

@Component({
  selector: 'app-search-focus-menu-panel',
  templateUrl: './search-focus-menu-panel.component.html',
  styleUrls: ['./search-focus-menu-panel.component.scss']
})
export class SearchFocusMenuPanelComponent implements OnInit {

  constructor(private commons: CommonsService,
              private modalService: MatDialog,
              private searchHandler: SearchHandlerService) { }

  @Input() searchContainer: SearchContainer;
  @Input() fsi: FocusServiceImplementation;
  @Output() closeFocusPanel = new EventEmitter<boolean>();

  dialogOpen = false;
  displayFocus: Array<Focus> = [];
  showSelectFocusPanel = false;
  searchQuery: string;
  storeMessage: string;

  ngOnInit(): void {
    if (!this.fsi.getCurrentFocus()) {
      this.setCurrentFocus(this.fsi.createNewFocus());
    }
    if (this.fsi.getFocuses()) {
      this.getSetCurrentFocus();
    }
    this.updateDisplayFocus(null);
    if (this.displayFocus.length > 0) {
      this.showSelectFocusPanel = true;
    }
    this.toggleSettingMenu();
  }

  getFocuses(): Focus[] {
    return this.fsi.getFocuses();
  }

  hasChanges() {
    return this.fsi.focusHasChanges(this.searchContainer.focus.curFocus);
  }

  private getSetCurrentFocus() {
    this.setCurrentFocus(this.fsi.getCurrentFocus());
  }

  private setCurrentFocus(curFocus: Focus) {
    this.fsi.setCurrentFocus(curFocus ? curFocus.focusId : null);
    if (curFocus && this.searchQuery !== undefined) {
      this.searchQuery = curFocus.focus.name;
    }
    this.searchContainer.focus.curFocus = this.commons.copy(curFocus);
  }

  toggleSelectFocusPanel() {
    this.showSelectFocusPanel = !this.showSelectFocusPanel;
    if (this.showSelectFocusPanel) {
      if (!this.displayFocus) {
        if (this.searchQuery === undefined) {
          this.clearSearchInput();
        }
        this.updateDisplayFocus(this.searchQuery);
      }
    }
    this.toggleSettingMenu();
  }

  async setFocusAsNewSearch() {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.dialogOpen = true;
    const dialog = this.modalService.open(SearchFocusEditDialogComponent, {
      panelClass: 'edit-dialog',
      data: {
        fsi: this.fsi,
        focusInEdit: this.searchContainer.focus.curFocus
      } as SearchFocusEditDialogData
    });
    const focusNameQuery = await dialog.afterClosed().toPromise();

    this.dialogOpen = false;
    if (focusNameQuery) {
      if (this.searchContainer.focus.curFocus.focus.name === '' && focusNameQuery) {
        this.searchContainer.focus.curFocus.focus.name = focusNameQuery;
      }
      try {
        let savedFocus: Focus;
        if (this.searchContainer.focus.curFocus.focus.stored) {
          savedFocus = await this.fsi.storeFocusAsNewFocus(this.searchContainer.focus.curFocus, focusNameQuery);
        } else {
          savedFocus = await this.fsi.createOrUpdateFocus(this.searchContainer.focus.curFocus);
        }
        if (savedFocus) {
          this.setCurrentFocus(savedFocus);
        }
        this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_STORED');
        this.updateDisplayFocus(null);
        await this.selectTheFocus(this.fsi.getCurrentFocus());
      } catch (e) {
        console.error(e);
        this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
      }
      dialog.close();
      this.closeFocusPanel.emit();
    }
  }

  async editFocus(focus: Focus) {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.dialogOpen = true;
    const dialog = this.modalService.open(SearchFocusEditDialogComponent, {
      panelClass: 'edit-dialog',
      data: {
        fsi: this.fsi,
        originalFocus: focus.focus.isDefault,
        focusInEdit: focus,
        focusNameQuery: focus.focus.name
      } as SearchFocusEditDialogData
    });
    const focusNameQuery = await dialog.afterClosed().toPromise();

    this.dialogOpen = false;
    if (focusNameQuery) {
      if (focus.focus.stored) {
        focus.focus.name = focusNameQuery;
        try {
          await this.fsi.createOrUpdateFocus(focus);
          this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_CHANGES_STORED');
          this.updateDisplayFocus(null);
        } catch (e) {
          console.error(e);
          this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
        }
      }
      this.closeFocusPanel.emit();
    }
  }

  getFocusNameWeighedWordBreak(focus: Focus) {
    return this.commons.applyWordBreakOpportunity(focus.focus.$$optionNameWeighed);
  }

  async storeFocusChanges() {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    try {
      await this.fsi.createOrUpdateFocus(this.searchContainer.focus.curFocus);
      this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__FOCUS_CHANGES_STORED');
    } catch (e) {
      console.error(e);
      this.setStoreMessage('TRANS__FOCUS__STORE_MESSAGE__NAME_EXISTS');
    }
  }

  clearSearchInput() {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.searchQuery = '';
    this.updateDisplayFocus(this.searchQuery);
  }

  async delete(focus: Focus) {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    const focusId = focus.focusId;
    await this.fsi.deleteFocus(focusId);
    this.updateDisplayFocus(null);
    this.closeFocusPanel.emit();
    if (focusId === this.searchContainer.focus.curFocusId) {
      this.searchContainer.focus.curFocusId = null;
    }
    if (this.searchContainer.focus.curFocusId === null) {
      this.searchContainer.focus.curFocus = null;
      this.searchContainer.focus.currentFocusName = null;
      await this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
    }
  }

  async selectFocus(focus: Focus) {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    if (focus) {
      this.setStoreMessage(null);
      await this.selectTheFocus(focus);
      if (!this.searchQuery) {
        this.searchQuery = focus.focus.name;
      }
      this.inputChange();
      this.closeFocusPanel.emit();
    }
  }

  private setSelectedFocus() {
    for (let displayFocusIdx = 0 ; displayFocusIdx < this.displayFocus.length ; displayFocusIdx++) {
      const displayFocus = this.displayFocus[displayFocusIdx];
      displayFocus.focus.marked = false;
      if (this.searchContainer.focus.curFocus && this.searchContainer.focus.curFocus.focusId === displayFocus.focusId) {
        this.searchContainer.focus.curFocus.focus.marked = true;
        displayFocus.focus.marked = true;
        const focuses = this.getFocuses();
        for (let focusIdx = 0 ; focusIdx < focuses.length ; focusIdx++) {
          const focus = focuses[focusIdx];
          if (focusIdx === displayFocusIdx) {
            focus.focus.marked = true;
          }
        }
      }
    }
  }

  private async selectTheFocus(focus: Focus) {
    this.setCurrentFocus(focus);
    await this.searchHandler.goPathView(focus.focus.homePath, this.searchContainer);
  }

  // clickInputField() {
  //   this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  // }

  inputChange() {
    this.updateDisplayFocus(this.searchQuery);
  }

  private updateDisplayFocus(query) {
    // Make copy of copies due to weight method setting properties on focuses that causes focuses to be set as changed
    const focusesCopy: Array<Focus> = this.commons.copy(this.getFocuses());
    this.displayFocus = this.getWeightedAndOrderedOptions(focusesCopy, query);
    this.setSelectedFocus();
  }

  private getWeightedAndOrderedOptions(options: Array<Focus>, query) {
    let res;
    const wOps = this.getWeightedOptions(options, query);
    res = query ? this.commons.sortArray(wOps, '$$weight') : wOps;
    return res;
  }

  private getWeightedOptions(options: Array<Focus>, query) {
    const res = [];
    options.forEach((option) => {
      const oWeight = this.weightOption(option, query);
      option.focus.$$optionNameWeighed = oWeight.name;
      option.focus.$$weight = oWeight.weight;
      if (!query || oWeight.weight < 9) {
        res.push(option);
      }
    });
    return res;
  }

  private weightOption(option: Focus, query) {
    let optionName, start, middle, end, endPos, qPos,
      startPos = 0, weight = 9, find;
    optionName = option.focus.name;
    if (query) {
      find = query.toLocaleUpperCase();
      do {
        qPos = optionName.toLocaleUpperCase().indexOf(find, startPos);
        if (qPos !== -1) {
          weight -= qPos === 0 ? 2 : 1;
          endPos = qPos + find.length;
          start = optionName.substring(0, qPos);
          middle = optionName.substring(qPos, endPos);
          end = optionName.substring(endPos);
          optionName = start + '<strong>' + middle +
            '</strong>';
          startPos = optionName.length;
          optionName += end;
        }
      } while (qPos !== -1);
    }
    return {
      name: optionName,
      weight: weight
    };
  }

  private setStoreMessage(message) {
    this.storeMessage = message;
    setTimeout(() => {
      this.setStoreMessage(null);
    }, 5000);
  }

  toggleSettingMenu(focus?: Focus) {
    // this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
    this.displayFocus.forEach(fo => {
      if (focus) {
        if (fo.focusId === focus.focusId) {
          fo.focus.settingMenu = !fo.focus.settingMenu;
        } else {
          fo.focus.settingMenu = false;
        }
      } else {
        fo.focus.settingMenu = false;
      }
      if (fo.focusId === this.searchContainer.focus.curFocusId) {
        this.searchContainer.focus.curFocus.focus.settingMenu = fo.focus.settingMenu;
      }
    });
  }
  closeMenu() {
    // this.showFocusPanel = false;
    this.toggleSettingMenu();
    this.closeFocusPanel.emit();
  }
}
