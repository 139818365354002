import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {FieldValueService} from '../../core/field-value.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {AbstractControl, ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {OperationService} from '../../operations/operation.service';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-edit-field-search-selector',
  templateUrl: './edit-field-search-selector.component.html',
  styleUrls: ['./edit-field-search-selector.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSearchSelectorComponent)
    }
  ]
})
export class EditFieldSearchSelectorComponent implements OnInit, ControlValueAccessor {
  @Input() fieldParameters: FieldParameters;
  @Input() formControlName;

  mapText = '';


  constructor(private searchSelectorService: SearchSelectorService,
              private fieldValueSvc: FieldValueService,
              private operationService: OperationService,
              private searchReferenceService: SearchReferenceService) { }

  ngOnInit() {
    this.setTextValue();

  }

  async clickEnableSelector() {
    const reference = await this.searchReferenceService.getSearchReferenceFromField(this.fieldParameters.field);
    const selector = reference.selector;
    const container = this.operationService.currentOperationContainer;
    const selected = this.getSelectedValues();
    const params = {
      selected: selected,
      used: this.getUsedValues(selected, selector.item_removed_property)
    } as SelectorCreationParams;
    // TODO: Add template group id to enableSelector call
    this.searchSelectorService.enableSelector(selector, container, params, {
      searchContainerCreated: (/*sc*/) => {
      },
      selectorCallback: (selectedObj: Array<SuperObjectModel>) => {
        this.searchSelectorService.disableSelector(container);
        const artifactId = selectedObj[0].artifact_id;
        const artifactName = selectedObj[0].artifact_name;
        this.fieldValueSvc.setFieldValue(this.fieldParameters.object, this.fieldParameters.field.name, artifactId);
        this.fieldValueSvc.setFieldValue(this.fieldParameters.object, this.fieldParameters.field.name, artifactName, true);
        this.setTextValue();
        const control: AbstractControl = this.fieldParameters.sectionsContainer.formGroup.controls[this.formControlName];
        if (control) {
          control.setValue(artifactName);
          control.markAsDirty();
        } else {
          console.error('Control not found: ' + this.formControlName);
        }
      }
    });
  }


  private setTextValue() {
    this.fieldValueSvc.getMappedFieldValue(this.fieldParameters.field, this.fieldParameters.object).then(
      (mapText) => {
        this.mapText = mapText;
      });
  }

  private getUsedValues(selected, itemRemovedProperty) {
    let parentArray = this.fieldParameters.sectionsContainer.rootObject[this.fieldParameters.field.parent_name];
    const res = [];
    if (parentArray) {
      if (!Array.isArray(parentArray)) {
        parentArray = [parentArray];
      }
      for (const item of parentArray) {
        const value = item[this.fieldParameters.field.name];
        if (value && selected.indexOf(value) === -1 && (!itemRemovedProperty || !item[itemRemovedProperty])) {
          res.push(value);
        }
      }
    }
    res.push(this.fieldParameters.sectionsContainer.rootObject.artifact_id);
    return res;
  }

  private getSelectedValues() {
    const res = [];
    const value = this.fieldValueSvc.getFieldValue(this.fieldParameters.object, this.fieldParameters.field.name);
    if (value) {
      res.push(value);
    }
    return res;
  }

  registerOnChange(/*fn: any*/): void {
    // N/A
  }

  registerOnTouched(/*fn: any*/): void {
    // N/A
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // N/A
  }

  writeValue(/*obj: any*/): void {
    // N/A
  }


}
