<button mat-stroked-button
        [matMenuTriggerFor]="actionMenu"
        [disabled]="!operationContainer?.operations?.length"
        type="button"
        class="admin-stroked-button">
  <mat-icon>more_horiz</mat-icon>
  <span class="bold uppercase admin-stroked-button__left-margin"
        translate="TRANS__ADMIN_CONCEPT__ACTION_MENU__TITLE"></span>
</button>

<mat-menu #actionMenu="matMenu"
          xPosition="before"
          class="removePaddingInMatMenu admin-action-list-menu">
  <ng-container *ngIf="operationContainer?.operations?.length">
    <ng-container *ngFor="let subOperation of operationContainer.operations[0].sub_operations">
      <div [matTooltip]="subOperation.$$toolTip" [matTooltipDisabled]="!subOperation.$$disabled">
        <button mat-menu-item
                (click)="openOperation(subOperation)"
                [disabled]="subOperation.$$disabled"
                class="admin-action-list-menu__button">
          <mat-icon *ngIf="subOperation.menu_icon && !subOperation.menu_icon.startsWith('icon-')">{{subOperation.menu_icon}}</mat-icon>
          <div *ngIf="subOperation.menu_icon && subOperation.menu_icon.startsWith('icon-')"
               class="admin-action-list-menu__icon">
            <i [ngClass]="subOperation.menu_icon"></i></div>
          <span [translate]="subOperation.menu_title"></span>
        </button>
      </div>
    </ng-container>
  </ng-container>
  <!-- Displaying the old type 'join concepts' menu -->
  <button mat-menu-item *ngFor="let conceptMenu of menus"
          (click)="conceptMenu.action()"
          [disabled]="conceptMenu.disabled()">
    <mat-icon>{{conceptMenu.icon}}</mat-icon>
    <span [translate]="conceptMenu.title"></span>
  </button>
</mat-menu>
