import {Injectable} from '@angular/core';
import {UndoHandlerService} from './undo-handler.service';
import {DateToolsService} from './date-tools.service';
import {FieldMetaHandlerService, GetMetaFieldParams} from './field-meta-handler.service';
import {AConst} from './a-const.enum';
import {ModelsService} from './models.service';
import {CommonsService} from './commons.service';
import {MetaField} from './definitions/meta-field';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {UserCacheService} from './user-cache.service';
import {LoggerService} from './logger.service';
import {FieldDateInfoService} from "./field-date-info.service";

@Injectable({
  providedIn: 'root'
})
export class ModelFactoryService {

  constructor(
    private logger: LoggerService,
    private undoHandler: UndoHandlerService,
    private dateTools: DateToolsService,
    private fieldMetaHandler: FieldMetaHandlerService,
    private models: ModelsService,
    private commons: CommonsService,
    private userCacheService: UserCacheService,
    private fieldDateInfoService: FieldDateInfoService) {
  }

  userData: UserData;

  private static isArrayItemDeleted(item) {
    return item._destroy;
  }

  // TODO: Set to receive fieldContainer
  public getOrigVal(object, fieldName) {
    return object.$$orig && object.$$orig[fieldName];
  }

  public getInlineModel(metaField: MetaField) {
    if (!metaField) {
      throw new Error('Meta data not set!');
    }
    return metaField.inline ? metaField.inline.model : null;
  }

  public async setModelItemAsync(modelName, data?: BaseModel): Promise<BaseModel> {
    const models = await this.models.getModelsAsync();
    await this.checkSetUserData();
    return this.setModelItem(modelName, data, models);
  }

  public createModelItem(modelName, data?): BaseModel {
    this.checkSetUserData().then();
    const item = this.setModelItem(modelName, data);
    item._create = true;
    return item;
  }

  public async createModelItemAsync(modelName, data?: BaseModel): Promise<BaseModel> {
    const item = await this.setModelItemAsync(modelName, data);
    item['_create'] = true;
    return item;
  }

  public createAddArrayItem(rootModel, arr, modelName,
                            data?: BaseModel) {
    const item = this.createModelItem(modelName, data);
    this.addArrayItem(arr, item);
    return item;
  }

  public async createAddArrayItemAsync(arr: any[], modelName, data): Promise<BaseModel> {
    const item = await this.createModelItemAsync(modelName, data);
    this.addArrayItem(arr, item);
    return item;
  }

  public deleteArrayItem(arr, index, rootModel) {
    const item = arr[index];
    const modelIdField = this.getModelIdField(rootModel);
    if (item._create || (modelIdField && !rootModel[modelIdField])) {
      arr.splice(index, 1);
      this.undoHandler.addUndo(arr, item, index);
    } else {
      item._destroy = true;
      this.undoHandler.addUndo(arr, item);
    }
    return item;
  }

  public undoDeleteArrayItem(arr) {
    this.undoHandler.undo(arr);
  }

  // For-each loop that ignores destroyed array elements
  public forEach(arr, fn) {
    arr.forEach((item, index) => {
      if (!ModelFactoryService.isArrayItemDeleted(item)) {
        fn(item, index);
      }
    });
  }

  // Count array elements that have not been deleted
  public countArrayElements(arr) {
    let res = 0;
    arr.forEach(item => {
      if (!ModelFactoryService.isArrayItemDeleted(item)) {
        res++;
      }
    });
    return res;
  }

  public traverseModelField(fn, model: BaseModel, fieldName) {
    const getMetaFieldParams = new GetMetaFieldParams();
    getMetaFieldParams.parentModel = model;
    getMetaFieldParams.propName = fieldName;
    getMetaFieldParams.noThrow = true;
    const metaField: MetaField = this.fieldMetaHandler.getMetaField(getMetaFieldParams);
    const subMod = model[fieldName];
    let show;
    if (metaField) {
      show = metaField.display;
    }
    if (metaField && (metaField.edit || show)) {
      fn(model, fieldName);
      if (this.getInlineModel(metaField)) {
        if (Array.isArray(subMod)) {
          subMod.forEach(
            (item, index) => {
              fn(model, fieldName, index);
              this.traverseModel(fn, item);
            });
        } else {
          this.traverseModel(fn, subMod);
        }
      }
    }
  }

  private setInlineDefaultValues(defValue, modelData) {
    for (const key in defValue) {
      if (!defValue.hasOwnProperty(key)) {
        continue;
      }
      const propValInfo = this.getPropVal(defValue, key);
      modelData[key] = propValInfo.value;
    }
  }

  // Set properties defined in model data but missing in model.
  // This is usually properties not to be stored, like $$arefs
  // and $$acl
  private setMissingDataProps(modelName, item: BaseModel, data: object,
                                     setProps) {
    if (data && typeof data !== 'object') {
      throw new Error('Data: ' + data + ' is not an object of model ' +
        'type ' + modelName);
    }
    for (const propName in data) {
      if (!data.hasOwnProperty(propName)) {
        continue;
      }
      const val = data[propName];
      if (val !== undefined && !setProps[propName]) {
        if (propName !== 'meta_type' &&
          propName.indexOf('$$') !== 0 &&
          !item.$$meta[propName]) {
          this.logger.warn(`Property "${modelName}.${propName}" of type "${typeof val}" is not defined in Models!`);
        }
        item[propName] = val;
      }
    }
  }

  private getModelIdField(model: BaseModel) {
    const meta = model.$$meta;
    let res;
    if (meta) {
      if (meta[AConst.ARTIFACT_ID]) {
        res = AConst.ARTIFACT_ID;
      } else {
        this.logger.info('No model id field found for object type ' + model.object_type);
      }
    }
    return res;
  }

  private setModelItemProps(modelItem: BaseModel, data, models) {
    const propsSet = {};
    for (const propName in modelItem) {
      if (!modelItem.hasOwnProperty(propName)) {
        continue;
      }
      const propValInfo = this.getPropVal(modelItem, propName, data);
      let propVal = propValInfo.value;
      const hadData = propValInfo.hadData;
      let inlineMod, origVal;
      if (propName.indexOf('$$') !== 0) {
        const getMetaFieldParams = new GetMetaFieldParams();
        getMetaFieldParams.parentModel = modelItem;
        getMetaFieldParams.propName = propName;
        getMetaFieldParams.noThrow = true;
        const metaData = this.fieldMetaHandler.getMetaField(getMetaFieldParams);
        if (metaData) {
          inlineMod = this.getInlineModel(metaData);
          if (inlineMod) {
            propVal = this.createSubModelItem(propVal, metaData,
              hadData, models);
          }
          const dateInfo = this.fieldDateInfoService.getFieldDateInfo(metaData);
          if (dateInfo?.today_date && !propVal) {
            propVal = this.dateTools.getTodayUtcTime();
          }
          origVal = this.findOrigVal(metaData, propVal);
          if (origVal !== undefined) {
            modelItem['$$orig'] = modelItem['$$orig'] || {};
            modelItem['$$orig'][propName] = origVal;
          }
        }
        modelItem[propName] = propVal;
        propsSet[propName] = true;
      }
    }
    return propsSet;
  }

  private getPropVal(item, propName: string, data?) {
    const res = {value: null, hadData: false};
    if (!propName.indexOf('$$') && propName !== '$$meta') {
      return res;
    }
    if (data) {
      res.value = data[propName];
      res.hadData = res.value !== null && res.value !== undefined;
    }
    res.value = !res.hadData ? item[propName] : res.value;
    if (typeof res.value === 'string' && res.value.indexOf('user.') === 0) {
      if (res.value === 'user.main_collection_id') {
        if (this.userData) {
          if (propName.indexOf('_value') === -1) {
            res.value = this.userData.main_collection_id;
          } else {
            res.value = this.userData.main_collection_id_value;
          }
        } else {
          this.logger.warn('User data not obtained yet!');
        }
      }
      if (res.value === 'user.artifact_id') {
        if (this.userData) {
          if (propName.indexOf('_value') === -1) {
            res.value = this.userData.artifact_id;
          } else {
            res.value = this.userData.name;
          }
        } else {
          this.logger.warn('User data not obtained yet!');
        }
      }
    }
    return res;
  }

  private async checkSetUserData() {
    this.userData = await this.userCacheService.getUserData();
  }

  // Set sub model items belonging to array or inline objects
  // of a parent model item
  private createSubModelItem(origVal, metaField: MetaField, hadData, models) {
    let arr, propVal = origVal;
    const primitives = ['string', 'numeric', 'decimal',
      'boolean', 'text'];
    const inlineMod = this.getInlineModel(metaField);
    models = models || this.models.getModels(false);
    if (metaField.field_type === 'inline') {
      if (!hadData) { // Get default value from sub model
        propVal = this.commons.copy(models[inlineMod]);
        this.setInlineDefaultValues(origVal, propVal);
      }
      propVal = this.setModelItem(inlineMod, propVal, models);
    } else if (metaField.field_type === 'array') {
      arr = [];
      if (propVal) {
        propVal.forEach((arrItem) => {
          if (primitives.indexOf(inlineMod) !== -1) {
            arr.push(arrItem);
          } else {
            arr.push(this.setModelItem(inlineMod,
              arrItem, models));
          }
        });
      }
      propVal = arr;
    }
    return propVal;
  }

  // Recursively populate a model with data. "Data" can either be
  // data from server or default values defined in model
  private setModelItem(modelName, data?: BaseModel, models?: { [name: string]: BaseModel }): BaseModel {
    let propsSet = {};
    let modelItem = new BaseModel();
    modelItem._create = false;
    models = models || this.models.getModels(false);
    if (models !== null && modelName in models) {
      modelItem = this.commons.copy(models[modelName]);
      propsSet = this.setModelItemProps(modelItem, data, models);
      this.setMissingDataProps(modelName, modelItem, data,
        propsSet);
    } else {
      this.logger.warn('Unknown model \'' + modelName + '\'');
    }
    return modelItem;
  }

  private addArrayItem(arr, item: BaseModel) {
    arr.push(item);
    this.undoHandler.resetUndo(arr);
    this.checkSetOrderNumber(arr, item);
  }

  private checkSetOrderNumber(arr, item) {
    let lastOrder = -1;
    const orderNumbers = {};
    if (!item.order_number) {
      arr.forEach((i) => {
        if (i.order_number !== undefined && i.order_number !== null) {
          if (orderNumbers[i.order_number]) {
            this.logger.warn('Order number already existed: ' + i.order_number);
            i.order_number++;
          }
          lastOrder =
            Math.max(i.order_number, lastOrder);
          orderNumbers[i.order_number] = true;
        }
      });
      item.order_number = lastOrder + 1;
    }
  }

  private findOrigVal(metaData: MetaField, propVal) {
    let t, val, arrVal;
    const arr = [];
    const inlineMod = this.getInlineModel(metaData);
    const show = metaData.display;
    if ((metaData.edit && metaData.edit.indexOf('edit') === 0) ||
      show) {
      val = propVal;
      if (inlineMod) {
        if (Array.isArray(propVal)) {
          for (t = 0; t < propVal.length; t++) {
            arrVal = propVal[t].order_number || t;
            arr.push(arrVal);
          }
          val = arr;
        }
      }
    }
    return this.commons.copy(val);
  }

  /**
   * Traversing an object and executing a callback every time
   * an editable or displayable field is reached
   * @param fn the callback receives the parameters model, field
   * name and, if the model is an array, an index for each item
   * in the array
   *
   * @param model the model to traverse
   */
  private traverseModel(fn, model: BaseModel) {
    for (const fieldName in model) {
      if (!model.hasOwnProperty(fieldName)) {
        continue;
      }
      if (fieldName.indexOf('$$') !== 0) {
        this.traverseModelField(fn, model, fieldName);
      }

    }
  }
}
