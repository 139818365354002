import {Component, OnInit, ViewChild} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {AdminUsersService} from '../../admin-users/admin-users.service';
import {
  AdminLocationPlaceFormModalComponent,
  AdminLocationFormModalData
} from '../admin-location-place-form-modal/admin-location-place-form-modal.component';
import {AdminLocationPlaceService} from '../admin-location-place.service';
import {
  ColumnLabels, PrimusSolrDataTableComponent,
  RefreshDataFn,
  RowTransformFn
} from '../../../shared/primus-tables/primus-solr-data-table/primus-solr-data-table.component';
import {TreeNode} from '../../../shared/primus-solr-tree-view/tree-node/tree-node.component';

export interface LocationRow {
  id: string;
  parentId: string;
  name: string;
  description: string;
  code: string;
  type: string;
  valid: boolean;
  createdAt: string;
}

@Component({
  selector: 'app-admin-location',
  templateUrl: './admin-location.component.html',
  styleUrls: ['./admin-location.component.scss']
})
export class AdminLocationComponent implements OnInit {

  @ViewChild(PrimusSolrDataTableComponent) tableComponent: PrimusSolrDataTableComponent;

  readonly topNodeLabel: string = 'TRANS__ADMIN__LOCATION__TOP_NODE_LABEL';

  readonly columnLabels: ColumnLabels = {
    location_type_id_value: 'TRANS__ADMIN__LOCATION__TABLE_COLUMN__TYPE',
    full_code_path: 'TRANS__ADMIN__LOCATION__TABLE_COLUMN__CODE',
    'name.name': 'TRANS__ADMIN__LOCATION__TABLE_COLUMN__LOCATION',
    code: 'TRANS__FIELD__LOCATION_CODE',
  };

  readonly columns: Array<string> = [
    'location_type_id_value',
    'full_code_path',
    'name.name',
    'code',
  ];

  readonly rowTransformer: RowTransformFn = (
    (value, row, col) => col === 'valid' ? !value : value
  );

  readonly parentIdProp: string = 'parent_id';
  readonly parentLabelProp: string = 'name.name';
  readonly baseQuery = 'object_type:Location';
  filterQuery?: string;
  currentFilterString = '';
  currentQuery: string;

  selectedLocations: string[];

  parentNavigationView: unknown;

  selectedParentNode: TreeNode | null = null;

  showQRCodeButton: boolean;

  constructor(private readonly modal: MatDialog,
              private readonly usersService: AdminUsersService,
              private locationService: AdminLocationPlaceService) {
    this.updateQuery();
  }

  async ngOnInit(): Promise<void> {
    this.selectedLocations = [];
    this.parentNavigationView = {
      path: 'admin',
      adminType: 'location',
      hasSubMenus: true,
      target_params: {admin_type: 'location'},
      currentState: 'locations'
    };
  }

  handleSelectionChanged(event) {
    this.selectedLocations = event;
    this.showQRCodeButton = this.selectedLocations.length > 0;
  }

  async handleEditOrCreateLocationClicked(refreshData: RefreshDataFn, locationId?: string): Promise<void> {
    const location = await this.modal.open(AdminLocationPlaceFormModalComponent, {
      panelClass: 'edit-dialog',
      data: {
        id: locationId,
        objectType: 'Location',
        parentId: this.selectedParentNode?.id,
        headingCreateNew: 'TRANS__ADMIN__LOCATION__CREATE_NEW_HEADING',
        headingEditExisting: 'TRANS__ADMIN__LOCATION__EDIT_EXISTING_HEADING',
        currentLevelName: this.selectedParentNode?.label || this.topNodeLabel,
      } as AdminLocationFormModalData
    }).afterClosed().toPromise();

    if (location) {
      await this.locationService.saveLocation(location);
      if (refreshData) {
        refreshData();
      }
    }
  }

  updateQuery(): void {
    const q = [this.baseQuery];
    if (this.selectedParentNode) {
      q.push(`${this.parentIdProp}:"${this.selectedParentNode.id}"`);
    } else {
      q.push(`-${this.parentIdProp}:*`);
    }
    if (this.filterQuery) {
      q.push(this.filterQuery);
    }
    this.currentQuery = `${q.join(' AND ')}`;
  }

  setFilterQuery(fq: string = ''): void {
    this.filterQuery = fq;
    this.updateQuery();
  }

  selectParent(node: TreeNode | null): void {
    this.selectedParentNode = node;
    this.updateQuery();
  }

  onActionPerformed() {
    this.tableComponent.dataSource.clearSelected();
    this.tableComponent.dataSource.refreshData();
  }
}
