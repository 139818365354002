<div class="dialog admin-location-form-dialog" *ngIf="model">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <ng-container *ngIf="!sectionsContainer?.rootObject?.authority_id">
        <span *ngIf="!!data && !!data.id">
          {{data.headingEditExisting | translate: {currentName: model.name?.name || ''} }}</span>
        <span  *ngIf="!!data && !data.id">
          {{data.headingCreateNew | translate: {parentName: (parentName | translate) || ''} }}</span>
      </ng-container>
      <ng-container *ngIf="sectionsContainer?.rootObject?.authority_id">
        <span>{{'TRANS__ADMIN_CONCEPT__VIEW' | translate:{conceptType: (data.objectType | translate), name: model.name?.name} }}</span>
      </ng-container>
    </h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>
  <form *ngIf="sectionsContainer?.formGroup"
        (ngSubmit)="handleSaveClicked()"
        [formGroup]="sectionsContainer.formGroup"
        class="inherit-border-color">
    <div mat-dialog-content class="modal-body modal-body-edit-fields">
      <div class="modal-main-content">

        <ng-container *ngIf="!sectionsContainer?.rootObject?.authority_id">
          <app-edit-fields
            *ngIf="primeFields"
            [sectionsContainer]="sectionsContainer"
            [object]="sectionsContainer.rootObject"
            [fields]="primeFields"
          ></app-edit-fields>
          <app-section-view-edit
            *ngIf="!primeFields"
            [sectionsContainer]="sectionsContainer"
          ></app-section-view-edit>
          </ng-container>
          <app-object-view
            *ngIf="sectionsContainer?.rootObject?.authority_id"
            [sectionsContainer]="sectionsContainer"
          ></app-object-view>

<!--      <p class="modal-row" *ngIf="isLocationChangedToInvalid() && objectCount > 0">-->
<!--        {{ 'TRANS__ADMIN__LOCATION__MODAL__OBJECT_USED_WARNING' | translate:{-->
<!--        count: objectCount,-->
<!--        names: locationUsedIn-->
<!--      }-->
<!--        }}-->
<!--      </p>-->
      </div>
    </div>

    <div mat-dialog-actions class="modal-footer">
      <div [matTooltip]="disabledReason"
           [matTooltipDisabled]="!disabled">
      <button mat-flat-button
              class="bold"
              type="submit"
              color='accent'
              [disabled]="disabled">
        {{ 'TRANS__ADMIN__LOCATION__MODAL__SAVE_BUTTON_LABEL' | translate }}
      </button>
      </div>
<!--    <button mat-button-->
<!--            *ngIf="isLocationChangedToInvalid() && objectCount > 0"-->
<!--            (click)="handleMoveObjectsToNewLocation()"-->
<!--            type="button"-->
<!--            color="warn"-->
<!--            disabled>-->
<!--      &lt;!&ndash; TODO: Implement this feature &ndash;&gt;-->
<!--      {{ 'TRANS__ADMIN__LOCATION__MODAL__MOVE_TO_LOCATION_BUTTON_LABEL' | translate }}-->
<!--    </button>-->
      <button mat-button
              color="accent"
              class="bold"
              type="button"
              (click)="dialogRef.close(false)">
        {{ 'TRANS__ADMIN__LOCATION__MODAL__CANCEL_BUTTON_LABEL' | translate }}
      </button>
      <div *ngIf="storeStatus.failed">{{storeStatus.message | translate}} {{storeStatus.serverMsg}}</div>
    </div>
  </form>
</div>
