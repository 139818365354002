<div class="faq-component">
  <app-top-header [parent]="parentInfo"
                class="admin-header-container"></app-top-header>

  <app-header-and-description heading="TRANS__FAQ__TITLE"
                              description="TRANS__FAQ__INGRESS"
                              href="support@kulturit.no">
    <ul class="content">
      <li *ngFor="let category of res" class="category">

        <a  class="category__items"
          (click)="toggleCategory(category)">
          <mat-icon [class.text-blue]="category.selected"
                    class="category__arrow">{{category.selected ? 'expand_more' : 'chevron_right'}}</mat-icon>
          <span class="category__title"
                [class.text-blue]="category.selected">{{category[AConst.CATEGORY_TITLE]}}</span>
        </a>

        <ul *ngIf="category.selected"
            class="faqs">
          <li *ngFor="let fq of category[AConst.FAQ]; let i = index"
              [attr.data-index]="i"
              class="faqs__items"
              (click)="toggleFq(fq)">
            <mat-icon [class.text-blue]="fq.selected"
                      class="faqs__arrow">{{fq.selected ? 'expand_more' : 'chevron_right'}}</mat-icon>
            <div class="faqs__vertical-line-container">
              <div *ngIf="fq.selected" class="faqs__vertical-line bg-blue"></div></div>
            <div class="faqs__content">
              <span class="faqs__question"
                    [class.text-blue]="fq.selected" [translate]="fq.question"></span>
              <div class="faqs__answer" *ngIf="fq.selected">
                <span [translate]="fq[AConst.ANSWER]"></span>
                <a *ngIf="fq.url"
                   class="link text-blue"
                   target="_blank"
                   [href]="fq.url">{{fq.url}}</a>
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
  </app-header-and-description>

</div>

