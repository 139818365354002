import {Component, OnInit, ViewChild} from '@angular/core';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {
  ColumnLabels, PrimusSolrDataTableComponent,
  RefreshDataFn,
  RowTransformFn
} from '../../../shared/primus-tables/primus-solr-data-table/primus-solr-data-table.component';
import {TreeNode} from '../../../shared/primus-solr-tree-view/tree-node/tree-node.component';
import {
  AdminLocationPlaceFormModalComponent,
  AdminLocationFormModalData
} from '../admin-location-place-form-modal/admin-location-place-form-modal.component';

// export interface PlaceRow {
//   id: string;
//   parentId: string;
//   artifact_name: string;
//   description: string;
//   code: string;
//   type: string;
//   valid: boolean;
//   createdAt: string;
// }

@Component({
  selector: 'app-admin-place',
  templateUrl: './admin-place.component.html',
  styleUrls: ['./admin-place.component.scss']
})
export class AdminPlaceComponent implements OnInit {

  @ViewChild(PrimusSolrDataTableComponent) tableComponent: PrimusSolrDataTableComponent;

  readonly topNodeLabel: string = 'TRANS__ADMIN__PLACE__TOP_NODE_LABEL';

  readonly columnLabels: ColumnLabels = {
    'name.name': 'TRANS__ADMIN__PLACE__TABLE_COLUMN__PLACE',
    place_type_id_value: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__TYPE',
    identification_number: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__ID',
    'collection.collection_id_value': 'TRANS__FIELD__COLLECTION',
    no_address: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__ADDRESS',
    valid: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__VALID',
    authority_id_value: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__AUTHORITY_DATASET',
    created_at: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__CREATED_AT',
    updated_at: 'TRANS__ADMIN__PLACE__TABLE_COLUMN__UPDATED_AT',
    'description.description': 'TRANS__ADMIN__PLACE__TABLE_COLUMN__DESCRIPTION'

  };

  readonly columns: Array<string> = [
    'name.name',
    'place_type_id_value',
    'identification_number.identification_number',
    'collection.collection_id_value',
    'no_address',
    'valid',
    'authority_id_value',
    'created_at',
    'updated_at',
    'description.description'
  ];

  readonly rowTransformer: RowTransformFn = (
    (value, row, col) => col === 'valid' ? !value : value
  );

  readonly parentIdProp: string = 'parent_id';
  readonly parentLabelProp: string = 'name.name';
  readonly baseQuery = 'object_type:place';
  filterQuery?: string;
  currentFilterString = '';
  currentQuery: string;

  parentNavigationView: unknown;

  selectedParentNode: TreeNode | null = null;
  selectedPlaces: string[];

  constructor(private readonly modal: MatDialog) {
    this.updateQuery();
  }

  async ngOnInit(): Promise<void> {
    this.parentNavigationView = {
      path: 'admin',
      adminType: 'place',
      hasSubMenus: true,
      target_params: {admin_type: 'place'},
      currentState: 'places'
    };
  }

  handleSelectionChanged(event) {
    this.selectedPlaces = event;
  }

  async handleEditOrCreatePlaceClicked(refreshData: RefreshDataFn, placeId?: string): Promise<void> {
    return new Promise((resolve, reject) => {
      const placeModal = this.modal.open(AdminLocationPlaceFormModalComponent, {
        panelClass: 'edit-dialog',
        disableClose: true,
        data: {
          id: placeId,
          objectType: 'place',
          parentId: this.selectedParentNode?.id,
          headingCreateNew: 'TRANS__ADMIN__PLACE__CREATE_NEW_HEADING',
          headingEditExisting: 'TRANS__ADMIN__PLACE__EDIT_EXISTING_HEADING',
          currentLevelName: this.selectedParentNode?.label || this.topNodeLabel,
        } as AdminLocationFormModalData
      });
      placeModal.afterClosed().subscribe(
        result => {
          if (result) {
            if (refreshData) {
              refreshData();
            }
            resolve(result);
          }
      },
          reason => {
          reject(reason);
        });
    });
  }

  updateQuery(): void {
    const q = [this.baseQuery];
    if (this.selectedParentNode) {
      q.push(`${this.parentIdProp}:"${this.selectedParentNode.id}"`);
    } else {
      q.push(`-${this.parentIdProp}:*`);
    }
    if (this.filterQuery) {
      q.push(this.filterQuery);
    }
    this.currentQuery = `${q.join(' AND ')}`;
  }

  setFilterQuery(fq: string = ''): void {
    this.filterQuery = fq;
    this.updateQuery();
  }

  selectParent(node: TreeNode | null): void {
    this.selectedParentNode = node;
    this.updateQuery();
  }

  onActionPerformed() {
    this.selectedPlaces = [];
    this.tableComponent.dataSource.clearSelected();
    this.tableComponent.dataSource.refreshData();
  }
}
