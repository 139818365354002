<div class="authentication-container normal-bg-color"
     *ngIf="!isAuthenticated()">
  <div class="authentication-container__auth-error-container">
    <i class="icon-primus text-blue" *ngIf="authErr !== ''"></i>
    <div class="authentication-container__auth-error" *ngIf="authErr !== ''">
      <mat-icon>error</mat-icon>
      <span>{{authErr}}</span>
      <button mat-button color="accent" (click)="this.redirectToLogin()">{{txtToLogin}}</button>
    </div>
  </div>

  <!-- GUI for choosing the Primus backend to connect to -->
  <app-choose-backend-api
    class="authentication-container__choose-backend normal-bg-color"
    *ngIf="showApiSelector">
  </app-choose-backend-api>

  <!-- Primus loader -->
  <div id="loader" class="authentication-container__loading-container normal-bg-color"
       *ngIf="!isAuthenticated() && !hideLoader && authErr === ''">
    <div class="authentication-container__loading">
      <i class="icon-primus text-blue"></i>
      <div class="authentication-container__spinner-container">
        <mat-spinner color="accent" diameter="50" id="awaiting-authentication"
                     class="authentication-container__spinner"></mat-spinner>
        <div><span>{{'TRANS__APP_COMPONENT__LOADING_CONTENTS' | translate}}</span>...</div>
      </div>
    </div>
  </div>
</div>
