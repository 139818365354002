<mat-expansion-panel class="no-active-blue-color edit-field-select-hierarchic-select"
                     (opened)="setExpansionPanelOpened()"
                     (closed)="setExpansionPanelClosed()"
                     [disabled]="isDisabled"
                     [ngClass]="fieldParameters.sectionsContainer.isDialog ?
     (fieldParameters.sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name">
  <mat-expansion-panel-header>
    <mat-chip-list *ngIf="items.length > 0"
                   [attr.aria-label]="fieldParameters.field.title">
      <ng-container *ngFor="let item of items">
        <mat-chip
          [removable]="true"
          (removed)="removeItem(item)">
          {{item[textField]}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </ng-container>
    </mat-chip-list>
    <mat-panel-title *ngIf="items.length === 0">
      <span translate="TRANS__HIERARCHIC_SELECT__HEADER"></span>&nbsp;
      <span class="lowercase"
            [translate]="fieldParameters.field.title"></span></mat-panel-title>
  </mat-expansion-panel-header>
  <mat-tab-group (selectedTabChange)="onSearchPanelTabChange($event)" [(selectedIndex)]="selectedSearchViewIndex">
    <mat-tab [label]="'TRANS__HIERARCHIC_SELECT__TAB__SEARCH' | translate">
      <app-hierarchic-search-panel
        #searchPanel
        class="small-mat-form-outline"
        [fieldParameters]="fieldParameters"
        [reference]="reference"
        [query]="query"
        [searchPanelOpen]="searchPanelOpen"
        (nodeSelected)="onNodeSelected($event)"
        (nodeOpened)="onNodeOpened($event)"
      ></app-hierarchic-search-panel>
    </mat-tab>
    <mat-tab [label]="'TRANS__HIERARCHIC_SELECT__TAB__LIST' | translate">
      <app-hierarchic-list-panel
        #listPanel
        [fieldParameters]="fieldParameters"
        [reference]="reference"
        [panelHasBeenOpened]="panelHasBeenOpened"
        (nodeSelected)="onNodeSelected($event)"
        (nodeOpened)="onNodeOpened($event)"
      ></app-hierarchic-list-panel>
    </mat-tab>
  </mat-tab-group>
  <app-hierarchic-create-option-menu
    *ngIf="panelHasBeenOpened"
    [fieldParameters]="fieldParameters"
    [reference]="reference"
    [query]="query"
    [isArray]="isArray"
    [isSingleItemArray]="isSingleItemArray"
    [currentOpenNode]="currentOpenNode"
    (createOptionEvent)="onCreateOption($event)">
  </app-hierarchic-create-option-menu>
</mat-expansion-panel>
