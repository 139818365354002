<div class="object-new-page" id="scrollToThisFromCopyCallback" *ngIf="operationContainer">
  <!--<search-header search-container="searchContainer"></search-header>-->
  <div class="object-new-page__container" id="innerCon">

    <div class="object-new-page__top">

      <div class="object-new-page__top-content">
        <h1>
          <app-object-icon
            class="object-new-page__top-icon"
            *ngIf="objectTypeName"
            [objectType]="objectTypeName"></app-object-icon>
          <span translate="TRANS__MODAL__CREATE"></span>
          <span>:</span>
          <span class="object-new-page__top-object"
                [translate]="newObjectText"></span>
        </h1>
      </div>

      <div class="object-new-page__copy">
          <button *ngIf="object.create_multiple !== undefined"
                  mat-stroked-button
                  class="object-new-page__create-multiple-button"
                  color="primary"
                  type="button"
                  [class.active]="createMultiple"
                  (click)="enableCreateMultiple()">
            <i class="icon-create-new"></i>
            <span class="bold uppercase"
                  translate="TRANS__OBJECT_NEW__CREATE_MULTIPLE_BUTTON"></span></button>

          <ng-container *ngIf="newModelInfo">
            <ng-container *ngFor="let selector of newModelInfo.selectors">
              <button mat-stroked-button
                      class="object-new-page__copy-button"
                      color="primary"
                      type="button"
                      [disabled]="isCopy"
                      (click)="enableSelector(selector)">
                <i class="icon-copy"></i>
                <span class="bold uppercase"
                      [translate]="selector.selector_button_text"></span></button>
            </ng-container>
          </ng-container>

          <div *ngIf="createMultiple" class="object-new-page__create-multiple">
            <mat-form-field appearance="outline">
              <mat-label translate="TRANS__OBJECT_NEW__MULTIPLE_OBJECT_COUNT"></mat-label>
              <input matInput type="number"
                     [(ngModel)]="createMultipleCount"
                     [min]="1"
                     [max]="maxCreateCount"
                     (change)="setMultipleCount()">
            </mat-form-field>

            <ng-container *ngIf="createMultipleOperationContainer?.operations?.length">
              <button mat-stroked-button
                      class="object-new-page__create-folder-button"
                      color="primary"
                      type="button"
                      *ngFor="let operation of createMultipleOperationContainer.operations"
                      [disabled]="!!createMultipleFolder"
                      (click)="openMultipleCreationOperation(operation)">
                <i class="{{operation.menu_icon}}"></i>
                <span class="bold uppercase"
                      [translate]="operation.menu_title"></span>
              </button>
            </ng-container>

            <span *ngIf="createMultipleFolder" class="bold object-new-page__created-folder">
              <span translate="TRANS__OBJECT_NEW__CREATED_FOLDER"></span>
              <span translate="TRANS__OBJECT_NEW__TARGET_FOLDER"></span>: {{createMultipleFolder.artifact_name}}</span>
          </div>

      </div>

      <app-object-new-object-view
        *ngIf="!isCopy && operationContainer?.rootSectionsContainer"
        [selectorContainer]="selectorContainer"
        [sectionsContainer]="operationContainer.rootSectionsContainer"
      ></app-object-new-object-view>

      <app-object-new-copy-view
        *ngIf="isCopy && operationContainer?.rootSectionsContainer && selectorContainer"
        [object]="object"
        [createMultiple]="createMultiple"
        [selectorContainer]="selectorContainer"
        [sectionsContainer]="operationContainer.rootSectionsContainer"
      ></app-object-new-copy-view>

    </div>

    <div class="object-new-page__sidebar">
      <app-media-selector-image-vertical
        *ngIf="canSelectImages()"
        [object]="object"
        [fieldName]="'images'"
        [target]="object"
        [curAnn]="currentObjectService.curAnn"
        [multipleImages]="object.object_type !== 'user' && object.object_type !== 'actor'"
      ></app-media-selector-image-vertical>


      <app-search-view-selector
        *ngIf="selectorContainer && selectorContainer.selectorEnabled"
        [selectorContainer]="selectorContainer"
      ></app-search-view-selector>
    </div>

    <div class="row"  *ngIf="debug">
      <pre>{{object | json}}</pre>
    </div>
  </div>

  <div class="row relative" *ngIf="object">
    <app-operation-toolbar
      *ngIf="operationContainer?.currentOperation?.$$currentStep"
      [operationContainer]="operationContainer"
      [operationStep]="operationContainer.currentOperation.$$currentStep"
      [disabled]="createMultipleCount > maxCreateCount"
    ></app-operation-toolbar>
  </div>
</div>
