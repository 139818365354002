<div class="section-menu" *ngIf="showSectionsMenu">
  <div class="section-menu__container">
    <div class="section-menu__content">

      <ul class="section-menu__sections">
        <li *ngFor="let sec of sectionMenuContainer.sections">
          <a *ngIf="sec.name != 'updated'"
             (click)="onClick(sec.name)">
            <span class="text-blue-hover bold"
                  [ngClass]="{'text-blue': sec.name === sectionMenuContainer.currentSectionInViewPort}"
                  [translate]="sec.title"></span>
          </a>
        </li>
      </ul>

      <span class="section-menu__object-name bold">
        <app-object-icon [object]="sectionMenuContainer.artifact"></app-object-icon>
        <app-object-name
          [object]="sectionMenuContainer.artifact"
          [dontApplyWordBreak]="true"></app-object-name>
      </span>

    </div>
  </div>
</div>
