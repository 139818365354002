import {Component, Input, OnChanges} from '@angular/core';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {MetaField} from '../../../core/definitions/meta-field';
import {IndexNotification} from '../../../core/definitions/inline';

@Component({
  selector: 'app-edit-field-inline-array-add-button',
  templateUrl: './edit-field-inline-array-add-button.component.html',
  styleUrls: ['./edit-field-inline-array-add-button.component.scss']
})
export class EditFieldInlineArrayAddButtonComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field: MetaField;
  @Input() object;
  @Input() parentIndex;
  @Input() disabled: boolean;

  addItemNotifications: IndexNotification[] = [];
  private fieldParameters: FieldParameters;

  constructor(private readonly inlineArrayItemSvc: InlineArrayItemService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.field = this.field;
    this.fieldParameters.object = this.object;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  async addItem(event) {
    event.preventDefault();
    await this.inlineArrayItemSvc.addInlineArrayItemToForm(this.fieldParameters);
  }

  canAddItem() {
    const inlineList = this.field.inline.inline_list;
    let res = true;
    if (inlineList) {
      let fixed, maxLength, overMax = false;
      const arr = this.object[this.field.name];
      if (arr === undefined) {
        throw new Error('Could not find array named ' + this.field.name);
      }
      const listLength = arr.length;
      fixed = inlineList.fixed;
      maxLength = inlineList.max_length;
      if (maxLength) {
        overMax = listLength >= maxLength;
      }
      res = !fixed && !overMax;
      const notifications = inlineList.index_notifications;
      if (notifications) {
        notifications.forEach(indexNot => {
          if (listLength >= indexNot.index && !this.addItemNotifications[indexNot.index]) {
            this.addItemNotifications[indexNot.index] = indexNot;
          }
        });
      }
    }
    return res;
  }


}
