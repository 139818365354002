import {Injectable} from '@angular/core';
import {AppNotification, NotificationService} from '../shared/notification.service';
import {CommonsService} from './commons.service';
import {FieldMetaHandlerService} from './field-meta-handler.service';
import {LoggerService} from './logger.service';

export interface CmsErrorHandlerParams {
  clientMsg: string;
  transValues: object;
}

@Injectable({
  providedIn: 'root'
})
export class CmsErrorHandlerService {

  constructor(private notificationService: NotificationService,
              private logger: LoggerService,
              private commons: CommonsService,
              private fieldMetaHandler: FieldMetaHandlerService) {
  }

  errHandler(response, params: CmsErrorHandlerParams) {
    // "response.error" is used for new Angular CmsService
    const data = response.data || response;
    let clientMsg = params.clientMsg;
    let cmsMsg;
    if (data.error?.message) {
      cmsMsg = data.error.message;
      clientMsg = clientMsg || data.message || data.statusText || 'TRANS__CMS_RESPONSE__UNKNOWN';
    } else if (data.message) {
      cmsMsg = data.message;
      clientMsg = clientMsg || data.statusText || 'TRANS__CMS_RESPONSE__UNKNOWN';
    } else if (data.error_message) {
      cmsMsg = data.error_message
      clientMsg = clientMsg || data.statusText || 'TRANS__CMS_RESPONSE__UNKNOWN';
    } else {
      cmsMsg = 'TRANS__CONNECTION__UNKNOWN_ERROR';
      clientMsg = clientMsg || data.statusText || 'TRANS__CMS_RESPONSE__UNKNOWN';
    }
    const status = response.status;
    const transValues = params.transValues;
    if (typeof response !== 'undefined' && response.status === 404) {
      // Do nothing!
      // This type is already displayed as an error in the Chrome Developer Console and in the network tab.
    } else if (this.statusErrMessage(response.status)) {
      this.notificationService.addNotification(
        new AppNotification(
          [this.statusErrMessage(response.status)],
          'error'
        ))
    } else {
      this.notificationService.addNotification(new AppNotification(
        this.formatSaveError(data, clientMsg, cmsMsg),
        'error',
        transValues,
        status
      ));
      this.logger.warn(`CMS function failed: ${cmsMsg} : ${status}`);
    }
  }

  formatSaveError(obj, clientMsg, errorObject): string[] {
    const errorTypes = {
      'error.not_string': {
        msg: 'TRANS__SERVER_ERROR__NOT_STRING'
      },
      'error.not_integer': {
        msg: 'TRANS__SERVER_ERROR__NOT_INTEGER'
      },
      'error.not_float': {
        msg: 'TRANS__SERVER_ERROR__NOT_FLOAT'
      },
      'error.not_date': {
        msg: 'TRANS__SERVER_ERROR__NOT_DATE'
      },
      'error.min_length': {
        msg: 'TRANS__SERVER_ERROR__MIN_LENGTH'
      },
      'error.max_length': {
        msg: 'TRANS__SERVER_ERROR__MAX_LENGTH'
      },
      'error.not_boolean': {
        msg: 'TRANS__SERVER_ERROR__NOT_BOOLEAN'
      },
      'error.required': {
        msg: 'TRANS__SERVER_ERROR__FIELD_REQUIRED'
      },
      'error.regexp': {
        msg: 'TRANS__SERVER_ERROR__FIELD_REGEXP'
      },
      'error.invalid_ct_val': {
        msg: 'TRANS__SERVER_ERROR__INVALID_LIST_VALUE'
      }
    };
    const msg = [];
    if (clientMsg) {
      msg.push(clientMsg);
    }
    // clientMsg = clientMsg || "TRANS__SERVER_ERROR__SAVE_FAILED";
    // clientMsg = clientMsg || "TRANS__SERVER_ERROR__SAVE_FAILED";
    // msg = [clientMsg, ": ", status];
    if (typeof errorObject === 'string') {
      msg.push(': ');
      msg.push(errorObject);
    } else {
      Object.keys(errorTypes).forEach(errTypeKey => {
        let title;
        let err;
        Object.keys(errorObject).forEach(fieldName => {
          const returnedErrorTypes = errorObject[fieldName];
          const errorIndex = returnedErrorTypes.indexOf(errTypeKey);
          if (errorIndex !== -1) {
            err = {itemName: fieldName};
          }
        });
        if (err) {
          msg.push(': ');
          msg.push(errorTypes[errTypeKey].msg);
          title = this.fieldMetaHandler.searchGetMetaProp(obj, err.itemName, 'title');
          title = title || err.itemName;
          msg.push(': ');
          msg.push(title);
        }
      });
    }
    return msg;
  }

  /**
   * Return custom error message for selected status codes
   * @param status
   * @private
   */
  private statusErrMessage(status) {
    if (status === 403) {
      return 'TRANS__SERVER_ERROR__403';
    } else {
      return null;
    }
  }
}
