import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MainMenuComponent} from './main-menu/main-menu.component';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {SharedModule} from '../shared/shared.module';
import {ObjectViewModule} from '../object-view/object-view.module';
import { JobStatusComponent } from './jobstatus/job-status.component';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import { MessageAndNotificationsComponent } from './message-and-notifications/message-and-notifications.component';
import { MainMenuUserComponent } from './main-menu-user/main-menu-user.component';
import { MatLegacyTabsModule as MatTabsModule } from '@angular/material/legacy-tabs';
import { JobStatusMessageListComponent } from './jobstatus/jobstatus-message-list/job-status-message-list.component';
import {AppRoutingModule} from '../app-routing/app-routing.module';

import * as Sentry from "@sentry/angular";

@NgModule({
    imports: [
        CommonModule,
        MatDialogModule,
        SharedModule,
        ObjectViewModule,
        MatExpansionModule,
        MatIconModule,
        MatButtonModule,
        MatTabsModule,
        AppRoutingModule,
        Sentry.TraceModule
    ],
  exports: [
    MainMenuComponent
  ],
  declarations: [
    MainMenuComponent,
    JobStatusComponent,
    MessageAndNotificationsComponent,
    MainMenuUserComponent,
    JobStatusMessageListComponent
  ]
})
export class MainMenuModule {
}
