import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {FieldStateService} from '../../core/field-state.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MetaField} from '../../core/definitions/meta-field';
import {Reference} from '../../core/definitions/reference';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {BaseModel} from '../../core/definitions/base-model';
import {SolrFilterService} from '../../core/solr-filter.service';
import {SearchService} from "../../core/search.service";
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-edit-field-compare',
  templateUrl: './edit-field-compare.component.html',
  styleUrls: ['./edit-field-compare.component.scss']
})
export class EditFieldCompareComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() field: MetaField;
  @Input() index: number;
  @Input() parentIndex: number;
  compareFieldId;
  compareObject: BaseModel;
  myField;

  constructor(private searchService: SearchService,
              private commons: CommonsService,
              private fieldStateSvc: FieldStateService,
              private solrFilter: SolrFilterService,
              private searchReferenceService: SearchReferenceService) { }

  ngOnChanges() {
    this.searchReferenceService.getSearchReferenceFromField(this.field).then(reference => {
      this.setCompareProps(reference);
      this.setCompareField().then();
    })
  }

  setCompareProps(reference: Reference) {
    const showCondFieldName = reference.compare_value_field;
    this.compareObject = this.fieldStateSvc.getSubObject(this.sectionsContainer.rootObject, this.field, this.index, this.parentIndex);
    let compareFieldId = this.compareObject[showCondFieldName];
    const parentName = this.field[AConst.PARENT_NAME];
    if (compareFieldId === undefined && parentName) {
      this.compareObject = this.compareObject[parentName];
      compareFieldId = this.compareObject[showCondFieldName];
    }
    this.compareFieldId = compareFieldId;
  }

  async setCompareField() {
    const params = {} as SearchParameters;
    this.solrFilter.addFq(params, 'object_type', 'MetaField');
    this.solrFilter.addFq(params, 'artifact_id', this.compareFieldId);

    const res = await this.searchService.search(params);
    let fiSearch: MetaField;
    let fiCreate: MetaField;
    let refType: string;
    let parentMeta: MetaField;
    let reference: Reference;
    let inline;
    const fi = this.field;
    const existingVal = this.compareObject[fi.name];
    if (res.artifacts) {
      const covert = <unknown>res.artifacts[0];
      fiSearch = <MetaField>covert;
      if (fiSearch.reference) {
        const fakeReference = this.parsePythonJson(fiSearch.reference);
        reference = await this.searchReferenceService.getSearchReferenceFromReferenceId(fakeReference.reference_id);
        refType = reference.object_type;
        if (existingVal && existingVal.indexOf(refType) !== 0) {
          this.compareObject[fi.name] = null;
        }
      }
      if (fiSearch.inline) {
        inline = this.parsePythonJson(fiSearch.inline);
      }
      parentMeta = this.compareObject.$$meta[fi.name];
      parentMeta.reference = reference;
      parentMeta.input_type = fiSearch.input_type;
      fiCreate = this.commons.copy(fi);
      fiCreate.field_type = fiSearch.field_type;
      fiCreate.input_type = fiSearch.input_type;
      fiCreate.reference = reference;
      fiCreate.inline = inline;
      this.myField = fiCreate;
    }
  }



  parsePythonJson(inStr) {
    let str = inStr.replace(/'/g, '"');
    str = str.replace(/: True/g, ': true');
    str = str.replace(/: False/g, ': false');
    str = str.replace(/: None/g, ': null');
    return JSON.parse(str);
  }

}
