import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {ObjectEditService} from '../../core/object-edit.service';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {Reference} from '../../core/definitions/reference';
import {UiToolsService} from '../../core/ui-tools.service';
import {SearchReferenceService} from "../../core/search-reference.service";
import {EditObjectDialogService} from "../edit-object-dialog.service";
import {EditObjectDialogData} from "../edit-object-dialog-data";

interface SelectObjectType {
  name: string;
  objectType: string;
}

@Component({
  selector: 'app-edit-object-dialog',
  templateUrl: './edit-object-dialog.component.html',
  styleUrls: ['./edit-object-dialog.component.scss']
})
export class EditObjectDialogComponent implements OnInit {

  sectionsContainer: SectionsContainer = new SectionsContainer();
  private fieldReference: Reference;
  primeFields;
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };
  selectObjectTypes: SelectObjectType[];
  selectedObjectTypeName = null;
  selectedObjectType;

  @HostListener('click', ['$event.target'])
  registerClick(event) {
    this.uiTools.registerDocumentClick(event);
  }

  constructor(public dialogRef: MatDialogRef<EditObjectDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditObjectDialogData,
              private objectEditService: ObjectEditService,
              private editObjectDialogService: EditObjectDialogService,
              private uiTools: UiToolsService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnInit() {
    this.initObject().then();
  }

  onSubmit() {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      value => {
        this.dialogRef.close(value);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

  objectTypeSelected(selectObjectType: SelectObjectType) {
    if (selectObjectType.objectType) {
      this.selectedObjectTypeName = selectObjectType.objectType;
      this.selectedObjectType = selectObjectType.objectType;
    }
  }

  nextStep() {
    this.editObjectDialogService.createObjectSectionsContainer(
      this.selectedObjectType,
      this.data,
      this.fieldReference,
      this.data.fieldParameters.object).then(
      sectionsContainer => {
        this.sectionsContainer = sectionsContainer;
        this.checkSetPrimeFields();
      });
  }

  private async initObject() {
    const fieldParams = this.data.fieldParameters;
    this.fieldReference = await this.searchReferenceService.getSearchReferenceFromField(fieldParams.field);
    if (this.fieldReference?.object_type || (fieldParams.object && fieldParams.object.object_type)) {
      this.sectionsContainer = await this.editObjectDialogService.createOptionFromFieldParameters(
        fieldParams, this.data, this.fieldReference, this.data.createText, this.data.parentId);
      if (!this.sectionsContainer) {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__NO_ACCESS';
      } else {
        this.primeFields = this.sectionsContainer.primeFields;
      }
    } else {
      this.selectObjectTypes = await this.editObjectDialogService.getSelectObjectTypesFromMetaTypes(
        this.fieldReference?.meta_type);
    }
  }

  private checkSetPrimeFields() {
    if (this.sectionsContainer && this.editObjectDialogService.getUsePrimeFields(
      this.selectedObjectType, this.fieldReference)) {
      this.primeFields = this.sectionsContainer.primeFields;
    }
  }
}
