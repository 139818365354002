import {Injectable} from '@angular/core';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {CmsApiService} from './cms-api.service';
import {PrimusRouteService} from './primus-route.service';
import {RefParams, RefService} from './ref.service';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';
import {Media} from './definitions/media';
import {ModelRelationsService} from './model-relations.service';
import {SearchParameters} from './definitions/search-parameters';
import {SolrFilterService} from './solr-filter.service';
import {SearchObject} from './definitions/search-object';
import {ObjectMediaContainer, ObjectMediaType} from './definitions/object-media-container';
import {MediaItem} from './definitions/media-item';
import {SearchService} from "./search.service";

export interface MediaMetaData {
  objectType: string;
  arrayName: string;
  mediaName: string;
}

@Injectable({
  providedIn: 'root'
})
export class MediaHelperService {
  onDragStart;
  onDrag;
  onDragEnd;
  dragActive = false;
  draggingStarted;
  container;
  lastZoomValue;
  imageLoaded;
  draggableImage;
  lastMousePosition = {x: null, y: null};
  imageSize = {width: 0, height: 0};
  zoomValue;
  PREFIX_IMAGE = 'IMG';
  PREFIX_VIDEO = 'VID';
  PREFIX_ATTACHMENT = 'ATT';
  PREFIX_AUDIO = 'AUDIO';

  private imageBlacklist = new Set();
  private mediaObjectTypes = ['Image', 'Attachment', 'Video', 'Audio'];

  private imageMediaMetaData = {
    objectType: 'Image', arrayName: 'images', mediaName: 'TRANS__MEDIA_NAME__IMAGES'} as MediaMetaData;
  private attachmentMediaMetaData = {
    objectType: 'Attachment', arrayName: 'attachments', mediaName: 'TRANS__MEDIA_NAME__ATTACHMENTS'} as MediaMetaData;
  private videoMediaMetaData = {
    objectType: 'Video', arrayName: 'videos', mediaName: 'TRANS__MEDIA_NAME__VIDEOS'} as MediaMetaData;
  private audioMediaMetaData = {
    objectType: 'Audio', arrayName: 'audios', mediaName: 'TRANS__MEDIA_NAME__AUDIOS'} as MediaMetaData;

  mediaMetaDataList: MediaMetaData[] = [
    this.imageMediaMetaData,
    this.videoMediaMetaData,
    this.attachmentMediaMetaData,
    this.audioMediaMetaData
  ];

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private ref: RefService,
              private primusRoute: PrimusRouteService,
              private modelRelationsService: ModelRelationsService,
              private solrFilter: SolrFilterService,
              private searchService: SearchService) {
  }

  public async getImageUrl(media: BaseModel, imageSize): Promise<string> {
    const imageId = this.getMediaId(media);
    return await this.getImageUrlFromImageId(imageId, imageSize);
  }

  public async getImageUrlFromImageId(imageId: string, imageSize?: string): Promise<string> {
    let res = '';
    if (this.artifactIdIsAudio(imageId)) {
      return 'primus-assets/img/audio.png';
    }
    const params = {
      image_id: imageId,
      size: imageSize
    };
    if (!this.imageBlacklist.has(params.image_id)) {
      try {
        const imageUrl = await this.cms.getImageUrl(params);
        if (imageUrl) {
          res = imageUrl.url;
        }
      } catch (e) {
        this.imageBlacklist.add(params.image_id);
        console.error(`Getting image url failed for ${imageId}: ${e.error.message} 😥🚫`);
      }
    }
    return res;
  }

  public async setThumbnailUrl(item: SearchObject, imageIdField) {
    let url: string;
    if (item.object_type?.toLowerCase() === 'attachment' || item.object_type?.toLowerCase() === 'video') {
      url = await this.getAttachmentThumbnailUrl(item);
    } else if (item.thumbnail_id) {
      url = await this.getImageUrlFromImageId(item.thumbnail_id);
    } else if (item.thumbnail_url) {
      url = item.thumbnail_url;
    } else if (!imageIdField || imageIdField === '0') {
      url = await this.getThumbnailUrl(item, 'thumbnail_id');
    } else if (imageIdField) {
      url = await this.getThumbnailUrl(item, imageIdField);
    }

    if (url) {
      let random = '';
      if (url.indexOf('primus-assets') !== 0) {
        random = `&random=${String(Math.random())}`;
      }
      item.$$thumbnailUrl = `${url}${random}`;
      if (item.thumbnail_id && item.$$uploading) {
        await this.waitForDoneUploadStatus(item, url, 20);
      }
    }
  }

  cancelUploadWait(item: SearchObject) {
    item.$$uploadWaitCancel = true;
  }

  private async waitForDoneUploadStatus(item: SearchObject, url: string, downCount) {
    if (item.$$uploadWaitCancel) {
      if (item.$$uploadTimeoutId) {
        clearTimeout(item.$$uploadTimeoutId);
      }
      return;
    }
    if (downCount) {
      const status = await this.cms.getUploadStatus({artifact_id: item.thumbnail_id});
      if (status === 'init' || status === 'convert' || status === 'converting') {
        if (status === 'converting') {
          item.$$uploadProgress = await this.cms.getUploadProgress({artifact_id: item.thumbnail_id});
          console.log('Progress: ' + item.$$uploadProgress);
        }
        item.$$uploadTimeoutId = setTimeout(() => {
          this.waitForDoneUploadStatus(item, url, downCount - 1).then();
        }, 1000);
      } else if (status === 'done') {
        item.$$uploading = false;
        item.$$thumbnailUrl = `${url}&random=${String(Math.random())}`;
      } else {
        item.$$uploading = false;
        console.error(`Got status ${status} from server`);
      }
    } else {
      item.$$uploading = false;
    }
  }

  public async getThumbUrl(object: BaseModel, imageIdField?): Promise<string> {
    return this.getImageUrlFromImageId(this.getMediaId(object, imageIdField));
  }

  public async getMediaPlaybackUrls(media: SuperObjectModel): Promise<Array<any>> {
    if (media.object_type === 'Video' || media.object_type === 'Audio') {
      const type = media.object_type === 'Video' ? 'video' : 'audio';
      try {
        const urls = await this.cms.orderPlaybackUrls({artifact_id: this.getMediaId(media)});
        const res = [];
        for (const [key, url] of Object.entries(urls)) {
          res.push({
            url: url,
            // url: $sce.trustAsResourceUrl(url),
            type: `${type}/${key}`
          });
        }
        return res;
      } catch (response) {
        console.error(`Unable to retrieve media URLS for ${this.getMediaId(media)}: ${response.message}: ${response.status}`);
      }
    } else if (media.object_type !== 'Image') {
      throw new Error(`Media type "${media.object_type} not supported yet`);
    }
  }

  public async getMediaUploadStatus(media: SuperObjectModel): Promise<string> {
    let status = '';
    try {
      status = await this.cms.getUploadStatus({artifact_id: media.artifact_id});
    } catch (response) {
      console.error(`Unable to retrieve media upload status for ${media.artifact_id}: ${response.message} : ${response.status}`);
    }
    return status;
  }

  public async getMediaUploadProgress(media: SuperObjectModel): Promise<string> {
    let progress = '';
    try {
      progress = await this.cms.getUploadProgress({artifact_id: media.artifact_id});
    } catch (response) {
      console.error(`Unable to retrieve media upload status for ${media.artifact_id}: ${response.message} : ${response.status}`);
    }
    return progress;
  }

  public async addImageUrls(objects: BaseModel[]) {
    for (const obj of objects) {
      obj.$$imageUrl = await this.getImageUrl(obj, 'medium');
      obj.$$thumbUrl = await this.getThumbUrl(obj);
    }
  }

  public async setMediaProps(media: BaseModel, parent: SuperObjectModel) {
    let contextIds = this.commons.getContextIds(parent[AConst.CONTEXTS]);

    if (!contextIds) {
      contextIds = [parent.artifact_id];
    }
    const ref = await this.ref.makeRef(new RefParams(
      <SuperObjectModel>media,
      {
        contextIds: contextIds,
        parentId: parent.artifact_id,
        parentObjectType: parent.object_type,
        rootObjId: this.primusRoute.params.rootObjId,
        rootObjType: this.primusRoute.params.rootObjType
      }));
    media.$$parentId = ref.param.parentId;
    media.$$parentObjectType = ref.param.parentObjectType;
    media.$$routerLink = ref.routerLink;
    media.$$queryParams = ref.queryParams;
    media.$$imageUrl = await this.getImageUrl(media, 'medium');
    media.$$thumbUrl = await this.getThumbUrl(media);
  }


  public async getMediaContainerForAllObjectMedia(obj: SuperObjectModel): Promise<ObjectMediaContainer> {
    const objectMediaContainer = new ObjectMediaContainer();
    const mediaMetaDataDict = {};
    let order = 0;
    for (const mediaMetaData of this.mediaMetaDataList) {
      const mediaArrayMeta = obj.$$meta[mediaMetaData.arrayName];
      if (mediaArrayMeta) {
        mediaMetaDataDict[order++] = mediaMetaData;
      }
    }
    const mediaMetaDataList = Object.keys(mediaMetaDataDict)
      .map(key => Number(key))
      .sort((a, b) => a - b)
      .map(key => mediaMetaDataDict[key]);
    for (const mediaMetaData of mediaMetaDataList) {
      const media = await this.getMediaObjects(mediaMetaData, obj);
      if (media.length) {
        const objectMediaType = new ObjectMediaType();
        objectMediaType.mediaType = mediaMetaData.objectType;
        objectMediaType.mediaElements = media;
        objectMediaType.objectMediaArrayName = mediaMetaData.arrayName;
        objectMediaType.objectMediaName = mediaMetaData.mediaName;
        objectMediaContainer.mediaTypes.push(objectMediaType);
      }
    }
    return objectMediaContainer;
  }

  // Get object type specific media container. The media items originates from the object in 'artTarget'
  public async getMediaContainerForObjectAndObjectType(sourceObject: SuperObjectModel, mediaObjectType): Promise<ObjectMediaContainer> {
    let mediaMetaData: MediaMetaData;
    switch (mediaObjectType) {
      case 'Image':
        mediaMetaData = this.imageMediaMetaData;
        break;
      case 'Video':
        mediaMetaData = this.videoMediaMetaData;
        break;
      case 'Audio':
        mediaMetaData = this.audioMediaMetaData;
        break;
      case 'Attachment':
        mediaMetaData = this.attachmentMediaMetaData;
        break;
      default:
        console.warn(`Unknown media type ${mediaObjectType}`);
        return;
    }
    const mediaContainer = new ObjectMediaContainer();
    const mediaType = new ObjectMediaType();
    mediaContainer.mediaTypes.push(mediaType);
    mediaType.mediaType = mediaMetaData.objectType;
    mediaType.mediaElements = await this.getMediaObjects(mediaMetaData, sourceObject);
    mediaType.objectMediaArrayName = mediaMetaData.arrayName;
    mediaType.objectMediaName = mediaMetaData.mediaName;
    return mediaContainer;
  }

  public async getMediaContainerForMediaObject(mediaObject: SuperObjectModel): Promise<ObjectMediaContainer> {
    const mediaContainer = new ObjectMediaContainer();
    const mediaType = new ObjectMediaType();
    mediaContainer.mediaTypes.push(mediaType);
    mediaType.mediaType = mediaObject.object_type;
    const media = new Media();
    media.mediaObject = mediaObject;
    mediaType.mediaElements = [media];
    await this.addImageUrls([mediaObject]);
    return mediaContainer;
  }

  public getPosition(position, containerSize, itemSize) {
    if (position > 0) {
      position = 0;
    }
    if (position < (containerSize - itemSize)) {
      position = containerSize - itemSize;
    }
    return position;
  }

  public imgDrag(container, imageLoaded, draggableImage, imageSize, zoomValue) {
    this.container = container;
    this.imageLoaded = imageLoaded;
    this.draggableImage = draggableImage;
    this.imageSize = imageSize;
    this.zoomValue = zoomValue;
    this.dragActive = true;
    this.onDragStart = this.dragStart.bind(this);
    this.onDrag = this.drag.bind(this);
    this.onDragEnd = this.dragEnd.bind(this);
    this.container.addEventListener('mousedown', this.onDragStart, true);
    this.container.addEventListener('mousemove', this.onDrag, true);
    this.container.addEventListener('mouseup', this.onDragEnd, true);
    this.container.addEventListener('touchstart', this.onDragStart, true);
    this.container.addEventListener('touchmove', this.onDrag, true);
    this.container.addEventListener('touchend', this.onDragEnd, true);
  }

  public dragEnd(event) {
    event.preventDefault();
    event.stopPropagation();
    this.draggingStarted = 0;
    this.stopCallBack.bind(this);
  }

  public dragStart(event) {
    event.preventDefault();
    event.stopPropagation();
    if (this.imageLoaded === 1 && this.dragActive) {
      this.draggingStarted = 1;
      if (event.type === 'touchstart') {
        this.lastMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        this.lastMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }
    }
  }

  public drag(event) {
    event.preventDefault();
    event.stopPropagation();
    if (event.type === 'mousemove' && event.buttons === 1) {
      this.setDraggedImagePosition(event);
    }
    if (event.type === 'touchmove') {
      this.setDraggedImagePosition(event);
    }
  }

  private setDraggedImagePosition(event) {
    if (this.draggingStarted === 1) {
      let currentMousePosition, changeX, changeY, img_top_new, img_left_new,
        widthValue, heightValue, img_width, img_height;
      if (event.type === 'touchmove') {
        currentMousePosition = {
          x: event.touches[0].pageX - this.container.offsetLeft,
          y: event.touches[0].pageY - this.container.offsetTop
        };
      } else {
        currentMousePosition = {
          x: event.pageX - this.container.offsetLeft,
          y: event.pageY - this.container.offsetTop
        };
      }

      changeX = currentMousePosition.x - this.lastMousePosition.x;
      changeY = currentMousePosition.y - this.lastMousePosition.y;
      this.lastMousePosition = currentMousePosition;

      img_top_new = this.draggableImage.offsetTop + changeY;
      img_left_new = this.draggableImage.offsetLeft + changeX;
      widthValue = this.imageSize.width * (Number(this.zoomValue) - 1);
      heightValue = this.imageSize.height * (Number(this.zoomValue) - 1);
      img_width = this.imageSize.width + widthValue;
      img_height = this.imageSize.height + heightValue;

      if (this.container.offsetHeight > img_height) {
        this.draggableImage.style.top = 'initial';
      } else {
        img_top_new = this.getPosition(img_top_new,
          this.container.offsetHeight, img_height);
        this.draggableImage.style.top = img_top_new + 'px';
      }
      if (this.container.offsetWidth > img_width) {
        this.draggableImage.style.left = 'initial';
      } else {
        img_left_new = this.getPosition(img_left_new,
          this.container.offsetWidth, img_width);
        this.draggableImage.style.left = img_left_new + 'px';
      }
    }
  }

  public stopCallBack() {
    this.dragActive = false;
    this.draggingStarted = 0;
    if (this.container) {
      this.container.removeEventListener('mousedown',
        this.onDragStart, true);
      this.container.removeEventListener('mousemove',
        this.onDrag, true);
      this.container.removeEventListener('mouseup',
        this.onDragEnd, true);
      this.container.removeEventListener('touchstart',
        this.onDragStart, true);
      this.container.removeEventListener('touchmove',
        this.onDrag, true);
      this.container.removeEventListener('touchend',
        this.onDragEnd, true);
    }

  }

  public setNewImageSize(container, image, width, height) {
    this.container = container;
    if (width > this.container.offsetWidth ||
      height > this.container.offsetHeight) {

      if (width > height) { // Landscape
        this.imageSize.width = this.container.offsetWidth;
        this.imageSize.height = image.clientHeight;
      } else if (height > width) { // portrait
        this.imageSize.height = this.container.offsetHeight;
        this.imageSize.width = image.clientWidth;
      } else {
        this.imageSize = {
          width: this.container.offsetWidth,
          height: this.container.offsetHeight
        };
      }
    } else {
      this.imageSize = {
        width: width,
        height: height
      };
    }
    return this.imageSize;
  }

  public resizeImage(container, zoomValue, image, imageNaturalSize, imageActiveSize, annotation) {
    let widthValue, heightValue, newWidth, newHeight, height, width, maxHeight,
      maxWidth;
    this.zoomValue = zoomValue;
    this.imageSize = imageActiveSize;
    widthValue = this.imageSize.width * (Number(zoomValue) - 1);
    heightValue = this.imageSize.height * (Number(zoomValue) - 1);
    newWidth = this.imageSize.width + widthValue;
    newHeight = this.imageSize.height + heightValue;

    if (Number(this.zoomValue) === 1) {
      height = 'auto';
      maxHeight = '100%';
      width = 'auto';
      maxWidth = '95%';
      this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      image.style.top = 'initial';
      image.style.left = 'initial';
      this.stopCallBack.bind(this);
    } else {
      if (imageNaturalSize.width > imageNaturalSize.height) { // Landscape
        height = 'auto';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else if (imageNaturalSize.height > imageNaturalSize.width) { // portrait
        height = newHeight + 'px';
        maxHeight = 'none';
        width = 'auto';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      } else {
        height = newHeight + 'px';
        maxHeight = 'none';
        width = newWidth + 'px';
        maxWidth = 'none';
        this.setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth);
      }
      if (Number(this.lastZoomValue) !== Number(this.zoomValue)) {
        this.setPosition(container, imageActiveSize, image, newWidth, newHeight);
      }
    }
    this.lastZoomValue = this.zoomValue;
  }

  public setPosition(container, imageActiveSize, image, newWidth, newHeight) {
    let left, top;
    if (imageActiveSize.width > container.offsetWidth) {
      left = this.getPosition(
        image.offsetLeft,
        container.offsetWidth,
        newWidth);
      left = left + 'px';
    } else {
      left = 'initial';
    }
    if (imageActiveSize.height > container.offsetHeight) {
      top = this.getPosition(image.offsetTop,
        container.offsetHeight, newHeight);
      top = top + 'px';
    } else {
      top = 'initial';
    }
    image.style.left = left;
    image.style.top = top;
  }

  public setWidthAndHeight(annotation, image, height, maxHeight, width, maxWidth) {
    image.style.maxHeight = maxHeight;
    image.style.maxWidth = maxWidth;
    image.style.height = height;
    image.style.width = width;
    if (annotation) {
      if (Number(this.zoomValue) === 1) {
        image.width = this.imageSize.width;
        image.height = this.imageSize.height;

      } else {
        image.width = width;
        image.height = height;
      }
    }
  }

  objectCanHaveImages(object): Promise<boolean> {
    return new Promise(resolve => {
      this.modelRelationsService.objectCanHaveObjectTypes(object, ['Image']).then(res => {
        resolve(res);
      });
    });
  }

  getArtifactIdPrefix(artifactId) {
    const idPrefixEnd = artifactId.indexOf('-');
    return artifactId.substring(0, idPrefixEnd);
  }

  objectIsMedia(object: SuperObjectModel) {
    return this.mediaObjectTypes.indexOf(object.object_type) !== -1;
  }

  artifactIdIsAudio(artifactId: string) {
    return this.getArtifactIdPrefix(artifactId) === this.PREFIX_AUDIO;
  }

  private async getAttachmentThumbnailUrl(item: SearchObject) {
    const attachmentId: string = item.artifact_id;
    if (!attachmentId) {
      return;
    }
    return await this.getImageUrlFromImageId(attachmentId);
  }

  private async getThumbnailUrl(item: SearchObject, propName: string) {
    const thumb_id = item[propName];
    let imgUrl;
    if (thumb_id) {
      imgUrl = await this.getThumbUrl(item, propName);
    }
    return imgUrl;
  }

  private async createMediaObjects(parent: SuperObjectModel,
                               mediaItemArtifactIds: string[],
                               mediaObjects: SearchObject[],
                               mediaItems?: MediaItem[]): Promise<Media[]> {
    const res: Media[] = [];
    for (const mediaArtifactId of mediaItemArtifactIds) {
      for (const mediaObject of mediaObjects) {
        if (mediaArtifactId === this.getMediaId(mediaObject)) {
          const mediaObjectCopy = Object.assign({}, mediaObject);
          const media = new Media();
          media.mediaObject = mediaObjectCopy;
          if (mediaItems) {
            this.setMediaItem(media, mediaItems);
          }
          res.push(media);
          if (parent.artifact_id) {
            await this.setMediaProps(media.mediaObject, parent);
          }
          break;
        }
      }
    }
    if (mediaItems) {
      await this.addImageUrls(mediaItems);
    }
    return res;
  }

  private setMediaItem(media: Media, mediaItems: MediaItem[]) {
    for (const mediaItem of mediaItems) {
      const mediaId = this.getMediaId(mediaItem);
      if (media.mediaObject.artifact_id === mediaId) {
        media.mediaItem = mediaItem;
        mediaItem.$$mediaId = mediaId;
        mediaItem.$$mediaName = this.getMediaName(mediaItem);
        break;
      }
    }
  }

  private async getMediaObjects(mediaMetaData: MediaMetaData, obj: SuperObjectModel): Promise<Media[]> {
    const mediaArtifactIds: string[] = [];
    let res: Media[] = [];
    let objects = [];
    if (mediaMetaData.objectType === obj.object_type) {
      objects = [obj];
      mediaArtifactIds.push(this.getMediaId(obj));
      res = await this.createMediaObjects(obj, mediaArtifactIds, objects);
    } else {
      const mediaItemArray = obj[mediaMetaData.arrayName];
      if (mediaItemArray) {
        mediaItemArray.forEach(mediaItem => {
          mediaArtifactIds.push(this.getMediaId(mediaItem));
        });
      }
      if (mediaArtifactIds.length > 0) {
        const params = {} as SearchParameters;
        this.solrFilter.addFq(params, 'artifact_id', mediaArtifactIds);
        const mediaObjectSearch = await this.searchService.search(params);
        const mediaObjects = mediaObjectSearch.artifacts ? mediaObjectSearch.artifacts : [];
        if (mediaArtifactIds.length > mediaObjects.length) {
          console.warn(`Did not find all media: ${mediaArtifactIds}`);
        }
        if (mediaObjects.length > 0) {
          res = await this.createMediaObjects(obj, mediaArtifactIds, mediaObjects, mediaItemArray);
        }
      }
    }
    return res;
  }

  private getMediaId(item: BaseModel, imageIdField?) {
    let res;
    if (imageIdField) {
      res = item[imageIdField];
    } else {
      res = item[this.commons.getObjectIdField(item)];
    }
    return res;
  }

  private getMediaName(item: BaseModel) {
    const idField = this.commons.getObjectIdField(item);
    let res;
    if (idField) {
      res = item[`${idField}_value`];
    }
    return res || 'name not found';
  }

}
