<img [alt]="'TRANS__THUMBNAIL__IMAGE' | translate"
     [src]="item.$$thumbnailUrl"
     *ngIf="!item.$$uploading"
     [class]="imgClass" />
<div class="uploading-placeholder"
     *ngIf="item.$$uploading"
     [class]="imgClass">
  <mat-progress-bar color="accent" mode="indeterminate"></mat-progress-bar>
  <i class="icon-image"></i>
</div>

