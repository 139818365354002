import {CollectionViewer, DataSource} from '@angular/cdk/collections';
import {BehaviorSubject, Observable} from 'rxjs';
import {ConceptListItem, SortDir} from '../../core/definitions/concepts';
import {CmsApiService} from '../../core/cms-api.service';
import {MatSort} from '@angular/material/sort';
import {ConceptsContainer} from '../../core/definitions/concepts-container';
import {MatLegacyPaginator as MatPaginator} from '@angular/material/legacy-paginator';
import {CheckFilter} from '../../core/definitions/search-objects';

export class ConceptDataSource implements DataSource<ConceptListItem> {
  data: Array<ConceptListItem> = [];
  sort: MatSort;
  paginator: MatPaginator;

  private conceptSubject = new BehaviorSubject<ConceptListItem[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);

  constructor(private cms: CmsApiService) {
  }

  connect(collectionViewer: CollectionViewer): Observable<ConceptListItem[]> {
    return this.conceptSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.conceptSubject.complete();
    this.loadingSubject.complete();
  }

  async loadConcepts(conceptsContainer: ConceptsContainer) {
    this.loadingSubject.next(true);
    if (this.paginator) {
      if (!this.paginator.pageSize) {
        this.paginator.pageSize = 20;
      }
      conceptsContainer.conceptsParams.rows = this.paginator.pageSize;
      conceptsContainer.conceptsParams.start = this.paginator.pageIndex * this.paginator.pageSize;
    }
    if (this.sort && this.sort.active) {
      conceptsContainer.conceptsParams.sort = this.sort.active;
      if (this.sort['_direction'] === 'asc') {
        conceptsContainer.conceptsParams.sort_dir = SortDir.Asc;
      } else {
        conceptsContainer.conceptsParams.sort_dir = SortDir.Desc;
      }
    }
    const concepts = await this.cms.getConcepts(conceptsContainer.conceptsParams);
    this.data = concepts.concepts;
    conceptsContainer.concepts = concepts;
    this.refreshVisibleConcepts();
    await this.setFiltersFromFacets(conceptsContainer);
    this.setExistingSelected(conceptsContainer);
    this.loadingSubject.next(false);
    this.conceptSubject.next(this.data);
  }

  refreshVisibleConcepts() {
    setTimeout(() => {
      this.data = this.getConceptsRemoveDeleted(this.data);
      this.conceptSubject.next(this.data);
    }, 200);
  }

  async setFiltersFromFacets(conceptsContainer: ConceptsContainer) {
    for (const filterGroup of conceptsContainer.filterGroups) {
      for (const filter of filterGroup.filters) {
        filter.count = 0;
      }
      const filterName = filterGroup.filters[0].name;
      const filterFacets = conceptsContainer.concepts.facets[filterName];
      if (filterFacets) {
        for (let t = 0; t < filterFacets.length; t += 2) {
          const facetValue = filterFacets[t];
          const facetCount = filterFacets[t + 1];
          let facetValueFound = false;
          for (const filter of filterGroup.filters) {
            if (filter.value?.toString() === facetValue?.toString()) {
              facetValueFound = true;
              filter.count = facetCount;
            }
          }
          if (!facetValueFound) {
            const checkFilter = new CheckFilter(filterName, facetValue);
            checkFilter.count = facetCount;
            checkFilter.checked_value = facetValue;
            checkFilter.checked = false;
            checkFilter.title = facetValue;
            filterGroup.filters.push(checkFilter);
          }
        }
      } else {
        console.warn(`No facets found for ${filterName}`);
      }
    }
  }

  setExistingSelected(conceptsContainer: ConceptsContainer) {
    this.setSelected(conceptsContainer, true);
  }

  setSelected(conceptsContainer: ConceptsContainer, selectedValue) {
    if (conceptsContainer.selected.length) {
      const selected = [...conceptsContainer.selected];
      for (let t = 0; t < this.data.length; t++) {
        const concept = this.data[t];
        let foundIndex;
        for (let i = 0; i < selected.length; i++) {
          const selectedConcept = selected[i];
          if (selectedConcept.artifact_id === concept.artifact_id) {
            concept.$$selected = selectedValue;
            foundIndex = i;
            break;
          }
        }
        if (foundIndex !== undefined) {
          selected.splice(foundIndex, 1);
          if (!selected.length) {
            break;
          }
        }
      }
    }
  }


  private getConceptsRemoveDeleted(concepts: Array<ConceptListItem>) {
    let res: Array<ConceptListItem> = [];
    concepts.forEach(concept => {
      if (!concept.$$deleted) {
        res.push(concept);
        res = res.concat(this.getChildren(concept));
      }
    });
    return res;
  }

  private getChildren(concept: ConceptListItem): Array<ConceptListItem> {
    let res: Array<ConceptListItem> = [];
    if (concept.open && concept.children && concept.children.length) {
      res = res.concat(this.getConceptsRemoveDeleted(concept.children));
      concept.children = null;
    }
    return res;
  }


}
