<div class="edit-field-inline-array-item-list">
  <div class="edit-field-inline-array-item-list__container"
       [ngClass]="{'no-field-title': !fieldParameters.field.title}"
       cdkDropList
       (cdkDropListDropped)="drop($event)">
    <div *ngIf="isInvalid" class="error-warn-bg-color error-warn-border-color error-warn-color
      edit-field-inline-array-item-list__error">
      <mat-icon color="warn">report_problem</mat-icon>
      <span>
          <span translate="TRANS__ARRAY_FIELD__ERROR__REQUIRED"></span>&nbsp;
          <span class="lowercase" [translate]="field.title || field.admin_title"></span>.
        </span>
    </div>
    <ul *ngIf="items.length">
      <ng-container *ngFor="let item of items ; index as i">

        <li *ngIf="!item._destroy"
            class="inline-array-item"
            [ngClass]="{'dialog': fieldParameters.sectionsContainer.isDialog}"
            cdkDrag cdkDragBoundary="edit-field-inline-array-item-list__container"
            (cdkDragStarted)="onDragStart()">
          <div class="inline-array-item-placeholder" *cdkDragPlaceholder></div>
          <app-edit-field-inline-array-item
            [fieldParametersIn]="fieldParameters"
            [item]="item"
            [arrayIndex]="i"
            (fieldFocus)="checkFieldOutsideTheScreen()"
          ></app-edit-field-inline-array-item>
          <div class="edit-field-inline-array-item-list__buttons"
               *ngIf="!item.$$disabled"
               [ngClass]="{'is-copy': fieldParameters.sectionsContainer.isCopy}">
            <button mat-button class="edit-field-inline-array-item-list__setting-button text-blue-hover"
                    type="button"
                 *ngIf="(!field.inline.inline_list || !field.inline.inline_list.fixed) && fieldParameters.canDelete"
                    [matMenuTriggerRestoreFocus]="false"
                    (menuOpened)="item.$$activeDropdown = true;"
                    (menuClosed)="item.$$activeDropdown = false;"
                    [matMenuTriggerFor]="arrayItemMenu">
              <mat-icon>settings</mat-icon>
            </button>
            <button mat-button type="button"
                    class="edit-field-inline-array-item-list__move-button text-blue-hover">
              <i class="icon-row-sort" cdkDragHandle></i></button></div>

          <mat-menu #arrayItemMenu="matMenu"
               class="edit-field-inline-array-item-list__setting-menu">
            <app-edit-field-inline-array-delete-button
              *ngIf="fieldParameters.canDelete && (item.$$deletable || item.$$justAdded)"
              [fieldParameters]="fieldParameters"
              [arrayIndex]="i"
            ></app-edit-field-inline-array-delete-button>
          </mat-menu>
        </li>

      </ng-container>

    </ul>
  </div>

</div>
