import { Injectable } from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import {IdentifierFormat} from './identifier-format';
import {GetArtifactParams} from '../../core/definitions/get-artifact-params';
import {Collection} from './collection';
import {SearchResult} from '../../core/definitions/search-result';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {AdminIdFormatEditComponent} from './admin-id-format-edit/admin-id-format-edit.component';
import {SearchService} from "../../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class AdminIdFormatService {

  constructor(private readonly cmsApi: CmsApiService,
              private readonly dialog: MatDialog,
              private readonly searchService: SearchService) { }

  public async getOne(artifactId: string): Promise<IdentifierFormat> {
    const getter: any = await this.cmsApi.getArtifact({
      ...new GetArtifactParams(),
      artifact_id: artifactId,
    });
    return getter as IdentifierFormat;
  }

  public async getAll(): Promise<Array<IdentifierFormat>> {
    return this.cmsApi.getAllIdentifierFormat();
  }

  public async save(body: IdentifierFormat): Promise<IdentifierFormat> {
    return await this.cmsApi.createIdentifierFormat(body);
  }

  public async delete(idFormat: IdentifierFormat): Promise<string | null> {
    if (!idFormat?.artifact_id) {
      return null;
    }
    const deletedId = await this.cmsApi.deleteIdentifierFormat(idFormat.artifact_id);
    return deletedId || null;
  }

  public async search(searchString: string): Promise<Array<IdentifierFormat>> {
    // TODO: Implement when API is ready
    console.warn('[AdminIdFormatService] [Search] Not implemented');
    return [];
  }

  public async getAllCollections(): Promise<Array<Collection>> {
    const collections: SearchResult = await this.searchService.search({
      query: 'object_type:"Collection"',
      fl: ['artifact_id', 'artifact_name'],
      sort: 'artifact_name asc',
      getAll: true
    } as SearchParameters);

    if (collections && collections.artifacts) {
      return collections.artifacts.map(coll => ({
        id: coll.artifact_id,
        name: coll.artifact_name,
        count: 0
      }));
    }
    return [];
  }

  public updateCollectionCount(collections: Array<Collection>, formats: Array<IdentifierFormat>): void {
    if (!collections || !formats || collections.length <= 0 || formats.length <= 0) {
      return;
    }
    collections.forEach(coll =>
      coll.count = formats.filter(f =>
        f.identifier_format_collection_id === coll.id
      ).length
    );
  }

  public async addOrEditIdentifierFormat(idFormat: IdentifierFormat, selectedCollection?: Collection): Promise<IdentifierFormat> {
    if (!idFormat) {
      throw Error('An instance of \'IdentifierFormat\' must be provided');
    }
    if (!!selectedCollection) {
      idFormat.identifier_format_collection_id = selectedCollection.id;
      idFormat.identifier_format_collection_id_value = selectedCollection.name;
    }
    return await this.dialog
      .open(AdminIdFormatEditComponent, {panelClass: 'edit-dialog', data: idFormat})
      .afterClosed()
      .toPromise();
  }
}
