import {Component, Input, OnInit} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {CmsApiService} from '../../core/cms-api.service';
import {CultureHubFolder} from '../../core/definitions/culture-hub-folder';
import {DateToolsService} from '../../core/date-tools.service';
import {Sort} from '@angular/material/sort';
import * as moment from 'moment';
import {AlertDialogComponent} from '../alert-dialog/alert-dialog.component';
import {ConfirmDialogData} from '../../object-edit/confirm-dialog/confirm-dialog.component';
import {CultureHubParams} from '../../core/definitions/culture-hub-params';

@Component({
  selector: 'app-culture-hub-importer-folders',
  templateUrl: './culture-hub-importer-folders.component.html',
  styleUrls: ['./culture-hub-importer-folders.component.scss']
})
export class CultureHubImporterFoldersComponent implements OnInit {

  @Input() dialogRef: MatDialogRef<any>;
  @Input() cultureHubData: CultureHubParams;

  displayedColumns = [  // Column order for the table
    'selected',
    'folderName',
    'remoteListName',
    'sourceUpdatedAt',
    'updated',
    'localUpdatedAt',
    'ownerType'
  ];
  cultureHubFolderDataSource: CultureHubFolder[] = [];   // DataSource from the DB
  sortedDataSource: CultureHubFolder[] = [];   // Sorted DataSource
  selectedMap: Map<any, any> = new Map(); // Hold artifact_id of selected folders
  importedStatusMessage: string[] = []; // Holds the status message for import status alert
  search = ''; // used for search filter
  progress: number = null; // Progress for progressbar

  errors = [];
  importing = false;

  constructor(private cms: CmsApiService,
              private dateTools: DateToolsService,
              private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.init().then();

  }

  async init() {
    try {
      this.importing = true;
      this.cultureHubFolderDataSource = await this.cms.retrieveCultureHubFolders({
        concept_type_id: this.cultureHubData.concept_type_id,
        suppressErrHandler: true
      });
    } catch (e) {
      this.errors.push('TRANS__CULTURE_HUB_FOLDERS__RETRIEVING_FOLDERS_FAILED');
      this.errors.push(e.error?.message);
    }
    this.sortedDataSource = this.deepCopy(this.cultureHubFolderDataSource);
    this.importing = false;
  }

  async runImport() {
    this.progress = 1;
    this.importing = true;

    let index = 1; // Used for progress calculation
    const length = this.selectedMap.size; // get Map Size
    const removeSelected = []; // Holds artifact_id to be removed after loop

    for (const [artifact_id, folder] of this.selectedMap.entries()) {
      try {
        await this.cms.updateCultureHubFolders({
          concept_type_id: this.cultureHubData.concept_type_id,
          artifact_id: artifact_id,
          suppressErrHandler: true
        })
        this.importedStatusMessage.push(`<strong>${folder.name.name}:</strong>&nbsp;Ok`)
        removeSelected.push(artifact_id);
      } catch (e) {
        this.importedStatusMessage.push(`<strong>${folder.name.name}:</strong>&nbsp;Feilet`)
        this.progress = 100;
        break;
      }
      this.progress = (index * 100) / length;
      index++; // increase index
    }

    const dialogRef = this.dialog.open(AlertDialogComponent, {
      panelClass: 'alert-dialog',
      data: {
        modalTitle: 'TRANS__CULTURE_HUB_IMPORTER__IMPORT_STATUS',
        modalContent: this.importedStatusMessage,
      } as ConfirmDialogData
    });

    dialogRef.afterClosed().subscribe(() => {
      this.progress = null;
      this.importedStatusMessage = [];
      removeSelected.forEach(artifact_id => {
        this.selectedMap.delete(artifact_id);
      })
    });

    this.importing = false;
    await this.init();
  }

  closeImport() {
    this.dialogRef.close();
  }

  /**
   * Converts input to Date
   * @param isoDate
   */
  getDateFromIso(isoDate: string) {
    if (isoDate) {
      return this.dateTools.isoDateToString(isoDate);
    } else {
      return '';
    }
  }

  /**
   * Handles changes to selected checkbox
   * @param folder
   */
  selectFolder(folder) {
    if (!this.selectedMap.has(folder.artifact_id)) {
      this.selectedMap.set(folder.artifact_id, folder);
    } else {
      this.selectedMap.delete(folder.artifact_id);
    }
  }

  /**
   * Sort the table
   * @param sort sorting criteria
   */
  sortData(sort: Sort) {
    const data = this.deepCopy(this.cultureHubFolderDataSource);

    if (!sort.active || sort.direction === '') {
      this.sortedDataSource = data;
      return;
    }

    this.sortedDataSource = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'selected':
          return this.compareSelect(a, b, isAsc);
        case 'folderName':
          return this.compare(a.name.name?.toLowerCase(), b.name.name?.toLowerCase(), isAsc);
        case 'remoteListName':
          return this.compare(a.remote_list.remote_list_name?.toLowerCase(), b.remote_list.remote_list_name?.toLowerCase(), isAsc);
        case 'sourceUpdatedAt':
          return this.compare(a.source_updated_at, b.source_updated_at, isAsc);
        case 'updated':
          return this.compare(!!a.imported_date, !!b.imported_date, isAsc);
        case 'localUpdatedAt':
          return this.compare(a.imported_date, b.imported_date, isAsc);
        case 'ownerType':
          return this.compare(a.folder_type.folder_type_id_value?.toLowerCase(),
            b.folder_type.folder_type_id_value?.toLowerCase(), isAsc);
        default:
          return 0;
      }
    });
  }

  /**
   * Used to compare input a and b
   * @param a
   * @param b
   * @param isAsc
   */
  private compare(a: number | string | boolean, b: number | string | boolean, isAsc: boolean) {
    if (!a || !b) {
      return (!a ? 1 : -1) * (isAsc ? 1 : -1);
    } else {
      return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }
  }

  /**
   * Used to compare selected
   * @param a
   * @param b
   * @param isAsc
   */
  private compareSelect(a: CultureHubFolder, b: CultureHubFolder, isAsc: boolean
  ) {
    if (!this.selectedMap.has(a.artifact_id) || !this.selectedMap.has(b.artifact_id)) {
      return (this.selectedMap.has(a.artifact_id) ? -1 : 1) * (isAsc ? 1 : -1);
    } else {
      return 0;
    }
  }

  /**
   * Deep Copy
   * @param data input to this method
   */
  private deepCopy(data: any[]) {
    return JSON.parse(JSON.stringify(data));
  }

  /**
   * Check if both dates are not null
   * Then do a compare returning true if updated_at is before source_updated_at
   * @param folder
   */
  isBefore(folder) {
    if (!folder.source_updated_at || !folder.updated_at) {
      return false;
    } else {
      const momentImported = moment(folder.updated_at);
      const momentChanged = moment(folder.source_updated_at);
      return momentImported.isBefore(momentChanged);
    }
  }
}
