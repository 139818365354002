import {Component, Input, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {FieldStateService} from '../../core/field-state.service';
import {MetaField} from '../../core/definitions/meta-field';

@Component({
  selector: 'app-field-title',
  templateUrl: './field-title.component.html',
  styleUrls: ['./field-title.component.scss']
})
export class FieldTitleComponent implements OnInit {

  AConst = AConst;
  fieldTitle = '';
  inputType;

  @Input() field: MetaField;
  @Input() rootObject;
  @Input() edit;
  @Input() editable: boolean;
  @Input() showToolTip;

  constructor(private fieldState: FieldStateService) { }

  ngOnInit() {
    this.fieldTitle = this.fieldState.getFieldTitle(this.field, this.rootObject, false, false);
    this.inputType = this.field.input_type || '';
  }

}
