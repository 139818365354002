<div class="edit-field-inline-array-item-top"><!--list-inline-array-item-content-->

  <a class="edit-field-inline-array-item-top__header"
     (click)="toggleOpenItem()"
     tabindex="0"
     (keyup.enter)="toggleOpenItemWithKey()">

    <div *ngIf="container && container.values"
         class="inline-array-header">

      <div *ngFor="let label of container.labels"
           class="inline-array-header__container"
           [ngClass]="{'textLabel': label.type === 'text' || label.name === 'url'}">

        <app-inline-array-header-item
          *ngIf="label.name !== '_destroy'"
          [label]="label"
          [container]="container"
        ></app-inline-array-header-item>

      </div>

    </div>

  </a>
</div>


