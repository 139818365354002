import {Component, Input} from '@angular/core';
import {FilterGroupHierarchyNode} from '../../../../core/definitions/search-objects';
import {SearchExecutorService} from '../../../search-executor.service';
import {SearchContainer} from '../../../../core/definitions/search-container';

@Component({
  selector: 'app-search-filter-hierarchy',
  templateUrl: './search-filter-hierarchy.component.html',
  styleUrls: ['./search-filter-hierarchy.component.scss']
})
export class SearchFilterHierarchyComponent {
  @Input() sideMenu;
  @Input() node: FilterGroupHierarchyNode;
  @Input() searchContainer: SearchContainer;
  @Input() nodeQuery: string;

  constructor(private searchExecutorService: SearchExecutorService) { }

  toggleOpen(node: FilterGroupHierarchyNode) {
    if (node.children && Object.keys(node.children).length) {
      node.open = !node.open;
    }
  }

  select(node: FilterGroupHierarchyNode) {
    const lastSelectedNode = this.searchContainer.filtersFacets.hierarchicFilterGroup.lastSelectedNode;
    if (!lastSelectedNode || lastSelectedNode.facet.id !== node.facet.id) {
      if (lastSelectedNode) {
        lastSelectedNode.facet.selected = false;
      }
      this.searchContainer.filtersFacets.hierarchicFilterGroup.lastSelectedNode = node;
      node.facet.selected = true;
    } else {
      lastSelectedNode.facet.selected = false;
      this.searchContainer.filtersFacets.hierarchicFilterGroup.lastSelectedNode = null;
    }
    this.searchExecutorService.runSearch(this.searchContainer).then();
  }

  getChildNodes() {
    if (!this.nodeQuery) {
      return this.node.childrenArray
    } else {
      return this.node.childrenArray.filter(
        child => child.facet.shortName.toLowerCase().indexOf(this.nodeQuery) !== -1);
    }
  }
}
