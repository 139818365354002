import {MetaField} from './meta-field';
import {ObjectStatus} from './object-status';
import {KeepObject} from './keep-object';
import {Section} from './sections-container';
import {SearchObject} from './search-object';

export class BaseModel {
  $$meta: { [name: string]: MetaField };
  $$deletable: boolean;
  $$activeDropdown: boolean;
  $$createdDate: string;
  $$justAdded: boolean;
  $$keep: KeepObject;
  $$icon: string;
  $$index: number;
  $$imageUrl: string;
  $$thumbUrl: string;
  $$parentId: string;
  $$parentObjectType: string;
  $$routerLink: [];
  $$queryParams: any;
  $$open: boolean;
  $$disabled = false;
  $$usage: Array<SearchObject> = [];

  canKeep: boolean;

  _create: boolean;
  _destroy: boolean;
  model_title: string;
  artifact_id: string;
  artifact_id_value: string;
  artifact_name: string;
  authority_id: string;
  created_at: string;
  created_by_id: string;
  created_by_id_value: string;
  code: string;
  context_id: string;
  context_id_value: string;
  filters: any[];
  meta_type: string;
  object_type: string;
  order_number: number;
  updated_at: string;
  updated_by_id_value: string;
  status: ObjectStatus;
  superobject_type_id: string;
  template_sections: Section[];
  tasks: BaseModel[];

  constructor(objectId?: string) {
    this.artifact_id = objectId;
  }
}
