import {Injectable, isDevMode, OnDestroy} from '@angular/core';
import {BehaviorSubject, concat, Observable, of, Subscription, throwError} from 'rxjs';
import {catchError, concatMap, delay, map, mergeMap, retryWhen, skip, tap} from 'rxjs/operators';
import {ResultParser} from './result-parser';
import {LoggerService} from '../logger.service';
import {AccessTokenService} from '../access-token.service';
import {CmsApiService} from '../cms-api.service';


@Injectable({
  providedIn: 'root'
})

/**
 * Service hosting methods used when polling backend for messages,
 * and automatic retry when communication with the backend fails.
 */
export class JobStatusSubscriberService implements OnDestroy {
  public readonly autoStart: boolean = false;
  public parser = new ResultParser();

  private readonly refreshMs: number = 5000;
  private readonly maxRetries: number = 10000;
  private readonly retryDelayMs: number = 2000;
  private localStorageKey = 'mqActive';

  private manuallyInitiatedPoll = false; // Set if poll is initiated by button click.
  private backendRequestor$: Observable<any>; // Observable holding the backend request and refresh logic.
  private backendRequest$ = null; // Observable holding the backend request URL with failover logic.
  private whenToRefresh$ = null;  // Observable holding the timer used to make continuous request at a given interval.

  private load$ = null;           // BehaviorSubject used when loading data continuously.
  private data$ = null;           // Holds data fetched from the request.

  // Store all subscribed observables in this array,
  // in order to clean up correctly later!
  private subscriptions: Subscription[] = [];


  /**
   * Object constructor
   * @param cms
   * @param accessTokenService AccessTokenService used to obtaining kit access token
   * @param logger LoggerService logging service
   */
  constructor(private cms: CmsApiService,
              private accessTokenService: AccessTokenService,
              private logger: LoggerService) {
    if (this.autoStart && !this.isActivePolling()) {
      this.startPolling();
    }
  }

  ngOnDestroy(): void {
    if (this.autoStart) {
      this.stopPolling();
    }
  }

  /**
   * True if active polling has been registered in localStorage.
   */
  private isActivePolling() {
    const state = localStorage.getItem(this.localStorageKey);
    return (state === 'true');
  }

  /**
   * Method used to update localStorage.
   * @param state boolean True if polling is manually initiated.
   */
  private trackPolling(state: boolean) {
    if (!localStorage) {
      this.logger.warn('Unable to track polling.');
    }

    if (!state) {
      localStorage.removeItem(this.localStorageKey);
      this.manuallyInitiatedPoll = false;
    } else {
      localStorage.setItem(this.localStorageKey, state.toString());
    }
  }

  /**
   * Handles http-request errors.
   * @param error Error object
   */
  private handleReqError(error): void {
    if (error.status && error.IMessage) {
      const errorMessage = `Error Code: ${error.status}\nMessage: ${error.IMessage}`;
      this.logger.error(errorMessage);
    }
  }

  /**
   * Retries http-requests after the specified amount of time if an HTTP-request error has occurred.
   */
  private delayedRetry() {
    let retries = this.maxRetries;
    return (src: Observable<any>) =>
      src.pipe(
        retryWhen((errors: Observable<any>) =>
          errors.pipe(
            delay(this.retryDelayMs),
            mergeMap(error => retries-- > 0
              ? of(error) : throwError('Max retries exceeded - giving up.'))
          )
        )
      );
  }

  /**
   * Starts polling the specified URL and parses the data.
   */
  startPolling(manuallyInitiated: boolean = false): void {

    const token = this.accessTokenService.getToken();

    if (!token) {
      this.stopPolling();
    }

    this.manuallyInitiatedPoll = manuallyInitiated;
    if (this.manuallyInitiatedPoll) {
      this.trackPolling(this.manuallyInitiatedPoll);
    }

    // eslint-disable-next-line no-console
    if (isDevMode()) { console.debug('[JOBSTATUS] -- start polling'); }

    this.load$ = new BehaviorSubject('');

    this.backendRequest$ = this.cms.getJobStatus().pipe(
      this.delayedRetry(),
      catchError(async () => this.handleReqError)
    );

    this.whenToRefresh$ = of('').pipe(
      delay(this.refreshMs),
      tap(_ => this.load$.next('')),
      skip(1),
    );

    // noinspection JSDeprecatedSymbols
    this.backendRequestor$ = concat(this.backendRequest$, this.whenToRefresh$);

    this.data$ = this.load$.pipe(
      concatMap(_ => this.backendRequestor$),
      map(response => response)
    );

    this.subscriptions.push(
      this.data$.subscribe(d => {
        if (d === null || d.messages.length === 0) {
          // If no jobs or no messages, quit polling!
          this.stopPolling();
        } else {
          d.messages = this.convertUTCDates(d.messages);
          this.parser.parse(d);
          if (d.messages.filter(m => m.status === 'finished').length === d.messages.length) {
            // If all report jobs has status finished, quit polling!
            this.stopPolling();
          }
        }
      }));
  }

  /**
   * Stops polling.
   */
  stopPolling(): void {
    if (this.manuallyInitiatedPoll) {
      this.trackPolling(false);
    }
    this.subscriptions.forEach(s => s.unsubscribe()); // Unsubscribe to all active observables.
    this.subscriptions = [];                                    // Clean array of observables
    this.load$ = null;
    this.whenToRefresh$ = null;
    this.backendRequestor$ = null;
    this.data$ = null;
    this.backendRequest$ = null;
    // eslint-disable-next-line no-console
    if (isDevMode()) { console.debug('[JOBSTATUS] -- no active jobs, stopped polling'); }
  }

  convertUTCDates(messages) {
    function dateStrToDateInts(dateStr) {
      const parts = dateStr.split(' ');
      const dateParts = parts[0].split('.');
      const timeParts = parts[1].split(':');
      const dateInts = [];
      [2, 1, 0].forEach(p => dateInts.push(dateParts[p]));
      [0, 1, 2].forEach(t => dateInts.push(timeParts[t]));
      return dateInts;
    }

    function dateTimeToLocal(dateStr) {
      if (!dateStr || dateStr === '') {
        return '';
      }
      const dateInts = dateStrToDateInts(dateStr);
      const utc = new Date(Date.UTC(dateInts[0], dateInts[1], dateInts[2], dateInts[3], dateInts[4], dateInts[5]));
      return dateInts[2] + '.' + dateInts[1] + '.' + dateInts[2] + ' ' + utc.toLocaleTimeString();
    }

    return messages.map(m => {
      return {
        ...m,
        started: dateTimeToLocal(m.started),
        ended: dateTimeToLocal(m.ended),
        registered: dateTimeToLocal(m.registered)
      };
    });
  }

}
