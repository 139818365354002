import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AdminLocationPlaceService} from '../admin-location-place.service';
import {MetaField} from '../../../core/definitions/meta-field';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {CreateSectionsContainerParams, ObjectEditService} from '../../../core/object-edit.service';
import {UiToolsService} from '../../../core/ui-tools.service';
import {TranslateService} from '@ngx-translate/core';

export interface AdminLocationFormModalData {
  id: string;
  objectType: string;
  parentId?: string;
  headingCreateNew: string;
  headingEditExisting: string;
  currentLevelName?: string;
}

@Component({
  selector: 'app-admin-location-place-form-modal',
  templateUrl: './admin-location-place-form-modal.component.html',
  styleUrls: ['./admin-location-place-form-modal.component.scss']
})
export class AdminLocationPlaceFormModalComponent implements OnInit {
  sectionsContainer: SectionsContainer;
  primeFields: Array<MetaField>;
  model;
  parentName: string;
  private oldInvalidFieldLength = 0;
  private invalidFieldText = '';

  // validValueBeforeEdit: boolean;
  // validValueAfterEdit: boolean;
  /**
   * Number of objects this location has been referenced from, if any.
   */
  objectCount: number;
  /**
   * A concatenated string of the names for all the
   * objects referencing this location, if any,
   */
  locationUsedIn: string;
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };

  @HostListener('click', ['$event.target'])
  registerClick(event) {
    this.uiTools.registerDocumentClick(event);
  }

  constructor(private readonly locationService: AdminLocationPlaceService,
              private objectEditService: ObjectEditService,
              private translate: TranslateService,
              public dialogRef: MatDialogRef<AdminLocationPlaceFormModalComponent>,
              private uiTools: UiToolsService,
              @Inject(MAT_DIALOG_DATA) readonly data: AdminLocationFormModalData) {
    // this.model = this.data.objectType;
    // this.valueChangeSubscription = this.formGroup.valueChanges.subscribe(this.handleFormValueChanged.bind(this));
  }

  async ngOnInit() {
   if (this.data?.id) {
     this.model = await this.locationService.getLocation(this.data.id);
     this.sectionsContainer = await this.createObjectSectionsContainer(this.model);
   } else {
     this.model = await this.locationService.getDefaultLocationModel(this.data.objectType);
     this.model.parent_id = this.data?.parentId;
     this.model.parent_id_value = this.data?.currentLevelName;
     this.sectionsContainer = await this.createObjectSectionsContainer();
   }
   if (this.data?.currentLevelName) {
     this.parentName = this.data.currentLevelName;
   }
   // this.validValueBeforeEdit = this.model.valid;
   if (this.model?.artifact_id && this.data.objectType === 'Location') {
     const res = await this.locationService.getObjectNamesWhereLocationIsUsed(this.model.artifact_id);
     this.objectCount = res.numObjects;
     this.locationUsedIn = res.objects?.map(o => o.name).join(', ');
   }
  }

  private async createObjectSectionsContainer(object?): Promise<SectionsContainer> {
    const useExisting = !!object;
    let sectionsContainer: SectionsContainer;
    if (object?.artifact_id) {
      sectionsContainer = await this.objectEditService.loadObjectGetSectionsContainer(object.artifact_id, false);
      sectionsContainer.isDialog = true;
      return sectionsContainer;
    } else {
      const params = {
        useExistingObject: useExisting,
        usePrimeFields: this.data.objectType === 'Location' ? !useExisting : false,
        objectType: this.data.objectType,
        objectId: object ? object.artifact_id : null,
        object: object ? object : this.model,
        getSourceObject: false
      } as CreateSectionsContainerParams;

      sectionsContainer = await this.objectEditService.createSectionsContainer(params);
      sectionsContainer.isDialog = true;

      if (params.usePrimeFields) {
        this.primeFields = sectionsContainer.primeFields;
      }

      return sectionsContainer;
    }
  }

  async handleMoveObjectsToNewLocation(): Promise<void> {
    throw Error('Not implemented');
  }

  // isLocationChangedToInvalid(): boolean {
  //   return this.validValueAfterEdit !== this.validValueBeforeEdit && this.validValueAfterEdit;
  // }

  get disabled() {
    return !this.sectionsContainer ||
      this.sectionsContainer.formGroup.invalid ||
      !this.sectionsContainer.formGroup.dirty;
  }

  get disabledReason() {
    let text = '';
    if (this.disabled) {
      if ( this.sectionsContainer.rootObject.authority_id) {
        text = this.translate.instant('TRANS__DISABLE_REASON__HAS_AUTHORITY');
      } else if (this.sectionsContainer?.formGroup.invalid) {
        const invalidFields = this.getInvalidFields();
        if (invalidFields.length !== this.oldInvalidFieldLength) {
          this.oldInvalidFieldLength = invalidFields.length;
          this.invalidFieldText = this.generateDisabledReason(invalidFields);
        }
        text = this.invalidFieldText;
      } else {
        text = this.translate.instant('TRANS__DISABLE_REASON__NO_CHANGES');
      }
    } else {
      this.oldInvalidFieldLength = 0;
    }
    return text;
  }

  private getInvalidFields() {
    const res = [];
    for (const name in this.sectionsContainer.formGroup.controls) {
      if (this.sectionsContainer.formGroup.controls[name].invalid) {
        res.push(name);
      }
    }
    return res;
  }

  private generateDisabledReason(invalidFields: string[]) {
    let text = this.translate.instant('TRANS__VALIDATION__INVALID_FIELDS');
    text += ': ';
    let add = '';
    for (const name of invalidFields) {
      const firstName = name.split('.')[0];
      const fieldMeta = this.sectionsContainer.rootObject.$$meta[firstName];
      const fieldTitle = fieldMeta.title || fieldMeta.admin_title;
      text += add + this.translate.instant(fieldTitle);
      add = ', ';
    }
    return text;
  }

  // handleAbortClicked(): void {
  //   this.dialogRef.close(false);
  // }

  handleSaveClicked(): void {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      value => {
        this.dialogRef.close(value);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  // private async handleFormValueChanged(val: Partial<Location>): Promise<void> {
  //   if (val && val.hasOwnProperty('valid')) {
  //     this.validValueAfterEdit = val.valid;
  //   }
  //   // this.cdr.markForCheck();
  // }
}
