<div class="edit-field-search-selector-multiple-item-list">
  <mat-chip-list *ngIf="itemsWithLimit.length > 0"
                 class="mat-chip-list-stacked edit-field-search-selector-multiple-item-list__selected-options"
                 cdkDropList
                 cdkDropListOrientation="vertical"
                 (cdkDropListDropped)="drop($event)">
    <ng-container *ngFor="let item of itemsWithLimit; index as i">
      <mat-chip *ngIf="!item._destroy"
                cdkDrag
                [cdkDragDisabled]="reference?.search_display_limit >= 1"
                [removable]="!reference?.ref_delete_disabled && (item.$$deletable || item.$$justAdded)"
                (removed)="deleteItemByIndex(item.$$index)">
        <app-object-icon *ngIf="item"
                         class="edit-field-search-selector-multiple-item-list__selected-option-icon"
                         [object]="item.$$object"></app-object-icon>
        <span *ngIf="item" class="edit-field-search-selector-multiple-item-list__selected-option-name">{{item.$$object.artifact_name}}</span>
        <a *ngIf="(reference?.can_edit_existing || reference?.can_edit_context) && !sectionsContainer.isCopy"
           (click)="editItem(item)"
           class="text-blue-hover edit-field-search-selector-multiple-item-list__selected-option-edit">
          <i class="icon-edit-alternative"></i></a>
        <mat-icon *ngIf="!reference?.ref_delete_disabled" matChipRemove>cancel</mat-icon>
      </mat-chip>
    </ng-container>
  </mat-chip-list>
</div>
