import { Injectable } from '@angular/core';
import {ModelsService} from './models.service';
import {MetaField} from './definitions/meta-field';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';

export class TraverseObjectResult {
  parentFieldName: string;
  fieldName: string;
  subObject: BaseModel;
  parentObject: BaseModel;
  parentIndex: number;
  parentKey: string;
}

@Injectable({
  providedIn: 'root'
})
export class ObjectFieldTraverseService {

  constructor(private modelsSvc: ModelsService) { }

  traverseObjectByPath(object: BaseModel, fieldPath, pathIndexOffset, createArrayElement?): TraverseObjectResult {
    let subObject = object;
    let parentObject = object;
    let fieldName = '';
    let parentFieldName = '';
    let parentIndex = null;
    let parentKey = '';
    let keySep = '';
    if (fieldPath) {
      const fieldNameSplits = fieldPath.split('.');
      fieldNameSplits.forEach((fieldNameSplit, index) => {
        const arrIndexRes = this.getFieldArrayIndex(fieldNameSplit);
        fieldName = arrIndexRes.fieldName;
        parentKey += keySep + fieldNameSplit;
        keySep = '.';
        if (subObject && index < fieldNameSplits.length - pathIndexOffset) {
          parentObject = subObject;
          subObject = subObject[fieldName];
          if (subObject) {
            if (Array.isArray(subObject)) {
              // Field path either contains array indexes, in which case the correct array items must be traversed,
              // or not, in which case the first element in the arrays are traversed because the indexes don't matter
              if (arrIndexRes.arrIndex !== -1) {
                subObject = subObject[arrIndexRes.arrIndex];
              } else {
                parentKey += '[{index1}]';
                if (subObject.length > 0) {
                  subObject = subObject[0];
                  parentIndex = 0;
                } else if (createArrayElement) {
                  const arrayMeta = object.$$meta[fieldName];
                  const model = this.modelsSvc.getModelCopy(arrayMeta.inline.model);
                  subObject.push(model);
                  subObject = subObject[0];
                  // This makes sure array based prime fields work
                  parentIndex = 0;
                } else {
                  console.warn('No items in array ' + fieldName);
                }
              }
            }
          }
        }
        if (index < fieldNameSplits.length - pathIndexOffset - 1) {
          parentFieldName = fieldName;
        }
      });
    }
    const res = new TraverseObjectResult();
    res.parentFieldName = parentFieldName;
    res.fieldName = fieldName;
    res.subObject = subObject;
    res.parentObject = parentObject;
    res.parentIndex = parentIndex;
    res.parentKey = parentKey;
    return res;
  }

  getSubObjectFromField(object: BaseModel, field: MetaField): BaseModel {
    let subObject = object;
    if (field.path) {
      const traverseRes = this.traverseObjectByPath(object, field.path, 0);
      subObject = traverseRes.subObject;
    }
    return subObject;
  }

  getFieldMetaAndSubObjectFromPath(object: SuperObjectModel, fieldPath: string) {
    const traverseRes = this.traverseObjectByPath(object, fieldPath, 1);
    let subObject = traverseRes.subObject;
    let subMeta;
    if (subObject) {
      let meta = subObject.$$meta;
      if (!meta && traverseRes.parentFieldName) {
        const parentFieldMeta = object.$$meta[traverseRes.parentFieldName];
        meta = this.modelsSvc.getModelMeta(parentFieldMeta.inline.model);
      }
      if (meta) {
        subMeta = meta[traverseRes.fieldName];
        if (!subMeta) {
          console.warn('Could not find meta field ' + traverseRes.fieldName);
        } else {
          if (subMeta.inline) {
            subObject = this.modelsSvc.getModelCopy(subMeta.inline.model);
          }
        }
      } else {
        subMeta = {};
        console.warn('Sub object missing meta');
      }
    } else {
      subMeta = {};
      console.warn('Could not find sub object for ' + fieldPath);
    }

    return {
      fieldMeta: subMeta,
      subObject: subObject
    };
  }

  private getFieldArrayIndex(fieldNameIn: string) {
    const bracketPos = fieldNameIn.indexOf('[');
    let arrIndex = -1;
    let fieldName = fieldNameIn;
    if (bracketPos !== -1) {
      fieldName = fieldNameIn.substring(0, bracketPos);
      arrIndex = Number(fieldNameIn.substring(bracketPos + 1, fieldNameIn.indexOf(']')));
    }
    return {
      fieldName: fieldName,
      arrIndex: arrIndex
    };
  }

}
