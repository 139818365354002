<div class="media-list"
     cdkDropList (cdkDropListDropped)="drop($event)">

  <div *ngIf="files.length > 1"
       class="media-list__process-section">
    <mat-expansion-panel class="no-active-blue-color media-list__select-button"
                         #commonReg
                         (opened)="commonRegVisible = true"
                         (closed)="commonRegVisible = false"
                         [disabled]="parentObject.$$annotateImage || disableCommonReg()">
      <mat-expansion-panel-header
        [matTooltip]="'TRANS__MEDIA_LIST__DISABLE_COMMON_REGISTRATION_BUTTON_TOOLTIP' | translate"
        matTooltipPosition="above"
        [matTooltipDisabled]="!disableCommonReg()">

        <mat-checkbox
          class="media-list__select-button-checkbox white-bg-in-checkbox"
          (ngModelChange)="checkAll($event, commonReg)"
          [(ngModel)]="allChecked"
          [checked]="allChecked"
          [indeterminate]="checkIfFileIsSelected()">
          <span translate="TRANS__MEDIA_LIST__SELECT_ALL"></span></mat-checkbox>

        <span [ngClass]="{'bold': commonReg.expanded}"
              translate="TRANS__MEDIA_LIST__SET_SIMILAR_INFORMATION"></span>
      </mat-expansion-panel-header>

      <app-edit-prime-fields
        *ngIf="commonContainer"
        [sectionsContainer]="commonContainer"
      ></app-edit-prime-fields>
    </mat-expansion-panel>
  </div>

  <ng-container *ngFor="let file of getPageFiles(); index as $index">
    <div *ngIf="!file._destroy"
         class="media-list__files"
         [ngClass]="{'active': file['checked'], 'handleSelection':commonRegVisible && file['checked']}"
         cdkDragBoundary=".media-list" cdkDrag
         [cdkDragPreviewClass]="isDialog ? 'dialog' : ''">
      <!-- Take a look at this at a later time to se what we can do with the Warning -->
      <div class="custom-placeholder" *cdkDragPlaceholder></div>
      <div class="media-list__files-container">
        <div class="media-list__files-img-container">
          <div class="checkbox-underlay"
               [ngClass]="{'disabled': parentObject.$$annotateImage}">
            <mat-checkbox
              class="white-bg-in-checkbox"
              [(ngModel)]="file['checked']"
              (ngModelChange)="fileChecked(file)"
              [checked]="file['checked']">
            </mat-checkbox></div>

          <app-thumbnail
            class="media-list__img"
            [item]="file"
            [imageIdField]="getObjectIdField(file)"
            [viewMode]="'thumbnail'"
            [stylingClass]="'media-list-image'"
          ></app-thumbnail>

          <button *ngIf="canAnnotate(file)"
             (click)="annotateImage(file)"
             class="media-list__files-img-container__annotate-button"
             [ngClass]="{
       'active': parentObject.$$annotateImage && file.$$annotateImage,
       'disabled': parentObject.$$annotateImage && !file.$$annotateImage}">
            <i class="icon-annotate"></i>
            <span translate="TRANS__IMAGE_SELECTOR__ANNOTATE_IMAGE"></span>
          </button>
        </div>
        <div class="media-list__files-content"
             [ngClass]="{'disabled': parentObject.$$annotateImage}">
          <div class="media-list__files-content-buttons">
            <app-dropdown-menu [icon]="'settings'" class="media-list__files-content-setting-button">
              <button mat-menu-item (click)="deleteFile($index)">
                <span translate="TRANS__DELETE_ITEM__DELETE"></span></button>
            </app-dropdown-menu>
            <button class="media-list__files-content-move-button"
                    mat-button
                    [ngClass]="{'disabled': files.length <= 1}"
                    cdkDragHandle>
              <i class="icon-row-sort"></i></button>
          </div>
          <app-edit-prime-fields
            *ngIf="getFileContainer(file)"
            [sectionsContainer]="fileContainers[file.$$id]"
          ></app-edit-prime-fields>
        </div>
      </div>
    </div>
  </ng-container>
  <div *ngIf="files.length">
    <span translate="TRANS__MEDIA_LIST__PAGE_NUMBER"></span>
    <ng-container *ngFor="let page of getPages()">
      <button mat-button (click)="setPage(page)">{{page + 1}}</button>
    </ng-container>
  </div>
</div>
