<div class="edit-field-precision-date-range"
     [formGroup]="fieldParameters.sectionsContainer.formGroup"
     *ngIf="fromDateControlName">

  <div class="edit-field-precision-date-range__first-field">
    <app-edit-field-date-historic
      *ngIf="fromDateField.input_type === 'date_iso'"
      [fieldParameters]="fieldParameters"
      [field]="fromDateField"
      [formControlName]="fromDateControlName"
      [otherFormControlName]="toDateControlName"
      [placeholder]="placeholderFromDate | translate"
    ></app-edit-field-date-historic>
    <app-edit-field-date-picker
      *ngIf="fromDateField.input_type == 'date_time_iso'"
      [fieldParameters]="fieldParameters"
      [field]="fromDateField"
      [otherField]="toDateField"
      [formControlName]="fromDateControlName"
      [otherFormControlName]="toDateControlName"
      [placeholder]="placeholderFromDate | translate"
    ></app-edit-field-date-picker>
  </div>
    <div class="edit-field-precision-date-range__second-field">
      <app-edit-field-date-historic
        *ngIf="fromDateField.input_type === 'date_iso'"
        [fieldParameters]="fieldParameters"
        [field]="toDateField"
        [formControlName]="toDateControlName"
        [otherFormControlName]="fromDateControlName"
        [placeholder]="placeholderToDate | translate"
      ></app-edit-field-date-historic>
      <app-edit-field-date-picker
        *ngIf="fromDateField.input_type !== 'date_iso'"
        [fieldParameters]="fieldParameters"
        [field]="toDateField"
        [otherField]="fromDateField"
        [formControlName]="toDateControlName"
        [otherFormControlName]="fromDateControlName"
        [isToDate]="true"
        [placeholder]="placeholderToDate | translate"
      ></app-edit-field-date-picker>
    </div>
</div>
