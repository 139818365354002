import {MediaItem} from './media-item';
import {SuperObjectModel} from './super-object-model';

export class Media {
  $$selected: boolean;
  $$parentId: string;

  mediaObject: SuperObjectModel; // The standalone media object
  mediaItem: MediaItem; // The media item as represented in a parent object (ImageItem, VideoItem etc)
}
