import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {ObjectNewPageComponent} from './object-new-page.component';
import {Observable} from 'rxjs';
import {PrimusRouterService} from '../../core/primus-router.service';

@Injectable()
export class CanDeactivateObjectNewPage implements CanDeactivate<ObjectNewPageComponent> {
  constructor(private primusRouter: PrimusRouterService) {
  }

  canDeactivate(
    component: ObjectNewPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.primusRouter.checkCancel();
  }
}
