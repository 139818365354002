import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AdminSettingsComponent} from './admin-settings/admin-settings.component';
import {AdminConceptListsComponent} from './admin-concept-lists/admin-concept-lists.component';
// eslint-disable-next-line max-len
import {AdminListActionToolbarComponent} from './admin-lists/admin-list-action-toolbar/admin-list-action-toolbar.component';
import {AdminListComponent} from './admin-lists/admin-list/admin-list.component';
// eslint-disable-next-line max-len
import {AdminListFilterMenuComponent} from './admin-lists/admin-list-filter-menu/admin-list-filter-menu.component';
// eslint-disable-next-line max-len
import {AdminListQueryComponent} from './admin-lists/admin-list-query/admin-list-query.component';
import {AdminEditItemComponent} from './admin-lists/admin-edit-item/admin-edit-item.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {SharedModule} from '../shared/shared.module';
import {AdminListTableComponent} from './admin-lists/admin-list-table/admin-list-table.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ObjectViewModule} from '../object-view/object-view.module';
import {MatLegacyCardModule as MatCardModule} from '@angular/material/legacy-card';
import {AdminSettingsSettingComponent} from './admin-settings/admin-settings-setting/admin-settings-setting.component';
import {AdminSettingsFieldValueComponent} from './admin-settings/admin-settings-field-value/admin-settings-field-value.component';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {AdminSettingsFaqComponent} from './admin-settings/admin-settings-faq/admin-settings-faq.component';
import {MatExpansionModule} from '@angular/material/expansion';
// eslint-disable-next-line max-len
import {AdminListItemActionComponent} from './admin-lists/admin-list-item-action/admin-list-item-action.component';
import {MatIconModule} from '@angular/material/icon';
import {AdminListItemSpecialValueComponent} from './admin-lists/admin-list-item-special-value/admin-list-item-special-value.component';
import {AdminListSettingsComponent} from './admin-lists/admin-list-settings/admin-list-settings.component';
import {AdminActionListMenuComponent} from './admin-lists/admin-list-action-menu/admin-action-list-menu.component';
import {AdminIdFormatComponent} from './admin-id-format/admin-id-format.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {AdminIdFormatGlobalViewComponent} from './admin-id-format/admin-id-format-global-view/admin-id-format-global-view.component';
import {AdminIdFormatCollectionViewComponent} from './admin-id-format/admin-id-format-collection-view/admin-id-format-collection-view.component';
import {AdminIdFormatFilterComponent} from './admin-id-format/admin-id-format-edit-section/admin-id-format-filter/admin-id-format-filter.component';
import {AdminIdFormatCollectionListComponent} from './admin-id-format/admin-id-format-collection-list/admin-id-format-collection-list.component';
import {AdminIdFormatEditSectionComponent} from './admin-id-format/admin-id-format-edit-section/admin-id-format-edit-section.component';
import {MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {AdminIdFormatEditComponent} from './admin-id-format/admin-id-format-edit/admin-id-format-edit.component';
import {AdminUsersComponent} from './admin-users/admin-users.component';
import {AdminUsersFilterComponent} from './admin-users/admin-users-filter/admin-users-filter.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {AdminUsersProfilePageComponent} from './admin-users/admin-users-profile-page/admin-users-profile-page.component';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import { UserDeletedDialogComponent } from './admin-users/admin-users-profile-page/user-deleted-dialog/user-deleted-dialog.component';
import { UserProfileSectionComponent } from './admin-users/admin-users-profile-page/user-profile-section/user-profile-section.component';
import { UserInfoSectionComponent } from './admin-users/admin-users-profile-page/user-info-section/user-info-section.component';
import { AccessRightsSectionComponent } from './admin-users/admin-users-profile-page/access-rights-section/access-rights-section.component';
import { ProfileImageUploaderComponent } from './admin-users/admin-users-profile-page/profile-image-uploader/profile-image-uploader.component';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { AdminLocationComponent } from './admin-location-place/admin-location/admin-location.component';
import { AdminLocationFilterComponent } from './admin-location-place/admin-location/admin-location-filter/admin-location-filter.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import { AdminLocationPlaceFormModalComponent } from './admin-location-place/admin-location-place-form-modal/admin-location-place-form-modal.component';
import { AdminConceptJoinConceptsComponent } from './admin-concept-lists/admin-concept-join-concepts/admin-concept-join-concepts.component';
import { AdminConceptQueryConceptTypesComponent } from './admin-concept-lists/admin-concept-query-concept-types/admin-concept-query-concept-types.component';
import { AdminConceptObjectFilterMenuComponent } from './admin-concept-lists/admin-concept-object-filter-menu/admin-concept-object-filter-menu.component';
import {MatTreeModule} from '@angular/material/tree';
import { AdminConceptConceptListSelectorComponent } from './admin-concept-lists/admin-concept-concept-list-selector/admin-concept-concept-list-selector.component';
import {AdminMatPaginatorIntl} from './admin-mat-paginator-intl';
import {AdminMainPageComponent} from './admin-main-page/admin-main-page.component';
import {AdminCreateNewComponent} from './admin-create-new/admin-create-new.component';
import {FaqPageComponent} from './faq-page/faq-page.component';
import {AboutPrimusComponent} from './about-primus/about-primus.component';
import {AdminTemplateGroupComponent} from './admin-template-group/admin-template-group.component';
import {AdminTemplateModelComponent} from './admin-template-model/admin-template-model.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {AdminPlaceComponent} from './admin-location-place/admin-place/admin-place.component';
import {AdminPlaceFilterComponent} from './admin-location-place/admin-place/admin-place-filter/admin-place-filter.component';
import {AdminConceptParentConceptSelectorComponent} from './admin-concept-lists/admin-concept-parent-concept-selector/admin-concept-parent-concept-selector.component';
import { AdminCollectionComponent } from './admin-collection/admin-collection.component';
import {RouterModule} from '@angular/router';
import { AdminAgentComponent } from './admin-agent/admin-agent.component';
import {OperationsModule} from '../operations/operations.module';
import { AdminNamedObjectComponent } from './admin-named-object/admin-named-object.component';
import { UserCollectionsComponent } from './admin-users/admin-users-profile-page/user-collections/user-collections.component';
import { UserCollectionsAdderComponent } from './admin-users/admin-users-profile-page/user-collections-adder/user-collections-adder.component';
import { UserCollectionRightsTypeSetterComponent } from './admin-users/admin-users-profile-page/user-collection-rights-type-setter/user-collection-rights-type-setter.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import { AdminServiceComponent } from './admin-service/admin-service.component';
import { AdminServiceFieldsComponent } from './admin-service/admin-service-fields/admin-service-fields.component';
import { AdminServiceApiComponent } from './admin-service/admin-service-api/admin-service-api.component';
import { AdminServiceIndexComponent } from './admin-service/admin-service-index/admin-service-index.component';


@NgModule({
  declarations: [
    AdminMainPageComponent,
    AdminCreateNewComponent,
    FaqPageComponent,
    AdminSettingsComponent,
    AdminConceptListsComponent,
    AdminListActionToolbarComponent,
    AdminListComponent,
    AdminListFilterMenuComponent,
    AdminListTableComponent,
    AdminListQueryComponent,
    AdminEditItemComponent,
    AdminSettingsSettingComponent,
    AdminSettingsFieldValueComponent,
    AdminSettingsFaqComponent,
    AdminListItemActionComponent,
    AdminListItemSpecialValueComponent,
    AdminListSettingsComponent,
    AdminActionListMenuComponent,
    AdminIdFormatComponent,
    AdminIdFormatGlobalViewComponent,
    AdminIdFormatCollectionViewComponent,
    AdminIdFormatFilterComponent,
    AdminIdFormatCollectionListComponent,
    AdminIdFormatEditSectionComponent,
    AdminIdFormatEditComponent,
    AdminUsersComponent,
    AdminUsersFilterComponent,
    AdminUsersProfilePageComponent,
    UserDeletedDialogComponent,
    UserProfileSectionComponent,
    UserInfoSectionComponent,
    AccessRightsSectionComponent,
    ProfileImageUploaderComponent,
    AdminLocationComponent,
    AdminLocationFilterComponent,
    AdminLocationPlaceFormModalComponent,
    AdminPlaceComponent,
    AdminPlaceFilterComponent,
    AdminConceptJoinConceptsComponent,
    AdminConceptQueryConceptTypesComponent,
    AdminConceptObjectFilterMenuComponent,
    AdminConceptConceptListSelectorComponent,
    AboutPrimusComponent,
    AdminTemplateGroupComponent,
    AdminTemplateModelComponent,
    AdminConceptParentConceptSelectorComponent,
    AdminCollectionComponent,
    AdminAgentComponent,
    AdminNamedObjectComponent,
    UserCollectionsComponent,
    UserCollectionsAdderComponent,
    UserCollectionRightsTypeSetterComponent,
    AdminServiceComponent,
    AdminServiceFieldsComponent,
    AdminServiceApiComponent,
    AdminServiceIndexComponent
  ],
    imports: [
        FormsModule,
        MatTableModule,
        MatButtonModule,
        MatProgressSpinnerModule,
        MatMenuModule,
        MatCheckboxModule,
        MatRadioModule,
        MatCardModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        CommonModule,
        SharedModule,
        ObjectViewModule,
        ObjectEditModule,
        MatExpansionModule,
        ReactiveFormsModule,
        MatIconModule,
        MatTabsModule,
        MatPaginatorModule,
        MatSortModule,
        MatTooltipModule,
        MatListModule,
        ScrollingModule,
        MatChipsModule,
        MediaSelectorModule,
        MatDialogModule,
        MatAutocompleteModule,
        MatTreeModule,
        ObjectSearchModule,
        RouterModule,
        OperationsModule,
        MatSlideToggleModule,
    ],
  providers: [
    { provide: MatPaginatorIntl, useClass: AdminMatPaginatorIntl }
  ]
})
export class AdministrationModule {
}
