import {Component, forwardRef, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldValueService} from '../../core/field-value.service';
import {FieldType} from '../../core/definitions/field-type.enum';
import {HierarchicNode} from '../../core/definitions/hierarchic-objects';
import {MatExpansionPanel} from '@angular/material/expansion';
import {CurrentQuery} from '../../core/definitions/current-query';
import {BaseModel} from '../../core/definitions/base-model';
import {HierarchicListPanelComponent} from './hierarchic-list-panel/hierarchic-list-panel.component';
import {HierarchicSearchPanelComponent} from './hierarchic-search-panel/hierarchic-search-panel.component';
import {UiToolsService} from '../../core/ui-tools.service';
import {OptionsService} from '../../core/options.service';
import {FieldConditionService} from '../../core/field-condition.service';
import {IfType} from '../../core/definitions/field-if';
import {SearchReferenceService} from "../../core/search-reference.service";
import {Reference} from "../../core/definitions/reference";

@Component({
  selector: 'app-edit-field-hierarchic-select',
  templateUrl: './edit-field-hierarchic-select.component.html',
  styleUrls: ['./edit-field-hierarchic-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldHierarchicSelectComponent)
    }
  ]
})
export class EditFieldHierarchicSelectComponent implements OnChanges, OnDestroy, ControlValueAccessor {
  @ViewChild(MatExpansionPanel) expansionPanel: MatExpansionPanel;
  @ViewChild('listPanel') listPanel: HierarchicListPanelComponent;
  @ViewChild('searchPanel') searchPanel: HierarchicSearchPanelComponent;
  @Input() fieldParameters: FieldParameters;
  @Input() formControlName: string;

  isArray: boolean;
  textField: string;
  isSingleItemArray = false;
  query = {
    value: ''
  } as CurrentQuery;
  currentOpenNode: HierarchicNode;
  panelHasBeenOpened = false;
  searchPanelOpen = false;
  items: BaseModel[] = [];
  selectedSearchViewIndex = 0;
  reference: Reference;
  private idFieldName;
  private clickListenerId;

  constructor(private fieldValueService: FieldValueService,
              private uiTools: UiToolsService,
              private optionsService: OptionsService,
              private fieldCondition: FieldConditionService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnChanges(): void {
    this.init().then();
  }

  onNodeSelected(node: HierarchicNode) {
    this.setNodes([node]);
  }

  private async init() {
    this.reference = await this.searchReferenceService.getSearchReferenceFromField(this.fieldParameters.field);
    this.fieldParameters.canAddNew = await this.optionsService.getCanAddNew(this.reference);
    this.isArray = this.fieldParameters.field.field_type === FieldType.ARRAY;
    this.isSingleItemArray = this.fieldValueService.isSingleItemArray(this.fieldParameters);
    const uniqueProps = this.fieldParameters.field.inline?.unique_props;
    this.idFieldName = uniqueProps?.length ? uniqueProps[0] : null;
    if (!this.idFieldName) {
      this.idFieldName = this.fieldParameters.field.name;
    }
    this.textField = this.isArray ? `${this.idFieldName}_value` : `${this.fieldParameters.field.name}_value`;
    this.setItems();
    if (this.reference.hierarchic_display_list_view) {
      // Need some time before displaying search list panel else list items will be located to the right of the panel
      setTimeout(() => {
        this.selectedSearchViewIndex = 1;
      }, 300);
    }
  }

  private setNodes(nodes: HierarchicNode[]) {
    if (this.isArray) {
      if (this.idFieldName) {
        this.setNodesForArray(nodes)
      } else {
        console.warn(`Missing inline prop for ${this.fieldParameters.field.name}`);
      }
    } else {
      this.fieldValueService.setFieldValueAndControlValue(
        this.fieldParameters,
        this.fieldParameters.object,
        this.fieldParameters.field.name,
        nodes[0].artifact_id,
        nodes[0].full_path || nodes[0].artifact_name).then();
      this.expansionPanel.close();
    }
    this.setItems();
  }

  private setNodesForArray(nodes: HierarchicNode[], isSelected?: boolean) {
    for (const node of nodes) {
      isSelected = isSelected !== undefined ? isSelected : node.$$isSelected;
      if (!isSelected) {
        const itemData = {};
        itemData[this.idFieldName] = node.artifact_id;
        itemData[this.idFieldName + '_value'] = node.artifact_name;
        if (this.fieldValueService.itemExists(this.fieldParameters, itemData)) {
          // This will happen when importing an existing item from culture hub
          continue;
        }
        if (!this.putBackDeleted(itemData)) {
          this.fieldValueService.addItemUsingFieldParameters(this.fieldParameters, itemData, this.formControlName);
        }
      } else {
        this.remove(node.artifact_id);
      }
      // This ensures that nodes are selected/deselected on both hierarchic panels
      this.setNodeIsSelectedById(node.artifact_id, !isSelected);
      if (node.children?.length) {
        this.setNodesForArray(node.children, isSelected);
      }
    }

  }

  onNodeOpened(node: HierarchicNode) {
    this.currentOpenNode = node;
  }

  onCreateOption(createOptionData) {
    if (createOptionData.data?.length) {
      this.setNodes(createOptionData.data);
      this.listPanel.setNodes().then();
    }
  }

  setExpansionPanelOpened() {
    this.panelHasBeenOpened = true;
    this.setSearchPanelOpened(true);
    this.clickListenerId = this.uiTools.addDocumentClickListener((event) => {
      this.checkOutsideClick(event);
    }, 'ignoreClicksHierarchic-' + this.fieldParameters.field.name);
  }

  setExpansionPanelClosed() {
    this.panelHasBeenOpened = false;
    this.setSearchPanelOpened(false);
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  onSearchPanelTabChange($event) {
    this.setSearchPanelOpened(!$event.index);
  }

  private setSearchPanelOpened(opened: boolean) {
    this.searchPanelOpen = opened;
  }

  registerOnChange(/*fn: any*/): void {
    // no need to implement for now
  }

  registerOnTouched(/*fn: any*/): void {
    // no need to implement for now

  }

  writeValue(/*obj: any*/): void {
    // no need to implement for now
  }

  removeItem(item: BaseModel) {
    const itemId = item[this.idFieldName];
    this.remove(itemId);
    // Deselecting item from hierarchies. Relatively heavy operations, but Necessary in order to toggle removed nodes
    this.setNodeIsSelectedById(itemId, false);
    this.setItems();
  }

  get isDisabled(): boolean {
    return this.fieldCondition.runIf(IfType.DISABLE, this.fieldParameters).result;
  }

  private setItems() {
    this.items = this.fieldValueService.getFieldValueFromFieldParametersAsArray(this.fieldParameters);
  }

  private setNodeIsSelectedById(itemId, isSelected) {
    this.listPanel.setNodeIsSelectedById(itemId, isSelected);
    this.searchPanel.setNodeIsSelectedById(itemId, isSelected);
  }

  private remove(itemId: string) {
    if (this.isArray) {
      this.fieldValueService.deleteItemUsingFieldParameters(this.fieldParameters, itemId, this.formControlName);
    } else {
      this.fieldValueService.setFieldValueAndControlValue(
        this.fieldParameters, this.fieldParameters.object, this.fieldParameters.field.name, '').then();
    }
  }

  private putBackDeleted(itemData): boolean {
    const array = this.fieldParameters.object[this.fieldParameters.field.name];
    let res = false;
    if (array) {
      array.forEach(item => {
        if (item[this.idFieldName] === itemData[this.idFieldName] && item._destroy) {
          item._destroy = false;
          res = true;
        }
      });
    }
    return res;
  }

  private checkOutsideClick(evt: Event) {
    let found;

    if (this.fieldParameters.sectionsContainer.isSecondDialog) {
      found = this.uiTools.findClassNameRecursively(evt, 'ignoreClicksSecondModal-' + this.fieldParameters.field.name) ||
        this.uiTools.findClassNameRecursively(evt, 'ignoreClicksModal-' + this.fieldParameters.field.name);
    } else if (this.fieldParameters.sectionsContainer.isDialog) {
      found = this.uiTools.findClassNameRecursively(evt, 'ignoreClicksModal-' + this.fieldParameters.field.name) ||
        this.uiTools.findClassNameRecursively(evt, 'ignoreClicks-' + this.fieldParameters.field.name);
    } else {
      found = this.uiTools.findClassNameRecursively(evt.target, 'ignoreClicks-' + this.fieldParameters.field.name);
    }
    if (!found) {
      this.expansionPanel.close();
      this.uiTools.removeDocumentClickListener(this.clickListenerId);
    }
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

}
