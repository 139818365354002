<div *ngFor="let item of getOptions()"
              class="edit-field-check-array">
  <mat-checkbox
    class="edit-field-check-array__checkbox white-bg-in-checkbox"
    (click)="toggleItem($event, item)"
    [checked]="item[checkField]">
    <span class="edit-field-check-array__text"
          [ngClass]="{'bold': item[checkField]}"
          [translate]="item['$$label']"></span>
  </mat-checkbox>
  <div *ngIf="item.$$description"
       class="edit-field-check-array__description"
       [translate]="item.$$description"></div>

</div>
