import {Injectable} from '@angular/core';
import {CommonsService} from '../core/commons.service';
import {
  CheckFilter,
  CheckFilterGroup,
  Facet,
  FacetItem,
  FilterFilter,
  RangeGroup,
  SearchViewMenu
} from '../core/definitions/search-objects';
import {FieldValueService, FindMappedValueParams} from '../core/field-value.service';
import {SearchExecutorService} from './search-executor.service';
import {SearchFacetService} from './search-facet.service';
import {SearchContainer} from '../core/definitions/search-container';
import {AConst} from '../core/a-const.enum';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SearchFilterService {

  constructor(private translate: TranslateService,
              private commons: CommonsService,
              private fieldValueService: FieldValueService,
              private searchExecutorService: SearchExecutorService,
              private searchFacetService: SearchFacetService) {
  }

  setTotalSelectedFilters(searchContainer: SearchContainer) {
    searchContainer.totalSelectedFilters = 0;
    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (checkedFilters) {
      for (const filter of Object.values(checkedFilters)) {
        searchContainer.totalSelectedFilters += filter.length;
      }
    } else {
      console.warn('No checked filters found');
    }
  }

  getCheckedFilters(searchContainer: SearchContainer) {
    return searchContainer.filtersFacets.checkedFilters;
  }

  /**
   * @param searchContainer
   * The setFiltersChecked will set the searchContainer property
   * filtersChecked to "checked" if there are any checked filters.
   * It is used in searchFilterMenuSmall directive to flag whether
   * filters have been checked.
   */
  setFiltersChecked(searchContainer: SearchContainer) {
    let res = 'notChecked', filterName, filters;
    const checkedFilters = this.getCheckedFilters(searchContainer);
    for (filterName in checkedFilters) {
      if (checkedFilters.hasOwnProperty(filterName)) {
        filters = checkedFilters[filterName];
        if (filters.length > 0) {
          res = 'checked';
          break;
        }
      }
    }
    searchContainer.filtersFacets.filtersChecked = res;
  }

  async setCheckFilterGroups(searchContainer: SearchContainer): Promise<void> {
    let checkFilterGroups: CheckFilterGroup[];
    const pathView = searchContainer.currentPathView;
    if (pathView) {
      if (pathView.search_view.check_filter_groups) {
        checkFilterGroups = [...pathView.search_view.check_filter_groups];
        for (const fGroup of checkFilterGroups) {
          await this.setCheckFilterGroupFilters(fGroup, searchContainer);
        }
      }
    }
    searchContainer.filtersFacets.filterGroups = checkFilterGroups;
  }

  async checkMenuFilter(menu: SearchViewMenu, searchContainer: SearchContainer, noSearch?) {
    await this.checkFilter(new CheckFilter(menu.facet, menu.facet_values[0]), menu, searchContainer, noSearch);
  }

  async checkCheckFilter(filter: CheckFilter, searchContainer): Promise<void> {
    await this.checkFilter(filter, filter, searchContainer, false);
  }

  setVisibleFilters(filterGroup: CheckFilterGroup) {
    filterGroup.visibleFilters = this.getMaxFilters(filterGroup, this.getQueriedFilters(filterGroup, filterGroup.checkFilters));
  }

  getFilterCount(filter: CheckFilter, searchContainer: SearchContainer): number {
    const facetCount = this.searchFacetService.getFacetCount(filter.name, searchContainer);
    let res = facetCount.itemCounts[filter.value] || facetCount.itemCounts[filter.name];
    if (res === undefined) {
      res = 0;
    }
    return res;
  }

  async setRangeFilter(rangeGroup: RangeGroup, range: Facet, searchContainer: SearchContainer) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    const rangeGroups = searchContainer.currentPathView.search_view.facet_range_groups;
    this.deleteCheckFilterValue(checkedFilters[this.getRangeFilterName(range)], range.oldRangeFilter);
    await this.checkFilter(new CheckFilter(this.getRangeFilterName(range), this.getRangeFilterValue(range)),
      range, searchContainer, false);
    for (const existingGroup of rangeGroups) {
      let facetRange;
      if (rangeGroup.title === existingGroup.title) {
        facetRange = this.getFacetRangeFromTitle(existingGroup.facet_ranges, range.title);
        if (facetRange) {
          facetRange.start = range.start;
          facetRange.end = range.end;
        }
      }
    }
  }

  async checkRangeFilter(rangeGroup: RangeGroup, range: Facet, searchContainer: SearchContainer) {
    const rangeFilterName = this.getRangeFilterName(range);
    const unChecked = await this.unCheckOldRangeFilters(rangeGroup, rangeFilterName, searchContainer);
    if (!unChecked) {
      await this.checkFilter(new CheckFilter(rangeFilterName, this.getRangeFilterValue(range)),
        range, searchContainer, false);
    }
  }

  // LOOP-O-RAMA!!!
  setCheckedRangeFacets(searchContainer: SearchContainer) {
    const facetRangeGroups: Array<RangeGroup> = searchContainer.searchResult.facet_range_groups;
    for (const [filterName, filterValues] of Object.entries(this.getCheckedFilters(searchContainer))) {
      if (filterValues.length > 0) {
        for (const group of facetRangeGroups) {
          for (const range of group.facet_ranges) {
            if (this.getRangeFilterName(range) === filterName) {
              range.checked = true;
              this.setRangeFromFilter(range, filterValues);
            }
          }
        }
      }
    }
  }

  setOldRangeFilters(searchContainer: SearchContainer) {
    for (const group of searchContainer.searchResult.facet_range_groups) {
      for (const range of group.facet_ranges) {
        range.oldRangeFilter = this.getRangeFilterValue(range);
      }
    }
  }

  setCheckedFiltersFromSearchContainer(searchContainer: SearchContainer, hasPathView, defaultCheckedFilters) {

    const checkedFilters = this.getCheckedFilters(searchContainer);
    if (hasPathView) {
      this.checkMenuFiltersFromDefaultFilters(
        searchContainer,
        defaultCheckedFilters);
      this.checkMenusFromCheckedFilters(searchContainer);
    }

    if (!checkedFilters || Object.keys(checkedFilters).length === 0) {
      this.setCheckedFilters(searchContainer,
        this.getDefaultCheckedFilters(searchContainer));
    } else {
      if (searchContainer.focus.curFocusId) {
        this.setTotalSelectedFilters(searchContainer);
      }

    }
  }

  getDefaultCheckedFilters(searchContainer: SearchContainer) {
    const res = {};
    if (searchContainer.currentPathView.search_view.check_filter_groups) {
      const filterGroups = [...searchContainer.currentPathView.search_view.check_filter_groups];
      for (const checkFilterGroup of filterGroups) {
        for (const filter of Object.values(checkFilterGroup.filters)) {
          if (filter.checked_value !== null && filter.checked_value !== undefined) {
            res[filter.name] = [filter.checked_value];
            filter.checked = true;
          }
        }
      }
    }
    return res;
  }

  private async getPreCheckedCheckFilter(filter: CheckFilter): Promise<CheckFilter> {
    const checkFilter = new CheckFilter(filter.name, filter.checked_value);
    checkFilter.preChecked = true;
    checkFilter.checked = true;
    checkFilter.noTransTitle = '';
    const params = {filters: {artifact_id: filter.checked_value}} as FindMappedValueParams;
    checkFilter.noTransTitle = await this.fieldValueService.findMappedValue(params);
    return checkFilter;
  }

  private getRangeFilterName(range: Facet) {
    return range.f_name + ':' + range.title;
  }

  private getRangeFilterValue(range: Facet): string {
    return '[' + range.start + ' TO ' + range.end + ']';
  }

  setCheckedFilters(searchContainer: SearchContainer, value) {
    searchContainer.filtersFacets.checkedFilters = value;
    this.setTotalSelectedFilters(searchContainer);
  }

  /*
  If a filter menu contains a pre-selected value, then only
  the single filter value will be displayed in the filter menu.
  If the user de-selects this value, then the filter menu needs to be
  rebuilt in order to include new filter values
   */
  async setCheckFilterMenusAfterSearch(searchContainer: SearchContainer) {
    if (!searchContainer.filtersFacets.filterGroups) {
      return;
    }
    for (const filterGroup of searchContainer.filtersFacets.filterGroups) {
      for (const filter of Object.values(filterGroup.filters)) {
        const facets = this.searchFacetService.getSearchFacet(filter, searchContainer);
        const namedFilterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
        if (facets && namedFilterGroup) {
          if (facets.items.length > namedFilterGroup.checkFilters.length) {
            await this.setCheckFilterGroupFilters(namedFilterGroup, searchContainer);
          }
        }
      }
    }
  }

  private async setCheckFilterGroupFilters(filterGroup: CheckFilterGroup, searchContainer: SearchContainer) {
    let checkFilters = [];
    let filterNames: string[] = [];
    let isPredefinedFilters = false;
    filterGroup.totalCount = 0;
    for (const filter of filterGroup.filters) {
      if (!filterNames.includes(filter.name)) {
        filterNames.push(filter.name);
      }
      const facet = this.searchFacetService.getSearchFacet(filter, searchContainer);
      this.checkSetFilterGroupCountFromFacet(facet, filterGroup, filter);
      isPredefinedFilters = await this.setCheckFiltersForFilter(filter, checkFilters, facet, isPredefinedFilters);
    }
    filterGroup.filterNames = filterNames;
    this.checkSetFilterChecked(searchContainer, checkFilters);
    if (isPredefinedFilters) {
      checkFilters = this.checkIfThereArePredefinedFilterValues(checkFilters, searchContainer);
    }
    filterGroup.checkFilters = checkFilters;
    filterGroup.filterFilter = new FilterFilter();
    this.setVisibleFilters(filterGroup);
  }

  private async setCheckFiltersForFilter(
    filter: CheckFilter,
    checkFilters: CheckFilter[],
    facet: Facet,
    isPredefinedFilters: boolean) {
    if (filter.value !== null && filter.value !== undefined) {
      checkFilters.push(filter);
      isPredefinedFilters = true;
    } else if (facet) {
      if (facet.items.length) {
        const checkFilter = this.getCheckFiltersFromFacet(facet, filter);
        checkFilters.push(...checkFilter);
      } else {
        if (filter.checked_value !== null && filter.checked_value !== undefined) {
          const preCheckedCheckFilter = await this.getPreCheckedCheckFilter(filter);
          checkFilters.push(preCheckedCheckFilter);
        }
      }
    }
    return isPredefinedFilters;
  }

  private checkSetFilterGroupCountFromFacet(facet: Facet, filterGroup: CheckFilterGroup, filter: CheckFilter) {
    if (facet) {
      if (filter.value !== null && filter.value !== undefined && filter.value !== true) {
        const facetItem = this.getFacetItemFromFilter(facet, filter);
        filterGroup.totalCount += facetItem.count;
      } else {
        filterGroup.totalCount += facet.total_count;
      }
    }
  }

  // This prevents "predefined filters", e.g. "has text blocks", from being displayed if there are no facet
  // values
  private checkIfThereArePredefinedFilterValues(checkFilters: CheckFilter[], searchContainer: SearchContainer) {
    return checkFilters.map(checkFilter => this.searchFacetService.getSearchFacet(
      checkFilter, searchContainer)).some(facet => facet.items.length) ? checkFilters : [];
  }

  private getCheckFiltersFromFacet(facet: Facet, filter: CheckFilter): Array<CheckFilter> {
    const res: Array<CheckFilter> = [];
    for (const facetItem of facet.items) {
      const checkFilter = new CheckFilter(filter.name, facetItem.id ? facetItem.id : facetItem.name);
      if (filter.translate) {
        checkFilter.title = facetItem.name;
      } else {
        checkFilter.noTransTitle = facetItem.name;
      }
      res.push(checkFilter);
    }
    return res;
  }

  // This function is necessary in order to show that filters set from focuses are checked
  private checkSetFilterChecked(searchContainer: SearchContainer, checkFilters: Array<CheckFilter>) {
    const checkedFilters = this.getCheckedFilters(searchContainer);
    for (const checkFilter of checkFilters) {
      if (checkedFilters[checkFilter.name]) {
        for (const checkedValue of checkedFilters[checkFilter.name]) {
          if (checkFilter.value === checkedValue) {
            checkFilter.checked = true;
          }
        }
      }
    }
  }

  private checkDeletePreCheckFilter(searchContainer: SearchContainer, filter: CheckFilter) {
    let filterGroup: CheckFilterGroup;
    if (filter.preChecked) {
      filterGroup = this.getNamedFilterGroup(searchContainer, filter.name);
      if (filterGroup) {
        this.findDeleteFilterGroupFilter(filterGroup.checkFilters, 'value', filter);
      }
    }
  }

  private async checkFilter(filter: CheckFilter, checkObject: any, searchContainer: SearchContainer, noSearch?): Promise<void> {
    let checkedFilters, existIndex;

    checkedFilters = this.getCheckedFilters(searchContainer);
    if (!checkedFilters[filter.name]) {
      checkedFilters[filter.name] = [];
    }
    existIndex = this.checkFilterExists(checkedFilters[filter.name],
      filter.value);
    if (existIndex !== -1) {
      checkedFilters[filter.name].splice(existIndex, 1);
      this.checkDeletePreCheckFilter(searchContainer, filter);
    } else {
      checkedFilters[filter.name].push(filter.value);
    }
    checkObject.checked = existIndex === -1;
    this.searchExecutorService.resetSearchPosition(searchContainer);
    this.setFiltersChecked(searchContainer);
    if (!noSearch) {
      searchContainer.selections.allSelected = false;
      await this.searchExecutorService.runSearch(searchContainer);
      await this.setCheckFilterMenusAfterSearch(searchContainer);
      this.setTotalSelectedFilters(searchContainer);
    } else {
      this.setTotalSelectedFilters(searchContainer);
    }
  }

  private checkFilterExists(filterValues, filterValue) {
    return filterValues.indexOf(filterValue);
  }

  private checkMenuFiltersFromDefaultFilters(searchContainer: SearchContainer, defFilters) {

    this.loopMenusAndFilters(searchContainer, defFilters,
      (menu) => {
        this.checkMenuFilter(menu, searchContainer, true).then();
      });
  }

  checkMenusFromCheckedFilters(searchContainer: SearchContainer) {
    this.loopMenusAndFilters(searchContainer, this.getCheckedFilters(searchContainer),
      (menu) => {
        menu.checked = true;
      });
  }

  private loopMenusAndFilters(searchContainer: SearchContainer, filters, fn) {
    if (filters && searchContainer.currentPathView.search_view.menus) {
      for (const menu of searchContainer.currentPathView.search_view.menus) {
        this.loopFindMenuFilters(menu, filters, fn);
      }
    }
  }

  private loopFindMenuFilters(menu: SearchViewMenu, filters: object, fn) {
    for (const [fName, values] of Object.entries(filters)) {
      let valIndex;
      if (menu.facet === fName) {
        valIndex = values.indexOf(menu.facet_values[0]);
        if (valIndex !== -1) {
          fn(menu);
        }
      }
    }
  }

  private deleteCheckFilterValue(filterValues, filterValue) {
    const index = this.checkFilterExists(filterValues, filterValue);
    if (index !== -1) {
      filterValues.splice(index, 1);
    }
  }

  private getFacetRangeFromTitle(facetRanges: Array<Facet>, title: string) {
    let res = null, t;
    for (t = 0; t < facetRanges.length; t++) {
      if (facetRanges[t].title === title) {
        res = facetRanges[t];
        break;
      }
    }
    return res;
  }

  private async unCheckOldRangeFilters(rangeGroup: RangeGroup, rangeName, searchContainer: SearchContainer) {
    let res = false;
    for (const oldRange of rangeGroup.facet_ranges) {
      const oldRangeName = this.getRangeFilterName(oldRange);
      if (oldRange.checked) {
        await this.checkFilter(new CheckFilter(oldRangeName, this.getRangeFilterValue(oldRange)),
          oldRange, searchContainer, false);
        if (oldRangeName === rangeName) {
          res = true;
        }
      }
    }
    return res;
  }

  private setRangeFromFilter(range: Facet, filterValues) {
    if (range.start) {
      if (range.$$origStart === undefined) {
        range.$$origStart = range.start;
        range.$$origEnd = range.end;
      }
      for (const val of filterValues) {
        const split = val.substring(1, val.length - 1).split(' TO ');
        range.start = split[0];
        range.end = split[1];
      }
    }
  }

  private getNamedFilterGroup(searchContainer: SearchContainer, name: string): CheckFilterGroup {
    let t, filterGroup: CheckFilterGroup, res: CheckFilterGroup;
    for (t = 0; t < searchContainer.filtersFacets.filterGroups.length; t++) {
      filterGroup = searchContainer.filtersFacets.filterGroups[t];
      if (filterGroup.filterNames.includes(name)) {
        res = filterGroup;
        break;
      }
    }
    if (!res) {
      console.warn('Could not find filter group ' + name);
    }
    return res;
  }

  private getFacetItemFromFilter(facet: Facet, filter: CheckFilter): FacetItem {
    let res = new FacetItem();
    res.count = 0;
    for (const item of facet.items) {
      if (!item.id) {
        console.warn('No item id!');
      }
      if (item.id === filter.value) {
        res = item;
      }
    }
    return res;
  }

  private findDeleteFilterGroupFilter(filters: Array<CheckFilter>, valueField, filter: CheckFilter) {
    const deleteIndex = filters.map(f => {
      return f[valueField];
    }).indexOf(filter.value);
    if (deleteIndex !== -1) {
      filters.splice(deleteIndex, 1);
    }
  }

  resetFilter(searchContainer: SearchContainer) {
    if (!searchContainer.focus.curFocusId) {
      this.setCheckedFilters(
        searchContainer, this.getDefaultCheckedFilters(searchContainer));
    }
    this.setFiltersChecked(searchContainer);
    this.resetMenuChecks(searchContainer);
    searchContainer.filtersFacets.facetCount = {};
  }

  private resetMenuChecks(searchContainer: SearchContainer) {
    this.loopPathViewMenus((menu) => {
      delete menu.checked;
    }, searchContainer);
  }

  private loopPathViewMenus(fn, searchContainer: SearchContainer) {
    const menus = searchContainer.currentPathView.search_view.menus;
    if (menus) {
      for (const mainMenu of menus) {
        fn(mainMenu);
        if (mainMenu.menus) {
          for (const subMenu of mainMenu.menus) {
            fn(subMenu);
          }
        }
      }
    }
  }

  private getMaxFilters(filterGroup: CheckFilterGroup, filters: Array<CheckFilter>): Array<CheckFilter> {
    const res: Array<CheckFilter> = [];
    for (let index = 0; index < filters.length; index++) {
      if (this.checkMaxFilterLen(filterGroup, index)) {
        res.push(filters[index]);
      } else {
        break;
      }
    }
    return res;
  }

  private checkMaxFilterLen(filterGroup: CheckFilterGroup, index) {
    let res = true;
    filterGroup.filterFilter.hasMore = false;
    if (index >= AConst.MAX_FILTER_LENGTH && index >= filterGroup.filterFilter.showMoreCount) {
      filterGroup.filterFilter.hasMore = true;
      res = false;
    }
    return res;
  }

  private getQueriedFilters(checkFilterGroup: CheckFilterGroup, filters: CheckFilter[]): CheckFilter[] {
    const res = [];
    checkFilterGroup.filterFilter.filterCount = 0;
    for (let index = 0; index < filters.length; index++) {
      const filter = filters[index];
      const name = this.getFilterTitle(filter);
      let found = true;
      if (!filter.checked && checkFilterGroup.filterFilter.query) {
        const q = checkFilterGroup.filterFilter.query.toLocaleLowerCase();
        found = name.toLocaleLowerCase().indexOf(q) !== -1;
      }
      if (found) {
        res.push(filter);
        checkFilterGroup.filterFilter.filterCount++;
      }
      if (index === filters.length - 1) {
        break;
      }
    }
    return res;
  }

  private getFilterTitle(filter: CheckFilter): string {
    let res;
    if (filter.noTransTitle) {
      res = filter.noTransTitle;
    } else if (filter.title) {
      res = this.translate.instant(filter.title);
    } else {
      res = 'NO TITLE';
    }
    return res;
  }

}
