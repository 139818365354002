import {Injectable} from '@angular/core';
import {Facet, FacetItem, FilterGroupHierarchyNode, HierarchicFilterGroup} from '../core/definitions/search-objects';
import {CommonsService} from '../core/commons.service';
import {SearchParameterService} from './search-parameter.service';
import {SearchContainer} from '../core/definitions/search-container';
import {LoggerService} from '../core/logger.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {SearchService} from "../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class HierarchicFilterGroupService {

  constructor(private matDialog: MatDialog,
              private commons: CommonsService,
              private logger: LoggerService,
              private searchParameterService: SearchParameterService,
              private searchService: SearchService) {
  }

  async setHierarchicFilterGroup(searchContainer: SearchContainer): Promise<void> {
    const orig = searchContainer.currentPathView.search_view.hierarchic_filter_group;
    let res: HierarchicFilterGroup;
    if (orig) {
      if (searchContainer.filtersFacets.hierarchicFilterGroup &&
        searchContainer.filtersFacets.hierarchicFilterGroup.title === orig.title) {
        // This may happen when returning to search page after being in another route state
        return;
      }
      const progressModal = this.matDialog.open(ProgressDialogComponent, {
        disableClose: true,
        panelClass: 'progress-modal'
      });
      try {
        res = this.commons.copy(orig);
        await this.generateHierarchy(searchContainer, res);
        this.generateHierarchyArrays(res);
        progressModal.close();
      } catch (e) {
        this.logger.error('Error generating filter hierarchy: ' + e);
        progressModal.close();
      }
    }
    searchContainer.filtersFacets.hierarchicFilterGroup = res;
  }

  private async generateHierarchy(searchContainer: SearchContainer, hierarchicGroup: HierarchicFilterGroup) {
    hierarchicGroup.children = {};
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer);
    searchParams.fl = ['artifact_id'];
    const searchRes = await this.searchService.search(searchParams);
    const facet = this.getHierarchicFacet(hierarchicGroup.list_filter_field, searchRes.facets);
    if (!facet) {
      return;
    }
    for (const item of facet.items) {
      const itemName = this.getCleanItemName(item.name);
      const splitNames = itemName.split('» ');
      let currentHierarchy = hierarchicGroup;
      for (const name of splitNames) {
        currentHierarchy.children[name] = currentHierarchy.children[name] || new HierarchicFilterGroup();
        currentHierarchy = <HierarchicFilterGroup>currentHierarchy.children[name];
      }
      currentHierarchy.facet = this.beautifyItem(item);
      currentHierarchy.facet.selected = false;
    }
  }

  private getHierarchicFacet(facetName, facets: Facet[]): Facet {
    let res = facets.find(facet => facet.f_name === facetName);
    if (!res) {
      this.logger.warn(`Facet not found: ${facetName}`);
    }
    return res;
  }

  // Remove extra item name information that prevents building of hierarchy from working
  private getCleanItemName(itemNameIn: string) {
    let res = itemNameIn;
    for (const unclean of [': ', ' (']) {
      const unCleanIndex = res.indexOf(unclean);
      if (unCleanIndex !== -1) {
        res = res.substring(0, unCleanIndex);
        break;
      }
    }
    return res;
  }

  private beautifyItem(item: FacetItem): FacetItem {
    const splitNames = item.name.split('» ');
    item.shortName = splitNames[splitNames.length - 1];
    return item;
  }

  // Generating and using arrays will speed up the GUI for drawing nodes
  private generateHierarchyArrays(filterGroupHierarchyNode: FilterGroupHierarchyNode) {
    filterGroupHierarchyNode.childrenArray = Object.values(filterGroupHierarchyNode.children);
    for (const node of filterGroupHierarchyNode.childrenArray) {
      this.generateHierarchyArrays(node);
    }
  }
}
