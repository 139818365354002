<div class="main-menu">
  <div class="main-menu__home">
    <div class="main-menu__icon-container text-blue">
      <span (click)="homeButton()">
        <i class="icon-primus"></i></span>
    </div>
    <div class="main-menu__vertical-line"></div>
    <div class="main-menu__museum">
      <span title="{{museum.name}}">{{museum.shortName}}</span>
    </div>
    <div class="main-menu__vertical-line"></div>
    <div class="main-menu__version-container text-blue-hover"
         (click)="goToAboutPrimus()">
      <span title="Client v{{clientVersion}}">v{{backendVersion}}</span>
      <div class="circle-container">
        <div class="circle"></div>
      </div>
      <span *ngIf="edition">{{edition.title | translate}}</span>
    </div>
  </div>

  <div class="main-menu__content">
    <div class="main-menu__message-button-container">
      <a *ngIf="notifications.length > 0"
         class="main-menu__message-button"
         (click)="toggleMessage()">
        <i class="icon-bell"></i>
        <div class="main-menu__notify">
          <span class="heartbeat"></span>
          <span class="point"></span>
        </div>
      </a>
    </div>

    <div class="drop-down-menu-button dropdownIgnoreClicks"
         (click)="toggleMenu()">
      <i class="icon-hamburger"></i></div>

    <div class="main-menu__menu-container dropdownIgnoreClicks"
         [ngClass]="{'showMainMenu': toggleMenuItem}">
      <div class="main-menu__menu uppercase">
        <ul>
          <li *ngFor="let operation of operationContainer?.operations"
              [ngClass]="{'active': operation.$$operationSelected &&
              (currentStateName.indexOf('admin') !== -1 ||
              currentStateName.indexOf('home.primus.about') !== -1 ||
              currentStateName.indexOf('home.primus.faq') !== -1)}">
            <a [routerLink]="primusStateMapper.routerLinkFromState(operation.operation_steps[0].change_state[0].state_name)"
               [queryParams]="getQueryParams()"
               routerLinkActive="active"
               class="main-menu__menu-button"
               (click)="selectedItem(operation.operation_steps[0].change_state[0].state_name, operation.operation_steps[0].change_state[0].state_params)">
              <span class="top-line bg-blue"></span>
              <mat-icon>{{operation.menu_icon}}</mat-icon>
              <span [ngClass]="{'bold': operation.$$operationSelected &&
              (currentStateName.indexOf('admin') !== -1 ||
              currentStateName.indexOf('home.primus.about') !== -1 ||
              currentStateName.indexOf('home.primus.faq') !== -1)}" [translate]="operation.menu_title"></span>
            </a>
          </li>
        </ul>
      </div>
      <app-main-menu-user
        *ngIf="user"
        class="main-menu__user uppercase"
        [user]="user"
        (click)="toggleJobStatus()"
      ></app-main-menu-user>

      <app-message-and-notifications
        *ngIf="openMessage && notifications.length > 0"
        [notifications]="notifications"
        [currentIndex]="currentIndex"
      ></app-message-and-notifications>
    </div>
    <app-job-statuses [closeMenu]="closeJobStatus" class="main-menu__job-status"></app-job-statuses>
  </div>
  <div class="overlay"
       *ngIf="openMessage && notifications.length > 0"
       (click)="toggleMessage()"></div>
</div>
