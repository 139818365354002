import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {ResultViewService} from '../../result-view.service';
import {SearchObject} from '../../../core/definitions/search-object';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchStateService} from '../../search-state.service';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchHandlerService} from '../../search-handler.service';
import {VirtualScrollSearchDataSourceList} from '../../virtual-scroll-search-data-source-list';
import {OverviewField} from '../../../core/definitions/object-view';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {SettingsService} from '../../../core/settings.service';
import {ClientConfig} from "../../../core/definitions/client-config";

@Component({
  selector: 'app-search-result-view-list-thumbnail',
  templateUrl: './search-result-view-list-thumbnail.component.html',
  styleUrls: ['./search-result-view-list-thumbnail.component.scss']
})
export class SearchResultViewListThumbnailComponent implements OnInit, OnChanges{
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width: string;

  MetaTypes = MetaTypes;
  ds: VirtualScrollSearchDataSourceList;
  clientConfig: ClientConfig;
  private containerWidth: number;


  constructor(private resultViewService: ResultViewService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private searchStateService: SearchStateService,
              private searchExecutorService: SearchExecutorService,
              private searchHandler: SearchHandlerService,
              private settingsService: SettingsService) {
  }

  ngOnInit() {
    this.clientConfig = this.settingsService.getClientConfig();
    this.ds = new VirtualScrollSearchDataSourceList(this.searchExecutorService, this.searchContainer);
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  ngOnChanges() {
    if (this.width !== undefined) {
      this.containerWidth = Number(this.width.substring(0, this.width.length - 2));
    }
  }

  select(obj, event) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    this.searchStateService.setState(this.searchContainer).then();
  }

  setSearchItemIndex(searchItemIndex) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }

  getColumns(searchObject: SearchObject): Array<OverviewField[]> {
    let columns = searchObject.$$columns;
    if (!columns) {
      columns = [];
      const overviewFields = searchObject.overview;
      if (overviewFields && this.containerWidth !== undefined) {
        overviewFields.forEach((ovf, index) => {
          const colIndex = Math.floor(index / 4);
          if (index < 12) {
            if (columns.length <= colIndex) {
              columns.push([]);
            }
            columns[colIndex].push(ovf);
          }
        });
        searchObject.$$columns = columns;
        if (columns.length === 0) {
          console.warn('No columns set!');
        }
      }
    }
    return columns;
  }
}
