import {Component, Input, OnInit} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {CheckboxField} from '../../core/definitions/object-view';
import {OperationService} from '../../operations/operation.service';
import {FieldValueService} from '../../core/field-value.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {AutoSaveSnackBarComponent} from '../auto-save-snack-bar/auto-save-snack-bar.component';
import {ObjectStorageService} from '../../core/object-storage.service';
import {MatLegacySnackBar as MatSnackBar} from '@angular/material/legacy-snack-bar';

@Component({
  selector: 'app-object-view-field-checkbox',
  templateUrl: './object-view-field-checkbox.component.html',
  styleUrls: ['./object-view-field-checkbox.component.scss']
})
export class ObjectViewFieldCheckboxComponent implements OnInit {
  @Input() sectionsContainer: SectionsContainer;
  @Input() checkboxField: CheckboxField;

  checkboxModel;

  constructor(private operationService: OperationService,
              private fieldValueService: FieldValueService,
              private objectStorage: ObjectStorageService,
              private snackBar: MatSnackBar) {
  }

  ngOnInit() {
    if (this.checkboxField) {
      this.checkboxModel = this.getCheckboxFieldModel();
    }
  }

  async checkboxValueChanged() {
    const fieldParams = new FieldParameters();
    const object = this.sectionsContainer.rootObject[this.checkboxField.array_field_name][this.checkboxField.index];
    fieldParams.sectionsContainer = this.sectionsContainer;
    fieldParams.field = {...object.$$meta[this.checkboxField.field_name]};
    fieldParams.field.key = this.checkboxField.array_field_name + '[{index1}].' + this.checkboxField.field_name;
    fieldParams.index = this.checkboxField.index;
    await this.fieldValueService.setFieldValueAndControlValue(
      fieldParams, object, this.checkboxField.field_name, this.checkboxModel);
    await this.autoSaveValueChange();
  }

  private getCheckboxFieldModel() {
    return this.sectionsContainer.rootObject[this.checkboxField.array_field_name][this.checkboxField.index][this.checkboxField.field_name];
  }

  autoSaveValueChange() {
    this.objectStorage.storeObject(this.sectionsContainer.rootObject).then(
      res => {
        if (res.artifact_id) {
          this.objectStorage.loadObject(res.artifact_id).then(obj => {
            this.sectionsContainer.rootObject = obj;
            this.snackBar.openFromComponent(AutoSaveSnackBarComponent,
              {data: obj, duration: 2000});
          });
        } else {
          console.error('Re-loaded object had no artifact id!');
        }
      },
      reason => {
        console.error('Store failed with message: ' + reason.message);
      });
  }
}
