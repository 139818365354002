import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {SearchResultSelectionsService} from '../search-result-selections.service';
import {SearchStateService} from '../search-state.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {AfterCloseOperation} from '../../operations/operation.service';

@Component({
  selector: 'app-search-menus',
  templateUrl: './search-menus.component.html',
  styleUrls: ['./search-menus.component.scss']
})
export class SearchMenusComponent implements OnInit {

  @Input() searchContainer: SearchContainer;
  @Output() operationPerformed = new EventEmitter<AfterCloseOperation>();
  currentResultView = {description: '', icon: '', name: ''};
  openSelection = false;

  constructor(private searchStateService: SearchStateService,
              private searchResultSelectionsService: SearchResultSelectionsService) {
  }

  ngOnInit() {
  }

  get actionMenuDisabled() {
    let res = true;
    let operationsLength = 0;
    if (this.searchContainer.operationContainer) {
      operationsLength = this.searchContainer.operationContainer.operations ? this.searchContainer.operationContainer.operations.length : 0;
    }
    if (operationsLength) {
      res = this.searchContainer.selections.selectedItems.length === 0 && !this.searchContainer.selections.allSelected;
    }
    return res;
  }

  get selectedCount() {
    return this.searchContainer.selections.selectedItems.length;
  }

  toggleSelection() {
    this.openSelection = !this.openSelection;
  }

  remove(art) {
    const sc = this.searchContainer;
    const artifactId = this.searchResultSelectionsService.getItemId(art);
    sc.selections.selectedItems.forEach(item => {
      if (this.searchResultSelectionsService.getItemId(item) === artifactId) {
        this.searchResultSelectionsService.removeItemIdById(this.searchContainer, item);
        this.searchResultSelectionsService.toggleSelectedItems(this.searchContainer);
        this.searchStateService.setState(this.searchContainer).catch();
      }
    });

  }

  removeAll() {
    this.searchResultSelectionsService.clearSelections(this.searchContainer);
    this.searchResultSelectionsService.toggleSelectedItems(this.searchContainer);
    this.searchStateService.setState(this.searchContainer).catch();
  }

  onOperationPerformed(afterCloseOperation: AfterCloseOperation) {
    this.operationPerformed.emit(afterCloseOperation);
  }
}
