import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectPageComponent} from './object-page/object-page.component';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SharedModule} from '../shared/shared.module';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {OperationsModule} from '../operations/operations.module';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {MatIconModule} from '@angular/material/icon';
import {ObjectContentTabModule} from '../object-content-tab/object-content-tab.module';



@NgModule({
  declarations: [
    ObjectPageComponent
  ],
  imports: [
    CommonModule,
    ObjectViewModule,
    ObjectSearchModule,
    SharedModule,
    MediaCarouselModule,
    OperationsModule,
    OverviewFieldsModule,
    MatIconModule,
    ObjectContentTabModule
  ]
})
export class ObjectPageModule { }
