import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {BaseModel} from '../../../core/definitions/base-model';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {MetaField} from '../../../core/definitions/meta-field';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {CommonsService} from '../../../core/commons.service';
import {FieldStateService} from '../../../core/field-state.service';
import {FieldValidationService} from '../../../core/field-validation.service';
import {CopyKeepService} from '../../copy-keep-checkbox/copy-keep.service';

@Component({
  selector: 'app-edit-field-inline-array-item-list',
  templateUrl: './edit-field-inline-array-item-list.component.html',
  styleUrls: ['./edit-field-inline-array-item-list.component.scss']
})
export class EditFieldInlineArrayItemListComponent implements OnChanges {

  @Input() items: BaseModel[];
  @Input() fieldParameters: FieldParameters;
  // TODO: Disable not implemented yet
  @Input() disabled: boolean;
  @Output() fieldOutsideTheScreen = new EventEmitter<object>();

  private fieldKey;

  constructor(private inlineArrayItemSvc: InlineArrayItemService,
              private commons: CommonsService,
              private fieldState: FieldStateService,
              private fieldValidationService: FieldValidationService,
              private copyKeepService: CopyKeepService) { }

  ngOnChanges(): void {
    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(this.field, this.fieldParameters.parentIndex);
  }

  get field(): MetaField {
    return this.fieldParameters.field;
  }

  checkFieldOutsideTheScreen() {
    this.fieldOutsideTheScreen.emit();
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.items, event.previousIndex, event.currentIndex);
    this.copyKeepService.moveKeepObjects(this.fieldParameters, event.previousIndex, event.currentIndex);
    this.items.forEach((item, index) => {
      item.order_number = index;
    });
    this.fieldParameters.index = event.currentIndex;
    this.inlineArrayItemSvc.changeInlineArrayItemsOrder(this.fieldParameters);
  }

  onDragStart() {
    this.inlineArrayItemSvc.setOpenArrayItemIndex(this.fieldParameters, undefined);
  }



  get isInvalid() {
    return this.fieldValidationService.isFieldInvalid(this.fieldParameters.sectionsContainer, this.fieldKey);
  }

}
