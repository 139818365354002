import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ImageItem} from '../../core/definitions/image-item';
import {MediaItem} from '../../core/definitions/media-item';

@Component({
  selector: 'app-media-selector-selected-files',
  templateUrl: './media-selector-selected-files.component.html',
  styleUrls: ['./media-selector-selected-files.component.scss']
})
export class MediaSelectorSelectedFilesComponent implements OnInit {

  @ViewChild('selectedImgContainer', { static: true }) selectedImgContainer: ElementRef;
  @Input() files;
  @Input() selectedFiles;
  @Input() text;
  @Input() imageIdField;
  @Input() showSelected;
  @Output() itemSelected = new EventEmitter<MediaItem>();
  @Output() fileDeleted = new EventEmitter<MediaItem>();

  hideRightOverflowButton = false;
  hideLeftOverflowButton = false;
  chosenItems: Array<ImageItem> = [];

  constructor() { }

  ngOnInit() {
  }

  selectFile() {
    if (this.showSelected) {
      this.chosenItems.forEach((item) => {
        this.itemSelected.emit(item);
      });
      this.chosenItems = [];
    }

  }

  isChosen(img) {
    if (this.showSelected) {
      let res = false;
      this.selectedFiles.forEach((item) => {
        if (img.image_id === item.artifact_id && !item._destroy) {
          item._create = true;
          res = true;
        } else {
          item._destroy = false;
        }
      });
      return res;
    }
  }

  public setFile(file) {
    if (!this.isChosen(file)) {
      const index: number = this.chosenItems.indexOf(file);
      if (index !== -1) {
        this.chosenItems.splice(index, 1);
      } else {
        this.chosenItems.push(file);
      }
    }
  }

  public getFiles() {
    return this.files.filter(f => !f._destroy);
  }

  isSelectedFile(index) {
    const file = this.files[index];
    let res = false;
    this.chosenItems.forEach((item) => {
      if (file.image_id === item.image_id && !item._destroy) {
        res = true;
      }
    });
    return res;
  }

  isOverflow() {
    const element = this.selectedImgContainer.nativeElement;
    this.hideRightOverflowButton = element.scrollLeft + element.offsetWidth > element.scrollWidth;
    this.hideLeftOverflowButton = element.scrollLeft === 0;
    return this.files.length * 150 > element.offsetWidth;
  }

  scrollLeft() {
    if (this.selectedImgContainer) {
      this.selectedImgContainer.nativeElement.scrollLeft -= 300;
    }
  }

  scrollRight() {
    if (this.selectedImgContainer) {
      this.selectedImgContainer.nativeElement.scrollLeft += 300;
    }
  }

  deleteFile(selectedFile) {
    // Get file in array.
    const file = this.files.find(f => {
      return f.thumbnail_id === selectedFile.thumbnail_id;
    });
    if (file._create) {
      // Get file index in array.
      const index = this.files.findIndex( f => {
        return f.thumbnail_id === selectedFile.thumbnail_id;
      });
      this.files.splice(index, 1);
    } else {
      file._destroy = true;
    }
    this.fileDeleted.emit(file);
  }
}
