import {Component, Input, OnInit} from '@angular/core';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-object-new-copy-view',
  templateUrl: './object-new-copy-view.component.html',
  styleUrls: ['./object-new-copy-view.component.scss']
})
export class ObjectNewCopyViewComponent implements OnInit {

  @Input() object;
  @Input() selectorContainer: SelectorContainer;
  @Input() sectionsContainer: SectionsContainer;
  @Input() createMultiple: boolean;

  constructor() {
  }

  ngOnInit() {
  }

}
