import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs';
import {debounceTime, filter, map, mergeMap, startWith, tap} from 'rxjs/operators';
import {MatLegacyOptionSelectionChange as MatOptionSelectionChange} from '@angular/material/legacy-core';
import {QueryField} from '../../../../../core/definitions/advanced-search/query-field';
import {InputOptions} from '../../../../../core/definitions/advanced-search/input-options';
import {SearchService} from "../../../../../core/search.service";

interface Option {
  label: string;
  value: string;
}

@Component({
  selector: 'app-select-input',
  templateUrl: './select-input.component.html',
  styleUrls: ['./select-input.component.scss']
})
export class SelectInputComponent implements OnChanges {

  @Input() field: QueryField;
  @Input() label: string;
  @Input() placeholder: string;
  @Input() multiple?: boolean;

  @Output() public readonly searchValueChanged: EventEmitter<string | null>;

  readonly selectedOptions: Array<Option>;
  readonly searchableSelectControl: UntypedFormControl;
  readonly options$: Observable<Array<Option>>;

  private inputOptions: InputOptions;
  availableOptionCount: number;
  loading: boolean;

  constructor(private readonly searchService: SearchService) {
    this.searchValueChanged = new EventEmitter<string | null>();
    this.selectedOptions = [];
    this.searchableSelectControl = new UntypedFormControl();
    this.multiple = false;
    this.loading = false;
    this.availableOptionCount = 0;

    this.options$ = this.searchableSelectControl.valueChanges.pipe(
      // Runs the pipe on init to run an initial search
      startWith(''),
      // Only take when data is available and not a selection
      filter(val => (
        !!this.field &&
        !!this.inputOptions?.optionsSearchParams &&
        typeof val === 'string'
      )),
      // Prevent event from running on every keystroke
      debounceTime(750),
      map(val => {
        return val ? `*${val}*` : '*';
      }),
      mergeMap(val => this.loadOptions(val)),
      tap(options => {
        this.availableOptionCount = options?.length || 0;
      })
    );
  }

  private async loadOptions(searchValue: string = '*'): Promise<Array<Option>> {
    if (!this.inputOptions?.optionsSearchParams) {
      return [];
    }
    this.loading = true;
    try {
      const params = this.inputOptions.optionsSearchParams;
      const labelProp = this.inputOptions.optionsLabelProperty || 'artifact_name';
      const idProp = this.inputOptions.optionsIdProperty || 'artifact_id';

      let q = `${labelProp}:${searchValue}`;
      if (this.selectedOptions.length > 0) {
        const ids = this.selectedOptions.map(o => o.value).join('","');
        q = `-(${idProp}:("${ids}")) AND ${q}`;
      }
      params.query = q;
      const res = await this.searchService.search(params);
      const newOptions = (res?.artifacts || []).map(option => ({
        label: option[labelProp],
        value: option[idProp]
      }));
      return [...this.selectedOptions, ...newOptions];
    } catch (e) {
      console.error('Unable to load opts', e);
      return [];
    } finally {
      this.loading = false;
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('field')) {
      this.field = changes.field.currentValue;
      this.inputOptions = this.field.inputOptions;
      this.selectedOptions.splice(0);
      this.selectedOptions.push(...(this.inputOptions?.additionalData?.options || []));
      this.searchableSelectControl.reset(this.selectedOptions[0] || null);
    }
  }

  displayWith(option: Option): string {
    return option?.label || '';
  }

  setSearchValue(event: MatOptionSelectionChange): void {
    const option: Option = event.source.value;
    if (this.multiple) {
      const idx = this.selectedOptions.findIndex(opt => opt.value === option.value);
      if (idx >= 0) {
        this.selectedOptions.splice(idx, 1);
      } else {
        this.selectedOptions.push(option);
      }
    } else {
      this.selectedOptions.splice(0);
      this.selectedOptions.push(option);
    }
    this.inputOptions.additionalData = {
      options: this.selectedOptions
    };
    this.searchValueChanged.emit(`("${this.selectedOptions.map(opt => opt.value).join('","')}")`);
  }

  isSelected(option: Option): boolean {
    return this.selectedOptions.some(opt => opt.value === option.value);
  }

  getSelectedOptionNames(): string {
    return this.selectedOptions.map(opt => opt.label).join(', ');
  }
}
