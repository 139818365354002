<div class="admin-place-wrapper">
  <app-top-header [parent]="parentNavigationView"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN__PLACE__HEADER"
                              description="TRANS__ADMIN__PLACE__DESCRIPTION">
    <div class="content-wrapper">
      <app-primus-solr-tree-view class="hierarchy-navigator"
                                 [tabName]="'TRANS__SOLR_TREE_VIEW__PLACES_TAB_NAME'"
                                 [topNodeLabel]="topNodeLabel"
                                 searchInputLabel="TRANS__ADMIN__PLACE__SEARCH_INPUT_LABEL"
                                 query="-valid:false AND object_type:place"
                                 secondaryLabelProp="place_type_id_value"
                                 [labelProp]="parentLabelProp"
                                 [parentIdProp]="parentIdProp"
                                 [selectedNode]="selectedParentNode"
                                 (selectionChanged)="selectParent($event)">
      </app-primus-solr-tree-view>
      <div class="admin-place-wrapper__content">


        <app-admin-place-filter [parentName]="selectedParentNode?.label || topNodeLabel"
                                [selectedPlaces]="selectedPlaces"
                                (filterChanged)="setFilterQuery($event)"
                                (filterStringChanged)="currentFilterString = $event"
                                (actionPerformed)="onActionPerformed()">
        </app-admin-place-filter>
        <app-primus-solr-data-table addRowButtonLabel="TRANS__ADMIN__PLACE__ADD_CHILD_BUTTON_LABEL"
                                    class="admin-place-wrapper__solr-data-table"
                                    [solrQuery]="currentQuery"
                                    [columnLabels]="columnLabels"
                                    [columns]="columns"
                                    [filterString]="currentFilterString"
                                    [rowValueTransformFn]="rowTransformer"
                                    [enableAddRowButton]="true"
                                    [enableEditing]="true"
                                    [linkToObjectPage]="true"
                                    [enableMultiSelection]="true"
                                    (selectionChanged)="handleSelectionChanged($event)"
                                    (editRowClicked)="handleEditOrCreatePlaceClicked($event.refreshTable, $event.row?.artifact_id)"
                                    (addRowClicked)="handleEditOrCreatePlaceClicked($event.refreshTable)">
        </app-primus-solr-data-table>
      </div>
    </div>

  </app-header-and-description>
</div>

