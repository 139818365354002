<div class="search-filter-menu-item"
     [ngClass]="{
     'sideMenu': sideMenu,
     'dropdownMenu': !sideMenu,
     'home': searchContainer.path === 'home'}">
  <div *ngIf="menu.facet_as_filter"
       [ngClass]="{'subMenu': subMenu, 'mainMenu': !subMenu}"
       class="filter-checkbox search-filter-menu-item__checkbox">
    <mat-checkbox (click)="checkMenuFilter($event)"
                  [checked]="menu.checked"
                  [ngClass]="{'with-button': searchContainer.searchView.paths.length > 1, 'bold': menu.checked}">
      <span class="search-filter-menu-item__item-text-content"
            >{{menu.title | translate}}</span>
      <span *ngIf="menu.count"
            class="search-filter-menu-item__item-countText">({{menu.count}})</span></mat-checkbox>

    <a class="search-filter-menu-item__go-to-path text-blue-hover"
       *ngIf="searchContainer.searchView.paths.length > 1"
       (click)="goToMenuPath()">
      <span class="filter-menu__vertical-line"></span
      ><i class="icon-step-in-to"></i>
      <div class="tooltip-container">
        <span>{{'TRANS__SEARCH__STEP_IN_TO' | translate}}</span>&nbsp;<span>{{menu.title | translate}}</span>
      </div>
    </a>
  </div>

  <table class="search-filter-menu-item__table"
         *ngIf="!menu.facet_as_filter"
         [ngClass]="{'subMenu': subMenu, 'mainMenu': !subMenu}">
    <tr>
      <td>
        <span *ngIf="menu.menus && menu.menus.length > 0" class="openFilterItem text-blue-hover"
              (click)="openFilterItem(menu)">
          <mat-icon [ngClass]="{'openMenu': menu.openItem, 'closedMenu': !menu.openItem}">arrow_right</mat-icon>
          <span class="openFilterItem__vertical-line"></span>
        </span>
      </td>
      <td [class.is-home-menu]="searchContainer.path === 'home' && !subMenu">
        <a [routerLink]="primusStateMapper.routerLinkFromState(searchContainer.state.targetState)"
           [queryParams]="primusStateMapper.queryParams({path: menu.path, targetId: 'none', objectCount: 0})"
           queryParamsHandling="merge">
          <span class="search-filter-menu-item__title"
                >{{menu.title | translate}}</span>
          <span *ngIf="menu.count"
                class="search-filter-menu-item__countText">({{menu.count}})</span>
        </a>
      </td>
    </tr>
  </table>
</div>
