import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';

export interface ObjectUsageDialogData {
  objects: Array<any>;
  hasUsageMessage: string;
}

@Component({
  selector: 'app-object-usage-dialog',
  templateUrl: './object-usage-dialog.component.html',
  styleUrls: ['./object-usage-dialog.component.scss']
})
export class ObjectUsageDialogComponent implements OnInit {

  constructor(public dialogRef: MatDialogRef<ObjectUsageDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: ObjectUsageDialogData) {
  }

  ngOnInit() {
  }

}
