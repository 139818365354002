/* eslint-disable curly */
import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {IdentifierFormat} from '../identifier-format';
import {AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {SearchParameters} from '../../../core/definitions/search-parameters';
import {SearchService} from "../../../core/search.service";
import {SettingsService} from "../../../core/settings.service";

// Illegal characters: /\\?%*:|"<>

interface ObjectType {
  id: string;
  name: string;
}

function formatValidator(control: AbstractControl): {[error: string]: any} | null {
  if (!control.value || (!control.dirty && !control.touched)) {
    return null;
  }
  // Validate that the format starts with '<prefix>'
  if (Validators.pattern(/^<prefix>.*$/)(control)) {
    return {
      startWithPrefix: true
    };
  }
  // Validate that the format contains '<n>'
  if (Validators.pattern(/^<prefix>.*<n>.*$/)(control)) {
    return {
      mustContainNumber: true
    };
  }
  // Validate that the format is valid
  if (Validators.pattern(/^<prefix>(.?<\w+>)+$/)(control)) {
    return {
      invalidFormatting: true
    };
  }
  // Validate that the format only contains '<prefix>', '<n>', '<yyyy>', '<sign>' and '<museumskode>'
  if (Validators.pattern(/^<prefix>(.?((?:<n>)|(?:<yyyy>)|(?:<sign>)|(?:<museumskode>)))+.?$/)(control)) {
    return {
      invalidSegment: true
    };
  }
  // Validate that the format only has one occurrence of each segment
  const hasDuplicate = ['prefix', 'n', 'yyyy', 'sign', 'museumskode']
    .map(segment => new RegExp(`(<${segment}>)`, 'g'))
    .map(regex => String(control.value).match(regex))
    .map(matchArray => matchArray ? matchArray.length : 0)
    .some(count => count > 1);
  if (hasDuplicate) {
    return {
      hasDuplicates: true
    };
  }
  // Separator can't contain: /\?%*:|"<>, letters, numbers or whitespace
  if (Validators.pattern(/^<prefix>(?:(?:_|[^/\\?%*:|"<>\w\d\s])?(?:<\w+>)){1,4}$/)(control)) {
    return {
      invalidSeparator: true
    };
  }
  // Format is valid
  return null;
}

@Component({
  selector: 'app-admin-id-format-edit',
  templateUrl: './admin-id-format-edit.component.html',
  styleUrls: ['./admin-id-format-edit.component.scss']
})
export class AdminIdFormatEditComponent implements OnInit {

  public readonly form: UntypedFormGroup;
  objectTypes: Array<ObjectType>;

  constructor(public dialogRef: MatDialogRef<AdminIdFormatEditComponent>,
              @Inject(MAT_DIALOG_DATA) readonly data: IdentifierFormat,
              private readonly searchService: SearchService,
              private readonly fb: UntypedFormBuilder,
              settings: SettingsService) {
    // Note: Control-names must correspond to the type 'keyof IdentifierFormat'
    this.form = this.fb.group({
      format: [
        null,
        [
          Validators.required,
          formatValidator
        ]
      ],
      prefix: [
        null,
        [
          Validators.required,
          Validators.pattern(/^[^/\\?%*:|"<>\s]+$/)
        ]
      ],
      digits: [
        1,
        [
          Validators.required,
          Validators.min(1)
        ]
      ],
      subtype_separator: [
        '.',
        [
          Validators.required,
          Validators.maxLength(1),
          Validators.pattern(/^_|[^/\\?%*:|"<>\s\w\d]+$/)
        ]
      ],
      subtype_digits: [
        1,
        [
          Validators.required,
          Validators.min(1)
        ]
      ],
      // Default type: "Ukjent"
      identifier_format_superobject_type_id: [
        settings.getClientConfig().IDENTIFIER_FORMAT_SUPEROBJECT_TYPE_ID,
        [
          Validators.required
        ]
      ]
    });

    Object.keys(this.form.controls)
      .filter(controlName =>
        this.data[controlName] !== null && this.data[controlName] !== undefined
      ).forEach(controlName =>
        this.form.get(controlName).setValue(this.data[controlName])
      );
  }

  async ngOnInit(): Promise<void> {
    const conceptResult = await this.searchService.search({
      query: 'object_type:"ct_128"',
      fl: ['artifact_id', 'name.name'],
      sort: 'name.name asc',
      getAll: true
    } as SearchParameters);

    const objectTypes = conceptResult && conceptResult.artifacts ? conceptResult.artifacts : [];

    this.objectTypes = objectTypes
      .filter(o => o.artifact_id && o['name.name'])
      .map(o => ({
        id: o.artifact_id,
        name: o['name.name']
      } as ObjectType));
  }

  public cancel(): void {
    this.dialogRef.close(null);
  }

  public save(): void {
    if (this.form.invalid) {
      return;
    }
    const selected = this.form.get('identifier_format_superobject_type_id').value;
    const otName = this.objectTypes.find(ot => ot.id === selected).name;
    this.dialogRef.close(new IdentifierFormat({
        ...this.data,
        ...this.form.value,
        identifier_format_superobject_type_id_value: otName
      },
      selected)
    );
  }
}
