<div class="search-menu-actions common-operation-menu-styling"
     [ngClass]="{'active': searchContainer.toggleActionMenu && !disabled,
     'tooltip1':  !selectedCount && actionMenuOperations.length}"
     matTooltip="{{'TRANS__VALIDATION__NO_SELECTED_OBJECT' | translate}}"
     [matTooltipDisabled]="!disabled">

  <button class="common-operation-menu-styling__button"
          (click)="toggleSearchActionMenu()"
          [disabled]="disabled"
          [matMenuTriggerFor]="searchMenu"
          [matMenuTriggerRestoreFocus]="false">
    <span class="common-operation-menu-styling__button-text common-operation-menu-styling__text1"
          translate="TRANS__SEARCH__ACTION_MENU__SECTION_TITLE__PROCESS_SELECTION"></span>
    <span class="common-operation-menu-styling__button-text common-operation-menu-styling__text2"
          translate="TRANS__SEARCH__TREAT"></span>
    <span class="common-operation-menu-styling__button-count" *ngIf="actionMenuOperations.length">({{selectedCount}})</span>
    <mat-icon>{{searchContainer.toggleActionMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

  <mat-menu #searchMenu="matMenu"
            class="common-operation-menu-styling__menu removePaddingInMatMenu"
            (closed)="searchContainer.toggleActionMenu = false;"
            [overlapTrigger]="false">
    <mat-accordion displayMode="flat">
      <mat-expansion-panel *ngFor="let operation of actionMenuOperations"
                           class="no-active-blue-color"
                           #currentOperationPanel
                           [expanded]="actionMenuOperations.length === 1"
                           [class.is-expanded]="currentOperationPanel.expanded || actionMenuOperations.length === 1"
                           [class.is-collapsed]="!currentOperationPanel.expanded">
        <mat-expansion-panel-header (click)="$event.stopPropagation()" [collapsedHeight]="'40px'" [expandedHeight]="'40px'">
          <mat-panel-title [ngClass]="{'bold': currentOperationPanel.expanded}"
                           [translate]="operation.menu_title"></mat-panel-title></mat-expansion-panel-header>

        <mat-list>
          <ng-container *ngFor="let subOperation of operation.sub_operations">
            <button mat-button
                    *ngIf="canShowOperation(subOperation)"
                    class="common-operation-menu-styling__sub-operation-button"
                    (click)="executeOperation(subOperation, operation)">
              <mat-icon *ngIf="subOperation.menu_icon && !subOperation.menu_icon.startsWith('icon-')">{{subOperation.menu_icon}}</mat-icon>
              <i *ngIf="subOperation.menu_icon && subOperation.menu_icon.startsWith('icon-')" class="{{subOperation.menu_icon}}"></i>
              <span [translate]="subOperation.menu_title"></span></button>
          </ng-container>
        </mat-list>
      </mat-expansion-panel>
    </mat-accordion>
  </mat-menu>
</div>
