<div class="choose-backend-api" id="authenticated-container" [hidden]=hidden>
  <i class="icon-primus text-blue choose-backend-api__primus-icon"></i>

  <div class="server-list">
    <mat-selection-list [multiple]="false" (selectionChange)="onServerSelect($event)">
      <mat-list-option
        *ngFor="let s of servers; let i = index;"
        [selected]="null"
        [disabled]="!s.online && s.name.toLowerCase() !== 'localhost'"
        [value]="s.api">
        <i *ngIf="s.logo === ''" mat-list-icon class="icon-p text-blue"></i>
        <img *ngIf="s.logo !== ''" [src]="s.logo" mat-list-icon [alt]=""/>
        <h4 mat-line class="opt-title" [ngClass]="{'bold':  showUrl}">
          {{s.name}}
          <span [ngClass]="{'offline': (!s.online && s.name.toLowerCase() !== 'localhost') && s.checked}" aria-label="Offline"></span>
        </h4>
        <span mat-line class="opt-description" *ngIf="showUrl">{{s.api}}</span>
        <mat-progress-bar
          *ngIf="!s.checked && s.name.toLowerCase() !=='localhost'"
          mode="query"
          style="display:block; margin-top:8px; margin-bottom: 8px;"
          color="accent">
        </mat-progress-bar>
      </mat-list-option>
    </mat-selection-list>
    <div *ngIf="!servers?.length"><h2 translate="TRANS__CHOOSE_BACKEND__NO_BACKENDS_AVAILABLE"></h2></div>
  </div>

</div>
