import {Injectable} from '@angular/core';
import {InlineViewItem} from "./definitions/inline-view-item";
import {CmsApiService} from "./cms-api.service";
import {LoggerService} from "./logger.service";
import {MetaField} from "./definitions/meta-field";
import {CmsQueueService} from "./cms-queue.service";

@Injectable({
  providedIn: 'root'
})
export class InlineViewService {

  private disableInlineViewApi = false;
  private inlineViewCache: {[object_type: string]: InlineViewItem[]} = {};

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueueService: CmsQueueService) {
  }

  async getInlineViewForField(objectType: string, field: MetaField): Promise<InlineViewItem> {
    let res: InlineViewItem;
    let inlineView = this.inlineViewCache[objectType];
    if (inlineView === undefined) {
      inlineView = await this.getInlineView(objectType);
      this.inlineViewCache[objectType] = inlineView;
      if (!inlineView) {
        this.logger.warn(`No inline view data for ${objectType}`);
      }
    }
    if (inlineView) {
      res = inlineView.find(item => item.field === field.name);
    }
    return res;
  }

  async getInlineView(objectType: string): Promise<InlineViewItem[]> {
    return new Promise(resolve => {
      if (this.disableInlineViewApi) {
        resolve(null);
        return;
      }
      this.cmsQueueService.runCmsFnWithQueue(this.cms.getInlineView, {objectType: objectType}, false,
        inlineView => {
          resolve(inlineView);
        }, e => {
          if (e.status === 404 && !this.disableInlineViewApi) {
            this.logger.warn('Inline view API does not seem to be implemented on backend yet. Disabling for now');
            this.disableInlineViewApi = true;
          }
          this.logger.error(`Unable to get inline view: ${JSON.stringify(e)}`);
          resolve(null);
        });
    });
  }
}
