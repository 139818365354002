import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';

import {SharedModule} from '../shared/shared.module';
import {SearchQueryComponent} from './search-query/search-query.component';
import {SearchFilterMenuComponent} from './search-filter-menu/search-filter-menu.component';
import {SearchFilterMenuPathComponent} from './search-filter-menu/search-filter-menu-path/search-filter-menu-path.component';
import {SearchFilterMenuItemComponent} from './search-filter-menu/search-filter-menu-item/search-filter-menu-item.component';
import {SearchFilterGroupComponent} from './search-filter-menu/search-filter-group/search-filter-group.component';
import {DateSearchFieldComponent} from './date-search-field/date-search-field.component';
import {SearchFilterMenuSmallComponent} from './search-filter-menu/search-filter-menu-small/search-filter-menu-small.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {SearchResultViewComponent} from './search-result-view/search-result-view.component';
// eslint-disable-next-line max-len
import {SearchResultViewThumbnailComponent} from './search-result-view/search-result-view-thumbnail/search-result-view-thumbnail.component';
// eslint-disable-next-line max-len
import {SearchResultViewListSelectorComponent} from './search-result-view/search-result-view-list-selector/search-result-view-list-selector.component';
// eslint-disable-next-line max-len
import {SearchResultViewListThumbnailComponent} from './search-result-view/search-result-view-list-thumbnail/search-result-view-list-thumbnail.component';
import {SearchResultViewListComponent} from './search-result-view/search-result-view-list/search-result-view-list.component';
import {SearchMenusComponent} from './search-menus/search-menus.component';
import {SearchMenuSortComponent} from './search-menus/search-menu-sort/search-menu-sort.component';
import {SearchMenuViewSelectorComponent} from './search-menus/search-menu-view-selector/search-menu-view-selector.component';
import {SearchMenuFolderSettingsComponent} from './search-menus/search-menu-folder-settings/search-menu-folder-settings.component';
import {SearchMenuActionsComponent} from './search-menus/search-menu-actions/search-menu-actions.component';
// eslint-disable-next-line max-len
import {SearchMenuSelectAllComponent} from './search-menus/search-menu-select-all/search-menu-select-all.component';
// eslint-disable-next-line max-len
import {SearchMenuToggleRestrictionsComponent} from './search-menus/search-menu-toggle-restrictions/search-menu-toggle-restrictions.component';
import {SearchMenuSelectChosenComponent} from './search-menus/search-menu-select-chosen/search-menu-select-chosen.component';
import {SearchResultPagerComponent} from './search-result-view/search-result-pager/search-result-pager.component';
import {SearchResultPagerListComponent} from './search-result-view/search-result-pager-list/search-result-pager-list.component';
import {SearchViewSelectorComponent} from './search-view-selector/search-view-selector.component';
import {SearchHeaderComponent} from './search-header/search-header.component';
import {SideMenuToggleComponent} from './search-header/side-menu-toggle/side-menu-toggle.component';
import {SearchFocusMenuComponent} from './search-menus/search-focus-menu/search-focus-menu.component';
import {SearchFocusEditDialogComponent} from './search-menus/search-focus-edit-dialog/search-focus-edit-dialog.component';
import {SearchViewFullComponent} from './search-view-full/search-view-full.component';
import {SearchPageComponent} from './search-page/search-page.component';
import {SearchQueryPathMenuComponent} from './search-query/search-query-path-menu/search-query-path-menu.component';
import {SearchResultViewGalleryComponent} from './search-result-view/search-result-view-gallery/search-result-view-gallery.component';
import {SearchViewFolderHeaderComponent} from './search-view-full/search-view-folder-header/search-view-folder-header.component';
import {SearchViewFullHeaderComponent} from './search-view-full/search-view-full-header/search-view-full-header.component';
// eslint-disable-next-line max-len
import {SearchSelectionBasketComponent} from './search-menus/search-selection-basket/search-selection-basket.component';
// eslint-disable-next-line max-len
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import {SearchFocusMenuPanelComponent} from './search-menus/search-focus-menu-panel/search-focus-menu-panel.component';
import {ScrollingModule} from '@angular/cdk/scrolling';
import {OperationsModule} from '../operations/operations.module';
import {PanelLayoutComponent} from './advanced-search-and-search-category/components/panel-layout/panel-layout.component';
import {ContentSectionComponent} from './advanced-search-and-search-category/components/content-section/content-section.component';
import {AdvancedSearchComponent} from './advanced-search-and-search-category/advanced-search/advanced-search.component';
import {SearchCategoryComponent} from './advanced-search-and-search-category/search-category/search-category.component';
import {FieldFilterComponent} from './advanced-search-and-search-category/components/field-filter/field-filter.component';
import {FieldListComponent} from './advanced-search-and-search-category/advanced-search/field-list/field-list.component';
import {QueryBuilderComponent} from './advanced-search-and-search-category/advanced-search/query-builder/query-builder.component';
import {InputComponent} from './advanced-search-and-search-category/advanced-search/input/input.component';
import {TextInputComponent} from './advanced-search-and-search-category/advanced-search/input/text-input/text-input.component';
import {DateInputComponent} from './advanced-search-and-search-category/advanced-search/input/date-input/date-input.component';
import {NumberInputComponent} from './advanced-search-and-search-category/advanced-search/input/number-input/number-input.component';
import {BooleanInputComponent} from './advanced-search-and-search-category/advanced-search/input/boolean-input/boolean-input.component';
import {SelectInputComponent} from './advanced-search-and-search-category/advanced-search/input/select-input/select-input.component';
import {MatLegacySlideToggleModule as MatSlideToggleModule} from '@angular/material/legacy-slide-toggle';
import {AdvancedSearchResultViewComponent} from './advanced-search-result-view/advanced-search-result-view.component';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import { SelectObjectTypeButtonComponent } from './advanced-search-and-search-category/components/select-object-type-button/select-object-type-button.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatSortModule} from '@angular/material/sort';
import { SearchFilterHierarchicComponent } from './search-filter-menu/search-filter-hierarchich/search-filter-hierarchic.component';
import { SearchFilterHierarchyComponent } from './search-filter-menu/search-filter-hierarchich/search-filter-hierarchy/search-filter-hierarchy.component';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatBadgeModule} from '@angular/material/badge';
import {MatTreeModule} from '@angular/material/tree';
import { SearchHierarchicBreadCrumbsComponent } from './search-view-full/search-hierarchic-bread-crumbs/search-hierarchic-bread-crumbs.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {AppRoutingModule} from '../app-routing/app-routing.module';
import {ObjectNavigatorComponent} from './object-search-menu/object-navigator/object-navigator.component';
import {SearchCollapseComponent} from './object-search-menu/search-collapse/search-collapse.component';
import {ObjectSearchMenuComponent} from './object-search-menu/object-search-menu.component';
import { SearchFacetRangeComponent } from './search-filter-menu/search-facet-range/search-facet-range.component';

@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        MatDialogModule,
        SharedModule,
        OverviewFieldsModule,
        MatTableModule,
        MatCheckboxModule,
        MatMenuModule,
        MatButtonModule,
        MatListModule,
        MatRadioModule,
        MatExpansionModule,
        MatRadioModule,
        MatTooltipModule,
        ReactiveFormsModule,
        DragDropModule,
        MatBottomSheetModule,
        MatIconModule,
        MatInputModule,
        ObjectEditModule,
        MatSelectModule,
        MatAutocompleteModule,
        MatDatepickerModule,
        MatMomentDateModule,
        MatChipsModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        ScrollingModule,
        OperationsModule,
        MatSlideToggleModule,
        MatPaginatorModule,
        MatButtonToggleModule,
        MatSortModule,
        MatSidenavModule,
        MatBadgeModule,
        MatTreeModule,
        MatTabsModule,
        AppRoutingModule
    ],
  exports: [
    ObjectNavigatorComponent,
    SearchQueryComponent,
    SearchViewSelectorComponent,
    SearchViewFullComponent,
    SearchMenuActionsComponent,
    SearchMenuSelectAllComponent,
    SearchMenuSortComponent,
    SearchFilterMenuSmallComponent,
    SearchResultPagerListComponent,
    ObjectSearchMenuComponent
  ],
    declarations: [
        ObjectNavigatorComponent,
        SearchCollapseComponent,
        ObjectSearchMenuComponent,
        SearchQueryComponent,
        SearchFilterMenuComponent,
        SearchFilterMenuPathComponent,
        SearchFilterMenuItemComponent,
        SearchFilterGroupComponent,
        DateSearchFieldComponent,
        SearchFilterMenuSmallComponent,
        SearchResultViewComponent,
        SearchResultViewThumbnailComponent,
        SearchResultViewListSelectorComponent,
        SearchResultViewListThumbnailComponent,
        SearchResultViewListComponent,
        SearchResultViewGalleryComponent,
        SearchMenusComponent,
        SearchMenuSortComponent,
        SearchMenuViewSelectorComponent,
        SearchMenuFolderSettingsComponent,
        SearchMenuActionsComponent,
        SearchMenuSelectAllComponent,
        SearchMenuToggleRestrictionsComponent,
        SearchMenuSelectChosenComponent,
        SearchResultPagerComponent,
        SearchResultPagerListComponent,
        SearchViewSelectorComponent,
        SearchHeaderComponent,
        SideMenuToggleComponent,
        SearchFocusMenuComponent,
        SearchFocusEditDialogComponent,
        SearchViewFullComponent,
        SearchPageComponent,
        SearchQueryPathMenuComponent,
        SearchViewFolderHeaderComponent,
        SearchViewFullHeaderComponent,
        AdvancedSearchComponent,
        SearchViewFullHeaderComponent,
        SearchSelectionBasketComponent,
        SearchCategoryComponent,
        SearchFocusMenuPanelComponent,
        SearchResultViewThumbnailComponent,
        PanelLayoutComponent,
        ContentSectionComponent,
        FieldFilterComponent,
        FieldListComponent,
        QueryBuilderComponent,
        InputComponent,
        TextInputComponent,
        DateInputComponent,
        NumberInputComponent,
        BooleanInputComponent,
        SelectInputComponent,
        AdvancedSearchResultViewComponent,
        SelectObjectTypeButtonComponent,
        SearchFilterHierarchicComponent,
        SearchFilterHierarchyComponent,
        SearchHierarchicBreadCrumbsComponent,
        SearchFacetRangeComponent,
    ],
    providers: []
})
export class ObjectSearchModule {
}
