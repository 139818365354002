export enum OperationStepType {
  CHANGE_STATE = 'change_state',
  CLOSE_OPERATION = 'close_operation',
  CLOSE_OPERATION_REFRESH = 'close_operation_refresh',
  CONTINUE_SUBMITTED_OBJECT = 'continue_submitted_object',
  CONTINUE_NOT_SUBMITTED_OBJECT = 'continue_not_submitted_object',
  CONTINUE_NOT_SUBMITTED_OBJECT_GET_OPERATION_CONTEXTS = 'continue_not_submitted_object_get_operation_contexts',
  COPY_OBJECT = 'copy_object',
  CREATE_OBJECT = 'create_object',
  CREATE_OBJECT_INSTANTLY = 'create_object_instantly',
  CREATE_OBJECT_FROM_MODEL_IN_PREV_STEP = 'create_object_from_model_in_prev_step',
  DELETE_FROM_OBJECT = 'delete_from_object',
  DELETE_OBJECT = 'delete_object',
  EDIT_NEW_OBJECT = 'edit_new_object',
  EDIT_OBJECT = 'edit_object',
  EDIT_SELECTED_OBJECT = 'edit_selected_object',
  GET_EDIT_OBJECT = 'get_edit_object',
  MODIFY_SELECTED_OBJECTS = 'modify_selected_objects',
  SELECT_EXISTING_OBJECTS = 'select_existing_objects',
  SELECT_EDIT_EXISTING_OBJECT = 'select_edit_existing_object',
  SELECT_MODEL_FOR_NEXT_STEP = 'select_model_for_next_step',
  SELECT_STORE_EXISTING_OBJECTS = 'select_store_existing_objects',
  UPLOAD_MEDIA = 'upload_media',
}
