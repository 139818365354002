import {Component, Input, OnInit} from '@angular/core';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {UntypedFormControl} from '@angular/forms';

@Component({
  selector: 'app-object-information-tabs',
  templateUrl: './object-information-tabs.component.html',
  styleUrls: ['./object-information-tabs.component.scss']
})
export class ObjectInformationTabsComponent implements OnInit {

  @Input() object: SuperObjectModel;

  tabIndex = new UntypedFormControl(0);

  constructor() { }

  ngOnInit(): void {

  }

  toggleTab(index) {
    if (index === this.tabIndex.value) {
      setTimeout(() => {
        this.tabIndex.setValue(0);
        }, 100);
    }
  }

}
