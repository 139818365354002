<div *ngIf="fieldTitle" class="field-title-component">

  <div class="field-title-component__text">
    <span [translate]="fieldTitle"></span>
    <span *ngIf="field.is_required && edit"
          class="field-title-component__required">*</span>
  </div>

  <app-field-tool-tip
    *ngIf="showToolTip && edit && editable"
    class="field-title-component__tooltip"
    [field]="field" [rootObject]="rootObject"></app-field-tool-tip>
</div>

