import {Injectable} from '@angular/core';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {ModelsService} from './models.service';
import {MetaField} from './definitions/meta-field';
import {BaseModel} from './definitions/base-model';
import {LoggerService} from './logger.service';

@Injectable({
  providedIn: 'root'
})
export class FieldMetaService {

  constructor(private logger: LoggerService,
              private objectFieldTraverse: ObjectFieldTraverseService,
              private modelsService: ModelsService) {
  }

  getFieldMetaData(object: BaseModel, field: MetaField): MetaField {
    let fieldMeta = {} as MetaField;
    let subObject = this.objectFieldTraverse.getSubObjectFromField(object, field);
    if (subObject) {
      if (Array.isArray(subObject)) {
        if (subObject.length > 0) {
          subObject = subObject[0];
        } else {
          this.logger.warn('Array is empty, unable to get metadata');
        }
      }
      if (subObject.$$meta) {
        fieldMeta = subObject.$$meta[field.name];
      } else {
        this.logger.warn('No meta data in object');
      }
    }
    return fieldMeta || {} as MetaField;
  }

  checkSetMetaData(item: BaseModel): {[name: string]: MetaField} {
    let res = item.$$meta;
    if (!res) {
      res = this.modelsService.getModelMeta(item.object_type);
      if (res) {
        item.$$meta = res;
      } else {
        this.logger.warn('Cannot get meta data for ' + item.object_type);
        res = null;
      }
    }
    return res;
  }

}
