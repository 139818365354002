<button (click)="toggleToolTip()" mat-icon-button
        [matMenuTriggerFor]="tooltipMenu"
        [matMenuTriggerRestoreFocus]="false"
        (menuOpened)="showToolTip = true;"
        (menuClosed)="showToolTip = false;"
        class="blue-hover-icon"
        tabindex="-1"
        type="button"
        [ngClass]="{'text-blue': showToolTip}">
  <i class="icon-help"></i></button>

<mat-menu #tooltipMenu="matMenu" class="field-tool-tip">
  <div class="field-tool-tip__inner-container">

    <div class="field-tool-tip__title">
      <mat-icon>lightbulb_outline</mat-icon>
      <span class="bold">{{fieldName}}</span></div>

    <mat-spinner *ngIf="loading" color="accent" diameter="20"></mat-spinner>

    <div *ngIf="!loading && tooltip.loaded" class="field-tool-tip__text">

      <p *ngIf="tooltip.default">{{tooltip.default}}</p>

      <p *ngIf="!tooltip.default" translate="TRANS__FIELD__TOOLTIP_NO_DESC"></p>

      <div *ngIf="tooltip.spectrum" class="field-tool-tip__spectrum">
        <span class="bold" translate="TRANS__FIELD__TOOLTIP_SPECTRUM"></span>
        <p>{{tooltip.spectrum}}</p></div>

    </div>

    </div>
</mat-menu>

