import { Injectable } from '@angular/core';
import {ConceptsContainer} from '../../core/definitions/concepts-container';
import {Concept} from '../../core/definitions/concepts';
import {
  AdminEditItemComponent,
} from '../admin-lists/admin-edit-item/admin-edit-item.component';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConceptDialogData, AdminConceptJoinConceptsData} from '../../core/concept.service';
import {AdminConceptJoinConceptsComponent} from './admin-concept-join-concepts/admin-concept-join-concepts.component';
import {CultureHubImporterDialogComponent} from '../../shared/culture-hub-importer-dialog/culture-hub-importer-dialog.component';

export interface OpenConceptParams {
  conceptsContainer: ConceptsContainer;
  concept: Concept;
  concept_id: string;
  index: number;
  callback;
}

@Injectable({
  providedIn: 'root'
})
export class ConceptDialogService {

  constructor(private modalService: MatDialog) { }

  openConcept(params: OpenConceptParams) {
    const modalRef = this.modalService.open(AdminEditItemComponent, {
      panelClass: 'edit-dialog',
      disableClose: true,
      data: {
        conceptDataSource: params.conceptsContainer.conceptDataSource,
        conceptList: params.conceptsContainer.conceptDataSource.data,
        conceptsParams: params.conceptsContainer.conceptsParams,
        conceptType: params.conceptsContainer.conceptType,
        concept: params.concept,
        concept_id: params.concept_id,
        index: params.index
      } as ConceptDialogData
    });
    modalRef.afterClosed().subscribe((edited: boolean) => {
      if (params.callback) {
        params.callback(edited);
      }
    });
  }

  openJoinConcepts(conceptsContainer: ConceptsContainer, callback) {
    const modalRef = this.modalService.open(AdminConceptJoinConceptsComponent, {
      panelClass: 'edit-dialog',
      data: {
        conceptsContainer: conceptsContainer,
        callback: callback
      } as AdminConceptJoinConceptsData
    });
    modalRef.afterClosed().subscribe(() => {
      conceptsContainer.conceptDataSource.refreshVisibleConcepts();
    });
  }

  openCultureHubImporter(conceptsContainer: ConceptsContainer, callback) {
    const modalRef = this.modalService.open(CultureHubImporterDialogComponent, {
      panelClass: 'edit-dialog',
      autoFocus: false,
      data: {
        conceptType: conceptsContainer.conceptType
      } as ConceptDialogData
    });
    modalRef.afterClosed().subscribe((hadImports: boolean) => {
      callback(hadImports);
    });

  }
}
