<div class="md-table admin-scroll-layout primus-solr-table-container">
  <!-- Show progressbar if loading -->
  <mat-progress-bar mode="indeterminate"
                    color="accent"
                    *ngIf="dataSource.loading$ | async">
  </mat-progress-bar>
  <!-- start table -->
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- Define column for checkboxes making each row selectable if needed -->
    <ng-container matColumnDef="select" sticky>
      <th mat-header-cell *matHeaderCellDef>
        <span class="admin-table-select-all-name">{{'TRANS__EDIT_FIELD__CHOOSE' | translate}}</span>
      </th>
      <td mat-cell *matCellDef="let row">
        <mat-checkbox (click)="$event.stopPropagation()"
                      (change)="dataSource.toggleSelection(row)"
                      [disabled]="!enableSelection && !enableMultiSelection"
                      [checked]="dataSource.isSelected(row)">
        </mat-checkbox>
      </td>
    </ng-container>

    <!-- Generate columns for each provided column -->
    <ng-container *ngFor="let col of columns" [matColumnDef]="col"
                  [sticky]="col === 'name.name'">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="">{{ getColumnLabel(col) | translate }}</th>
      <td mat-cell *matCellDef="let row" [ngClass]="{'image-col': col === imageProperty}" (click)="rowClicked.emit(row)">

        <!-- Display as image if the column is of type image -->
        <app-thumbnail *ngIf="col === imageProperty && !!row[imageProperty]"
                       [imageIdField]="imageProperty"
                       [item]="row"
                       class="primus-table-thumbnail"
                       stylingClass="profile-image">
        </app-thumbnail>

        <!-- Display broken image if image-column and no image -->
        <mat-icon *ngIf="col === imageProperty && !row[imageProperty]" class="none-profile-image">image</mat-icon>

        <!-- Display value -->
        <span *ngIf="col !== imageProperty && !isBooleanValue(row, col)">
          {{ getRowValue(row, col) }}
        </span>

        <!-- Display boolean value -->
        <span *ngIf="isBooleanValue(row, col)">
             <mat-checkbox [disabled]="true" [checked]="!getRowValue(row, col)"></mat-checkbox>
        </span>

      </td>
    </ng-container>


    <!-- Add column for edit-button if needed -->
    <ng-container matColumnDef="editButton" stickyEnd>
      <th mat-header-cell *matHeaderCellDef></th>
      <td mat-cell *matCellDef="let row">
        <div>
          <button mat-icon-button
                  class="action-icon-in-table text-blue-hover"
                  (click)="handleEditRow(row)">
            <mat-icon>edit</mat-icon>
          </button>
          <button *ngIf="linkToObjectPage"
                  mat-icon-button
                  class="action-icon-in-table"
                  (click)="goToCurrentObject(row)">
            <mat-icon color="accent">arrow_forward</mat-icon>
          </button>
        </div>

      </td>
    </ng-container>

    <!-- Add definition for rows -->
    <tr mat-header-row *matHeaderRowDef="columnsToDisplay; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: columnsToDisplay"
        [ngClass]="{'primus-table-row': enableSelection || enableMultiSelection}">
    </tr>
  </table>

</div>

<!-- Add paginator -->

<div class="admin-action-toolbar">
  <span class="add-row-button-wrapper">
    <button mat-stroked-button
            color="primary"
            type="button"
            *ngIf="enableAddRowButton"
            (click)="handleAddRow()">
      <mat-icon matPrefix>add</mat-icon>
      <span class="bold uppercase">{{ (addRowButtonLabel || '') | translate }}</span>
    </button>
  </span>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 50, 100, 200]"
                 [pageSize]="25"
                 showFirstLastButtons>
  </mat-paginator>
</div>
