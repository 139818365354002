import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {UiToolsService} from '../../core/ui-tools.service';
import {UploadService} from '../../core/upload.service';
import {ObjectEditService} from '../../core/object-edit.service';
import {MediaHelperService} from '../../core/media-helper.service';
import {CmsApiService} from '../../core/cms-api.service';
import {ImageItem} from '../../core/definitions/image-item';
import {SuperObjectModel} from '../../core/definitions/super-object-model';

@Component({
  selector: 'app-media-selector-image-vertical',
  templateUrl: './media-selector-image-vertical.component.html',
  styleUrls: ['./media-selector-image-vertical.component.scss']
})
export class MediaSelectorImageVerticalComponent implements OnInit {

  @Input() fieldName: string;
  @Input() object: SuperObjectModel;
  @Input() target: SuperObjectModel;
  @Input() curAnn: AnnotationHandler;
  @Input() multipleImages: boolean;
  @ViewChild('selectedImgContainer', {static: true}) selectedImgContainer: ElementRef;
  computer = true;
  uploadContainer = {};
  selectImg = false;
  cameraOpen = false;
  parentImages: Array<ImageItem> = [];


  constructor(private uiTools: UiToolsService,
              private uploadService: UploadService,
              private objectEditService: ObjectEditService,
              private mediaHelper: MediaHelperService,
              private cms: CmsApiService) {
  }

  get images(): ImageItem[] {
    return this.object[this.fieldName] || [];
  }

  ngOnInit() {
    if (this.target) {
      if (this.target.contexts) {
        this.target.contexts.forEach(context => {
          this.loadImages(context.context_id);
        });
      }
      if (this.target.parent_id) {
        this.loadImages(this.target.parent_id);
      }
    }

    if (this.multipleImages === undefined) {
      this.multipleImages = true;
    }
    this.computer = this.uiTools.getClientType().isComputer;
  }

  openCamera() {
    this.cameraOpen = !this.cameraOpen;
    this.selectImg = false;
  }

  // At the drag drop area
  // (drop)="onDropFile($event)"
  async onDropFile(event) {
    event.preventDefault();
    if (event.dataTransfer.files.length && event.dataTransfer.files.length > 0) {
      await this.uploadFiles(event.dataTransfer.files);
    }
  }

  // At the drag drop area
  // (dragover)="onDragOverFile($event)"
  onDragOverFile(event) {
    event.stopPropagation();
    event.preventDefault();
  }

  // At the file input element
  // (change)="selectFile($event)"
  async onFileSelect(event) {
    if (event.target.files.length && event.target.files.length > 0) {
      await this.uploadFiles(event.target.files);
    }
  }

  scrollLeft() {
    if (this.selectedImgContainer) {
      this.selectedImgContainer.nativeElement.scrollLeft -= 300;
    }
  }

  async uploadFiles(files) {
    const res = await this.uploadService.uploadFiles(this.uploadContainer, files, 'image', this.object);
    const items = await this.getImageItems(res);
    items.forEach(item => {
      this.images.push(item);
    });
  }

  private async getImageItems(images: Array<SuperObjectModel>): Promise<ImageItem[]> {
    const res = [];
    for (const element of images) {
      const img = element;
      const item = new ImageItem();
      item.image_id = img.artifact_id;
      item.thumbnail_id = img.thumbnail_id;
      item.context_id = this.object.artifact_id;
      item.$$uploading = img.$$uploading;
      const itemCreated = await this.objectEditService.createModelItem('ImageItem', item);
      res.push(itemCreated);
    }
    return res;
  }

  private loadImages(parentId) {
    this.loadNewImages(parentId).then(images => {
      this.mediaHelper.addImageUrls(images).then(() => {
        this.parentImages = this.parentImages.concat(images);
      });
    });
  }

  private loadNewImages(parentId): Promise<Array<ImageItem>> {
    return new Promise(resolve => {
      this.cms.getArtifactImages({artifact_id: parentId}).then(
        data => {
          const newImages: Array<ImageItem> = data.images;
          const res = [];
          newImages.forEach(newImage => {
            let exists = false;
            this.parentImages.forEach(existingImg => {
                if (newImage.image_id === existingImg.image_id) {
                  exists = true;
                }
              }
            );
            if (!exists) {
              res.push(newImage);
            }
          });
          resolve(res);

        },
        function (status) {
          console.warn('Failed with code: ' + status);
        }
      );
    });
  }

}
