import {Component, Input, OnChanges, OnDestroy, OnInit} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {CommonsService} from '../../core/commons.service';
import {CmsApiService} from '../../core/cms-api.service';
import {PrimusRouteService} from '../../core/primus-route.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {LoginService} from '../../core/login.service';
import {EventHarvesterService} from '../../core/event-harvester.service';
import {OperationService} from '../../operations/operation.service';
import {UserData} from '../../core/definitions/user-data';
import {PrimusBackendInstanceService, PrimusInstanceDetails} from '../../core/primus-backend-instance.service';
import {environment} from '../../../environments/environment';
import {PrimusRouterService} from '../../core/primus-router.service';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {OperationTarget} from '../../core/definitions/operation-target.enum';
import {OperationContainer} from '../../core/definitions/operation-container';
import {Router} from '@angular/router';


@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit, OnDestroy, OnChanges {
  AConst = AConst;
  toggleMenuItem = false;
  openMessage = false;
  notifications = [];
  message = [];
  upComing = [];
  user;
  currentIndex = 0;
  computer = false;
  backendVersion = undefined;
  clientVersion: string = environment.version;
  closeJobStatus = false;
  museum: PrimusInstanceDetails;

  edition = undefined;
  waitTime = 600000;
  operationContainer: OperationContainer;

  private clickListenerId;

  @Input() currentStateName;

  constructor(private cms: CmsApiService,
              private commons: CommonsService,
              private loginService: LoginService,
              private primusRouter: PrimusRouterService,
              public primusStateMapper: PrimusStateMapperService,
              private primusRoute: PrimusRouteService,
              private uiTools: UiToolsService,
              public router: Router,
              private operationService: OperationService,
              private eventHarvester: EventHarvesterService) {
  }

  ngOnInit() {
    this.computer = this.uiTools.getClientType().isComputer;

    this.loginService.currentUser.subscribe(user => {
      this.initComponent(user).then();
    });

    this.clickListenerId = this.uiTools.addDocumentClickListener(() => {
      this.toggleMenuItem = false;
    }, 'mainMenu');
    this.museum = PrimusBackendInstanceService.getInstanceDetails();
  }

  ngOnChanges() {
    let isNotAdminState, isNotFaqState, isNotAboutState, isNotNewState;
    isNotAdminState = this.currentStateName.indexOf('admin') === -1;
    isNotFaqState = this.currentStateName.indexOf('home.primus.faq') === -1;
    isNotAboutState = this.currentStateName.indexOf('home.primus.about') === -1;
    isNotNewState = this.currentStateName.indexOf('home.primus.artifactNew') === -1;

    if (isNotAdminState && isNotFaqState && isNotAboutState && isNotNewState) {
      this.selectedItem(this.currentStateName);
    }
  }

  ngOnDestroy(): void {
    this.uiTools.removeDocumentClickListener(this.clickListenerId);
  }

  setMenuProp() {
    const params = {
      admin_type: this.primusRoute.params.admin_type
    };
    this.selectedItem(this.primusRouter.currentState(), params);
  }

  private async initComponent(user: UserData) {
    if (user) {
      this.user = user;
      this.operationContainer = await this.operationService.createOperationContainer(OperationTarget.MAIN_MENU_VIEW);
      await this.operationService.setOperations(this.operationContainer);
      this.setMenuProp();
      this.setEditionAndVersion();
      this.checkChangedNotifications();
    }
  }

  toggleJobStatus() {
    this.closeJobStatus = true;
    setTimeout(() => {
      this.closeJobStatus = false;
    }, 500);
  }

  toggleMenu() {
    this.toggleJobStatus();
    this.toggleMenuItem = !this.toggleMenuItem;
    this.uiTools.ignoreNextDocumentClick(this.clickListenerId);
  }

  toggleMessage() {
    this.toggleJobStatus();
    this.openMessage = !this.openMessage;
  }

  getIndex(id) {
    let index = 0;
    this.notifications.forEach((item, i) => {
      if (item.message.id === id) {
        index = i;
      }
    });
    return index;
  }

  async getUpComingVersion(): Promise<void> {
    const data = await this.cms.getUpcomingVersion();
    let id, index, exist;
    if (data[AConst.MESSAGE]) {
      id = data[AConst.MESSAGE].id;
      index = this.getIndex(id);
      exist = this.notifications[index];

      if (!exist) {
        data[AConst.NAME] = 'upComing';
        this.upComing.push(data);
      } else {
        if ((JSON.stringify(data[AConst.MESSAGE].description) !== JSON.stringify(exist[AConst.MESSAGE].description)) ||
          (JSON.stringify(data[AConst.MESSAGE].title) !== JSON.stringify(exist[AConst.MESSAGE].title))) {
          this.notifications[index].message =
            data[AConst.MESSAGE];
        }
        if (JSON.stringify(data[AConst.WHAT_IS_NEW]) !== JSON.stringify(exist[AConst.WHAT_IS_NEW])) {
          this.notifications[index][AConst.WHAT_IS_NEW] =
            data[AConst.WHAT_IS_NEW];
        }
      }
    } else {
      if (this.upComing.length > 0) {
        this.upComing = [];
      }
    }
  }

  setNotifications() {
    this.notifications = this.message.concat(this.upComing);
    this.commons.sortArray(this.notifications, 'priority');
  }

  getMessage(): Promise<void> {
    return new Promise((resolve, reject) => {
      this.cms.getMessage().then(
        (data) => {
          if (data[AConst.MESSAGES]) {
            this.message = data[AConst.MESSAGES];
          } else {
            this.message = [];
          }
          resolve();
        },
        (response) => {
          if (reject) {
            reject(response);
          }
        }
      );
    });
  }

  checkChangedNotifications() {
    if (!this.computer) {
      return;
    }

    setInterval(() => {
      this.getMessage().then(
        () => this.getUpComingVersion()).then(
        () => this.setNotifications());

      if (this.currentIndex > this.notifications.length) {
        this.currentIndex = this.currentIndex - 1;
      }
    }, this.waitTime);
  }

  setEditionAndVersion() {
    this.backendVersion = this.user.version;
    this.edition = this.commons.setEditionTitle(this.user);
  }

  selectedItem(state?, params?) {
    let targetState, location, adminType, isAdmin;
    this.toggleJobStatus();
    location = this.primusRouter.currentState();


    if (state) {
      if (state === 'home.primus.faq') {
        this.eventHarvester.faqClicked();
      }
      location = state;
    }
    if (params) {
      adminType = params['admin_type'];
    }

    isAdmin = location.indexOf('admin');
    const operations = this.operationContainer?.operations || [];
    for (const operation of operations) {
      if (operation.operation_steps[0].change_state[0].state_name) {
        targetState = operation.operation_steps[0].change_state[0].state_name;
        if (targetState === location) {
          operation.$$operationSelected = !(targetState === 'admin-page' &&
            adminType !== 'create-new');
        } else {
          operation.$$operationSelected = targetState === 'admin' &&
            isAdmin !== -1 && adminType !== 'create-new';
        }
      }
    }
    if (this.toggleMenuItem) {
      this.toggleMenuItem = false;
    }
  }

  async homeButton() {
    this.toggleJobStatus();
    await this.primusRouter.goBackToSearch();
  }

  async goToAboutPrimus() {
    const state = 'home.primus.about';
    await this.primusRouter.navigateState(state, {}, {reload: true});
    this.selectedItem(state);
  }

  getQueryParams() {
    // Deep copy params
    const params = JSON.parse(JSON.stringify(this.primusRoute.params));
    // Remove the params under to prevent unintended consequences
    delete params.artifactId;
    delete params.parentId;
    delete params.rootObjId;
    delete params.rootObjType;
    return params;
  }
}
