import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../../core/a-const.enum';
import {CheckFilterGroup} from '../../../core/definitions/search-objects';
import {SearchFilterService} from '../../search-filter.service';
import {SearchFacetService} from '../../search-facet.service';
import {SearchResultViewType} from '../../../core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../../core/definitions/search-container';

@Component({
  selector: 'app-search-filter-group',
  templateUrl: './search-filter-group.component.html',
  styleUrls: ['./search-filter-group.component.scss']
})
export class SearchFilterGroupComponent implements OnChanges {

  @Input() filterGroup: CheckFilterGroup;
  @Input() sideMenu;
  @Input() searchContainer: SearchContainer;

  showFilterItem = true;
  hasManyFilters = false;
  AConst = AConst;

  constructor(private searchFilterService: SearchFilterService,
              private searchFacetService: SearchFacetService) {
  }

  ngOnChanges() {
    if (this.filterGroup) {
      if (this.filterGroup.checkFilters.length > AConst.MAX_FILTER_LENGTH) {
        this.hasManyFilters = true;
      }
    }

    if (this.searchContainer.searchResultViews.default === SearchResultViewType.SELECTOR ||
      this.searchContainer.searchResultViews.default === SearchResultViewType.CONTENT_LIST) {
      this.showFilterItem = false;
    }
  }

  checkFilter($event, filter) {
    $event.preventDefault();
    this.searchFilterService.checkCheckFilter(filter, this.searchContainer).then(() => {
      this.setVisibleFilters();
    });
  }

  toggle() {
    this.showFilterItem = !this.showFilterItem;
  }

  showMore() {
    this.filterGroup.filterFilter.showMoreCount += this.filterGroup.filterFilter.showMoreAdd;
    this.setVisibleFilters();
  }

  getTotalCount() {
    return this.searchFacetService.getTotalCount(this.filterGroup, this.searchContainer);
  }

  queryChanged() {
    this.filterGroup.filterFilter.showMoreCount = 10;
    this.setVisibleFilters();
  }

  private setVisibleFilters() {
    this.searchFilterService.setVisibleFilters(this.filterGroup);
  }

}
