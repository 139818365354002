<div class="adminEventCreate operation-view-upload" *ngIf="uploadMediaContainer">
  <div class="row operation-view-upload__selector-general"
       [ngClass]="{'disabled': parentObject.$$annotateImage}">
    <app-media-selector-general
      [uploadInfo]="uploadMediaContainer.uploadInfo"
      [parentObject]="operationContainer.contentInfo.artifact"
      [files]="uploadMediaContainer.files"
      (deleteImage)="handleDeleteImage($event)"
    ></app-media-selector-general>
  </div>

  <div class="row operation-view-upload__uploaded-content">
    <span class="operation-view-upload__uploaded-headline bold">
      <span>{{uploadMediaContainer.files.length}}&nbsp;</span>
<!--      <span class="lowercase" translate>{{operationUploadContainer.menu_title}}&nbsp;</span>-->
      <span translate="TRANS__MENU_UPLOAD__UPLOADED"></span>
    </span>
    <hr>
    <div>
      <app-media-list
        [uploadMediaContainer]="uploadMediaContainer"
        [parentObject]="parentObject"
        (deleteMedia)="handleDeleteImage($event)"
      ></app-media-list>
    </div>
  </div>

</div>
