import {Injectable} from '@angular/core';
import {AbstractControl} from '@angular/forms';
import {AConst} from './a-const.enum';
import {ModelFactoryService} from './model-factory.service';
import {FormGroupParams, PrimusFormGroupService} from './primus-form-group.service';
import {FieldValueService} from './field-value.service';
import {FieldStateService} from './field-state.service';
import {FieldParameters} from './definitions/field-parameters';
import {MetaField} from './definitions/meta-field';
import {BaseModel} from './definitions/base-model';

export interface AddInlineArrayItemResult {
  item: BaseModel;
  array: any[];
}

@Injectable({
  providedIn: 'root'
})
export class InlineArrayItemService {

  constructor(private modelFactory: ModelFactoryService,
              private formGroupService: PrimusFormGroupService,
              private fieldValueSvc: FieldValueService,
              private fieldStateSvc: FieldStateService) {
  }

  async addInlineArrayItemToForm(fieldParameters: FieldParameters, noToggle?: boolean, itemData?: any): Promise<any> {
    const validCurrent = this.checkValidInlineArrayItem(fieldParameters);
    if (validCurrent.valid) {
      const res = await this.addInlineArrayItem(fieldParameters, itemData);
      const newIndex = res.array.length - 1;
      res.item.$$justAdded = true;
      const parentKey = this.getParentKey(fieldParameters.field);
      this.formGroupService.setFormGroupFieldInlineArrayItem({
        rootObject: fieldParameters.object,
        object: fieldParameters.object,
        field: fieldParameters.field,
        group: fieldParameters.sectionsContainer.formGroup,
        parentKey: parentKey,
        index1: fieldParameters.parentIndex
      } as FormGroupParams, res.item, newIndex);
      if (!noToggle) {
        this.toggleInlineArrayItemOpen(fieldParameters, newIndex);
      }
      res.item['$$addedIndex'] = newIndex;
      if (fieldParameters.useMultipleSetter) {
        res.item.$$open = true;
      }
    } else {
      this.notifyInvalidArrayItem(validCurrent.invalidFieldKey);
    }
  }

  deleteInlineArrayItemFromForm(fieldParameters: FieldParameters, index: number) {
    const parentKey = this.getParentKey(fieldParameters.field);
    const arr = fieldParameters.object[fieldParameters.field.name];
    const item = this.modelFactory.deleteArrayItem(arr, index, fieldParameters.sectionsContainer.rootObject);
    const params = {
      object: fieldParameters.object,
      field: fieldParameters.field,
      group: fieldParameters.sectionsContainer.formGroup,
      parentKey: parentKey,
      index1: fieldParameters.parentIndex
    } as FormGroupParams;
    if (item._destroy) {
      // This makes sure that the store button is enabled after removing array elements
      this.formGroupService.markFormGroupFieldInlineArrayItemAsDeleted(params, index);
    }
    this.formGroupService.removeFormGroupFieldInlineArrayItem(params, item, index);
    this.setValidIndex(fieldParameters);
    this.setInlineArrayFormControlValue(fieldParameters);
  }

  changeInlineArrayItemsOrder(fieldParameters: FieldParameters) {
    this.formGroupService.setSectionsContainerFormGroup(fieldParameters.sectionsContainer);
    this.markAsDirty(fieldParameters, AConst.ORDER_NUMBER);
  }

  getOpenArrayItemIndex(fieldParameters: FieldParameters): number {
    const arrayObject = this.getArrayItems(fieldParameters);
    return arrayObject.$$openIndex;
  }

  setOpenArrayItemIndex(fieldParameters: FieldParameters, index) {
    const arrayObject = this.getArrayItems(fieldParameters);
    const currentOpenIndex = arrayObject.$$openIndex;
    if (index === undefined) {
      arrayObject.$$openIndex = undefined;
      arrayObject.forEach(item => {
        item.$$open = false;
      });
    } else if (!fieldParameters.useMultipleSetter) {
      if (index !== currentOpenIndex) {
        arrayObject[index].$$open = true;
        if (currentOpenIndex !== undefined && arrayObject.length > currentOpenIndex) {
          arrayObject[currentOpenIndex].$$open = false;
        }
        arrayObject.$$openIndex = index;
      } else {
        arrayObject[index].$$open = false;
        arrayObject.$$openIndex = undefined;
      }
    } else {
      arrayObject[index].$$open = !arrayObject[index].$$open;
      arrayObject.$$openIndex = arrayObject[index].$$open ? index : undefined;
    }
  }

  setOpenArrayItemIndexFromKey(object, key) {
    const bracketStart = key.lastIndexOf('[');
    let index;
    if (bracketStart !== -1) {
      const bracketEnd = key.lastIndexOf(']');
      if (bracketEnd !== -1) {
        const arrayObjectKey = key.substring(0, bracketStart);
        const arrayObject = this.getArrayItemsFromKey(object, arrayObjectKey);
        index = Number(key.substring(bracketStart + 1, bracketEnd));
        arrayObject.forEach(item => {
          item.$$open = false;
        });
        arrayObject[index].$$open = true;
        arrayObject.$$openIndex = index;
      }
    }
    if (index === undefined) {
      console.warn('Unable to get index from ' + key);
    }
  }

  isInlineArrayItemOpen(fieldParameters: FieldParameters) {
    const arrayObject = this.getArrayItems(fieldParameters);
    let res = false;
    if (fieldParameters.index < arrayObject.length) {
      res = arrayObject[fieldParameters.index].$$open;
    } else {
      console.warn('Inline array index larger than array size');
    }
    return res;
  }

  getInlineArrayItemId(fieldParameters: FieldParameters) {
    let key;
    if (fieldParameters.parentIndex === undefined) {
      key = fieldParameters.field.key + '[{index1}]';
    } else {
      key = fieldParameters.field.key + '[{index2}]';
    }
    const metaField: MetaField = {} as MetaField;
    metaField.key = key;
    return this.fieldStateSvc.getFieldKeyWhileDrawingInputs(metaField, fieldParameters.index, fieldParameters.parentIndex);
  }

  toggleInlineArrayItemOpen(fieldParameters: FieldParameters, indexIn?: number) {
    const index = indexIn !== undefined ? indexIn : fieldParameters.index;
    const validCurrent = this.checkValidInlineArrayItem(fieldParameters);
    if (validCurrent.valid) {
      this.setOpenArrayItemIndex(fieldParameters, index);
    } else {
      this.notifyInvalidArrayItem(validCurrent.invalidFieldKey);
    }
  }

  getArrayItems(fieldParameters: FieldParameters) {
    return this.getArrayItemsFromKey(fieldParameters.sectionsContainer.rootObject, this.getInlineArrayFieldKey(fieldParameters));
  }

  getNotDeletedArrayItems(fieldParameters: FieldParameters) {
    return this.getArrayItems(fieldParameters).filter(arrayItem => !arrayItem._destroy);
  }

  private async addInlineArrayItem(fieldParameters: FieldParameters, itemData?: any): Promise<AddInlineArrayItemResult> {
    const arrayObject = this.getArrayItems(fieldParameters);
    const item = await this.modelFactory.createAddArrayItemAsync(arrayObject, fieldParameters.field.inline[AConst.MODEL], itemData);
    this.setInlineArrayFormControlValue(fieldParameters);
    return {item: item, array: arrayObject};
  }

  // Get field key for the inline array
  private getInlineArrayFieldKey(fieldParameters: FieldParameters) {
    return this.fieldStateSvc.getFieldKeyWhileDrawingInputs(fieldParameters.field, fieldParameters.parentIndex);
  }

  // Necessary in order to trigger required validation for list arrays that are set to required
  private setInlineArrayFormControlValue(fieldParameters: FieldParameters) {
    const inlineArray = this.getNotDeletedArrayItems(fieldParameters);
    const fieldKey = this.getInlineArrayFieldKey(fieldParameters);
    const formControlField = fieldParameters.sectionsContainer.formGroup.controls[fieldKey];
    // If form control field is missing when setting inline array field length, it is because the current inline array
    // has been added to a parent inline array that's been added recently. E.g. treatment methods -> materials. Thus,
    // it is not necessary to mark the field as dirty as the parent array has already been marked as dirty.
    if (formControlField) {
      formControlField.setValue(inlineArray.length ? inlineArray.length : '');
      formControlField.markAsDirty();
    }
  }

  private markAsDirty(fieldParameters: FieldParameters, fieldName) {
    const key = this.getInlineArrayItemId(fieldParameters) + '.' + fieldName;
    const control: AbstractControl = fieldParameters.sectionsContainer.formGroup.controls[key];
    control.markAsDirty();
  }

  private getParentKey(field) {
    let parentKey;
    const lastIndexOf = field.key.lastIndexOf('.');
    if (lastIndexOf !== -1) {
      parentKey = field.key.substring(0, lastIndexOf);
    }
    return parentKey;
  }

  private getArrayItemsFromKey(object, fieldKey) {
    let items = this.fieldValueSvc.getFieldValue(object, fieldKey);
    if (!items) {
      items = [];
      console.warn('Unable to retrieve array items for ' + fieldKey);
    }
    return items;
  }

  private checkValidInlineArrayItem(fieldParameters: FieldParameters) {
    const res = {
      valid: true,
      invalidFieldKey: undefined
    };
    const curIndex = this.getOpenArrayItemIndex(fieldParameters);
    if (curIndex !== undefined) {
      fieldParameters.field.inline_fields.forEach((subFieldMeta: MetaField) => {
        // Generate a subfield key based on parent key, array index and subfield name;
        let subFieldKey;
        if (fieldParameters.parentIndex === undefined) {
          subFieldKey = subFieldMeta.key.replace('{index1}', curIndex.toString());
        } else {
          subFieldKey = subFieldMeta.key.replace('{index1}',
            fieldParameters.parentIndex.toString()).replace('{index2}', curIndex.toString());
        }
        const subFieldCtrl = fieldParameters.sectionsContainer.formGroup.controls[subFieldKey];
        // If the field
        if (subFieldCtrl) {
          if (subFieldCtrl.invalid) {
            res.valid = false;
            res.invalidFieldKey = subFieldKey;
          }
        }
      });
    }
    return res;
  }

  private notifyInvalidArrayItem(fieldKey) {
    const inputEl = document.getElementById(fieldKey);
    if (inputEl) {
      inputEl.classList.add('shaking');
      setTimeout(() => {
        inputEl.classList.remove('shaking');
      }, 4000);
    }
  }

  // Set "valid" index, usually used after deleting items from an
  // array. A valid index is the index of an element in an array
  // that hasn't been marked as "_destroy".
  private setValidIndex(fieldParameters: FieldParameters) {
    let curIndex = this.getOpenArrayItemIndex(fieldParameters);

    if (curIndex !== undefined) {
      let valIndex;
      const arr = fieldParameters.object[fieldParameters.field.name];
      if (curIndex >= arr.length) {
        curIndex--;
      }

      for (let i = 0; i < arr.length; i++) {
        const item = arr[i];
        if (!item._destroy) {
          valIndex = i;
          if (i >= curIndex) {
            break;
          }
        }
      }
      this.setOpenArrayItemIndex(fieldParameters, valIndex);
    }
  }

}
