import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import User from '../../User';
import {SearchObject} from '../../../../core/definitions/search-object';
import {UntypedFormControl} from '@angular/forms';
import {MatLegacySelectChange as MatSelectChange} from '@angular/material/legacy-select';
import {LoggerService} from '../../../../core/logger.service';
import {UserCollectionItems} from '../../../../core/definitions/user-collection-items';
import {UserCollectionItemsBase} from '../../../../core/definitions/user-collection-items-base';
import {UserVirtualCollectionItems} from '../../../../core/definitions/user-virtual-collection-items';
import {AdminUsersService} from '../../admin-users.service';

@Component({
  selector: 'app-user-collections-adder',
  templateUrl: './user-collections-adder.component.html',
  styleUrls: ['./user-collections-adder.component.scss']
})
export class UserCollectionsAdderComponent implements OnInit {

  allCollections: SearchObject[] = [];
  selectedCollections = new UntypedFormControl();
  collectionItemsList: UserCollectionItemsBase[] = [];
  enableCommonRights = new UntypedFormControl();
  commonRightsCollection = {} as UserCollectionItemsBase;
  collectionType: string;

  private collectionRightTypes: SearchObject[] = [];
  private allSelectedLastTime = false;
  private user: User;

  constructor(private readonly dialogRef: MatDialogRef<UserCollectionsAdderComponent>,
              private readonly logger: LoggerService,
              private readonly adminUsersService: AdminUsersService,
              @Inject(MAT_DIALOG_DATA) private readonly data: User) {
  }

  ngOnInit(): void {
    this.collectionType = this.data['collectionType'];
    this.user = this.data['user'];
    switch (this.collectionType) {
      case 'collections':
        this.collectionItemsList = [...this.user.collections];
        break;
      case 'virtual_collections':
        this.collectionItemsList = [...this.user.virtual_collections];
        break;
      default:
        this.logger.warn('Unknown type');
    }
    this.init().then();
  }

  async init() {
    this.collectionRightTypes = await this.adminUsersService.getCollectionRightTypes(this.collectionType);
    await this.loadCollections();
    this.setSelectedCollections();
  }

  toggleUserCollections(event: MatSelectChange) {
    this.enableCommonRights.setValue(false);
    this.commonRightsCollection.collection_rights_id = null;
    let selectedCollectionIds: string[] = event.value;
    if (selectedCollectionIds.includes('all')) {
      if (!this.allSelectedLastTime) {
        selectedCollectionIds = this.allCollections.map(collection => collection.artifact_id);
        this.allSelectedLastTime = true;
      } else {
        // Remove the 'all' collection
        selectedCollectionIds.splice(0, 1);
        this.allSelectedLastTime = false;
      }
      this.selectedCollections.setValue(selectedCollectionIds);
    } else {
      if (this.allSelectedLastTime) {
        selectedCollectionIds = [];
        this.selectedCollections.setValue([]);
      }
      this.allSelectedLastTime = false;
    }
    for (const collection of this.allCollections) {
      const existingCollectionId = collection.artifact_id;
      if (existingCollectionId === 'all') {
        continue;
      }
      const existingUserCollectionPos = this.collectionItemsList
        .map(userCollection => userCollection.collection_id)
        .indexOf(existingCollectionId);
      const existingUserCollection = existingUserCollectionPos !== -1 ? this.collectionItemsList[existingUserCollectionPos] : null;
      if (selectedCollectionIds.includes(existingCollectionId)) {
        // The collection has been added or not removed
        if (existingUserCollection) {
          // The user already had this collection, must clear _destroy flag in case it was removed
          existingUserCollection._destroy = false;
        } else {
          // The user did not already have this collection
          const userCollection = this.createNewUserCollectionFromCollection(collection, !this.collectionItemsList.length);
          this.collectionItemsList.push(userCollection);
        }
      } else {
        // The collection has been removed or not added
        if (existingUserCollection) {
          // The user had this collection, must remove if created else set destroy flag
          this.removeUserCollection(existingUserCollection, false);
        }
      }
    }
  }

  removeUserCollection(existingUserCollection: UserCollectionItemsBase, removeFromSelected: boolean) {
    if (existingUserCollection._create) {
      const existingUserCollectionPos = this.collectionItemsList
        .map(userCollection => userCollection.collection_id)
        .indexOf(existingUserCollection.collection_id);
      if (existingUserCollectionPos !== -1) {
        this.collectionItemsList.splice(existingUserCollectionPos, 1);
      } else {
        this.logger.warn('Collection to be deleted was not found');
      }
    } else {
      existingUserCollection._destroy = true;
    }
    if (removeFromSelected) {
      this.setSelectedCollections();
    }
  }

  onChangedMainCollection(newMainCollection: UserCollectionItemsBase) {
    this.user.main_collection_id = newMainCollection.collection_id;
    this.collectionItemsList.filter(collection => collection.collection_id !== newMainCollection.collection_id).forEach(
      notMainCollection => {
        notMainCollection['is_main_collection'] = false;
      });
  }

  onCommonRightsSet(collectionRightsId) {
    for (const userCollection of this.collectionItemsList) {
      userCollection.collection_rights_id = collectionRightsId;
    }
  }

  closeDialog(success: boolean): void {
    if (success) {
      if (this.collectionType === 'collections') {
        this.user.collections = this.collectionItemsList.map(
          collectionItems => <UserCollectionItems>collectionItems);
      } else if (this.collectionType === 'virtual_collections') {
        this.user.virtual_collections = this.collectionItemsList.map(
          collectionItems => <UserVirtualCollectionItems>collectionItems);
      }
    }
    this.dialogRef.close(success);
  }

  private setSelectedCollections() {
    this.selectedCollections.setValue(this.collectionItemsList
      .filter(userCollection => !userCollection._destroy)
      .map(userCollection => userCollection.collection_id));
  }

  private createNewUserCollectionFromCollection(collection: SearchObject, isFirst: boolean): UserCollectionItemsBase {
    let collectionItems: UserCollectionItemsBase;
    if (this.collectionType === 'collections') {
      collectionItems = new UserCollectionItems();
      collectionItems['is_main_collection'] = isFirst;
    } else {
      collectionItems = new UserVirtualCollectionItems();
    }
    collectionItems.collection_id = collection.artifact_id;
    collectionItems.collection_id_value = collection.artifact_name;
    collectionItems.context_id = this.user.artifact_id;
    collectionItems.collection_rights_id = this.collectionRightTypes[0].artifact_id;
    collectionItems._create = true;
    return collectionItems;
  }

  private async loadCollections(): Promise<void> {
    this.allCollections = await this.adminUsersService.getCollections(this.collectionType);
  }

}
