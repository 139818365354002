import {Component, Input, OnChanges} from '@angular/core';
import {ObjectViewDataParams, ObjectViewDataService} from '../../core/object-view-data.service';
import {AConst} from '../../core/a-const.enum';
import {ObjectView} from '../../core/definitions/object-view';
import {ObjectEditService} from '../../core/object-edit.service';
import {SectionsContainer} from '../../core/definitions/sections-container';

@Component({
  selector: 'app-object-view',
  templateUrl: './object-view.component.html',
  styleUrls: ['./object-view.component.scss']
})
export class ObjectViewComponent implements OnChanges {

  @Input() objectId: string;
  @Input() sectionsContainer: SectionsContainer;
  @Input() templateGroupId: string;
  @Input() hideSectionTitle;
  @Input() noHr;

  AConst = AConst;
  objectViewData: ObjectView;

  constructor(private objectViewDataService: ObjectViewDataService,
              private objectEditService: ObjectEditService) {
  }

  ngOnChanges() {
    if (!this.sectionsContainer) {
      this.objectEditService.loadObjectGetSectionsContainer(this.objectId, false, this.templateGroupId).then(sectionsContainer => {
        this.sectionsContainer = sectionsContainer;
        this.getObjectViewData().then(objectViewData => {
            this.objectViewData = objectViewData;
        });
      });
    } else {
      this.getObjectViewData().then(objectViewData => {
        this.objectViewData = objectViewData;
      });
    }
  }

  private async getObjectViewData() {
    let res;
    const objectViewDataParams = new ObjectViewDataParams();
    objectViewDataParams.objectId = this.objectId;
    objectViewDataParams.templateGroupId = this.templateGroupId;
    if (this.sectionsContainer.objectView) {
      res = this.sectionsContainer.objectView;
    } else {
      res = await this.objectViewDataService.createViewData(objectViewDataParams);
    }
    return res;
  }
}
