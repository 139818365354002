<div class="dialog {{'ignoreClicks-' + data.fieldParameters.field.name}}"
     [ngClass]="data.secondDialogOpen ?
     'ignoreClicksModal-' + data.fieldParameters.field.name : ''">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span *ngIf="sectionsContainer?.rootObject && sectionsContainer?.rootObject.model_title">{{sectionsContainer.rootObject.model_title | translate}}</span>
      <span *ngIf="!sectionsContainer?.rootObject || !sectionsContainer?.rootObject.model_title" translate="TRANS__EDIT_OBJECT_DIALOG__CREATE_ENTITY"></span>
    </h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <div *ngIf="!sectionsContainer?.rootObject && selectObjectTypes"
       class="inherit-border-color">
    <div mat-dialog-content
         class="modal-body modal-body-edit-fields">
      <div class="modal-main-content">
        <span class="edit-object-dialog__radio-button-headline bold"
               translate="TRANS__NEW_MODAL__SELECT_OBJECT_TYPE"></span>
        <mat-radio-group aria-label="select an option"
                         class="edit-object-dialog__radio-button-group"
                         [(ngModel)]="selectedObjectTypeName">
          <ng-container *ngFor="let  objectType of selectObjectTypes;">
            <mat-radio-button
              *ngIf="objectType.name"
              class="edit-object-dialog__radio-button"
              [value]="objectType"
              (click)="objectTypeSelected(objectType)">{{objectType.name}}</mat-radio-button>
          </ng-container>
        </mat-radio-group>
      </div>
    </div>
    <div mat-dialog-actions class="modal-footer">
      <button type="submit"
              mat-flat-button
              color="accent"
              tabindex="0"
              (click)="nextStep()"
              [disabled]="selectedObjectTypeName === null">
        <span class="bold" translate="TRANS__EDIT_OBJECT_DIALOG__NEXT"></span></button>
      <button type="button"
              tabindex="0"
              mat-button
              color="accent"
              (click)="dialogRef.close(false)"
              (keyup)="($event.key === 'Enter') && dialogRef.close(false)">
        <span class="bold" translate="TRANS__EDIT_OBJECT_DIALOG__CANCEL"></span></button>
    </div>
  </div>

  <form *ngIf="sectionsContainer?.formGroup"
        (ngSubmit)="onSubmit()"
        [formGroup]="sectionsContainer?.formGroup"
        class="inherit-border-color">
    <div mat-dialog-content  class="modal-body modal-body-edit-fields">
      <div class="modal-main-content">
        <app-reference-usage
          [fieldParameters]="data.fieldParameters"
          [parentObject]="sectionsContainer.parentObject"
          [showReferencesAsLink]="false">
        </app-reference-usage>
      <app-edit-fields
        *ngIf="primeFields"
        [sectionsContainer]="sectionsContainer"
        [object]="sectionsContainer.rootObject"
        [fields]="primeFields"
      ></app-edit-fields>
      <app-section-view-edit
        *ngIf="!primeFields"
        [sectionsContainer]="sectionsContainer"
      ></app-section-view-edit>
      </div>
    </div>
    <div mat-dialog-actions class="modal-footer">
      <button mat-flat-button
              type="submit"
              class=""
              color="accent"
              tabindex="0"
              [disabled]="!isValid">
        <span class="bold" translate="TRANS__EDIT_OBJECT_DIALOG__SUBMIT"></span></button>
      <button type="button"
              class=""
              mat-button
              color="accent"
              tabindex="0"
              (click)="dialogRef.close(false)"
              (keyup)="($event.key === 'Enter') && dialogRef.close(false)">
        <span class="bold" translate="TRANS__EDIT_OBJECT_DIALOG__CANCEL"></span></button>
      <div *ngIf="storeStatus.failed">{{storeStatus.message | translate}} {{storeStatus.serverMsg}}</div>
    </div>
  </form>

  <div *ngIf="!sectionsContainer?.formGroup && storeStatus.failed" class="modal-footer">
    <div><h3>{{storeStatus.message | translate}}</h3></div>
    <button type="button" class="" mat-button color="accent" tabindex="0"
            (click)="dialogRef.close(false)"
            (keyup)="($event.key === 'Enter') && dialogRef.close(false)">
      <span class="bold" translate="TRANS__EDIT_OBJECT_DIALOG__CANCEL"></span></button>
  </div>
</div>


