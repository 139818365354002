import {Injectable} from '@angular/core';
import {CmsApiService} from '../../core/cms-api.service';
import User, {UserPrivilege} from './User';
import {SearchParameters, SearchParametersForOverview} from '../../core/definitions/search-parameters';
import {TableColumn} from '../../shared/primus-tables/primus-table/primus-table.component';
import {SearchObject} from '../../core/definitions/search-object';
import {OverviewField} from '../../core/definitions/object-view';
import {LoginService} from '../../core/login.service';
import {ObjectStorageService} from '../../core/object-storage.service';
import {UserData} from 'src/app/core/definitions/user-data';
import {SearchResult} from 'src/app/core/definitions/search-result';
import {BehaviorSubject, from, Observable} from 'rxjs';
import {SolrFilterService} from '../../core/solr-filter.service';
import {UserCollectionItems} from '../../core/definitions/user-collection-items';
import {AccessTokenService} from '../../core/access-token.service';
import {MetaTypes} from '../../core/definitions/meta-types';
import {ConceptsParams} from '../../core/definitions/concepts';
import {SettingsService} from '../../core/settings.service';
import {TranslateService} from '@ngx-translate/core';
import {SearchService} from "../../core/search.service";

export interface RightsLevel {
  id: string;
  name: string;
  code: string;
}

@Injectable({
  providedIn: 'root'
})
export class AdminUsersService {
  private cachedOverviewFields: Array<any>;
  private currentUserId: string;
  private currentUserPrivilege: UserPrivilege;
  private currentUser: UserData;

  public editSection: BehaviorSubject<any> = new BehaviorSubject(null);
  public cancelEditSection: BehaviorSubject<string> = new BehaviorSubject(null);
  public usersSelected: BehaviorSubject<any> = new BehaviorSubject(false);

  constructor(private readonly translate: TranslateService,
              private readonly cmsApi: CmsApiService,
              private readonly loginService: LoginService,
              private readonly accessTokenService: AccessTokenService,
              private readonly objectStorageService: ObjectStorageService,
              private readonly solrFilter: SolrFilterService,
              private readonly settings: SettingsService,
              private readonly searchService: SearchService) {
    this.loginService.currentUser.subscribe(async user => {
      if (user) {
        this.currentUserPrivilege = user['rights_level']
          ? user['rights_level'] as UserPrivilege : UserPrivilege.GUEST;
        this.currentUserId = user.artifact_id;
        this.currentUser = user;
      } else {
        await this.reloadUserData();
      }
    });
  }

  public get isAdmin(): boolean {
    return this.hasPrivilege(UserPrivilege.ADMIN) ||
      this.hasPrivilege(UserPrivilege.SUPER_USER);
  }

  /**
   * Broadcasts the name of the user-profile section currently in edit-mode.
   */
  public setEditMode(sectionName: string) {
    this.editSection.next(sectionName);
  }

  /**
   * Broadcasts the name of the user-profile section to cancel the edit-mode for.
   */
  public cancelEditMode(sectionName: string) {
    this.cancelEditSection.next(sectionName);
  }

  /**
   * Observable used by the AdminUsersDataSource.
   */
  public getUserTableDataNew(pageSize = 50, pageIndex = 0, filterSettings = null): Observable<SearchResult> {
    // Convert existing promise to an observable using rxjs.from()
    // before returning it.
    const startIx = pageIndex > 0 ? (pageSize * pageIndex) - 1 : 0;
    const searchParams = new SearchParametersForOverview();
    searchParams.overview = true;
    searchParams.rows = pageSize;
    searchParams.start = startIx;
    this.solrFilter.setFqFromObject(searchParams, filterSettings);
    const promise = this.getUsers(searchParams);
    promise.then((res: SearchResult) => {
      if (!res) {
        return;
      } else {
        res.artifacts = res.artifacts?.map(of =>
          this.mapOverviewFieldsToObject(of.overview, of)
        ) as SearchObject[];
      }
    });
    return from(promise);
  }

  public hasPrivilege(privilege: UserPrivilege): boolean {
    return this.currentUserPrivilege === privilege;
  }

  public setUsersSelected(value: boolean): void {
    this.usersSelected.next(value);
  }

  public async loadRightsLevels(): Promise<any[]> {
    const res = await this.cmsApi.getConcepts({
      concept_type_id: this.settings.getClientConfig().CONCEPT_TYPE_USER_RIGHTS_LEVEL,
      valid: true,
      meta_type: MetaTypes.CONCEPT
    } as ConceptsParams);
    return res['concepts'] ? res['concepts'] : null;
  }

  public async getUsersWithId(userIds: Array<string>): Promise<Array<User>> {
    return await Promise.all(
      (userIds || []).map(u => this.getOneUser(u))
    );
  }

  public async getOneUser(userId: string): Promise<User | null> {
    const res = await this.objectStorageService.loadObject(userId);
    return res ? new User(res) : null;
  }

  public async getUserTableColumns(includeAvatarColumn = false): Promise<Array<TableColumn<any>>> {
    if (!this.cachedOverviewFields) {
      this.cachedOverviewFields = await this.cmsApi.getModelOverviewFields({modelName: 'user'});
    }

    const cols: Array<TableColumn<any>> = [];
    if (includeAvatarColumn) {
      cols.push({
        title: '',
        property: 'thumbnail_id',
        isImage: true
      });
    }
    cols.push(
      ...this.cachedOverviewFields.sort((a, b) => a.order - b.order)
        .map(of => ({
          property: of.name,
          title: of.title || of.admin_title
        }))
    );

    return cols;
  }

  public async deactivateUser(user: User): Promise<User> {
    user.deactivated = true;
    await this.saveUser(user);
    return user;
  }

  public async activateUser(user: User): Promise<User> {
    user.deactivated = false;
    await this.saveUser(user);
    return user;
  }

  public async saveUser(user: User): Promise<string> {
    if (user) {
      if (typeof (user.deactivated) === 'undefined' || user.deactivated === null) {
        user.deactivated = false;
      }
      await this.controlChangesToMainCollection(user);
      const {artifact_id} = await this.cmsApi.saveUser({
        artifact: user,
        jwt: this.accessTokenService.getToken()
      }) as any;
      return artifact_id;
    }
  }

  public async getCollectionRightTypes(collectionType: string): Promise<SearchObject[]> {
    const clientConfig = this.settings.getClientConfig();
    const conceptTypeCollectionRights = collectionType === 'collections' ?
      clientConfig.CONCEPT_TYPE_USER_COLLECTION_RIGHTS : clientConfig.CONCEPT_TYPE_VIRTUAL_COLLECTION_RIGHTS;
    const searchRes = await this.searchService.search({
      query: `object_type:${conceptTypeCollectionRights}`,
      fl: ['artifact_id', 'artifact_name'],
      getAll: true,
      sort: 'artifact_name asc'
    } as SearchParameters);
    return searchRes.artifacts;
  }

  public async getCollections(collectionType: string): Promise<SearchObject[]> {
    const collectionObjectType = collectionType === 'collections' ?
      'Collection' : this.settings.getClientConfig().CONCEPT_TYPE_VIRTUAL_COLLECTION;
    let allCollections = [{
      artifact_id: 'all',
      artifact_name: this.translate.instant('TRANS__USER_COLLECTIONS_ADDER__SELECT_ALL')
    } as SearchObject];
    const searchRes = await this.searchService.search({
      query: `object_type:${collectionObjectType}`,
      fl: ['artifact_id', 'artifact_name'],
      getAll: true,
      sort: 'artifact_name asc'
    } as SearchParameters);
    // Need to skip procedure collections that are not available to the instance
    let collections = searchRes.artifacts;
    if (searchRes.artifacts.length && collectionType === 'virtual_collections') {
      collections = [];
      const availableVirtualCollections = await this.cmsApi.getAvailableVirtualCollections();
      for (const foundColl of searchRes.artifacts) {
        if (availableVirtualCollections.indexOf(foundColl.artifact_id) !== -1) {
          collections.push(foundColl);
        }
      }
    }
    allCollections = allCollections.concat(collections);
    return allCollections;
  }

  private mapOverviewFieldsToObject(overviewFields: Array<OverviewField>, user: SearchObject): object {

    const object = {
      artifact_id: user.artifact_id,
      thumbnail_id: user.thumbnail_id || '',
      source: user
    };
    if (overviewFields && overviewFields.length > 0) {
      overviewFields.forEach(of =>
        object[of.field_name] = of.items.map(i =>
          i.field_values.values.map(v => v.value).join(', ')
        ).join(', ')
      );
    }
    object['ekultur_user'] = user['ekultur_user'];
    return object;
  }

  private async controlChangesToMainCollection(user: User): Promise<void> {
    if (user.main_collection_id) {
      if (!user.collections) {
        user.collections = [];
      }
      user.collections.forEach(c => c.is_main_collection = false);
      const existingCollectionIdx = user.collections.findIndex(c => c.collection_id === user.main_collection_id);
      if (existingCollectionIdx >= 0) {
        user.collections[existingCollectionIdx].is_main_collection = true;
      } else {
        await this.assignMainCollectionToUser(user);
      }
    }
  }

  private async assignMainCollectionToUser(user: User) {
    const addedCollection: UserCollectionItems =
      await this.cmsApi.createArtifact({object_type: 'UserCollectionItems'}) as UserCollectionItems;
    addedCollection.is_main_collection = true;
    addedCollection.collection_id = user.main_collection_id;
    addedCollection._create = true;
    user.collections.push(addedCollection);
  }

  private async reloadUserData() {
    await this.loginService.getUserData();
  }

  private async getUsers(params: SearchParametersForOverview): Promise<SearchResult> {
    try {
      params.query = '*:*';
      this.solrFilter.addFq(params, 'object_type', 'user');
      params.sort = 'full_name asc';
      const res = await this.searchService.searchWithOverview(params);
      if (!res || !res.artifacts) {
        return null;
      }
      return res;
    } catch (e) {
      console.error('Fetching users failed: ', e);
    }
    return null;
  }
}
