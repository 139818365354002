import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output} from '@angular/core';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {InlineArrayHeaderService} from '../inline-array-header.service';
import {InlineArrayHeaderContainer} from '../inline-array-header-container';

@Component({
  selector: 'app-edit-field-inline-array-item-top-bar',
  templateUrl: './edit-field-inline-array-item-top-bar.component.html',
  styleUrls: ['./edit-field-inline-array-item-top-bar.component.scss']
})
export class EditFieldInlineArrayItemTopBarComponent implements OnChanges, OnDestroy {
  item;
  @Input() fieldParameters: FieldParameters;
  @Output() setFirstInputFocus = new EventEmitter<object>();

  container: InlineArrayHeaderContainer;
  hasIcon = false;

  constructor(private inlineArrayItemSvc: InlineArrayItemService,
              private inlineArrayHeaderSvc: InlineArrayHeaderService) {
  }

  ngOnChanges() {
    this.item = this.fieldParameters.object;

    this.inlineArrayHeaderSvc.createContainer(
      this.fieldParameters.sectionsContainer.rootObject,
      this.fieldParameters.field,
      this.fieldParameters.index,
      this.fieldParameters.parentIndex).then(container => {
      this.container = container;
      this.inlineArrayHeaderSvc.setValues(this.container, 2000);
      this.container.labels.forEach((label) => {
        if (label.icon === '{conceptIcon}') {
          this.hasIcon = true;
        }
      });
    });
  }

  ngOnDestroy() {
    if (this.container?.timeOutId) {
      clearTimeout(this.container.timeOutId);
    }
  }

  toggleOpenItem() {
    if (this.item.$$activeDropdown) {
      return;
    }
    this.inlineArrayItemSvc.toggleInlineArrayItemOpen(this.fieldParameters);
    this.setFirstInputFocus.emit();
  }

  toggleOpenItemWithKey(/*event*/) {
    this.toggleOpenItem();
    this.setFirstInputFocus.emit();
  }
}
