import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectContentListSourceArrayPagerComponent} from './object-content-list-source-array-pager/object-content-list-source-array-pager.component';
import {ObjectContentTabsComponent} from './object-content-tabs/object-content-tabs.component';
import {ObjectContentMainTabComponent} from './object-content-main-tab/object-content-main-tab.component';
import {ObjectContentListComponent} from './object-content-list/object-content-list.component';
// eslint-disable-next-line max-len
import {ObjectContentListOverviewFieldsComponent} from './object-content-list-overview-fields/object-content-list-overview-fields.component';
import {ObjectContentListSourceArraySortComponent} from './object-content-list-source-array-sort/object-content-list-source-array-sort.component';
import {ObjectContentListSourceArrayFilterComponent} from './object-content-list-source-array-filter/object-content-list-source-array-filter.component';
import {ObjectContentListSourceArrayItemsComponent} from './object-content-list-source-array-items/object-content-list-source-array-items.component';
import {ObjectContentListSourceArrayComponent} from './object-content-list-source-array/object-content-list-source-array.component';
import {ObjectContentDropdownTabComponent} from './object-content-dropdown-tab/object-content-dropdown-tab.component';
import {ObjectContentDropdownMenuComponent} from './object-content-dropdown-menu/object-content-dropdown-menu.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SharedModule} from '../shared/shared.module';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import {OperationsModule} from '../operations/operations.module';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {MatIconModule} from '@angular/material/icon';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import {MatLegacyRadioModule as MatRadioModule} from '@angular/material/legacy-radio';
import {FormsModule} from '@angular/forms';
import {ObjectContentListRegularComponent} from './object-content-list-regular/object-content-list-regular.component';
import {ObjectContentListRegularItemsComponent} from './object-content-list-regular-items/object-content-list-regular-items.component';
import { ObjectContentListSearchViewComponent } from './object-content-list-search-view/object-content-list-search-view.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';



@NgModule({
  exports: [
    ObjectContentTabsComponent,
    ObjectContentListComponent,
    ObjectContentListSourceArrayComponent,
    ObjectContentListRegularComponent
  ],
  declarations: [
    ObjectContentListSourceArrayPagerComponent,
    ObjectContentTabsComponent,
    ObjectContentMainTabComponent,
    ObjectContentListComponent,
    ObjectContentListOverviewFieldsComponent,
    ObjectContentListSourceArraySortComponent,
    ObjectContentListSourceArrayFilterComponent,
    ObjectContentListSourceArrayItemsComponent,
    ObjectContentListSourceArrayComponent,
    ObjectContentDropdownTabComponent,
    ObjectContentDropdownMenuComponent,
    ObjectContentListRegularComponent,
    ObjectContentListRegularItemsComponent,
    ObjectContentListSearchViewComponent
  ],
  imports: [
    FormsModule,
    MatIconModule,
    MatMenuModule,
    MatExpansionModule,
    MatCheckboxModule,
    MatButtonModule,
    MatListModule,
    MatRadioModule,
    CommonModule,
    ObjectSearchModule,
    SharedModule,
    OverviewFieldsModule,
    OperationsModule,
    MatProgressSpinnerModule,
  ]
})
export class ObjectContentTabModule { }
