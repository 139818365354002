<div class="search-selection-basket">
  <div class="search-selection-basket__top">
    <div class="search-selection-basket__top-headline">
      <span translate="TRANS__SELECTION_BASKET__YOUR_CHOICES"></span>
      <span class="search-selection-basket__top-headline-count">({{selectedCount}})</span>
    </div>
    <a (click)="removeAll()" class="light-gray-color">
      <span translate="TRANS__SELECTION_BASKET__REMOVE_ALL"></span></a></div>
  <hr>
  <div class="search-selection-basket__content">
    <ng-container *ngIf="selectedItems.length">
      <mat-accordion multi="false">
        <mat-expansion-panel
          *ngFor="let item of selectedItems"
          class="search-selection-basket__expansion-panel"
          (opened)="item.$$panelState = true"
          (closed)="item.$$panelState = false"
          [hideToggle]="true">
          <mat-expansion-panel-header>
            <div class="search-selection-basket__indicator">
              <mat-icon>{{item.$$panelState ? 'expand_less' : 'expand_more'}}</mat-icon></div>
            <div class="search-selection-basket__name"
                 [class.text-blue]="item.$$panelState">
              <i class="{{item.icon}} text-blue"></i>
              <span>{{item.artifact_name}}</span></div>
            <div class="search-selection-basket__remove">
              <a (click)="remove(item); $event.stopPropagation();" class="light-gray-color">
                <span translate="TRANS__SELECTION_BASKET__REMOVE"></span></a>
            </div>
          </mat-expansion-panel-header>
          <div class="search-selection-basket__overview-fields selection-dropdown-item__content">
            <div class="search-selection-basket__overview-fields__first-container">
              <ng-container *ngFor="let field of item.overview">
                <ng-container *ngIf="field.items">
                  <span *ngFor="let item of field.items" class="search-selection-basket__overview-fields-item">
                      <app-overview-field-item
                        *ngIf="item.field_values"
                        [fieldItem]="item"
                        [disableLinks]="false"
                        [last]="true"
                        [toneDownTextColor]="false"
                        class="search-selection-basket__field-value"
                      ></app-overview-field-item>
                    <span *ngIf="item.field_values"
                          class="search-selection-basket__divider"></span>
                  </span>
                </ng-container>
              </ng-container>
            </div>
            <div class="search-selection-basket__overview-fields__second-container"></div>
          </div>
        </mat-expansion-panel>
      </mat-accordion>
      <div class="search-selection-basket__bottom-menu">
        <span>{{start + selectedItems.length}} / {{selectedCount}}</span>
        <button mat-stroked-button
                color="primary"
                [disabled]="start === 0"
                (click)="prev()"><span translate="TRANS__SEARCH_SELECTION_BASKET__PREV"></span></button>
        <button mat-stroked-button
                color="primary"
                [disabled]="(start + selectedItems.length) === selectedCount"
                (click)="next()"><span translate="TRANS__SEARCH_SELECTION_BASKET__NEXT"></span></button>
      </div>
    </ng-container>
    <ng-container *ngIf="selectedItems.length === 0 && !loading">
      <div class="search-selection-basket__empty-list">
        <span translate="TRANS__SELECTION_BASKET__EMPTY_LIST"></span></div>
    </ng-container>
    <ng-container *ngIf="selectedItems.length !== selectedCount && loading">
      <div class="search-selection-basket__loading">
        <span translate="TRANS__SEARCH__LOADING_INFO"></span></div>
    </ng-container>
  </div>
</div>

