import {AfterViewInit, Component, isDevMode, OnDestroy, OnInit} from '@angular/core';
import { PrimusBackendInstanceService, PrimusInstanceDetails } from '../../core/primus-backend-instance.service';
import {TranslateService} from '@ngx-translate/core';
import {TranslationSetterService} from '../../core/translation-setter.service';
import {MatLegacySelectionListChange as MatSelectionListChange} from "@angular/material/legacy-list";

@Component({
  selector: 'app-choose-backend-api',
  templateUrl: './choose-backend-api.component.html',
  styleUrls: ['./choose-backend-api.component.scss']
})
export class ChooseBackendApiComponent implements OnInit, AfterViewInit, OnDestroy {

  public showUrl: boolean = isDevMode();
  public servers: Array<PrimusInstanceDetails>;
  public hidden: boolean;

  public txtHeader: string;

  private serversLoaded: boolean;


  constructor(
    private translationSetter: TranslationSetterService,
    private translate: TranslateService,
    private primusBackendInstanceService: PrimusBackendInstanceService
  ) {
    this.translationSetter.setTranslations();
  }

  ngOnInit(): void {
    this.translate.get('TRANS__SHARED_CHOOSE_BACKEND__PRIMUS_INSTANCES').subscribe(s => {
      this.txtHeader = s;
    });
  }

  async ngAfterViewInit(): Promise<void> {
    this.primusBackendInstanceService.authenticated.subscribe(async (state) => {
      if (state.user && !this.hidden) {
        this.servers = await this.primusBackendInstanceService.getUserPrimusServers();
        await this.pingServers();

        // If only granted access to a single server, redirect to that server automatically.
        if (this.servers?.length === 1) {
          const server = this.servers[0] as any as PrimusInstanceDetails;
          this.authApi(server);
        }

        this.serversLoaded = true;
      }
    });
  }

  ngOnDestroy(): void {
    // Called once, before the instance is destroyed.
    // Add 'implements OnDestroy' to the class.

    this.primusBackendInstanceService.authenticated.unsubscribe();
    this.serversLoaded = false;
    this.hidden = false;
  }

  async pingServers() {
    for (let i = 0, max = this.servers.length; i < max; i++) {
      const server = this.servers[i];
      server['online'] = true;

      if (server.name.toLowerCase() === 'localhost') {
        continue;
      }

      let dbOnline = false;
      let solrOnline = false;

      try {
        const ping = await fetch(server.api + '/healthcheck/', { method: 'GET' });
        const data = await ping.json();

        if (data['db'].conn === 'ok') {
          dbOnline = true;
        }

        const solr = data['solr'];
        if (solr.add === 'ok' &&
          solr.conn === 'ok' &&
          solr.delete === 'ok' &&
          solr.query === 'ok') {
          solrOnline = true;
        }
      } catch (e) {
        server['checked'] = true;
      } finally {
        server['online'] = dbOnline && solrOnline;
        server['checked'] = true;
      }
    }
  }

  onServerSelect(change: MatSelectionListChange) {
    const apiUrl = change.options[0].value;
    const server = this.servers.find(s => s.api === apiUrl) as any as PrimusInstanceDetails;
    this.authApi(server);
  }

  private authApi(server: PrimusInstanceDetails) {
    try {
      this.primusBackendInstanceService.apiAuthenticate(server);
      window.location.replace('/home');
    } catch (e) {
      console.error(e);
     }
  }
}
