import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {FieldConditionService} from '../../core/field-condition.service';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {CommonsService} from '../../core/commons.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldInputType} from '../../core/definitions/field-input-type.enum';
import {IfType} from '../../core/definitions/field-if';
import {InlineViewService} from "../../core/inline-view.service";
import {FieldDateInfoService} from "../../core/field-date-info.service";
import {DateInfo} from "../../core/definitions/date-info";

@Component({
  selector: 'app-edit-fields',
  templateUrl: './edit-fields.component.html',
  styleUrls: ['./edit-fields.component.scss']
})
export class EditFieldsComponent implements OnChanges {

  AConst = AConst;

  @Input() sectionsContainer: SectionsContainer;
  @Input() object;
  @Input() grandParentObject;
  @Input() fields: Array<MetaField>;
  @Input() index: number;
  @Input() parentIndex: number;
  @Output() closeEdit = new EventEmitter<any>();

  fieldParameters: FieldParameters;
  orderedFields: Array<MetaField> = [];
  FieldInputType = FieldInputType;
  allDateInfo: {[id: string]: DateInfo};

  constructor(private fieldCondition: FieldConditionService,
              private commons: CommonsService,
              private inlineViewService: InlineViewService,
              private fieldDateInfoService: FieldDateInfoService) {
  }

  ngOnChanges() {
    this.allDateInfo = this.fieldDateInfoService.getAllFieldDateInfo();
    this.setFields().then();
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.sectionsContainer;
    this.fieldParameters.rootObject = this.sectionsContainer.rootObject || this.object;
    this.fieldParameters.grandParentObject = this.grandParentObject;
    this.fieldParameters.object = this.object;
    this.fieldParameters.edit = true;
    this.fieldParameters.index = this.index;
    this.fieldParameters.parentIndex = this.parentIndex;
  }

  canShow(field): boolean {
    this.fieldParameters.field = field;
    return this.fieldCondition.runIf(IfType.SHOW, this.fieldParameters).result;
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }

  private async setFields() {
    if (this.fields) {
      this.orderedFields = this.commons.sortArray(this.fields, 'order');
      for (let field of this.orderedFields) {
        this.setLegacyDateInfo(field);
        field.$$inlineView = await this.inlineViewService.getInlineViewForField(this.object.object_type, field);
      }
    }
  }

  // This should make it possible to run new client on old backends
  private setLegacyDateInfo(field: MetaField) {
    if (field.date_info && !field.date_info_id) {
      field.date_info_id = Math.random().toString();
      this.allDateInfo[field.date_info_id] = field.date_info;
    }
  }
}
