import {
  CheckFilterGroup, FacetCount,
  HierarchicFilterGroup,
  RangeGroup,
  RootSearchView,
  SearchViewPath
} from './search-objects';
import {SelectorContainer} from './selector-container';
import {SearchResult} from './search-result';
import {Focus} from './focus';
import {SuperObjectModel} from './super-object-model';
import {SearchObject, SearchObjectRow} from './search-object';
import {OperationContainer} from './operation-container';
import {SearchCategory} from './advanced-search/search-category';
import {SearchSelections} from '../../object-search/search-selections';
import {QueryContainer} from '../query-container';

export class ScrollInfo {
  scrollElement: any;
  searchingScroll: boolean;
  searchingScrollDir: string;
}

export class SearchContainerBase {
  keepSelected: boolean;
  restrictions: any[];
  rows: any = {};
  searchPostFn: any;
  targetObject: SuperObjectModel;
  templateGroupId: string;
  listFieldTemplateId: string;
  refreshTemplateOverviewFields = false;
  toState: string;
  used: Array<string> = [];
}

export class SearchContainerParams extends SearchContainerBase {
  defaultCheckedFilters: object;
  filters: any;
  getFocus: boolean;
  runSearch: boolean;
  selectedItems: Array<SearchObject> = [];
  searchViewName: string;
  stateParams = {};
  targetState: string;
  useFocus: boolean;
}

export class FiltersFacets {
  checkedFilters: object = {};
  facetCount: {[name: string]: FacetCount} = {};
  facetRangeGroups: Array<RangeGroup> = undefined;
  filterGroups: Array<CheckFilterGroup>;
  filtersChecked = 'notChecked';
  hierarchicFilterGroup: HierarchicFilterGroup;
}

export class FocusContainer {
  curFocus: Focus;
  curFocusId: string;
  currentFocusName: string;
  focuses: Array<Focus>;
}

export class SearchContainer extends SearchContainerBase {
  animateNewSearchResult = false;
  breadcrumbs: SearchObject[] = [];
  cachedScrollDataColumns: SearchObjectRow[];
  cachedScrollDataList: SearchObject[];
  combineHorizontal = true;
  focus = new FocusContainer();
  currentPathView: SearchViewPath;
  displayAll = false;
  filtersFacets = new FiltersFacets();
  fetchedPages = new Set<number>();
  isFolderInfoOpen: boolean;
  maxPage: number;
  noFullSearch: boolean;
  object: any;
  objectCount: number;
  oldTempGroupId: string;
  operationContainer: OperationContainer;
  operationContainerDropDown: OperationContainer;
  private order: string = null;
  orderIsDefault = false;
  orderRandomSeed: string = '666';
  path: string = undefined;
  queryContainer = new QueryContainer();
  reportContainer: any;
  scrollInfo: ScrollInfo = new ScrollInfo();
  scrollToY = 20;
  searchCategory: SearchCategory;
  searchEngine: string;
  searching: boolean;
  searchItemIndex = 0;
  searchPage = 1;
  searchResultCallbacks: Array<any> = [];
  newScrollItemsCallback;
  searchResultViewName: string;
  searchResultViewDefaultSet = true;
  searchResultViews: { default: string, views: Array<any> };
  searchResult: SearchResult = undefined;
  searchRow: number;
  searchView: RootSearchView;
  selectButtonText: string;
  selections: SearchSelections = new SearchSelections();
  selectorContainer: SelectorContainer;
  state = {
    targetState: ''
  };
  targetId: string;
  totalSelectedFilters: number;
  toggleSortMenu: boolean;
  toggleActionMenu: boolean;
  virtualScrollEnabled = true;
  virtualScrollViewOffset: number;
  getTargetId = () => {
    return this.targetId;
  }

  setOrder(order: string, orderIsDefault: boolean) {
    this.order = order;
    this.orderIsDefault = orderIsDefault;
  }

  getOrder() {
    return this.order;
  }
}
