import {Component, OnInit} from '@angular/core';
import {ConceptService} from '../../core/concept.service';
import {ConceptType} from '../../core/definitions/concept-types';
import {ConceptTypeMainMenu, ConceptTypeMenu} from './concept-type-menu';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ConceptsContainer} from 'src/app/core/definitions/concepts-container';
import {PrimusRouterService} from '../../core/primus-router.service';

@Component({
  selector: 'app-admin-concept-lists',
  templateUrl: './admin-concept-lists.component.html',
  styleUrls: ['./admin-concept-lists.component.scss']
})
export class AdminConceptListsComponent implements OnInit {
  loadingConceptTypes = true;
  parent;
  conceptTypeMainMenus: MatTableDataSource<ConceptTypeMainMenu>;
  conceptsContainer: ConceptsContainer;
  curConceptTypeMenu: ConceptTypeMenu;
  // conceptParams: ConceptsParams;
  // concepts: SearchConcepts;

  private adminType = 'concepts';
  private conceptTypes: ConceptType[];
  private query: string;
  private selectedObjectTypes: string[] = [];

  constructor(
    private primusRouter: PrimusRouterService,
    private conceptService: ConceptService) {
  }

  ngOnInit() {
    this.parent = {
      path: 'admin',
      adminType: this.adminType,
      hasSubMenus: true,
      target_params: {admin_type: this.adminType},
      currentState: this.primusRouter.currentState()
    };

    this.conceptService.getConceptTypes(true).then(conceptTypes => {
      this.conceptTypes = conceptTypes.concept_types;
      this.generateMenus(this.conceptTypes);
      this.loadingConceptTypes = false;
    });
  }

  async onQueryFilterChange(query) {
    this.query = query;
    await this.generateFilteredMenus();
  }

  async onObjectFilterChange(objectTypes) {
    this.selectedObjectTypes = objectTypes.map(objectType => objectType.type);
    await this.generateFilteredMenus();
  }

  onConceptTypeMenuChange(conceptTypeMenu: ConceptTypeMenu) {
    this.curConceptTypeMenu = conceptTypeMenu;
    if (this.conceptsContainer) {
      this.conceptsContainer.loadingConcepts = true;
    }
    setTimeout(() => {
      this.conceptService.setConceptsContainer(conceptTypeMenu.conceptType).then(conceptsContainer => {
        this.conceptsContainer = conceptsContainer;
        this.conceptsContainer.loadingConcepts = false;
      });
    }, 50);
  }

  private async generateFilteredMenus() {
    let conceptTypes = this.getConceptTypesFilteredOnQuery();
    conceptTypes = await this.getConceptTypesFilteredOnSelectedObjectTypes(conceptTypes);
    this.generateMenus(conceptTypes);
  }

  private getConceptTypesFilteredOnQuery(): ConceptType[] {
    let conceptTypes: ConceptType[] = [];
    if (this.query) {
      this.conceptTypes.forEach(conceptType => {
        if (conceptType.concept_name.toLocaleLowerCase().indexOf(this.query.toLocaleLowerCase()) !== -1) {
          conceptTypes.push(conceptType);
        }
      });
    } else {
      conceptTypes = this.conceptTypes;
    }
    return conceptTypes;
  }

  private async getConceptTypesFilteredOnSelectedObjectTypes(conceptTypes: ConceptType[]) {
    let conceptTypesRes: ConceptType[] = [];
    if (this.selectedObjectTypes.length) {
      const conceptFieldUsages = await this.conceptService.getConceptFieldUsage();
      conceptTypes.forEach(conceptType => {
        const conceptFieldUsage = conceptFieldUsages[conceptType.concept_type_id];
        if (conceptFieldUsage) {
          const usageObjectTypes = conceptFieldUsage.object_types;
          for (let t = 0; t < this.selectedObjectTypes.length; t++) {
            if (usageObjectTypes.indexOf(this.selectedObjectTypes[t]) !== -1) {
              conceptTypesRes.push(conceptType);
              break;
            }
          }
        } else {
          console.log('No usage for ' + conceptType.concept_type_id);
        }
      });
    } else {
      conceptTypesRes = conceptTypes;
    }
    return conceptTypesRes;
  }

  private generateMenus(conceptTypes: ConceptType[]) {
    const mainMenus = {};
    this.conceptTypeMainMenus = new MatTableDataSource<ConceptTypeMainMenu>();
    this.conceptTypeMainMenus.data = [];
    const conceptTypeMainMenus: ConceptTypeMainMenu[] = [];
    conceptTypes.forEach(conceptType => {
      const firstLetter = conceptType.concept_name[0];
      if (!mainMenus[firstLetter]) {
        mainMenus[firstLetter] = new ConceptTypeMainMenu(firstLetter);
      }
      const mainMenu = <ConceptTypeMainMenu>mainMenus[firstLetter];
      const menu = new ConceptTypeMenu();
      menu.menuTitle = conceptType.concept_name;
      menu.conceptType = conceptType;
      mainMenu.subMenus.push(menu);
    });
    for (const letter in mainMenus) {
      if (mainMenus.hasOwnProperty(letter)) {
        conceptTypeMainMenus.push(mainMenus[letter]);
      }
    }
    this.conceptTypeMainMenus.data = conceptTypeMainMenus;
  }

}
