<div class="object-content-tabs-container">
  <div class="object-content-tabs">
    <ul class="object-content-tabs__main-tabs" #menuControl
        [ngClass]="{'loadedMenu': contentInfo.menus && contentInfo.selectedMainMenu}"
        [@slideInContentTab]="contentInfo.menus && contentInfo.menus.length">
        <app-object-content-main-tab
          class="tab"
          *ngFor="let menu of contentInfo.menus; index as i"
          [invisible]="contentInfo.visibleDropDowns[i]"
          [menu]="menu"
          [isEditing]="isEditing"
          (menuOpened)="openMenu(menu)"
        ></app-object-content-main-tab>


      <app-object-content-dropdown-menu
        class="tab"
        *ngIf="menuItemsReady && menuItems"
        [contentInfo]="contentInfo"
        [menuItems]="menuItems"
        [myRoot]="myRoot"
        [selectedMainMenu]="contentInfo.selectedMainMenu"
        [isEditing]="isEditing"
        (menuOpened)="openMenu($event)"
      ></app-object-content-dropdown-menu>
    </ul>

    <ul class="object-content-tabs__sub-tabs"
        *ngIf="contentInfo.selectedMainMenu && contentInfo.selectedMainMenu[AConst.SUB_MENUS]">
      <li *ngFor="let subMenu of contentInfo.selectedMainMenu[AConst.SUB_MENUS]">
        <a (click)="openMenu(subMenu)"
           class="object-content-tabs__sub-tab-item text-blue-hover"
           [ngClass]="{'active-subMenu': subMenu.open}">
          <span class="object-content-tabs__sub-tab-text object-content-tab-text"
                [class.text-blue]="subMenu.open">
            <i class="{{subMenu.icon}}"></i>
            <span class="object-content-tabs__sub-tab-caption"
                  [ngClass]="{'bold': subMenu.open}" [translate]="subMenu.caption"></span>
            <span *ngIf="subMenu.count" class="object-content-tab-count">
              <span class="text-blue">{{subMenu.count}}</span>
            </span>
          </span>
        </a>
      </li>
    </ul>
  </div>

</div>
