// import * as angular from 'angular';
// import {setAngularJSGlobal} from '@angular/upgrade/static';

import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';

import * as Sentry from "@sentry/angular";
import { BrowserTracing } from "@sentry/tracing";




// setAngularJSGlobal(angular);
if (environment.production) {
  enableProdMode();

  // Init Sentry
  Sentry.init({
    dsn: "https://2ee0eb66fedd45ff8a39b781bb7d8779@o1289125.ingest.sentry.io/6634740",
    integrations: [
      new BrowserTracing({
        routingInstrumentation: Sentry.routingInstrumentation,
      }),
    ],
    debug: false,
    environment: (environment.production)? "production":"development",
    release: environment.version,

    tracesSampleRate: 0.2,
  });
}



platformBrowserDynamic().bootstrapModule(AppModule).catch(console.error);
