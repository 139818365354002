<div class="field-identifier" [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <div class="field-identifier__input-container">
    <input type="text"
           [name]='formControlName'
           [formControlName]="formControlName"
           [placeholder]='placeholder | translate'
           [id]="formControlName"
           autocomplete="off"
           title="{{placeholder | translate}}"
           (change)="onValueChange($event)"
           (blur)="onFieldIdentifierBlur()"
           (focus)="onFieldIdentifierFocus()"
           [ngClass]="{'errorInputFrame': fieldValidation.isFieldInvalid(fieldParameters.sectionsContainer, formControlName)}">
    <a *ngIf="fieldParameters.sectionsContainer.formGroup.controls[formControlName]"
       class="clearInputField"
       (click)="clearValue()">
      <mat-icon>close</mat-icon>
    </a>
  </div>
  <div class="field-identifier__button"
       [ngClass]="{'inFocus': inFocus.value}">
  <button mat-stroked-button
          color="primary"
          type="button"
          (click)="generateAnIdentifier()"
          [disabled]="isDisabled"
          (keydown.enter)="!isDisabled && pressedKey($event)">
    <span translate="TRANS__IDENTIFIER__GENERATE"></span>
  </button>
  </div>
</div>
