import {SuperObjectModel} from './super-object-model';
import {OverviewField} from './object-view';
import { OverviewFieldSimple } from './overview-field-simple';
import { RefData } from '../ref.service';


export class SearchObject extends SuperObjectModel {
  $$used: boolean;
  $$refData: RefData;
  $$name: string;
  $$imageUrl: string;
  $$icon: string;
  $$noImageIcon: string;
  $$columns: Array<OverviewField[]>;
  $$hasChildren: boolean;

  authority_id: string;
  authority_id_value: string;
  authority_marked: boolean;
  content: string[];
  id: string;
  level: number;
  overview: OverviewField[];
  overview_simple: OverviewFieldSimple[];
  selected: boolean;
  source: string;
  is_leaf: boolean;
  filters: any;
}

export class SearchObjectRow {
  columns: SearchObject[] = [];
}
