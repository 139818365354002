import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {SettingsService} from './settings.service';
import {IsoDateWithPrecision} from './definitions/iso-date-with-precision';
import {MetaField} from './definitions/meta-field';
import {FieldDateInfoService} from "./field-date-info.service";

@Injectable({
  providedIn: 'root'
})
export class DateToolsService {

  constructor(private settings: SettingsService,
              private fieldDateInfoService: FieldDateInfoService) {
  }

  private static getUtcDate(dateIn) {
    const date = new Date(dateIn.getTime());
    const offset = date.getTimezoneOffset();
    const osh = Math.floor(offset / 60);
    const osm = offset % 60;
    const hours = date.getHours();
    const minutes = date.getMinutes();
    date.setHours(hours + osh);
    date.setMinutes(minutes + osm);
    return date;
  }

  private static getDateSplitter(dateFormat) {
    const splitters = ['.', '-', '/', ':'];
    let res, test, t;
    for (t = 0; t < splitters.length; t++) {
      test = splitters[t];
      // Check for position > 0 in order for negative years to work
      if (dateFormat.indexOf(test) > 0) {
        res = test;
        break;
      }
    }
    return res;
  }

  // splitData avoids empty split parts
  private static splitDate(date, splitter) {
    const res = [];
    const split = date.split(splitter);
    for (let t = 0; t < split.length; t++) {
      if (split[t] && split[t] !== '0') {
        res.push(split[t]);
      } else {
        break;
      }
    }
    return res;
  }

  private static validateDateParts(dateParts) {
    const res = {
      valid: true,
      error: ''
    };

    if (dateParts.dayPart !== -1 && (dateParts.dayPart < 1 || dateParts.dayPart > 31)) {
      res.valid = false;
      res.error = 'TRANS__VALIDATION__INVALID_DAY_NUMBER';
    }

    if (dateParts.monthPart !== -1 && (dateParts.monthPart < 0 || dateParts.monthPart > 11)) {
      res.valid = false;
      res.error = 'TRANS__VALIDATION__INVALID_MONTH_NUMBER';
    }
    // if (dateParts.yearPart !== -1 && (dateParts.yearPart > (new Date()).getFullYear())) {
    //   res.valid = false;
    //   res.error = 'TRANS__VALIDATION__INVALID_YEAR_NUMBER';
    // }

    return res;
  }

  private static getDateFromDateParts(p) {
    const res = {date: undefined, valid: false, solrDate: null};
    try {
      res.date = new Date(p.yearPart, p.monthPart, p.dayPart, 0, 0, 0, 0);
      if (res.date.getTime()) {
        res.valid = true;
      } else {
        res.date = null;
      }
    } catch (e) {
      console.warn('Invalid date');
    }
    return res;
  }

  private static createDate(year, month, day, hour, minute, sec) {
    let res;
    if (year >= 0) {
      res = new Date(DateToolsService.isoDateStr(year, month, day, hour, minute, sec));
    } else {
      res = new Date(Date.UTC(year, month - 1, day, hour, minute, sec, 0));
    }
    return res;
  }

  private static isoDateStr(year, month, day, hour, minute, sec) {
    return DateToolsService.numAsFixedStr(year, 4) + '-' +
      DateToolsService.numAsFixedStr(month, 2) + '-' +
      DateToolsService.numAsFixedStr(day, 2) + 'T' +
      DateToolsService.numAsFixedStr(hour, 2) + ':' +
      DateToolsService.numAsFixedStr(minute, 2) + ':' +
      DateToolsService.numAsFixedStr(sec, 2) + 'Z';
  }

  private static numAsFixedStr(num, digits) {
    const str = num.toString().split('-').pop();
    return '0000'.substr(0, digits - str.length) + str;
  }

  public isoDateStringToUtcDate(isoDate: string): Date {
    let res = null;
    if (isoDate) {
      const year = Number(isoDate.substring(0, 4));
      const month = Number(isoDate.substring(5, 7)) - 1;
      const day = Number(isoDate.substring(8, 10));
      const hours = Number(isoDate.substring(11, 13));
      const minutes = Number(isoDate.substring(14, 16));
      const seconds = Number(isoDate.substring(17, 19));
      const utcTime = Date.UTC(year, month, day, hours, minutes, seconds);
      res = new Date(utcTime);
    }
    return res;
  }

  public isoDateToString(isoDate: string, precision?: string): string {
    let textDate = '';
    if (isoDate) {
      const utcDate = this.isoDateStringToUtcDate(isoDate);
      if (precision === 'year') {
        textDate = this.getLocalStringDateYear(utcDate);
      } else if (precision === 'month') {
        textDate = this.getLocalStringDateYearMonth(utcDate);
      } else if (precision === 'datetime') {
        textDate = this.getLocalStringDateTime(utcDate);
      } else  {
        textDate = this.getLocalStringDate(utcDate);
      }
    }
    return textDate;
  }

  public validateDate(dateString: string): IsoDateWithPrecision {
    let dateParts, splitChar, newDate, newPrecision, validate;
    const res = new IsoDateWithPrecision();
    if (!dateString) {
      return res;
    }
    splitChar = DateToolsService.getDateSplitter(dateString);
    if (this.hasIllegalDateChars(dateString, splitChar)) {
      res.dd_error_message = 'TRANS__VALIDATION__ILLEGAL_CHARACTERS_IN_DATE';
      return res;
    }
    dateParts = this.getDateParts(dateString, true);
    validate = DateToolsService.validateDateParts(dateParts);
    if (!validate.valid) {
      res.dd_error_message = validate.error;
      return res;
    }

    // y, yy, yyy now is based on year 2000 not 1900
    const yearLength = (dateParts.yearPart).toString().length;
    if (yearLength <= 3) {
      dateParts.yearPart = '2000'.substring(0, 4 - yearLength) + dateParts.yearPart;
    }

    newPrecision = 'date';
    if (dateParts.dayPart !== -1 && dateParts.monthPart !== -1) {
      newDate = new Date(Date.UTC(dateParts.yearPart, dateParts.monthPart, dateParts.dayPart));
    } else if (dateParts.monthPart !== -1) {
      newPrecision = 'month';
      newDate = new Date(Date.UTC(dateParts.yearPart, dateParts.monthPart));
    } else {
      newPrecision = 'year';
      newDate = DateToolsService.createDate(dateParts.yearPart, 1, 1, 0, 0, 0);
    }

    return this.dateToPrecisionDate(newDate, newPrecision);
  }

  public dateToPrecisionDate(date: Date, precision?): IsoDateWithPrecision {
    precision = precision ? precision : 'date';
    const res = new IsoDateWithPrecision();
    res.isoDate = this.dateToIsoString(date);
    res.precision = precision;
    return res;
  }

  // Unfortunately, the ISO  format provided in JavaScript differs from the ISO format used in the application, so
  // need to strip millisecond information from the JavaScript generated format
  public dateToIsoString(date: Date) {
    return date.toISOString().substring(0, 19) + 'Z';
  }

  public solrDateToLocalStringDate(solrDate) {
    let res = '', datePart, date;
    if (solrDate) {
      datePart = solrDate.substring(0, 10);
      date = new Date(datePart);
      res = this.getLocalStringDate(date);
    }
    return res;
  }

  public localStringDateToSolrDate(stringDate, propName?: string) {
    let dateRes = {
      solrDate: '',
      date: null,
      valid: true
    };
    if (stringDate) {
      dateRes = this.getDateFromLocalStringDate(stringDate);
      if (dateRes.date) {
        if(propName === 'end'){
          dateRes.solrDate = moment(dateRes.date).endOf('day').format('YYYY-MM-DDTHH:mm:ss');
        } else {
          dateRes.solrDate = moment(dateRes.date).format('YYYY-MM-DDTHH:mm:ss');
        }
        if (dateRes.solrDate) {
          dateRes.solrDate += 'Z';
        } else {
          dateRes.valid = false;
          console.warn('Invalid date filtering');
        }
      }
    }
    return dateRes;
  }

  public checkExpiredIsoDate(metaField: MetaField, isoDateString: string, statusType) {
    let res = false, currentDate;
    const dateInfo = this.fieldDateInfoService.getFieldDateInfo(metaField);
    if (dateInfo?.check_expired) {
      currentDate = this.getTodayUtcTime();
      if (isoDateString && currentDate > isoDateString) {
        if (statusType !== 'closed') {
          res = true;
        }
      }
    }
    return res;
  }

  public getTodayUtcTime(): string {
    const todayUtc = DateToolsService.getUtcDate(new Date());
    return this.dateToIsoString(todayUtc);
  }

  getDateFromLocalStringDate(localDate) {
    const p = this.getDateParts(localDate, false);
    return DateToolsService.getDateFromDateParts(p);
  }

  private hasIllegalDateChars(dateString, sep) {
    let hasIllegalChars = false, index;
    for (index = 0; index < dateString.length; index++) {
      if (!this.isLegalDateChar(dateString.charAt(index), sep)) {
        hasIllegalChars = true;
      }
    }
    return hasIllegalChars;
  }

  private isLegalDateChar(char, sep) {
    if (this.isNumeric(char) || char === '-') {
      return true;
    }
    return char === sep;
  }

  public isNumeric(char) {
    return /^\d+$/.test(char);
  }

  private getLocalStringDateTime(date) {
    return moment(date).format(this.settings.getClientConfig().LOCAL_DATE_FORMAT_FULL_WITH_TIME);
  }

  private getLocalStringDate(date) {
    return moment(date).format(this.settings.getClientConfig().LOCAL_DATE_FORMAT_FULL);
  }

  private getLocalStringDateYearMonth(date) {
    return moment(date).format(this.settings.getClientConfig().LOCAL_DATE_FORMAT_YEAR_MONTH);
  }

  private getLocalStringDateYear(date) {
    let dateStr;
    const fullYear = date.getFullYear();
    if (fullYear >= 0) {
      dateStr = moment(date).format(this.settings.getClientConfig().LOCAL_DATE_FORMAT_YEAR);
    } else {
      dateStr = fullYear.toString();
    }
    return dateStr;
  }

  private getDatePartIndexes(dateFormat, dateSplitter) {
    let dayPartIndex = -1, monthPartIndex = -1, yearPartIndex = -1;
    dateFormat.split(dateSplitter).forEach(
      (dateFormatPart, index) => {
        if (dateFormatPart === 'DD') {
          dayPartIndex = index;
        } else if (dateFormatPart === 'MM') {
          monthPartIndex = index;
        } else if (dateFormatPart === 'YYYY') {
          yearPartIndex = index;
        }
      }
    );
    return {
      day: dayPartIndex,
      month: monthPartIndex,
      year: yearPartIndex
    };
  }

  getDateParts(localDate, setNullIfMissing) {
    const clientConfig = this.settings.getClientConfig();
    let dayPart = 1, monthPart = 0, yearPart;
    let localDateSplit, dpi, dateFormat;
    const dateSplitter = DateToolsService.getDateSplitter(localDate);
    if (setNullIfMissing) {
      dayPart = -1;
      monthPart = -1;
    }

    if (!dateSplitter) {
      yearPart = localDate;
    } else {
      localDateSplit = DateToolsService.splitDate(localDate, dateSplitter);
      if (localDateSplit.length === 1) {
        dateFormat = clientConfig.LOCAL_DATE_FORMAT_YEAR;
      } else if (localDateSplit.length === 2) {
        dateFormat = clientConfig.LOCAL_DATE_FORMAT_YEAR_MONTH;
      } else {
        dateFormat = clientConfig.LOCAL_DATE_FORMAT_FULL;
      }
      dpi = this.getDatePartIndexes(dateFormat, dateSplitter);
      yearPart = Number(localDateSplit[dpi.year]);
      if (dpi.month !== -1) {
        monthPart = Number(localDateSplit[dpi.month]) - 1;
      }
      if (dpi.day !== -1) {
        dayPart = Number(localDateSplit[dpi.day]);
      }
    }

    return {
      dayPart: dayPart,
      monthPart: monthPart,
      yearPart: yearPart
    };
  }
}
