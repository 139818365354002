<ul [formGroup]="sectionsContainer.formGroup">

  <ng-container *ngFor="let field of fields">

    <li *ngIf="canShow(field)"
        class="list-inline-array-item-content__item"
        [ngClass]="{'userRights':field[AConst.PARENT_NAME] === 'user_rights'}">

      <app-edit-field-switch
        [sectionsContainer]="sectionsContainer"
        [field]="field"
        [grandParentObject]="grandParentObject"
        [object]="object"
        [index]="index"
        [parentIndex]="parentIndex"
      ></app-edit-field-switch>

    </li>

  </ng-container>

</ul>
