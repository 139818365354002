import {Injectable} from '@angular/core';
import {Option, OptionInfo} from './definitions/option-info';
import {ModelRelationsService} from './model-relations.service';
import {FieldParameters} from './definitions/field-parameters';
import {FieldConditionService} from './field-condition.service';
import {FieldIf} from './definitions/field-if';
import {FieldValueService} from './field-value.service';
import {ValueOptionService} from "./value-option.service";

@Injectable({
  providedIn: 'root'
})
export class FieldOptionsService {

  constructor(private modelRelationsService: ModelRelationsService,
              private fieldConditionService: FieldConditionService,
              private fieldValueService: FieldValueService,
              private valueOptionsService: ValueOptionService) {
  }

  async initOptions(fieldParameters: FieldParameters) {
    await this.filterOptionsOnObjectRelationsFilter(fieldParameters);
  }

  // getOptions need to be called continuously in order for conditional
  // options to work
  getOptions(fieldParameters: FieldParameters): Option[] {
    const valueOptions = this.valueOptionsService.getValueOptionsForField(fieldParameters.field);
    const options = valueOptions.$$filteredOptions;
    let res = [];
    if (options) {
      res = this.getConditionalOptions(fieldParameters, options);
    }
    return res;
  }

  private getConditionalOptions(fieldParameters: FieldParameters, options: Option[]): Option[] {
    const res: Option[] = [];
    const valueOptions = this.valueOptionsService.getValueOptionsForField(fieldParameters.field);
    if (!valueOptions) {
      return res;
    }
    if (valueOptions.$$hasNoConditions) {
      return options;
    }
    valueOptions.$$hasNoConditions = true;
    for (const option of options) {
      let canAdd = true;
      if (option.field_ifs) {
        valueOptions.$$hasNoConditions = false;
        for (const fieldIf of option.field_ifs) {
          if (!this.fieldConditionService.runIfItem(fieldIf, fieldParameters)) {
            canAdd = false;
            break;
          }
        }
      }
      if (canAdd) {
        res.push(option);
      }
    }
    return res;
  }

  private async filterOptionsOnObjectRelationsFilter(fieldParameters: FieldParameters) {
    const optionInfo: OptionInfo = this.valueOptionsService.getValueOptionsForField(fieldParameters.field);
    const contextObjects = fieldParameters.sectionsContainer.operationContextObjects;
    const res = [];

    if (optionInfo.context_object_relation_filter && contextObjects) {
      for (const opt of optionInfo.options) {
        if (opt.relation_object_type) {
          const canUseOption = await this.modelRelationsService.objectsCanHaveObjectTypes(
            contextObjects, [opt.relation_object_type]);
          if (!canUseOption) {
            continue;
          }
        }
        res.push(opt);
      }
      optionInfo.$$filteredOptions = res;
    } else {
      optionInfo.$$filteredOptions = optionInfo.options;
    }
    this.setSelectedOption(fieldParameters);
  }

  private setSelectedOption(fieldParameters: FieldParameters) {
    const optionInfo: OptionInfo = this.valueOptionsService.getValueOptionsForField(fieldParameters.field);
    let selectedOption;
    for (const option of optionInfo.$$filteredOptions) {
      if (option.selected) {
        selectedOption = option.value;
      }
      const selectedIf = this.getSetSelectedIf(option);
      if (selectedIf) {
        const isSelected = this.fieldConditionService.runIfItem(selectedIf, fieldParameters);
        if (isSelected) {
          selectedOption = option.value;
          break;
        }
      }
    }
    if (selectedOption) {
      this.fieldValueService.setFieldValueAndControlValue(
        fieldParameters, fieldParameters.object, fieldParameters.field.name, selectedOption).then();
    } else {
      console.warn(`No option set to selected for field "${fieldParameters.field.name}"`);
    }
  }

  private getSetSelectedIf(option: Option): FieldIf {
    let res = null;
    if (option.field_ifs) {
      for (const fieldIf of option.field_ifs) {
        if (fieldIf.if_type === 'set_selected') {
          res = fieldIf;
          break;
        }
      }
    }
    return res;
  }
}
