import {Component, Input, Output, EventEmitter, OnChanges} from '@angular/core';
import {OperationService} from '../operation.service';
import {OperationDef} from '../../core/definitions/operation-def';
import {OperationDialogService} from '../operation-dialog.service';
import {OperationContainer} from '../../core/definitions/operation-container';
import {SuperObjectModel} from '../../core/definitions/super-object-model';

@Component({
  selector: 'app-operation-dropdown-menu',
  templateUrl: './operation-dropdown-menu.component.html',
  styleUrls: ['./operation-dropdown-menu.component.scss']
})
export class OperationDropdownMenuComponent implements OnChanges {

  @Input() contextObjects: SuperObjectModel[];
  @Input() operationTarget: string;
  @Input() showSubMenusOnly: boolean;
  @Output() operationPerformed = new EventEmitter<any>();

  private operationContainer: OperationContainer;

  constructor(private operationService: OperationService,
              private operationDialogService: OperationDialogService) {
  }

  ngOnChanges(): void {
    if (!this.operationContainer) {
      this.createOperationContainer().then();
    } else {
      this.operationContainer.operationContextObjects = this.contextObjects;
      this.operationService.setOperations(this.operationContainer).then();
    }
  }

  get operationGroups(): OperationDef[] {
    return this.operationContainer?.operations || [];
  }

  async openOperation(operation: OperationDef) {
    await this.operationService.openOperationView(this.operationContainer, operation, null,
      operationRes => {
        this.operationPerformed.emit(operationRes);
      }
    );
  }

  private async createOperationContainer() {
    this.operationContainer = await this.operationService.createOperationContainer(this.operationTarget);
    this.operationContainer.operationContextObjects = this.contextObjects;
    await this.operationService.setOperations(this.operationContainer);
    this.operationContainer.openOperationDialogFn = () => {
      this.operationDialogService.openOperationDialog(this.operationContainer).then(operationRes => {
        this.operationPerformed.emit(operationRes);
      });
    };
  }

}
