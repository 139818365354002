import {Injectable} from '@angular/core';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {PrimusStateMapperService} from './primus-state-mapper.service';
import {LoggerService} from './logger.service';
import {CurrentObjectService} from './current-object.service';
import {ChangeTrackerService} from './change-tracker.service';
import {TranslateService} from '@ngx-translate/core';
import {CommonsService} from './commons.service';
import {Observable, Subscription} from 'rxjs';
import {PrimusRouteService} from './primus-route.service';
import {map} from "rxjs/operators";

@Injectable({
  providedIn: 'root'
})
export class PrimusRouterService {
  constructor(
    private translate: TranslateService,
    private logger: LoggerService,
    private router: Router,
    private primusRoute: PrimusRouteService,
    private primusStateMapper: PrimusStateMapperService,
    private currentObjectService: CurrentObjectService,
    private changeTracker: ChangeTrackerService,
    private commons: CommonsService) {
  }

  // class name observable, prevent NG0100 error on parent.
  classNameObservable: Observable<any> = this.router.events.pipe(
    map(() => {
      return this.className;
    })
  );

  prevSnapshotUrl: string;
  lastSnapshotUrl: string;
  lastStateName: string;
  lastClassName: string;


  async navigateState(stateName: string, params?: any, options?: any): Promise<boolean> {
    const routerParams = this.primusStateMapper.routerLinkFromState(stateName);
    let replace = false;
    if (options) {
      replace = options.location === 'replace';
      this.logger.info('Options not implemented yet: ' + JSON.stringify(options));
    }
    const queryParams = this.primusStateMapper.queryParams(params, replace);
    return this.router.navigate(
      routerParams,
      {
        queryParams: queryParams,
        queryParamsHandling: 'merge',
      });
  }

  async navigate(url: string) {
    const urlSplit = url.split('?');
    let queryParams;
    if (urlSplit.length > 1) {
      queryParams = {};
      const paramPairs = urlSplit[1].split('&');
      for (const paramPair of paramPairs) {
        const paramSplit = paramPair.split('=');
        queryParams[paramSplit[0]] = paramSplit[1];
      }
    }
    return this.router.navigate(
      [urlSplit[0]],
      {
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      }
    );
  }

  currentState(): string {
    const urlPath = this.router.routerState.snapshot.url.split('?')[0];
    return this.primusStateMapper.stateFromRouterLink(urlPath);
  }

  navigationHandler(afterChangeStateFn, navigationType = 'NavigationEnd'): Subscription {
    return this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && navigationType === 'NavigationEnd') {
        afterChangeStateFn(event);
        this.setMissingRootStuff();
      } else if (event instanceof NavigationStart && navigationType === 'NavigationStart') {
        afterChangeStateFn(event);
      }
    });
  }

  checkCancel(): boolean {
    const dialogText = this.translate.instant('TRANS__MODAL_DIALOG__ARE_YOU_SURE');
    let res = true;
    if (this.currentObjectService.isEditing) {
      const curObj = this.currentObjectService.currentObject;
      if (curObj) {
        if (this.changeTracker.objectHasChanges(curObj)) {
          res = window.confirm(dialogText);
        }
      } else {
        console.warn('No current object available for change check');
      }
    }
    return res;
  }

  get className(): string {
    let res;
    if (this.router.routerState.snapshot.url === this.lastSnapshotUrl) {
      return this.lastClassName;
    }
    this.prevSnapshotUrl = this.lastSnapshotUrl;
    this.lastSnapshotUrl = this.router.routerState.snapshot.url;
    const currentStateName = this.currentState();
    if (this.currentObjectService.isEditing && currentStateName !== 'home.primus.search.annotate') {
      res = 'edit-mode';
    } else if (this.lastStateName === currentStateName) {
      res = this.lastClassName;
    } else {
      res = this.primusStateMapper.classNameFromState(currentStateName);
      this.lastStateName = currentStateName;
      this.lastClassName = res;
    }
    return res;
  }

  private setMissingRootStuff() {
    this.commons.getStatusTypeIds().then();
  }

  /**
   * Routes back to search, with current parameters. Deletes artifactId and parentId to prevent data leaking into other components
   */
  async goBackToSearch() {
    // Deep Copy, to remove connections between object
    const params = JSON.parse(JSON.stringify(this.primusRoute.params));
    // Delete artifactId and parentId when going back to search
    delete params.artifactId;
    delete params.parentId;
    delete params.rootObjId;
    delete params.rootObjType;
    await this.router.navigate(['/search'], {queryParams: params}).catch();
  }

  // Keeping this from the old transition handler service

  // private transHandlerPostOperations(trans) {
  //   const toState = trans._targetState._definition;
  //   const toParams = trans._targetState._params;
  //
  //   if (toParams.debug) {
  //     this.loginService.setDebug(true);
  //   }
  //
  //   this.currentObjectService.isEditing = this.primusRoute.params.edit === 'true' || toState.name === 'home.primus.artifactNew';
  //
  //   const auth = this.auth.getAuth();
  //   if (!auth) {
  //     if (toState.name !== 'home') {
  //       this.loginService.setLoginDestinationState(toState, toParams);
  //       setTimeout(() => {
  //         this.primusRouter.navigateState('home', {
  //           debug: this.loginService.isDebug()
  //         }).then();
  //       }, 1000);
  //     }
  //   }
  //   if (toState.name === 'home') {
  //     // Hard-coding login info for now
  //   }
  //   // this.currentObjectService.setClassName();
  // }

}
