<div [formGroup]="fieldParameters.sectionsContainer.formGroup" *ngIf="generalFieldType" class="edit-field-input">
  <ng-container [ngSwitch]="fieldInputType">
    <span *ngSwitchCase="'display'">{{fieldTextValue}}</span>

    <textarea
      *ngSwitchCase="FieldInputType.TEXT_AREA"
      rows="3"
      [formControlName]="fieldKey"
      [placeholder]="placeholder"
      title="{{placeholder}}"
      [id]="fieldKey"
      [name]="fieldKey"
      (change)="onValueChange($event)"
      (blur)="onFieldBlur()"
      (focus)="onFieldFocus()"
      (keyup)="autoExpand()"
      [tabIndex]="isDisabled ? -1 : 0"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy,
        'errorInputFrame': fieldValidation.isFieldInvalid(fieldParameters.sectionsContainer, fieldKey),
        'disabled': isDisabled}"
      #textarea></textarea>
    <div *ngSwitchCase="'select'"
         class="edit-field-input__select-container">
      <mat-icon class="edit-field-input__select-icon">arrow_drop_down</mat-icon>
      <select
        [id]="fieldKey"
        [name]="fieldKey"
        [formControlName]="fieldKey"
        title="{{placeholder}}"
        (change)="onValueChange($event)"
        class="custom-select edit-field-input__select"
        [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy, 'selectedValue': fieldParameters.object[fieldParameters.field.name]}">
        <option *ngIf="fieldParameters.field.is_required !== 'required'" [value]=""></option>
        <option *ngFor="let opt of valueOptionService.getValueOptionsForField(fieldParameters.field).options"
                [value]="opt['value']">{{opt['label'] | translate}}</option>
      </select>
      <label *ngIf="fieldTextValue === ''" class="custom-select__placeholder">
        <span translate="TRANS__EDIT_FIELD__CHOOSE"></span>&nbsp;
        <span class="custom-select__placeholder-key">{{placeholder}}..</span></label>
    </div>
    <app-edit-field-radio-options
      *ngSwitchCase="FieldInputType.RADIO_OPTION"
      [formControlName]="fieldKey"
      [fieldParameters]="fieldParameters"
    ></app-edit-field-radio-options>
    <app-edit-field-select
      *ngSwitchCase="FieldInputType.MAP_ID"
      [fieldParameters]="fieldParameters"
      [formControlName]="fieldKey"
      title="{{placeholder}}"
      [inFocus]="inFocus"
      [forceUpdate]="forceUpdate"
      (fieldBlur)="onFieldBlur()"
      (fieldFocus)="onFieldFocus()"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
    ></app-edit-field-select>
    <app-edit-field-hierarchic-select
      *ngSwitchCase="FieldInputType.HIERARCHIC_SELECT"
      [formControlName]="fieldKey"
      [fieldParameters]="fieldParameters"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
    ></app-edit-field-hierarchic-select>
    <app-edit-field-identifier
      *ngSwitchCase="FieldInputType.IDENTIFIER"
      [fieldParameters]="fieldParameters"
      [formControlName]="fieldKey"
      [placeholder]="placeholder"
      title="{{placeholder}}"
      [inFocus]="inFocus"
      (fieldBlur)="onFieldBlur()"
      (fieldFocus)="onFieldFocus()"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
    ></app-edit-field-identifier>
    <app-edit-field-search-selector
      *ngSwitchCase="FieldInputType.SEARCH_SELECTOR"
      [fieldParameters]="fieldParameters"
      [formControlName]="fieldKey"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
    ></app-edit-field-search-selector>
    <app-edit-field-search-selector-multiple
      *ngSwitchCase="FieldInputType.SEARCH_SELECTOR_MULTIPLE"
      [fieldParameters]="fieldParameters"
      [formControlName]="fieldKey"
      [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy}"
    ></app-edit-field-search-selector-multiple>
    <mat-checkbox
      *ngSwitchCase="FieldInputType.CHECKBOX"
      class="edit-field-input__type-checkbox white-bg-in-checkbox"
      [formControlName]="fieldKey"
      (keyup)="checkValueWithKey($event)"
      [id]="fieldKey"
      (change)="onValueChange($event)"></mat-checkbox>
    <app-edit-field-check-array
      *ngSwitchCase="FieldInputType.CHECK_ARRAY"
      [fieldParameters]="fieldParameters"
      [formControlName]="fieldKey"
      [formControl]="getFormControlField()"
    ></app-edit-field-check-array>
    <div *ngSwitchCase="FieldInputType.DATE_ISO"
         class="edit-field-input__edit-field-precision-date">
      <app-edit-field-date-historic
        [fieldParameters]="fieldParameters"
        [field]="fieldParameters.field"
        [formControlName]="fieldKey"
        [placeholder]="placeholder"
      ></app-edit-field-date-historic>
    </div>
    <div *ngSwitchCase="FieldInputType.DATE_TIME_ISO"
         class="edit-field-input__edit-field-precision-date">
      <app-edit-field-date-picker
        [fieldParameters]="fieldParameters"
        [field]="fieldParameters.field"
        [formControlName]="fieldKey"
        [placeholder]="placeholder"
      ></app-edit-field-date-picker>
    </div>
    <app-edit-field-precision-date-range
      *ngSwitchCase="FieldInputType.DATE_TIME_ISO_RANGE"
      [fieldParameters]="fieldParameters"
      [fromDateField]="fieldParameters.field"
      [fromDateControlName]="fieldKey"
    ></app-edit-field-precision-date-range>
    <app-object-usage-field
      *ngSwitchCase="FieldInputType.OBJECT_USAGE"
      [fieldParameters]="fieldParameters"
    ></app-object-usage-field>
    <input *ngSwitchDefault
           [formControlName]="fieldKey"
           [placeholder]="placeholder"
           title="{{placeholder}}"
           [id]="fieldKey"
           [name]="fieldKey"
           [type]="fieldInputType"
           (blur)="onFieldBlur()"
           (focus)="onFieldFocus()"
           (change)="onValueChange($event)"
           [tabIndex]="isDisabled ? -1 : 0"
           [ngClass]="{'content-with-copy': fieldParameters.sectionsContainer.isCopy,
             'errorInputFrame': fieldValidation.isFieldInvalid(fieldParameters.sectionsContainer, fieldKey),
              'disabled': isDisabled}"
           autocomplete="off">
  </ng-container>
  <app-copy-keep-checkbox
    *ngIf="fieldParameters.sectionsContainer.isCopy"
    class="copy-keep-checkbox"
    [sectionsContainer]="fieldParameters.sectionsContainer"
    [object]="fieldParameters.object"
    [field]="fieldParameters.field"
  ></app-copy-keep-checkbox>

  <div *ngIf="fieldWarning" class="field-warning error-warn-color"><span>{{fieldWarningText | translate}}</span></div>
  <div *ngIf="checkDisabled && disabledReason" class="disable-reason">
    <i class="icon-warning"></i>
    <span>{{disabledReason | translate}}</span></div>

  <div *ngIf="isFieldInvalid"
       class="errorInEditField"
       [ngClass]="{'unfocused': !inFocus.value, 'focused': inFocus.value}">
    <i class="icon-warning"></i>
    <span *ngIf="invalidTypeErrors['maxlength']" translate=""
          [translateParams]="{maxLength: invalidTypeErrors['maxlength']['requiredLength'], propLength: invalidTypeErrors['maxlength']['actualLength']}">TRANS__VALIDATION__INVALID_MAX_LENGTH</span>
    <span *ngIf="invalidTypeErrors['minlength']" translate=""
          [translateParams]="{minLength: invalidTypeErrors['minlength']['requiredLength'], propLength: invalidTypeErrors['minlength']['actualLength']}">TRANS__VALIDATION__INVALID_MIN_LENGTH</span>
    <span *ngIf="invalidTypeErrors['max']" translate=""
          [translateParams]="{max: invalidTypeErrors['max']['max'], propNumber: invalidTypeErrors['max']['actual']}">TRANS__VALIDATION__INVALID_MAX_NUMBER</span>
    <span *ngIf="invalidTypeErrors['min']" translate=""
          [translateParams]="{min: invalidTypeErrors['min']['min'], propNumber: invalidTypeErrors['min']['actual']}">TRANS__VALIDATION__INVALID_MIN_NUMBER</span>
    <span *ngIf="invalidTypeErrors['required']" translate="">TRANS__VALIDATION__REQUIRED</span>
    <span
      *ngIf="invalidTypeErrors['validatePrecisionDate']">{{invalidTypeErrors['validatePrecisionDate'].message | translate}}</span>
    <span *ngIf="invalidTypeErrors['validateIdentifier']" translate="">TRANS__VALIDATION__IDENTIFIER_EXISTS</span>
    <span
      *ngIf="invalidTypeErrors['validateCompare']">{{invalidTypeErrors['validateCompare'].message | translate}}</span>
    <span *ngIf="invalidTypeErrors['validateUsername']" translate="">TRANS__VALIDATION__INVALID_USERNAME</span>
  </div>

</div>
