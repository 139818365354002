<div class="top-header">
  <button mat-button class="top-header__go-back text-blue-hover"
          (click)="goToSearch()">
    <mat-icon>navigate_before</mat-icon>
    <span *ngIf="parent['hasSubMenus']"
          class="underline-first-letter"
          >{{'TRANS__TOP_HEADER__BACK' | translate}}</span>
    <span *ngIf="!parent['hasSubMenus']"
          class="underline-first-letter"
          >{{'TRANS__TOP_HEADER__BACK_TO_SEARCH' | translate}}</span>
  </button>

  <div *ngIf="parent['hasSubMenus']"
       class="top-header__breadcrumbs">
    <div class="top-header__breadcrumbs__buttons dropdownIgnoreClicks">
      <a class="top-header__breadcrumbs__button border-blue"
         (click)="toggleBreadcrumbsMenu()"
         [ngClass]="{'active': toggleMenu}">
        <span class="text-blue">{{currentFirstOperation?.menu_title | translate}}</span>
        <mat-icon>{{toggleMenu ? 'expand_less' : 'expand_more'}}</mat-icon>
      </a>
      <div class="top-header__breadcrumbs__drop-down" *ngIf="toggleMenu">
        <ul>
          <ng-container *ngFor="let operation of firstSelectOperation.sub_operations">
            <li>
              <a [routerLink]="primusStateMapper.routerLinkFromState(operation.operation_steps[0].change_state[0].state_name)"
                 [queryParams]="primusStateMapper.queryParams(operation.operation_steps[0].change_state[0].state_params)"
                 class="text-blue-hover">
                <i class="{{operation.menu_icon}}"></i>
                <span [ngClass]="{'text-blue bold':operation.menu_title === currentFirstOperation?.menu_title}"
                      >{{operation.menu_title | translate}}</span>
              </a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>

