<section class="header-and-description-wrapper">
  <header>
    <div class="heading">
      <h3>{{ (heading || '') | translate }}
        <span *ngIf="headingName">: {{headingName}}</span></h3>
    </div>
    <div class="description">
      <div class="description__vertical-line bg-blue"></div>
      <h5>{{ (description || '') | translate }}
        <span *ngIf="count">({{count}})</span>
        <a *ngIf="href" class="link text-blue"
           href="mailto:{{href}}">{{href}}</a>
      </h5>
    </div>
  </header>
  <article>
    <ng-content></ng-content>
  </article>
</section>
