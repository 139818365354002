import {
  Component,
  ElementRef, EventEmitter,
  Input, OnChanges, Output,
  ViewChild
} from '@angular/core';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {AConst} from '../../../core/a-const.enum';
import {InlineArrayItemService} from '../../../core/inline-array-item.service';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {MetaField} from '../../../core/definitions/meta-field';
import {BaseModel} from '../../../core/definitions/base-model';
import {FieldConditionService} from '../../../core/field-condition.service';
import {SearchReferenceService} from "../../../core/search-reference.service";

@Component({
  selector: 'app-edit-field-inline-array-item',
  templateUrl: './edit-field-inline-array-item.component.html',
  styleUrls: ['./edit-field-inline-array-item.component.scss']
})
export class EditFieldInlineArrayItemComponent implements OnChanges {

  @Input() fieldParametersIn: FieldParameters;
  @Input() item: BaseModel;
  @Input() arrayIndex: number;
  @ViewChild('fieldInlineArrayItem') fieldInlineArrayItem: ElementRef;
  @Output() fieldFocus = new EventEmitter<object>();

  AConst = AConst;
  fields: Array<MetaField>;
  arrayItemFieldId = '';
  fieldParameters: FieldParameters;

  constructor(private readonly inlineArrayItemSvc: InlineArrayItemService,
              private readonly fieldCondition: FieldConditionService,
              private readonly searchReferenceService: SearchReferenceService) {
  }

  ngOnChanges() {
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.sectionsContainer = this.fieldParametersIn.sectionsContainer;
    this.fieldParameters.grandParentObject = this.fieldParametersIn.object;
    this.fieldParameters.object = this.item;
    this.fieldParameters.field = this.fieldParametersIn.field;
    this.fieldParameters.parentIndex = this.fieldParametersIn.parentIndex;
    this.fieldParameters.edit = true;
    this.fieldParameters.index = this.arrayIndex;
    this.fieldParameters.useMultipleSetter = this.fieldParametersIn.useMultipleSetter;
    this.fields = this.fieldParameters.field.inline_fields;
    this.arrayItemFieldId = this.inlineArrayItemSvc.getInlineArrayItemId(this.fieldParameters);
    this.onSetFirstInputFocus();
  }

  get sectionsContainer(): SectionsContainer {
    return this.fieldParameters.sectionsContainer;
  }

  get object(): BaseModel {
    return this.fieldParameters.grandParentObject;
  }

  get field(): MetaField {
    return this.fieldParameters.field;
  }

  get parentIndex(): number {
    return this.fieldParameters.parentIndex;
  }

  private canShow(metaField: MetaField): boolean {
    let res = false;
    if (metaField.display === 'yes') {
      res = true;
    }
    if (res && metaField.field_ifs) {
      for (const fieldIf of metaField.field_ifs) {
        if (fieldIf.if_type === 'show') {
          res = this.fieldCondition.runIfItem(fieldIf, this.fieldParameters);
        }
      }
    }
    return res;
  }

  onSetFirstInputFocus() {
    if (!this.arrayItemIsOpen()) {
      return;
    }
    const fields = this.fields.filter((item: MetaField) => this.canShow(item) === true );
    const displayFields = fields.filter(item =>
      (item.edit === 'edit' || (item.edit === 'edit_once' && this.item.$$justAdded)));
    const firstEditableItem = displayFields.find((item) => item);
    if (!firstEditableItem) {
      return;
    }

    setTimeout(() => {
      const index = fields.indexOf(firstEditableItem);
      const container = this.fieldInlineArrayItem.nativeElement.querySelector('ul');
      const focusElement = container.children[index];
      const inputType = firstEditableItem.input_type;
      if (inputType === 'input' || inputType === 'map_id') {
        this.searchReferenceService.getSearchReferenceFromField(firstEditableItem).then(reference => {
          reference?.is_hierarchic ?
            focusElement.querySelector('mat-expansion-panel-header').focus() :
            focusElement.querySelector('input').focus();
          this.onFieldFocus();
        });
      } else if (inputType === 'text_area') {
        focusElement.querySelector('textArea').focus();
        this.onFieldFocus();
      } else if (inputType === 'select') {
        focusElement.querySelector('select').focus();
        this.onFieldFocus();
      } else if (inputType === 'action_button') {
        focusElement.querySelector('button').focus();
        this.onFieldFocus();
      }
    }, 500);
  }
  onFieldFocus() {
    if (!this.fieldParameters.sectionsContainer.isDialog) {
      this.fieldFocus.emit();
    }
  }

  arrayItemIsOpen() {
    return this.inlineArrayItemSvc.isInlineArrayItemOpen(this.fieldParameters);
  }

}
