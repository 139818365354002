import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {CommonsService} from '../../core/commons.service';
import {CheckFilterGroup, SearchViewMenu} from '../../core/definitions/search-objects';
import {SearchFilterService} from '../search-filter.service';
import {SearchExecutorService} from '../search-executor.service';
import { SearchResultViewType } from 'src/app/core/definitions/search-result-view-type.enum';
import {SearchContainer} from '../../core/definitions/search-container';
import {SearchFacetService} from '../search-facet.service';

export class PathView {
  menus: Array<SearchViewMenu> = [];
}

@Component({
  selector: 'app-search-filter-menu',
  templateUrl: './search-filter-menu.component.html',
  styleUrls: ['./search-filter-menu.component.scss']
})
export class SearchFilterMenuComponent implements OnInit, OnDestroy {

  @Input() searchContainer: SearchContainer;
  @Input() sideMenu;
  @Input() dropdownMenu;
  @Input() sideMenuSmallScreen;
  @Output() toggleSideMenuSmallScreen = new EventEmitter<object>();
  @ViewChild('searchFilterMenu', {static: false}) searchFilterMenu: ElementRef;

  pathView = new PathView();
  filterGroups: Array<CheckFilterGroup> = [];
  SearchResultViewNames = SearchResultViewType;
  private path = 'not set';
  private curFocusId;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchFilterService: SearchFilterService,
              private commons: CommonsService,
              private searchFacetService: SearchFacetService) {
  }

  ngOnInit() {
    this.searchExecutorService.subscribeToSearchResult(this.searchContainer, this.init);
  }

  ngOnDestroy(): void {
    this.searchExecutorService.unSubscribeToSearchResult(this.searchContainer, this.init);
  }

  toggleSideFilterMenuSmallScreen() {
    this.toggleSideMenuSmallScreen.emit();
  }

  scrollToBottom() {
    setTimeout(() => {
      if (this.searchFilterMenu) {
        this.searchFilterMenu.nativeElement.scrollTop = this.searchFilterMenu.nativeElement.scrollHeight;
      }
    }, 1200);
  }

  private async setCheckFilters() {
    if (!this.searchContainer.filtersFacets.filterGroups) {
      await this.searchFilterService.setCheckFilterGroups(this.searchContainer);
    }
    this.filterGroups = this.searchContainer.filtersFacets.filterGroups ? this.commons.sortArray(
      this.searchContainer.filtersFacets.filterGroups, 'order') : [];
  }

  private init = () => {
    const sc = this.searchContainer;
    if (sc) {
      if (this.path !== sc.path || this.curFocusId !== sc.focus.curFocusId) {
        let menus = this.searchContainer.currentPathView.search_view.menus;
        if (menus) {
          menus = this.commons.sortArray(menus, 'order');
          menus.forEach(mainMenu => {
            mainMenu.menus = mainMenu.menus ? this.commons.sortArray(mainMenu.menus, 'order') : [];
          });
          this.pathView.menus = menus;
        }
        if (this.path !== 'not set') {
          this.searchContainer.filtersFacets.filterGroups = null;
        }
        this.path = sc.path;
        this.curFocusId = sc.focus.curFocusId;
      }

      if (sc.searchResult) {
        this.setCheckFilters().then(() => {
          this.setFilterCount();
        });
        this.setMenuCount();
      }
    }
  }

  private setMenuCount() {
    if (this.searchContainer.currentPathView.path_name.indexOf(this.searchContainer.path) !== 0) {
      console.warn('Incompatible paths');
      return;
    }
    for (const mainMenu of this.searchContainer.currentPathView.search_view.menus || []) {
      if (this.searchContainer.path.indexOf(this.searchContainer.path) === 0) {
        mainMenu.count = this.searchFacetService.getMenuCount(mainMenu, this.searchContainer);
        if (mainMenu.menus) {
          for (const subMenu of mainMenu.menus) {
            subMenu.count = this.searchFacetService.getMenuCount(subMenu, this.searchContainer);
          }
        }
      }
    }
  }

  private setFilterCount() {
    for (const filterGroup of this.filterGroups) {
      for (const filter of filterGroup.checkFilters) {
        filter.count = this.searchFilterService.getFilterCount(filter, this.searchContainer);
      }
    }
  }
}
