import {Injectable} from '@angular/core';
import {CmsApiService} from '../core/cms-api.service';
import {AConst} from '../core/a-const.enum';
import {ObjectUsageDialogComponent, ObjectUsageDialogData} from './object-usage-dialog/object-usage-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Injectable({
  providedIn: 'root'
})
export class UserDialogsService {

  constructor(private modalService: MatDialog, private cms: CmsApiService) {
  }

  showObjectUsage(artifactId, hideIfNoUsage?, hasUsageMessage?) {
    return new Promise((resolve, reject) => {
      this.cms.getArtifactUsage({artifact_id: artifactId}).then(
        res => {
          const ids = res[AConst.USAGE] || [];
          if (ids.length > 0 || !hideIfNoUsage) {
            const objects = [];
            hasUsageMessage = ids.length ? hasUsageMessage : '';
            ids.forEach(id => {
              objects.push({artifact_id: id});
            });
            const modalRef = this.modalService.open(ObjectUsageDialogComponent, {
              panelClass: 'confirm-dialog',
              data: {
                objects: objects,
                hasUsageMessage: hasUsageMessage
              } as ObjectUsageDialogData
            });
            modalRef.afterClosed().subscribe(() => {
              resolve(ids.length);
            });
          } else {
            resolve(0);
          }
        },
        reason => {
          reject(reason.error.message);
        }
      );
    });
  }
}
