<mat-menu #menu [overlapTrigger]="false">
  <span *ngFor="let item of items">
    <span *ngIf="item.items && item.items.length > 0">
      <button mat-menu-item
              type="button"
              [matMenuTriggerFor]="subMenu.menu">
        <mat-icon *ngIf="item.icon" [color]="getColor(item)">{{ item.icon }}</mat-icon>
        {{ item.label | translate }}
      </button>
      <app-primus-menu-button-item #subMenu
                                   [items]="item.items"
                                   [selectionState]="selectionState"
                                   [color]="color"
                                   (menuItemClicked)="handleMenuItemClicked($event)">
      </app-primus-menu-button-item>
    </span>

    <button mat-menu-item
            type="button"
            [ngClass]="{'active': isActive(item)}"
            *ngIf="!item.items || item.items.length === 0"
            (click)="handleMenuItemClicked(item, $event)">
      <ng-container *ngIf="item.mode === 'radio'">
        <mat-icon class="on-active" [color]="getColor(item)">radio_button_checked</mat-icon>
        <mat-icon class="on-not-active" [color]="getColor(item)">radio_button_unchecked</mat-icon>
      </ng-container>
      <ng-container *ngIf="item.mode === 'checkbox'">
        <mat-icon class="on-active" [color]="getColor(item)">check_box</mat-icon>
        <mat-icon class="on-not-active" [color]="getColor(item)">check_box_outline_blank</mat-icon>
      </ng-container>
        <mat-icon *ngIf="item.icon" [color]="getColor(item)">{{ item.icon }}</mat-icon>
      {{ item.label | translate }}
    </button>
  </span>
</mat-menu>
