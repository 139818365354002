<div  class="admin-concept-object-filter-menu">
  <button mat-stroked-button
        type="button"
        class="admin-concept-object-filter-menu__button"
        [matMenuTriggerFor]="filterConceptListMenu">
  <i class="icon-filter2"></i>
  <span class="bold uppercase admin-concept-object-filter-menu__button-text"
        translate="TRANS__FILTER__CONCEPT_LIST"></span></button>

  <mat-menu #filterConceptListMenu="matMenu">
    <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" (click)="$event.stopPropagation()">

      <!-- This is the tree node template for leaf nodes -->
      <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding="">
        <mat-label>
          <mat-checkbox [(ngModel)]="node.selected" (change)="setObjectFilter(node)">{{node.name}}</mat-checkbox>
        </mat-label>
      </mat-tree-node>

      <!-- This is the tree node template for expandable nodes -->
      <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding="">
        <button mat-icon-button matTreeNodeToggle
                [attr.aria-label]="'toggle ' + node.name">
          <mat-icon class="mat-icon-rtl-mirror">
            {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
          </mat-icon>
        </button>
        <mat-checkbox [(ngModel)]="node.selected"
                      (ngModelChange)="toggleAllLeafNodes(node)"
                      [indeterminate]="checkIfChildNodeIsSelected(node)">{{node.name}}</mat-checkbox>
      </mat-tree-node>
    </mat-tree>
  </mat-menu>

  <mat-chip-list aria-label="Remove object filter"
                 aria-orientation="horizontal"
                 class="admin-concept-object-filter-menu__chip-list">
    <mat-chip *ngFor="let objectFilter of selectedFilters | slice:0:chipDisplayLimit" [selectable]="true"
              [removable]="true" (removed)="removeObjectFilter(objectFilter)">
      {{objectFilter.name}}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>

  <button mat-button color="accent" *ngIf="selectedFilters.length > chipDisplayLimit" [matMenuTriggerFor]="showAllSelectedFilters">
    <span class="bold">+{{selectedFilters.length - chipDisplayLimit}}</span></button>

  <mat-menu #showAllSelectedFilters="matMenu">
    <mat-chip-list aria-label="Remove object filter"
                   aria-orientation="vertical"
                   class="admin-concept-object-filter-menu__display-remaining-chip-list">
      <mat-chip *ngFor="let objectFilter of selectedFilters | slice:chipDisplayLimit:selectedFilters.length" [selectable]="true"
                [removable]="true" (removed)="removeObjectFilter(objectFilter)">
        {{objectFilter.name}}
        <mat-icon matChipRemove>cancel</mat-icon>
      </mat-chip>
    </mat-chip-list>
  </mat-menu>
</div>
