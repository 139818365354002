<div class="field-select" *ngIf="fieldParameters" [formGroup]="fieldParameters.sectionsContainer.formGroup"
     [ngClass]="{'fieldSelectOpen': inFocus.value}">

  <app-edit-field-select-query
    *ngIf="cachedParams"
    [sectionsContainer]="fieldParameters.sectionsContainer"
    [fieldParameters]="fieldParameters"
    [reference]="reference"
    [query]="query"
    [inFocus]="inFocus"
    [showOptions]="showOptions"
    [fullSearch]="fullSearch"
    [temporaryFieldValueName]="cachedParams.temporaryFieldValueName"
    (queryEvent)="onChildEvent($event)"
  ></app-edit-field-select-query>


  <div style="padding: 0" *ngIf="showOptions.show" class="show-options"
       [ngClass]="fieldParameters.sectionsContainer.isDialog ?
     (fieldParameters.sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name"
       [class.show-options-in-modal]="fieldParameters.sectionsContainer.isDialog">

    <app-field-select-options
      *ngIf="fieldParameters"
      [fieldParameters]="fieldParameters"
      [reference]="reference"
      [query]="query.value"
      [isArray]="isArray"
      [selectedRow]="selectedRow"
      [fullSearch]="fullSearch.value"
      [dataSource]="ds"
      [keyPressed]="keyPressed"
      (optionSelected)="onOptionsSelected([$event])"
      (optionUnChecked)="onOptionUnchecked($event)"
      (keyEventsInShowOptions)="onKeyEventsInShowOptions($event)"
    ></app-field-select-options>

    <div class="createConcept"
         *ngIf="fieldParameters.canAddNew">
      <button mat-button
              (keyup)="createOptionWithKey($event)"
              (blur)="createOptionBlur()"
              type="button"
              (click)="createOption(); $event.stopPropagation();" #addNewOption>
        <mat-icon>add</mat-icon>
        <span>{{'TRANS__FIELD_SELECT__ADD_NEW' | translate}}</span>
      </button>
      <button mat-stroked-button
              (click)="searchCultureHub(); $event.stopPropagation();"
              (keyup)="searchCultureHubWithKey($event)"
              type="button"
              (blur)="searchCultureHubBlur()"
              *ngIf="reference?.search_kulturnav">
        <mat-icon>get_app</mat-icon>
        <span>{{'TRANS__FIELD_SELECT__GET_FROM_AUTHORITY' | translate}}</span>
      </button>
    </div>

  </div>

  <ul *ngIf="isArray"
      class="field-select__multi-selected-options"
      [ngClass]="{'focusMultiSelectedOptions': inFocus.value}"> <!-- if multi-select -->
    <app-field-select-selected-options
      [dragula]="fieldParameters.field.name"
      [(dragulaModel)]="fieldParameters.object[fieldParameters.field.name]"
      [fieldParameters]="fieldParameters"
      (optionUnChecked)="onOptionUnchecked($event)"
    ></app-field-select-selected-options>
  </ul>

</div>

