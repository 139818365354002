<div [ngSwitch]="field.input_type">
  <div *ngSwitchCase="FieldInputType.INLINE">
    <app-edit-field-inline-field
      *ngFor="let subField of field.sub_fields; let i = index"
      [subFieldIndex]="i"
      [sectionsContainer]="sectionsContainer"
      [grandParentObject]="myObject"
      [object]="myObject[field.name]"
      [field]="subField"
      [index]="index"
      [parentIndex]="parentIndex"
    ></app-edit-field-inline-field>
  </div>

  <div *ngSwitchCase="FieldInputType.INLINE_ARRAY">
    <app-edit-field-inline-array
      [sectionsContainer]="sectionsContainer"
      [object]="myObject"
      [field]="field"
      (fieldOutsideTheScreen)="checkIfFieldOutsideTheScreen()"
      [parentIndex]="index"
    ></app-edit-field-inline-array>
  </div>

  <div *ngSwitchCase="FieldInputType.CONTEXT">
    <app-edit-field-context
      *ngIf="sectionsContainer.isDialog"
      [contexts]="sectionsContainer.rootObject[field.name]"
      [field]="field"
    ></app-edit-field-context>
  </div>

  <div *ngSwitchCase="FieldInputType.ACTION_BUTTON">
    <app-field-action-button
      class="section-field__action-button"
      [sectionsContainer]="sectionsContainer"
      [object]="myObject"
      [field]="field"
      [edit]="true"
      [parentIndex]="index"
      (closeEdit)="onCloseEdit()"
    ></app-field-action-button>
  </div>

  <app-edit-field-compare
    *ngSwitchCase="'compare_value'"
    [sectionsContainer]="sectionsContainer"
    [field]="field"
    [parentIndex]="parentIndex"
    [index]="index"
  ></app-edit-field-compare>

  <app-edit-field-meta-operation-field
    *ngSwitchCase="FieldInputType.META_OPERATION_FIELD"
    [sectionsContainer]="sectionsContainer"
    [field]="field"
    [setAddChoice]="sectionsContainer.rootObject['set_add_choice']"
  ></app-edit-field-meta-operation-field>

  <div *ngSwitchDefault>
    <app-edit-field
      [sectionsContainer]="sectionsContainer"
      [grandParentObject]="grandParentObject"
      [object]="myObject"
      [field]="field"
      [index]="index"
      [parentIndex]="parentIndex"
    ></app-edit-field>
  </div>
</div>
