/*
* The following classes are a reflection of classes defined on the service side, object_view_builder.py.
* Any changes to the server classes must be repeated here.
* This is also the reason why fields are written as "snake case" instead of camel case.
* */

import {MetaField} from './meta-field';
import {SuperObjectModel} from './super-object-model';
import {BaseModel} from './base-model';
import {OperationDef} from './operation-def';

export class ObjectView {
  artifact_name: string;
  authority_id: string;
  authority_id_value: string;
  icon: string;
  meta_type: string;
  object_type: string;
  artifact_id: string;
  overview: Array<OverviewField>;
  sections: Array<ViewSection>;
  thumbnail_id: string;
  thumbnail_url: string;
  $$relatedObjectId: string;
}

export class ObjectViewAndData {
  view_data: ObjectView;
  artifact: SuperObjectModel;
}

export class ObjectAndOperations {
  view_data: ObjectView;
  artifact: SuperObjectModel;
  operations: OperationDef[];
}

export class ViewSection {
  title: string;
  name: string;
  section_fields: Array<ViewField>;
  hide_title: boolean;
}

export class OverviewField {
  field_name: string;
  /**
   * @deprecated
   */
  title: string;
  items: Array<FieldItem>;
  field_type: string;
  order;
  field_class: string;
  container_class: string;
  is_link: boolean;
  is_array: boolean;
}

export interface ActionButtonField extends MetaField {
  actions: Array<any>;
  actions_id: string;
  display: string;
}

export class ViewField {
  title: string;
  items: Array<FieldItem>;
  field_type: string;
  action_button: ActionButtonField;

  $$value: string;
  $$icon: string;
}

export class FieldItem {
  field_values: FieldValues;
  header_values: FieldValues;
  is_new_array_item: boolean;
  checkbox_field: CheckboxField;
}

export class FieldValues {
  values: Array<FieldValue>;
  field_icon: FieldIcon;
  title_values: Array<FieldValue>;
  level: number;
}

export class FieldValue {
  value: any;
  ref_id: string;
  ref_object_type: string;
  ref_meta_type: string;
  is_text: boolean;
  $$linkItems: BaseModel[];
}

export class FieldIcon {
  icon: string;
  icon_frame: string;
}

export class CheckboxField {
  array_field_name: string;
  index: number;
  field_name: string;
}
