<div class="edit-field-inline-array-add-button">
  <button *ngIf="canAddItem()"
          mat-stroked-button
          color="primary"
          type="button"
          (click)="addItem($event)"
          [disabled]="disabled"
          (keydown.enter)="addItem($event)">
    <i class="icon-plus-circle"></i>
    <span class="bold uppercase" translate="TRANS__FIELD__ARRAY_ADD"></span>
    <span>&nbsp;</span>
    <span class="bold uppercase" [translate]="field.title"></span>
  </button>

  <div *ngFor="let notification of addItemNotifications"
       class="add-button-notification">
    <div *ngIf="notification && !notification['hide']" class="add-button-notification__content">
      <span class="{{notification.message_class}}">
        <span class="icon-information"></span>
        <span class="icon-warning"></span>
        <span [translate]="notification.message"></span>
        <button (click)="notification.hide = true"><mat-icon>close</mat-icon></button>
      </span>
    </div>
  </div>
</div>
