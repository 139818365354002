import {Component, Input, OnChanges} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {RefParams, RefService} from '../../core/ref.service';
import {SearchHandlerService} from '../../object-search/search-handler.service';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';

@Component({
  selector: 'app-object-url',
  templateUrl: './object-url.component.html',
  styleUrls: ['./object-url.component.scss']
})
export class ObjectUrlComponent implements OnChanges {
  AConst = AConst;
  @Input() object;
  @Input() searchItemIndex;
  @Input() rootObjId;
  @Input() rootObjType;
  @Input() searchContainer;
  @Input() disable;
  @Input() listName;
  @Input() hasImage;
  @Input() useUrl;
  @Input() aClass;

  isDisabled: boolean;
  uiOptions = {
    reload: true
  };
  routerLink;
  queryParams;
  externalLink;

  constructor(private primusStateMapper: PrimusStateMapperService,
              private ref: RefService,
              private searchHandler: SearchHandlerService) {
  }

  ngOnChanges() {
    this.getRef().then();
    this.isDisabled = this.disable || !this.object;
    if (this.isDisabled) {
      this.uiOptions.reload = false;
    }
  }

  async getRef(): Promise<void> {
    if (this.object.artifact_id) {
      const params = new RefParams(this.object, {
        listName: null
      });
      // params.useUrl = this.useUrl;
      params.searchItemIndex = null;
      params.rootObjId = null;
      if (this.searchItemIndex !== undefined) {
        params.searchItemIndex = this.searchItemIndex.toString();
      }
      if (this.rootObjId !== undefined) {
        params.rootObjId = this.rootObjId;
        params.rootObjType = this.rootObjType;
      }
      if (this.listName) {
        params.stateParams.listName = this.listName;
      }
      const refData = await this.ref.makeRef(params);
      if (refData?.param.externalLink) {
        this.externalLink = refData.param.externalLink;
      } else if (refData) {
        this.externalLink = refData.param.externalLink;
        this.routerLink = refData.routerLink;
        this.queryParams = refData.queryParams;
        // Delete parentId
        delete this.queryParams.parentId;
        this.isDisabled = this.isDisabled || refData.disabled;
      } else {
        this.isDisabled = true;
      }
    }
  }

  clickItem() {
    if (this.searchContainer && this.searchItemIndex !== undefined) {
      this.searchHandler.setSearchItemIndex(this.searchContainer, this.searchItemIndex);
    }
  }
}
