import {Component, ElementRef, Input, OnChanges, OnDestroy, ViewChild} from '@angular/core';
import {FieldInputType} from '../../core/definitions/field-input-type.enum';
import {UserCacheService} from '../../core/user-cache.service';
import {FieldStateService} from '../../core/field-state.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {IfType} from '../../core/definitions/field-if';
import {FieldConditionService} from '../../core/field-condition.service';
import {FieldValueService} from '../../core/field-value.service';
import {FieldType} from '../../core/definitions/field-type.enum';
import {UntypedFormControl} from '@angular/forms';
import {InFocus} from '../in-focus';
import {UiToolsService} from '../../core/ui-tools.service';
import {FieldValidationService} from '../../core/field-validation.service';
import {ValueOptionService} from "../../core/value-option.service";

@Component({
  selector: 'app-edit-field-input',
  templateUrl: './edit-field-input.component.html',
  styleUrls: ['./edit-field-input.component.scss']
})
export class EditFieldInputComponent implements OnChanges, OnDestroy {
  @Input() fieldParameters: FieldParameters;
  @Input() disabled: boolean;
  @ViewChild('textarea') textarea: ElementRef;

  FieldInputType = FieldInputType;
  isGenerallyEditable = false;
  generalFieldType: string;
  fieldKey: string;
  placeholder: string;
  inFocus: InFocus = new InFocus();
  mediumScreen;
  fieldWarningText = '';
  disabledReason = '';
  forceUpdate = false;
  isDisabled = false;

  private stopSizeWatch;


  constructor(private userCacheService: UserCacheService,
              private fieldState: FieldStateService,
              private fieldCondition: FieldConditionService,
              private fieldValueSvc: FieldValueService,
              private uiTools: UiToolsService,
              public fieldValidation: FieldValidationService,
              public valueOptionService: ValueOptionService
  ) {
  }

  ngOnChanges(): void {
    this.init().then();
  }

  private async init() {
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        this.mediumScreen = newVal.width < 1025;
      }
    );
    const sectionsContainer = this.fieldParameters.sectionsContainer;
    const field = this.fieldParameters.field;
    const object = this.fieldParameters.object;
    const userData = await this.userCacheService.getUserData();
    this.isGenerallyEditable = !this.disabled && this.fieldState.isEditable(
      sectionsContainer.rootObject, object, field, userData);
    this.generalFieldType = await this.fieldState.getFieldInputType(
      field, sectionsContainer.rootObject, object, userData);

    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(field, this.fieldParameters.index, this.fieldParameters.parentIndex);
    this.placeholder = this.fieldState.getFieldTitle(field, sectionsContainer.rootObject, true, true);
    setTimeout(() => {
      this.autoExpand();
    }, 10);
  }

  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  autoExpand() {
    if (this.textarea) {
      this.textarea.nativeElement.style.height = 'auto';
      this.textarea.nativeElement.style.height = this.textarea.nativeElement.scrollHeight + 'px';
    }
  }

  get fieldInputType() {
    let res = this.generalFieldType;
    if (res !== FieldInputType.OBJECT_USAGE && res !== 'display' && !this.isEditable) {
      res = 'display';
    }
    return res;
  }

  get isEditable() {
    return this.isGenerallyEditable && this.fieldCondition.runIf(IfType.EDIT, this.fieldParameters).result;
  }

  get fieldTextValue() {
    return this.fieldValueSvc.getFieldTextValue(this.fieldParameters.sectionsContainer.rootObject, this.fieldKey, true);
  }

  get checkDisabled(): boolean {
    let disabled = !this.isGenerallyEditable;
    let disabledReason = '';
    if (!disabled) {
      const runIfResult = this.fieldCondition.runIf(IfType.DISABLE, this.fieldParameters);
      if (runIfResult.hadIfType) {
        disabled = runIfResult.result;
        disabledReason = runIfResult.resultText;
      }
    }
    if (this.fieldParameters.field.edit) {
      const formControl = this.getFormControlField();
      if (disabled && formControl.enabled) {
        formControl.disable();
      } else if (!disabled && formControl.disabled) {
        formControl.enable();
      }
      this.disabledReason = disabledReason && disabled ? disabledReason : '';
    }
    this.isDisabled = disabled;
    return disabled;
  }

  onValueChange($event) {
    const object = this.fieldParameters.object;
    const field = this.fieldParameters.field;
    let value = field.field_type === FieldType.BOOLEAN ? $event.checked : $event.target.value;
    if (field.field_type === FieldType.BOOLEAN) {
      value = !object[field.name];
      object[field.name] = value;
      const formField = this.getFormControlField();
      formField.setValue(value);
      formField.markAsDirty();
    } else if (field.input_type === FieldInputType.NUMBER) {
      object[field.name] = value !== '' && value !== null ? Number(value) : null;
      const formField = this.getFormControlField();
      formField.setValue(value);
      $event.stopPropagation();
    } else {
      object[field.name] = value;
      $event.stopPropagation();
    }
  }

  onFieldBlur() {
    this.inFocus.value = false;
  }

  onFieldFocus() {
    if (!this.fieldParameters.sectionsContainer.isDialog && !this.mediumScreen) {
      this.inFocus.value = true;
    }
  }

  getFormControlField(): UntypedFormControl {
    const res = <UntypedFormControl>this.fieldParameters.sectionsContainer.formGroup.controls[this.fieldKey];
    if (!res) {
      console.warn('No form control for key: ' + this.fieldKey);
    }
    return res;
  }

  checkValueWithKey(evt) {
    switch (evt.key) {
      case 'Enter':
      case ' ': // Space
        this.onValueChange(evt);
        evt.preventDefault();
        break;
    }
  }

  get isFieldInvalid() {
    if (this.fieldParameters.field.edit) {
      return this.fieldValidation.isFieldInvalid(this.fieldParameters.sectionsContainer, this.fieldKey);
    }
  }

  get fieldWarning() {
    const runIfResult = this.fieldCondition.runIf(IfType.FIELD_WARNING, this.fieldParameters, () => {
      this.forceUpdate = true;
    });
    if (runIfResult.result) {
      this.fieldWarningText = runIfResult.resultText;
    }
    return runIfResult.result;
  }

  get invalidTypeErrors() {
    let res = {};
    const field = this.getFormControlField();
    if (field) {
      res = field.errors;
    }
    return res || {};
  }

}
