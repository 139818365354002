import {Component, Input, OnInit} from '@angular/core';
import {animate, state, style, transition, trigger} from '@angular/animations';
import {UserCollectionItems} from '../../../../core/definitions/user-collection-items';
import {UserCollectionItemsBase} from '../../../../core/definitions/user-collection-items-base';
import User from '../../User';

@Component({
  selector: 'app-user-collections',
  templateUrl: './user-collections.component.html',
  styleUrls: ['./user-collections.component.scss'],
  animations: [
  trigger('deleteItem', [
    state('expanded', style({ height: '*', /*display: 'block',*/ color: 'black' })),
    state('collapsed', style({ height: '0px', maxHeight: '0', display: 'none', color: '#eceff1' })),
    transition('expanded <=> collapsed', [animate('500ms cubic-bezier(0.4, 0.0, 0.2, 1)')]),
  ]),
],
})
export class UserCollectionsComponent implements OnInit {

  @Input() user: User;
  @Input() collections: UserCollectionItemsBase[];
  @Input() collectionType: string;
  @Input() enableEditMode: boolean;
  displayedColumns = [];

  constructor() {
  }

  ngOnInit(): void {
    if (this.collectionType === 'collections') {
      this.displayedColumns = ['collection_name', 'collection_rights', 'is_main_collection', 'delete'];
    } else {
      this.displayedColumns = ['collection_name', 'collection_rights', 'delete'];
    }
  }

  onChangedMainCollection(newMainCollection: UserCollectionItems) {
    this.user.main_collection_id = newMainCollection.collection_id;
    this.collections.filter(collection => collection.collection_id !== newMainCollection.collection_id).forEach(
      notMainCollection => {
        const notMainUserCollection = <UserCollectionItems>notMainCollection;
        notMainUserCollection.is_main_collection = false;
      });
  }

  onRemoveCollection(removeCollection: UserCollectionItems) {
    removeCollection._destroy = true;
  }

}
