import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {Section, SectionsContainer} from '../../core/definitions/sections-container';
import {ObjectEditService} from '../../core/object-edit.service';
import {UserSettingsService} from '../../core/user-settings.service';

@Component({
  selector: 'app-section-view-edit',
  templateUrl: './section-view-edit.component.html',
  styleUrls: ['./section-view-edit.component.scss']
})
export class SectionViewEditComponent implements OnChanges {

  AConst = AConst;
  allSections = {keep: true};
  hideSectionTitle = false;

  @Input() sectionsContainer: SectionsContainer;
  @Input() sectionsToShow: string[];
  @Output() closeEdit = new EventEmitter<any>();

  sections: Section[] = [];

  constructor(private objectEditService: ObjectEditService,
              private userSettings: UserSettingsService) {
  }

  ngOnChanges() {
    this.setSectionsToShow();
    this.hideSectionTitle = this.sectionsContainer.hideSectionTitle;
    const copyOptions = this.sectionsContainer.copyOptions;
    if (copyOptions) {
      const objType = this.sectionsContainer.rootObject.object_type;
      if (typeof copyOptions[objType].allSections === 'object') {
        this.allSections = copyOptions[objType].allSections;
      } else {
        copyOptions[objType].allSections = this.allSections;
      }
    }
  }

  setSectionsToShow() {
    if (this.sectionsToShow) {
      const res = [];
      this.sectionsContainer.sections.forEach(section => {
        if (this.sectionsToShow.indexOf(section.name) !== -1) {
          res.push(section);
        }
      });
      this.sections = res;
    } else {
      this.sections = this.sectionsContainer.sections;
    }
  }

  setAllSectionKeep() {
    const objType = this.sectionsContainer.rootObject.object_type;
    const objCopyOptions = this.sectionsContainer.copyOptions[objType];
    this.allSections.keep = !this.allSections.keep;
    for (const i in objCopyOptions.sections) {
      if (objCopyOptions.sections.hasOwnProperty(i)) {
        objCopyOptions.sections[i].keep = this.allSections.keep;
      }
    }
    this.userSettings.storeCopyOptions(this.sectionsContainer.copyOptions);
  }

  onUpdateKeepSection(section) {
    this.sectionsContainer.sections.forEach((val, index) => {
      if (val.name === section.name) {
        this.sectionsContainer.sections[index].keep = section.keep;
      }
    });
  }

  onCloseEdit() {
    this.closeEdit.emit();
  }

}
