<div class="edit-field-image-selector">
  <div class="edit-field-image-selector__image-drop-zone"
       (drop)="onDropFile($event)"
       (dragover)="onDragOverFile($event)">
    <div class="edit-field-image-selector__image">
      <i *ngIf="!object[field.name].value || object[field.name].value === ('None' || null)"
         class="edit-field-image-selector__image-icon icon-image"></i>
      <div  *ngIf="object[field.name].value && object[field.name].value !== ('None' || null)"
            class="edit-field-image-selector__image-container">
        <app-thumbnail
          [item]="object[field.name]"
          [imageIdField]="'value'"
          [stylingClass]="'image-uploaded-edit'"></app-thumbnail>
        <a class="edit-field-image-selector__image-delete"
           (click)="deleteImage()">
          <i class="icon-trash"></i></a>
      </div>

    </div>
    <div class="edit-field-image-selector__text" >
      <h5 class="bold" translate="TRANS__IMAGE_SELECTOR__DRAG_AND_DROP_FILE_HERE"></h5>
      <span class="edit-field-image-selector__passive-text" translate="TRANS__IMAGE_SELECTOR__OR"></span></div>
  </div>
  <div class="edit-field-image-selector__button">
    <button mat-stroked-button
            type="button"
            color="primary">
      <label for="upload_file">
        <i class="icon-upload"></i>
        <span *ngIf="computer"
              class="bold"
              translate="TRANS__IMAGE_SELECTOR__UPLOAD_IMAGE"></span>
        <span *ngIf="!computer"
              class="bold"
              translate="TRANS__IMAGE_SELECTOR__CHANGE_IMAGE_AND_CAMERA"></span></label>
    </button>
    <input type="file" id="upload_file" (change)="onFileSelect($event)">
  </div>
</div>
