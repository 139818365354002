import {APP_INITIALIZER, ErrorHandler, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {DragulaModule} from 'ng2-dragula';
import {registerLocaleData} from '@angular/common';
import localeNb from '@angular/common/locales/nb';

import {CoreModule} from './core/core.module';
import {SharedModule} from './shared/shared.module';
import {ObjectSearchModule} from './object-search/object-search.module';
import {CmsApiService} from './core/cms-api.service';
import {AccessTokenService} from './core/access-token.service';

import {ObjectEditModule} from './object-edit/object-edit.module';
import {OptionsService} from './core/options.service';
import {LandingPagesModule} from './landing-pages/landing-pages.module';
import {ObjectViewModule} from './object-view/object-view.module';
import {MainMenuModule} from './main-menu/main-menu.module';
import {MediaCarouselModule} from './media-carousel/media-carousel.module';

import {OverviewFieldsModule} from './overview-fields/overview-fields.module';
import {ImageAnnotationModule} from './image-annotation/image-annotation.module';
import {AppComponent} from './app.component';
import {MAT_MOMENT_DATE_ADAPTER_OPTIONS} from '@angular/material-moment-adapter';
// eslint-disable-next-line max-len
import {AdministrationModule} from './administration/administration.module';
import {SpectrumProcedureModule} from './spectrum-procedure/spectrum-procedure.module';
import {ServiceWorkerModule} from '@angular/service-worker';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {environment} from '../environments/environment';
import {InformationModule} from './information-page/information.module';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacySnackBarModule as MatSnackBarModule} from '@angular/material/legacy-snack-bar';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {AppRoutingModule} from './app-routing/app-routing.module';
import {CanDeactivateInformationPage} from './information-page/can-deactivate-information-page';
import {CanDeactivateProcedurePage} from './spectrum-procedure/can-deactivate-procedure-page';
import {CanDeactivateObjectPage} from './object-page/object-page/can-deactivate-object-page';
import {CanDeactivateMediaPage} from './media-page/media-page/can-deactivate-media-page';
import {CanDeactivateObjectNewPage} from './create-object/object-new-page/can-deactivate-object-new-page';
import {ObjectPageModule} from './object-page/object-page.module';
import {MediaPageModule} from './media-page/media-page.module';
import {CreateObjectModule} from './create-object/create-object.module';

import * as Sentry from "@sentry/angular";
import {Router} from "@angular/router";
import { AuthenticatorComponent } from './authenticator/authenticator.component';
import { NotFoundComponent } from './app-routing/not-found/not-found.component';
registerLocaleData(localeNb);

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'https://translation.primus.org/translation_api/translations/', ',10.0.0');
}

@NgModule({
  declarations: [
    AppComponent,
    AuthenticatorComponent,
    NotFoundComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    DragulaModule.forRoot(),
    MatProgressSpinnerModule,
    MatIconModule,
    MatSnackBarModule,
    MatButtonModule,
    CoreModule,
    SharedModule,
    ObjectEditModule,
    ObjectPageModule,
    MediaPageModule,
    CreateObjectModule,
    LandingPagesModule,
    ObjectViewModule,
    ObjectSearchModule,
    MainMenuModule,
    MediaCarouselModule,
    OverviewFieldsModule,
    ImageAnnotationModule,
    AdministrationModule,
    SpectrumProcedureModule,
    InformationModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
      registrationStrategy: 'registerImmediately'
    }),
    SpectrumProcedureModule,
  ],
  providers: [
    CmsApiService,
    AccessTokenService,
    OptionsService,
    CanDeactivateObjectPage,
    CanDeactivateMediaPage,
    CanDeactivateInformationPage,
    CanDeactivateProcedurePage,
    CanDeactivateObjectNewPage,
    {provide: LOCALE_ID, useValue: 'nb'},
    {provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: {useUtc: true, strict: true}},
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(trace: Sentry.TraceService) {
  }
}
