<div class="object-new-copy-view">

  <div *ngFor="let selected of selectorContainer.searchContainer.searchResult.artifacts">
    <span *ngIf="selected.artifact_id === selectorContainer.searchContainer.selections.selectedItems[0].artifact_id"
          class="object-new-copy-view__copy-information">
      <span class="object-new-copy-view__copy-information-headline">
        <span translate="TRANS__CREATE_NEW__COPY_HEADLINE"></span>
        <span>&nbsp;</span>
        <span>{{selected.artifact_name}}</span>
      </span>
      <span class="object-new-copy-view__copy-information-text">
        <span translate="TRANS__CREATE_NEW__COPY_TEXT"></span>
        <span *ngIf="!createMultiple" translate="TRANS__CREATE_NEW__COPY_TEXT_MORE_INFO"></span>
      <span *ngIf="createMultiple" translate="TRANS__CREATE_NEW__COPY_MULTIPLE_TEXT_MORE_INFO"></span>
      </span>
    </span>
  </div>

  <div class="row object-new-copy-view__content">
    <app-object-edit
      *ngIf="object"
      [sectionsContainer]="sectionsContainer"
    ></app-object-edit>
  </div>

</div>
