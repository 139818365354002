import {MediaPageComponent} from './media-page.component';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable()
export class CanDeactivateMediaPage implements CanDeactivate<MediaPageComponent> {
  constructor(private primusRouter: PrimusRouterService) {
  }

  canDeactivate(
    component: MediaPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.primusRouter.checkCancel();
  }

}
