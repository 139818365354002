<div class="notifications-component">
  <ul>
    <li *ngFor="let n of notifications; index as $index" class="{{notType(n)}}">
      <span>
        <span *ngFor="let m of n.messages" [translate]="m" [translateParams]="n.values"></span>
      </span>
      <button (click)="remove($index)"><mat-icon>close</mat-icon></button>
    </li>
  </ul>
</div>
