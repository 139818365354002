<div class="image-full-screen"
     tabindex="0"
     (keyup)="goBackWithKey($event)" #imageFullScreen>
  <div class="image-full-screen__slider">
    <mat-slider min="1" max="2" step="0.1" [value]="zoomValue"
                (change)="onChangeZoomValue($event)"></mat-slider></div>

  <div class="image-full-screen__active-image"
       id="imageFullScreenContainer"
       [ngClass]="{'open': toggleAnnotationPointContainer}">
    <app-active-media
      *ngIf="!toggleAnnotations && container"
      [activeMedia]="mediaContainer.currentMediaType.mediaElements[mediaContainer.currentMediaType.currentMediaIndex].mediaObject"
      [zoomValue]="zoomValue"
      [container]="container"></app-active-media>
    <app-active-annotate-image
      *ngIf="toggleAnnotations && curAnn.annotateImage"
      [curAnn]="curAnn"
      [imgIndex]="mediaContainer.currentMediaType.currentMediaIndex"
      [zoomValue]="zoomValue"
      [lockHeight]="false"
      [largeImg]="true"
      [container]="container"
      [edit]="false"></app-active-annotate-image>
  </div>

  <a class="image-full-screen__annotation-points-button"
     *ngIf="toggleAnnotations"
     (click)="toggleAnnotationPoints()"
     [ngClass]="{'open': toggleAnnotationPointContainer}">
    <mat-icon>{{toggleAnnotationPointContainer ? 'navigate_before' : 'navigate_next'}}</mat-icon></a>

  <div *ngIf="toggleAnnotationPointContainer"
       class="image-full-screen__annotation-points-slideIn">
    <span class="image-full-screen__annotation-points-title"
          translate="TRANS__ANNOTATION__ANNOTATION_POINTS"></span>
    <ul class="image-full-screen__annotation-points-items">
      <li class="image-full-screen__annotation-points-item"
          *ngFor="let annotation of curAnn.annotations; index as i">
        <span>{{i + 1}}. {{annotation.description.description}}</span>
      </li>
    </ul>

  </div>

  <button mat-icon-button class="image-full-screen__close" (click)="goBack()">
    <mat-icon>close</mat-icon>
  </button>
</div>

