import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest, HttpResponse} from '@angular/common/http';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {CmsApiService} from './cms-api.service';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {AppNotification, NotificationService} from '../shared/notification.service';
import {AConst} from './a-const.enum';
import {Image} from './definitions/image';
import User from '../administration/admin-users/User';
import {ImageItem} from './definitions/image-item';
import {ContextList} from './definitions/context-list';
import {SuperObjectModel} from './definitions/super-object-model';
import {UrlData} from './definitions/url-data';
import {GetArtifactParams} from './definitions/get-artifact-params';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private readonly http: HttpClient,
              private readonly cms: CmsApiService,
              private readonly modalService: MatDialog,
              private readonly notificationService: NotificationService) {
  }

  async uploadFiles(container, files, uploadType, parentObject): Promise<Array<SuperObjectModel>> {
    let res = [];
    if (files) {
      const waitParams = {
        downCount: files.length,
        maxWaits: 2000,
        container: container,
        result: [],
      };
      const progressModal = this.modalService.open(ProgressDialogComponent, {
        disableClose: true,
        panelClass: 'progress-modal'
      });
      for (const file of files) {
        let urlInfo;
        try {
          urlInfo = await this.getUploadURl(uploadType, file.name);
        } catch (e) {
          console.log('Getting url info failed');
        }
        if (!urlInfo) {
          progressModal.close();
          return res;
        }
        const options = {
          headers: new HttpHeaders({
            Slug: file.name,
            'Content-Type': file.type
          })
        };
        this.http.request(new HttpRequest('PUT', urlInfo.url, file, options)).subscribe(
          async event => {
            if (event instanceof HttpResponse) {
              const mediaObject = await this.cms.getArtifact({artifact_id: urlInfo.artifact_id} as GetArtifactParams);
              if (parentObject?.object_type === 'user' && mediaObject) {
                await this.handleUploadProfileImage(mediaObject as Image, parentObject as User);
              }
              mediaObject.$$uploading = true;
              waitParams.result.push(mediaObject);
              waitParams.downCount--;
            }
          },
          err => {
            console.error('Upload Error:', err);
            this.notificationService.addNotification(new AppNotification(
              ['TRANS__UPLOAD_SERVICE__UPLOAD_ERROR'], 'error'));
            progressModal.close();
          });
      }
      res = await this.waitForUpload(waitParams);
      progressModal.close();
    }
    return res;
  }

  private async getUploadURl(uploadType: string, fileName: string): Promise<UrlData> {
    let res;
    switch (uploadType) {
      case 'image':
        res = await this.cms.getImageUploadUrl({fileName: fileName});
        break;
      case 'video':
        res = await this.cms.getVideoUploadUrl({fileName: fileName});
        break;
      case 'attachment':
        res = await this.cms.getAttachmentUploadUrl({fileName: fileName});
        break;
      case 'audio':
        res = await this.cms.getAudioUploadUrl({fileName: fileName});
        break;
      default:
        console.warn(`Unsupported upload type ${uploadType}`);
    }
    return res;
  }

  private async handleUploadProfileImage(image: Image, user: User): Promise<void> {
    const userImageRelationItem: ImageItem = await this.cms.createArtifact({object_type: 'ImageItem'}) as ImageItem;
    if (!userImageRelationItem) {
      console.error('Unable to create Image-User-relation:', userImageRelationItem);
      return;
    }

    const subArtifactList = new ContextList();

    if (user?.images?.length > 0) {
      user.images.filter(i => !!i.image_id).forEach(i => i._destroy = true);
      subArtifactList.contexts.push(...user.images);
    }

    userImageRelationItem._create = true;
    userImageRelationItem[AConst.CONTEXT_ID] = user.artifact_id;
    userImageRelationItem.image_id = image.artifact_id;

    subArtifactList.contexts.push(userImageRelationItem);

    await this.cms.saveSubArtifacts(subArtifactList);
  }

  private async waitForUpload(params, origResolve?): Promise<Array<SuperObjectModel>> {
    return new Promise(resolve => {
      setTimeout(() => {
        origResolve = origResolve || resolve;
        params.maxWaits--;
        if (params.downCount && params.maxWaits) {
          this.waitForUpload(params, origResolve).then();
        } else {
          if (params.downCount) {
            console.warn('Waited too long. Stopping now');
            params.container.progress = 1;
          }
          origResolve(params.result);
        }
      }, 100);
    });
  }

}
