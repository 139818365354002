<div class="image-carousel-annotation-points"
     [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen,
     'showMoreAnn': showAllAnnotationPoints}">
  <ul *ngIf="activeAnnotation" id="annotationPoints">
    <li *ngFor="let annotation of curAnn.annotations; index as i">
      <a *ngIf="activeAnnotationRef[i]"
         class="text-blue"
         [routerLink]="activeAnnotationRef[i].routerLink"
         [queryParams]="activeAnnotationRef[i].queryParams"
         queryParamsHandling="merge">
        <span>{{i + 1}}. {{annotation.description.description}}</span>
      </a>
    </li>
  </ul>

  <a class="image-carousel-annotation-points__create text-blue-hover"
     *ngIf="activeAnnotationRefCreate"
     [routerLink]="activeAnnotationRefCreate.routerLink"
     [queryParams]="activeAnnotationRefCreate.queryParams"
     queryParamsHandling="merge">
    <i class="icon-plus"></i>
    <span>{{'TRANS__IMAGE_CAROUSEL__CREATE_NEW_ANNOTATION' | translate}}</span></a>

  <a *ngIf="isOverflown"
     (click)="toggleAllAnnotationPoints()"
     class="image-carousel-annotation-points__showOverflow">
    <div class="image-carousel-annotation-points__showOverflow-container">
      <div class="image-carousel-annotation-points__showOverflow-content text-blue">
        <mat-icon>{{showAllAnnotationPoints ? 'expand_less' : 'expand_more'}}</mat-icon>
      </div>
    </div>
  </a>
</div>
