import {Component, Input, OnChanges, Output, EventEmitter} from '@angular/core';
import {SearchSelectorItem} from '../edit-field-search-selector-multiple.component';
import {SectionsContainer} from '../../../core/definitions/sections-container';
import {CdkDragDrop} from '@angular/cdk/drag-drop';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {BaseModel} from '../../../core/definitions/base-model';
import {Reference} from "../../../core/definitions/reference";

@Component({
  selector: 'app-edit-field-search-selector-multiple-item-list',
  templateUrl: './edit-field-search-selector-multiple-item-list.component.html',
  styleUrls: ['./edit-field-search-selector-multiple-item-list.component.scss']
})
export class EditFieldSearchSelectorMultipleItemListComponent implements OnChanges {
  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() items: SearchSelectorItem[];

  @Output() itemDelete = new EventEmitter<number>();
  @Output() itemEdit = new EventEmitter<SearchSelectorItem>();
  @Output() itemDrop = new EventEmitter<CdkDragDrop<SearchSelectorItem[]>>();

  itemsWithLimit: SearchSelectorItem[] = [];

  ngOnChanges(): void {
    this.setItemsWithLimit();
  }

  get sectionsContainer(): SectionsContainer {
    return this.fieldParameters.sectionsContainer;
  }

  get object(): BaseModel {
    return this.fieldParameters.object;
  }

  setItemsWithLimit() {
    const items = this.items;
    let res: SearchSelectorItem[];
    if (this.reference.search_display_limit) {
      res = this.items.splice(Math.max(items.length - this.reference.search_display_limit, 0));
    } else {
      res = items;
    }
    this.itemsWithLimit = res;
  }

  drop(event: CdkDragDrop<SearchSelectorItem[]>) {
    this.itemDrop.emit(event);
  }

  deleteItemByIndex(index) {
    this.itemDelete.emit(index);
  }

  editItem(item: SearchSelectorItem) {
    this.itemEdit.emit(item);
  }

}
