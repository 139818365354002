import {Component, EventEmitter, Input, Output, OnChanges} from '@angular/core';
import {FieldParameters} from '../../../core/definitions/field-parameters';
import {HierarchicNode} from '../../../core/definitions/hierarchic-objects';
import {FlatTreeControl} from '@angular/cdk/tree';
import {OptionsService} from '../../../core/options.service';
import {BehaviorSubject, merge, Observable} from 'rxjs';
import {FieldType} from '../../../core/definitions/field-type.enum';
import {OptionsDialogService} from '../../options-dialog.service';
import {CollectionViewer, SelectionChange} from '@angular/cdk/collections';
import {map} from 'rxjs/operators';
import {DataSource} from '@angular/cdk/table';
import {Reference} from "../../../core/definitions/reference";

export class DynamicHierarchicNode {
  level: number;

  $$description: string;
  $$name: string;
  expandable: boolean;

  isLoading: boolean;
  childrenSet: boolean;
  authority: string;
  parentNode: DynamicHierarchicNode;

  constructor(public hierarchicNode: HierarchicNode, nodeDisplayField: string) {
    this.level = hierarchicNode.level;

    this.$$description = hierarchicNode['description.description'];
    this.$$name = hierarchicNode[nodeDisplayField] || hierarchicNode['name.name'];
    this.expandable = !!hierarchicNode.children && hierarchicNode.children.length > 0;
  }
}

export class DynamicHierarchicDataSource implements DataSource<DynamicHierarchicNode> {
  dataChange = new BehaviorSubject<DynamicHierarchicNode[]>([]);

  get data(): DynamicHierarchicNode[] {
    return this.dataChange.value;
  }

  set data(value: DynamicHierarchicNode[]) {
    this.treeControl.dataNodes = value;
    this.dataChange.next(value);
  }

  constructor(private treeControl: FlatTreeControl<DynamicHierarchicNode>,
              private optionsService: OptionsService,
              private nodeDisplayField) {
  }

  connect(collectionViewer: CollectionViewer): Observable<DynamicHierarchicNode[]> {
    this.treeControl.expansionModel.changed.subscribe(change => {
      if (change.added || change.removed) {
        this.handleTreeControl(change);
      }
    });
    return merge(collectionViewer.viewChange, this.dataChange).pipe(map(() => this.data));
  }

  disconnect(): void {
    // Nothing to do here, really
  }

  handleTreeControl(change: SelectionChange<DynamicHierarchicNode>) {
    if (change.added) {
      change.added.forEach(node => this.toggleNode(node, true));
    }
    if (change.removed) {
      change.removed.slice().reverse().forEach(node => this.toggleNode(node, false));
    }
  }

  async toggleNode(parentNode: DynamicHierarchicNode, expand: boolean) {
    // For some reason, the SelectionChange event is called twice, thus the need for the following if.
    if (parentNode.isLoading || (expand && parentNode.childrenSet) || (!expand && parentNode.childrenSet === false)) {
      return;
    }
    if (!parentNode.hierarchicNode.children.length) {
      return;
    }
    if (expand) {
      parentNode.isLoading = true;
      if (!parentNode.hierarchicNode.$$grandChildrenSet) {
        await this.setGrandChildren(parentNode);
      }
      this.addNodes(parentNode, null);
      parentNode.isLoading = false;
    } else {
      this.removeNodes(parentNode, true);
    }
    parentNode.childrenSet = expand;
  }

  async setGrandChildren(parentNode: DynamicHierarchicNode) {
    // Getting 'grand children' nodes, this is necessary in order for the 'expandable' checks to work
    await this.optionsService.setGrandChildren(parentNode.hierarchicNode, this.nodeDisplayField);
    parentNode.hierarchicNode.$$grandChildrenSet = true;
  }

  removeNodes(parentNode: DynamicHierarchicNode, applyChanges: boolean) {
    const index = this.data.indexOf(parentNode);
    let count = 0;
    for (let i = index + 1; i < this.data.length && this.data[i].level > parentNode.level; i++) {
      count++
    }
    this.data.splice(index + 1, count);
    if (applyChanges) {
      // notify the change
      this.dataChange.next(this.data);
    }
  }

  addNodes(parentNode: DynamicHierarchicNode, rootNode: HierarchicNode) {
    if (!parentNode) {
      this.dataChange.next(rootNode.children.map(child => new DynamicHierarchicNode(child, this.nodeDisplayField)));
      return;
    }
    const index = this.data.indexOf(parentNode);
    const dynamicChildren = parentNode.hierarchicNode.children.map(child => new DynamicHierarchicNode(child, this.nodeDisplayField));
    const lastChild = dynamicChildren[dynamicChildren.length - 1];
    if (lastChild.hierarchicNode.$$nodeType === 'loadMoreNode') {
      lastChild.parentNode = parentNode;
    }
    this.data.splice(index + 1, 0, ...dynamicChildren);
    // notify the change
    this.dataChange.next(this.data);
  }
}

@Component({
  selector: 'app-hierarchic-list-panel',
  templateUrl: './hierarchic-list-panel.component.html',
  styleUrls: ['./hierarchic-list-panel.component.scss']
})
export class HierarchicListPanelComponent implements OnChanges {

  @Input() fieldParameters: FieldParameters;
  @Input() reference: Reference;
  @Input() panelHasBeenOpened: boolean;
  @Output() nodeSelected = new EventEmitter<HierarchicNode>();
  @Output() nodeOpened = new EventEmitter<HierarchicNode>();

  rootNode: HierarchicNode;
  searching = false;
  isArray: boolean;
  treeControl: FlatTreeControl<DynamicHierarchicNode>;
  dataSource: DynamicHierarchicDataSource;

  private pageSize = 20;
  private nodeDisplayField: string;

  constructor(private optionsService: OptionsService,
              private optionsDialogService: OptionsDialogService) {
  }

  ngOnChanges(): void {
    if (!this.treeControl) {
      this.treeControl = new FlatTreeControl<DynamicHierarchicNode>(this.getLevel, this.isExpandable);
    }
    if (this.panelHasBeenOpened && !this.rootNode) {
      this.initPanel().then();
    }
  }

  onNodeSelected(node: DynamicHierarchicNode, isLeafNode: boolean) {
    if (!isLeafNode && this.reference.hierarchic_parent_select_forbidden) {
      if (!this.treeControl.isExpanded(node)) {
        this.treeControl.expand(node);
        this.onNodeOpened(node);
      } else {
        this.treeControl.collapse(node);
      }
    } else {
      this.nodeSelected.emit(node.hierarchicNode);
    }
  }

  onNodeOpened(node: DynamicHierarchicNode) {
    this.nodeOpened.emit(node.hierarchicNode);
  }

  onNodeLoadMore(loadMoreNode: DynamicHierarchicNode) {
    this.nextNodes(loadMoreNode).then();
  }

  async nextNodes(loadMoreNode: DynamicHierarchicNode) {
    const page = Math.floor(loadMoreNode.hierarchicNode.$$rows / this.pageSize);
    loadMoreNode.isLoading = true;
    await this.optionsService.addHierarchicNodes(
      loadMoreNode.hierarchicNode.$$parentNode,
      this.fieldParameters,
      this.reference,
      page,
      this.pageSize,
      loadMoreNode.hierarchicNode.parent_id,
      loadMoreNode.level);
    this.setSelectedHierarchicNodes();
    if (loadMoreNode.parentNode) {
      this.dataSource.removeNodes(loadMoreNode.parentNode, false);
      await this.dataSource.setGrandChildren(loadMoreNode.parentNode);
    }
    this.dataSource.addNodes(loadMoreNode.parentNode, this.rootNode);
    loadMoreNode.isLoading = false;
  }

  getLevel = (node: DynamicHierarchicNode) => node.level;

  isExpandable = (node: DynamicHierarchicNode) => node.expandable;

  hasChild = (_: number, node: DynamicHierarchicNode) => node.expandable;

  isLoadMore = (_: number, _nodeData: DynamicHierarchicNode) => _nodeData.hierarchicNode.$$nodeType === 'loadMoreNode';

  // Will be called upon by parent component, must thus be public
  setNodeIsSelectedById(idFieldValue, isSelected) {
    this.optionsService.setNodeIsSelectedById(this.rootNode, idFieldValue, isSelected);
  }

  async setNodes() {
    this.searching = true;
    this.rootNode = new HierarchicNode();
    await this.optionsService.addHierarchicNodes(
      this.rootNode, this.fieldParameters, this.reference, 0, this.pageSize, null, 1);
    this.setSelectedHierarchicNodes();
    this.dataSource.data = this.rootNode.children.map(hierarchicNode => new DynamicHierarchicNode(hierarchicNode, this.nodeDisplayField));
    this.searching = false;
  }

  private async initPanel() {
    this.nodeDisplayField = await this.optionsService.getNodeDisplayField(this.reference, 'name.name');
    this.dataSource = new DynamicHierarchicDataSource(this.treeControl, this.optionsService, this.nodeDisplayField);
    this.isArray = this.fieldParameters.field.field_type === FieldType.ARRAY;
    this.searching = true;
    // Should give time for the spinner to be displayed
    setTimeout(() => {
      this.setNodes().then();
    }, 50)
  }

  private setSelectedHierarchicNodes() {
    this.optionsService.setSelectedHierarchicNodes(this.rootNode, this.fieldParameters);
  }

  openDescription(node) {
    this.optionsDialogService.toggleDescription(node);
  }
}
