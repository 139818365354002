import {Injectable} from '@angular/core';
import {
  CheckFilter, CheckFilterGroup,
  Facet,
  FacetCount,
  FacetItem,
  SearchViewMenu
} from '../core/definitions/search-objects';
import {SearchContainer} from '../core/definitions/search-container';

@Injectable({
  providedIn: 'root'
})
export class SearchFacetService {

  constructor() {
  }

  setFacetCount(searchContainer: SearchContainer) {
    const sr = searchContainer.searchResult;
    this.resetFacetCounts(searchContainer);
    for (const facet of sr.facets) {
      const facetName = facet.f_name;
      const facetCount = this.getFacetCount(facetName, searchContainer);
      facetCount.totalCount = facet.total_count;
      this.setFacetItemCounts(facet, facetCount);
    }
  }

  getMenuCount(menu: SearchViewMenu, searchContainer: SearchContainer) {
    let facetItems: FacetItem[], res = 0;
    const facet = this.getSearchFacet(new CheckFilter(menu.facet, null), searchContainer);
    if (facet) {
      facetItems = this.getFacetItems(facet, menu.facet_values);
      if (facetItems.length) {
        res = facetItems.map(item => item.count).reduce((previousValue, currentValue) => previousValue + currentValue);
      }
    }
    return res ? res : 0;
  }

  private getFacetItems(facet: Facet, facetValues: any[]): FacetItem[] {
    let res = [];
    for (const facetValue of facetValues) {
      res = res.concat(facet.items.filter(item => facetValue === item.id || facetValue === item.name));
    }
    return res;
  }

  private setFacetItemCounts(facet: Facet, facetCount: FacetCount) {
    for (const item of facet.items) {
      const name = item.id ? item.id : item.name;
      facetCount.itemCounts[name] = item.count;
      if (item.id === 'true') {
        facetCount.trueCount = item.count;
      } else if (item.id === 'false' && !facetCount.trueCount) {
        facetCount.trueCount = 0;
      }
    }
  }

  private resetFacetCounts(searchContainer: SearchContainer) {
    for (const facetCount of Object.values(searchContainer.filtersFacets.facetCount)) {
      for (const itemCountsKey in facetCount.itemCounts) {
        if (facetCount.itemCounts.hasOwnProperty(itemCountsKey)) {
          facetCount.itemCounts[itemCountsKey] = 0;
        }
      }
      facetCount.totalCount = 0;
      facetCount.trueCount = 0;
    }
  }

  getTotalCount(filterGroup: CheckFilterGroup, searchContainer: SearchContainer): number {
    let res;
    if (filterGroup.filterNames.length === 1) {
      res = this.getFacetCount(filterGroup.filterNames[0], searchContainer).totalCount;
    } else {
      // This is used with the "has admin event" filter in order to get only "true" counts per admin event type.
      res = filterGroup.filterNames.map(filterName => this.getFacetCount(filterName, searchContainer).trueCount).reduce(
        (trueCount, c) => trueCount + c, 0);
    }
    return res;
  }

  getFacetCount(facetName: string, searchContainer: SearchContainer): FacetCount {
    if (!searchContainer.filtersFacets.facetCount[facetName]) {
      searchContainer.filtersFacets.facetCount[facetName] = {
        totalCount: 0,
        trueCount: 0,
        itemCounts: {}
      };
    }
    return searchContainer.filtersFacets.facetCount[facetName];
  }

  getSearchFacet(filter: CheckFilter, searchContainer: SearchContainer): Facet {
    let res;
    const sr = searchContainer.searchResult;
    const facetName = filter.name;
    if (sr && sr.facets) {
      const facets = sr.facets;
      for (const facet of facets) {
        if (facet.f_name === facetName) {
          res = facet;
          break;
        }
      }
    }
    if (!res) {
      console.warn('Facet named \'' + facetName + '\' not found for path view ' + searchContainer.path);
    }
    return res;
  }


}
