<div class="search-filter-group"
     [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">
  <button mat-button (click)="toggle()" class="search-filter-group__title-button">
    <span class="search-filter-group__title-button-text-container">
      <span class="search-filter-group__title-button-text"
            [ngClass]="{'bold': sideMenu}"
            [translate]="filterGroup.title"></span>
      <span class="search-filter-group__title-button-count">({{getTotalCount()}})</span>
    </span>
    <mat-icon *ngIf="!sideMenu">{{showFilterItem ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

  <div *ngIf="showFilterItem" class="dropdownIgnoreClicks">
    <div class="search-filter-group__input" *ngIf="hasManyFilters">
      <input type="text"
             [placeholder]="'TRANS__FILTER__SEARCH_PLACEHOLDER' | translate"
             [(ngModel)]="filterGroup.filterFilter.query"
             (ngModelChange)="queryChanged()"
             class="search-input">
      <mat-icon class="search-icon">search</mat-icon>
    </div>

    <ul class="search-filter-group__items">
      <li class="search-filter-group__item filter-checkbox"
          *ngFor="let filter of filterGroup.visibleFilters; index as i">
        <mat-checkbox (click)="checkFilter($event, filter)"
                      [checked]="filter.checked"
                      class="search-filter-group__item-checkbox">

          <span class="search-filter-group__item-text-container" [ngClass]="{'bold': filter.checked}">
            <span *ngIf="filter.title"
                  class="search-filter-group__item-text"
                  [translate]="filter.title"></span>
            <span *ngIf="filter.noTransTitle" class="search-filter-group__item-text" [innerHTML]="filter.noTransTitle"></span></span>

          <span class="search-filter-group__item-text-count" *ngIf="filter.count">({{filter.count}})</span>
        </mat-checkbox>
      </li>

      <li class="search-filter-group__item"
          *ngIf="filterGroup.filterFilter.hasMore">
        <button mat-button (click)="showMore()" class="search-filter-group__showMore">
          <span>...</span>
          <span [translateParams]="{count: filterGroup.filterFilter.filterCount - filterGroup.filterFilter.showMoreCount }"
                translate="TRANS__FILTER__SHOW_MORE"></span>
        </button>
      </li>
    </ul>

  </div>
</div>
