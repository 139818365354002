import {SuperobjectCollection} from './superobject-collection';
import {Status} from './status';
import {ImageItem} from './image-item';
import {BaseModel} from './base-model';
import {Context} from './context';
import {SuperobjectName} from './superobject-name';
import {RectoVerso, VersoRecto} from './recto-verso';
import {ObjectMediaContainer} from './object-media-container';
import {SuperobjectFolderItem} from './super-object-folder-item';
import {ObjectUsage} from './object-usage';

export class SuperObjectModel extends BaseModel {
  $$mediaContainer: ObjectMediaContainer;
  $$annotateImage: boolean;
  $$canAnnotate: boolean;
  $$id: string;
  read_only: boolean;
  $$mediaPlaybackUrls: any[];
  $$thumbnailUrl;
  $$uploading = false;
  $$uploadProgress;
  $$uploadTimeoutId;
  $$uploadWaitCancel = false;
  object_usage: ObjectUsage[];

  adm_events: BaseModel[];
  superobject_type_id: string;
  superobject_type_id_value: string;
  artifacts: Array<SuperObjectModel>; // Used in spectrum procedures
  collection: SuperobjectCollection;
  context: Context; // Used by admin events
  contexts: Array<Context>;
  description: any = {};
  // description = new SuperObjectDescription();
  identifier: string;
  identification_number: any;
  images: Array<ImageItem>;
  name: SuperobjectName;
  parent_id: string; // Used by admin events
  super_artifact_id: string;
  thumbnail_id: string;
  thumbnail_url: string;
  status: Status;
  recto_verso: RectoVerso;
  verso_recto: VersoRecto;
  folders: SuperobjectFolderItem[];

  create_multiple: boolean;
  create_multiple_count: number;

  operation_contexts: any[];
  operation_contexts_restricted: any[];

}
