<div class="change-media-order-media-type" #mediaElement>
  <div class="change-media-order-media-type__items"
       [dragula]="objectMediaType.mediaType"
       [(dragulaModel)]="mediaItems">
    <mat-card class="change-media-order-media-type__item"
              *ngFor="let mediaItem of mediaItems; let i = index">

      <div mat-card-image class="change-media-order-media-type__item-img-container">
        <img id='media-{{i}}' alt="" [src]="mediaItem.$$imageUrl">
        <div class="change-media-order-media-type__item-order-number bold">
          <span>{{mediaItem.order_number + 1}}</span></div></div>

      <div class="bold change-media-order-media-type__item-name">
        <i class="icon-{{objectMediaType.mediaType.toLowerCase()}}"></i>
        <span>{{mediaItem.$$mediaName}}</span></div>

      <mat-card-content class="small-mat-form-outline">
      </mat-card-content>
    </mat-card>
  </div>
</div>
