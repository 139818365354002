import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlValueAccessor, UntypedFormControl, NG_VALUE_ACCESSOR} from '@angular/forms';
import {DateToolsService} from '../../core/date-tools.service';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import {FieldValidationService} from '../../core/field-validation.service';


@Component({
  selector: 'app-edit-field-date-picker',
  templateUrl: './edit-field-date-picker.component.html',
  styleUrls: ['./edit-field-date-picker.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldDatePickerComponent)
    }
  ]
})
export class EditFieldDatePickerComponent implements OnInit, ControlValueAccessor {

  @Input() fieldParameters;
  @Input() field: any;
  @Input() otherField: any;
  @Input() formControlName: string;
  @Input() otherFormControlName: string;
  @Input() isToDate: boolean;
  @Input() placeholder: string;

  dateControl: AbstractControl;
  otherDateControl: AbstractControl;
  pickerDateControl: UntypedFormControl;
  private inFocus;

  constructor(private readonly dateTools: DateToolsService,
              private readonly fieldValidation: FieldValidationService) {
  }

  ngOnInit() {
    const isoDate = this.fieldParameters.object[this.field.name];
    const fieldDateValue = this.dateTools.isoDateStringToUtcDate(isoDate);
    this.pickerDateControl = new UntypedFormControl(fieldDateValue);
    this.dateControl = this.fieldParameters.sectionsContainer.formGroup.controls[this.formControlName];
    if (this.otherFormControlName) {
      this.otherDateControl = this.fieldParameters.sectionsContainer.formGroup.controls[this.otherFormControlName];
    }
  }

  isInvalid() {
    return this.fieldValidation.isFieldInvalid(this.fieldParameters.sectionsContainer, this.formControlName);
  }

  onFieldBlur() {
    this.inFocus = false;
  }

  onFieldFocus() {
    if (!this.fieldParameters.sectionsContainer.isDialog) {
      this.inFocus = true;
      if (this.isToDate && this.dateControl.value === null) {
        const fromDate = this.fieldParameters.object[this.otherField.name];
        if (fromDate) {
          this.fieldParameters.object[this.field.name] = fromDate;
          this.pickerDateControl.setValue(this.dateTools.isoDateStringToUtcDate(fromDate));
        }
      }
    }
  }

  onValueChange($event) {
    const value = $event.target.value;
    const validDate = this.dateTools.validateDate(value);
    if (!validDate.dd_error_message) {
      this.fieldParameters.object[this.field.name] = validDate.isoDate;
      this.dateControl.setValue(this.dateTools.isoDateToString(validDate.isoDate));
      if (validDate && this.otherDateControl) {
        this.otherDateControl.updateValueAndValidity();
      }
    } else {
      // This will trigger validations
      this.dateControl.setValue($event.target.value);
    }
  }

  onDateSelection(dateEvent: MatDatepickerInputEvent<Date>) {
    this.dateControl.markAsDirty();
    if (dateEvent.value) {
      // Adding 1 second to date in order to avoid issues with date facet ranges returning too many objects
      const isoDate = this.dateTools.dateToIsoString(dateEvent.value['_d']).replace('00:00:00', '00:00:01');
      this.fieldParameters.object[this.field.name] = isoDate;
      this.dateControl.setValue(this.dateTools.isoDateToString(isoDate));
    }
  }

  get minDate() {
    let minDate = new Date(-5000, 0, 1);
    if (this.isToDate) {
      const otherFieldVal = this.fieldParameters.object[this.otherField.name];
      if (otherFieldVal) {
        minDate = this.dateTools.isoDateStringToUtcDate(otherFieldVal);
      }
    }
    return minDate;
  }

  get maxDate() {
    let maxDate = new Date(5000, 0, 1);
    if (!this.isToDate && this.otherField) {
      const otherFieldVal = this.fieldParameters.object[this.otherField.name];
      if (otherFieldVal) {
        maxDate = this.dateTools.isoDateStringToUtcDate(otherFieldVal);
      }
    }
    return maxDate;
  }

  registerOnChange(fn: any): void {
  }

  registerOnTouched(fn: any): void {
  }

  setDisabledState(isDisabled: boolean): void {
  }

  writeValue(obj: any): void {
  }

}
