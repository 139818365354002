import {Injectable} from '@angular/core';
import {ModelsService} from './models.service';
import {AConst} from './a-const.enum';
import {CommonsService} from './commons.service';
import {FieldParameters} from './definitions/field-parameters';
import {MetaField} from './definitions/meta-field';
import {Reference} from './definitions/reference';
import {OverviewField} from './definitions/object-view';
import {Inline} from './definitions/inline';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';

export class MetaPropParams {
  metaPropName: string;
  fieldName: string;
  parentFieldName: string;
  parentModel: BaseModel;
  grandParentModel: BaseModel;
  rootModel: SuperObjectModel;
  noWarn: boolean;
}

export class GetMetaFieldParams {
  fieldId: string;
  parentModel: BaseModel;
  propName: string;
  noThrow: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class FieldMetaHandlerService {

  constructor(private models: ModelsService, private commons: CommonsService) {
  }

  // private callbacks = {};
  private cachedMetaProps = {};

  private static getPathItem(path, itemIndex) {
    let pathSplit, res = null;
    if (path) {
      pathSplit = path.split('.');
      if (itemIndex < pathSplit.length) {
        res = pathSplit[itemIndex];
      } else {
        console.warn('Wrong path index: ' + itemIndex +
          ' for ' + path);
      }
    }

    return res;
  }

  private static genMetaFromDynamicField(metaFieldIn: MetaField): MetaField {
    const metaField: MetaField = {} as MetaField;
    metaField.order = metaFieldIn.order;
    metaField.name = 'dyn-field-' + metaFieldIn.field_uuid;
    // metaField.display = 'pri';
    metaField.edit = 'edit';
    metaField.title = metaFieldIn.name;
    metaField.input_type = null;
    metaField.reference = new Reference();
    // meta.reference.parent_ref_type = null;

    if (metaFieldIn.field_type === 'text') {
      metaField.field_type = 'string';
      metaField.input_type = 'input';
    } else if (metaFieldIn.field_type === 'list') {
      metaField.field_type = AConst.MAP_ID;
      metaField.input_type = AConst.MAP_ID;
      // meta.reference.object_type = field.concept_type_id;
      // meta.parent_ref_type = field[AConst.PARENT_CONCEPT_TYPE_ID];
    } else {
      throw new Error('Unknown field type \'' + metaFieldIn.field_type +
        '\'');
    }
    return metaField;
  }

  private static getMetaFieldValueFromRoot(params: MetaPropParams): any {
    let metaField: MetaField, res;
    if (params.parentFieldName) {
      metaField = params.rootModel.$$meta[params.parentFieldName];
      if (metaField) {
        res = metaField[params.metaPropName];
      }
    }
    return res;
  }

  /**
   * Get the meta properties belonging to a field, using information
   * in a field container
   * @param fieldParameters container with information about a specific field within a model structure
   * @param parentLevel set to "current" if not set.
   * If set to "parent", return meta properties for the parent level.
   * If set to "root", return meta properties for the root level
   * @param noThrow set to true to avoid exceptions if meta missing
   * @returns {*}
   */
  public getMetaParamsFromFieldParameters(fieldParameters: FieldParameters, parentLevel?, noThrow?): MetaField {
    let res: MetaField, fieldName, parentName;
    const getMetaFieldParams = new GetMetaFieldParams();
    getMetaFieldParams.fieldId = fieldParameters.field.key;
    getMetaFieldParams.noThrow = noThrow;
    parentLevel = parentLevel || 'current';
    switch (parentLevel) {
      case 'current':
        getMetaFieldParams.parentModel = fieldParameters.object;
        getMetaFieldParams.propName = fieldParameters.field.name;
        res = this.getMetaField(getMetaFieldParams);
        break;
      case 'parent':
        if (!fieldParameters.grandParentObject && !noThrow) {
          console.warn('No grandparent model');
        } else {
          parentName = fieldParameters.field.parent_name || fieldParameters.field.path;
          getMetaFieldParams.parentModel = fieldParameters.grandParentObject;
          getMetaFieldParams.propName = parentName;
          res = this.getMetaField(getMetaFieldParams);
        }
        break;
      case 'root':
        if (!fieldParameters.rootObject && !noThrow) {
          console.warn('No root model');
        } else {
          fieldName = FieldMetaHandlerService.getPathItem(
            fieldParameters.field.path, 0);
          if (fieldName) {
            getMetaFieldParams.parentModel = fieldParameters.rootObject;
            getMetaFieldParams.propName = fieldName;
            res = this.getMetaField(getMetaFieldParams);
          }
        }
        break;
      default:
        console.warn('Unknown level ' + parentLevel);
    }
    return res;
  }


  public getMetaField(params: GetMetaFieldParams): MetaField {
    let meta, metaField: MetaField;
    const parentModel = params.parentModel, propName = params.propName;
    const noThrow = params.noThrow;
    if (parentModel) {
      meta = parentModel.$$meta;
      if (meta) {
        metaField = this.getParamsFromMeta(meta, params);
      } else {
        if (parentModel[AConst.FIELD_UUID]) {
          metaField = FieldMetaHandlerService.genMetaFromDynamicField(parentModel.$$meta[AConst.FIELD_UUID]);
        } else {
          metaField = this.getMetaFromModels(parentModel, propName, noThrow);
        }
      }
    }
    return metaField;
  }

  /**
   * Get a named meta property belonging to an object field within
   * a parent object. If meta property not found, try to search for
   * the meta property within the grandparent model, if provided
   *
   * @param params
   * @param params.metaPropName
   * @param params.fieldName
   * @param params.parentFieldName,
   * @param params.parentModel
   * @param params.grandParentModel
   * @param params.rootModel
   * @param params.noWarn
   * @returns {*}
   */
  public getMetaFieldValue(params: MetaPropParams): any {
    const metaPropName = params.metaPropName;
    const fieldName = params.fieldName;
    const parentModel = params.parentModel;
    const grandParentModel = params.grandParentModel;

    let metaField: MetaField;
    let res = null;
    let myParent: BaseModel;
    // noinspection SuspiciousTypeOfGuard
    if (Array.isArray(parentModel) && typeof fieldName === 'number') {
      metaField = parentModel.$$meta[fieldName];
      myParent = parentModel[fieldName];
    } else {
      const getMetaFieldParams = new GetMetaFieldParams();
      getMetaFieldParams.parentModel = parentModel;
      getMetaFieldParams.propName = fieldName;
      getMetaFieldParams.noThrow = true;
      metaField = this.getMetaField(getMetaFieldParams);
      myParent = parentModel;
    }
    if (metaField) {
      res = metaField[metaPropName];
    }
    if (!res && grandParentModel) {
      res = this.getMetaFieldValueFromAncestor(grandParentModel, myParent, metaPropName);
    }
    if (!res && params.rootModel) {
      res = FieldMetaHandlerService.getMetaFieldValueFromRoot(params);
    }
    if (!params.noWarn && res === null &&
      metaPropName.indexOf('_') !== 0) {
      console.warn('No meta prop \'' + metaPropName +
        '\' found. Should be defined in ' +
        myParent.object_type + '.' + fieldName +
        ' or in grand parent model');
    }
    return res;
  }

  public searchGetMetaProp(mod: SuperObjectModel, propName, metaPropName): any {
    let metaProp, searchRes, searchKey;
    const objType = mod.object_type;
    if (!objType) {
      console.warn('Missing object type creating search key!');
    } else {
      searchKey = objType + ':' + propName + ':' + metaPropName;
    }
    if (!searchKey || !this.cachedMetaProps.hasOwnProperty(searchKey)) {
      searchRes = this.commons.searchModel(mod, propName);
      if (searchRes) {
        let params = new MetaPropParams();
        params.metaPropName = metaPropName;
        params.fieldName = propName;
        params.parentModel = searchRes.parentModel;
        params.noWarn = true;
        metaProp = this.getMetaFieldValue(params);
        if (!metaProp && searchRes.grandParentModel) {
          params = new MetaPropParams();
          params.metaPropName = metaPropName;
          params.fieldName = searchRes.parentPropName;
          params.parentModel = searchRes.grandParentModel;
          params.noWarn = true;
          metaProp = this.getMetaFieldValue(params);
        }
      }
      this.cachedMetaProps[searchKey] = metaProp;
    } else {
      metaProp = this.cachedMetaProps[searchKey];
    }
    return metaProp;
  }

  private getMetaFromModels(parentModel: BaseModel, propName, noThrow) {
    const parentObjectType = parentModel.object_type;
    return this.getMetaFromModelsSub(this.models.getModels(false), parentObjectType, propName, noThrow);
  }

  // private getMetaFromModelsAsync(
  //   parentObjectType, propName, noThrow, fn) {
  //   const callbackKey = parentObjectType + ':' + propName;
  //   let cList = this.callbacks[callbackKey];
  //   if (cList && cList.length > 0) {
  //     cList.push(fn);
  //   } else {
  //     this.callbacks[callbackKey] = [fn];
  //     this.models.getModelsAsync().then(mods => {
  //       let callback;
  //       const metaParams = this.getMetaFromModelsSub(
  //         mods, parentObjectType, propName, noThrow);
  //       if (metaParams) {
  //         cList = this.callbacks[callbackKey];
  //         do {
  //           callback = cList.pop();
  //           callback(metaParams);
  //         } while (cList.length > 0);
  //       }
  //     });
  //   }
  // }

  private getMetaFromModelsSub(
    mods: { [name: string]: BaseModel }, parentObjectType, propName, noThrow): MetaField {
    let metaField: MetaField, realParent: BaseModel;
    if (mods) {
      realParent = mods[parentObjectType];
      if (realParent) {
        let meta = realParent.$$meta;
        if (!meta[propName]) {
          meta = this.getMetaFromOverviewField(
            meta, propName, mods);
        }
        metaField = this.getParamsFromMeta(
          meta, {propName: propName, noThrow: noThrow});
      }
    } else {
      throw new Error('Missing models');
    }
    return metaField;
  }

  private getMetaFromOverviewField(meta, propName, mods: { [name: string]: BaseModel }) {
    let res = null;
    const overviewFields: Array<OverviewField> = <Array<OverviewField>>meta[AConst.OVERVIEW_FIELDS];
    let foundOvf, ovfMetaField: MetaField, ovfName, ovfModel;
    if (overviewFields) {
      overviewFields.forEach((ovf: OverviewField) => {
        if (ovf.field_name.indexOf(propName) !== -1) {
          foundOvf = ovf;
        }
      });
      if (foundOvf) {
        ovfName = foundOvf.name.split('.')[0];
        ovfMetaField = meta[ovfName];
        if (ovfMetaField) {
          ovfModel = ovfMetaField.inline ? ovfMetaField.inline.model : null;
          if (ovfModel) {
            res = mods[ovfModel].$$meta;
            if (!res) {
              console.warn('No meta in model ' + ovfModel);
            }
          } else {
            console.warn('Missing \'model\' prop from ' +
              ovfName);
          }
        } else {
          console.warn('No meta found for ' + ovfName);
        }
      } else {
        console.warn('No overview field containing ' + propName +
          ' found');
      }
    } else {
      console.warn('No overview fields in meta');
    }
    return res;
  }

  private getParamsFromMeta(meta: { [name: string]: MetaField }, params): MetaField {
    let metaField: MetaField, propNames, inline: Inline, subName;
    const propNamePath = params.fieldId || params.propName;

    if (meta) {
      if (!propNamePath) {
        throw new Error('Prop name path not found in model');
      } else if (meta[params.propName]) {
        metaField = <MetaField>meta[params.propName];
      } else if (propNamePath && propNamePath.indexOf('.') !== -1) {
        propNames = propNamePath.split('.');
        for (let t = propNames.length - 1; t >= 0; t--) {
          subName = propNames[t].split('[')[0];
          metaField = <MetaField>meta[subName];
          if (metaField) {
            inline = metaField.inline;
            if (inline) {
              meta = this.models.getModelMeta(inline.model);
            } else {
              break;
            }
          } else {
            metaField = meta[params.propName];
          }
        }
      } else {
        metaField = null;
      }
    }
    if (!metaField && !params.noThrow) {
      if (propNamePath) {
        throw new Error('Meta prop \'' + propNamePath +
          '\' not found in model');
      } else {
        throw new Error('Meta props not found in model');
      }
    }
    return metaField;
  }

  // Try to retrieve meta properties of an object's parent object.
  // This will only work if there are no two child objects having the
  // same object type within the parent object...
  private getGrandMetaField(grandParentModel: BaseModel, parentModel: BaseModel): MetaField {
    let grandMetaField: MetaField = null, val: SuperObjectModel, name, objType;
    for (name in grandParentModel) {
      if (grandParentModel.hasOwnProperty(name)) {
        val = grandParentModel[name];
        if (Array.isArray(val) && val.length > 0) {
          val = val[0];
        }
        if (name.indexOf('$$') === -1 && val && !Array.isArray(val) && typeof val === 'object') {
          objType = val.object_type;
          if (objType &&
            objType === parentModel.object_type) {
            const getMetaFieldParams = new GetMetaFieldParams();
            getMetaFieldParams.parentModel = grandParentModel;
            getMetaFieldParams.propName = name;
            getMetaFieldParams.noThrow = true;
            grandMetaField = this.getMetaField(getMetaFieldParams);
            break;
          }
        }
      }
    }
    return grandMetaField;
  }

  private getMetaFieldValueFromAncestor(ancestor: BaseModel, parent: BaseModel, metaPropName): any {
    let metaField: MetaField, res;
    if (ancestor.hasOwnProperty(metaPropName)) {
      metaField = ancestor.$$meta[metaPropName];
    } else {
      metaField = this.getGrandMetaField(ancestor, parent);
    }
    if (metaField) {
      res = metaField[metaPropName];
    }
    return res;
  }

}
