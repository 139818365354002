<app-authenticator *ngIf="!isAuthenticated()"></app-authenticator>

<!-- Main application -->
<div class="app-component"
      *ngIf="isAuthenticated()"
      [ngClass]="className" (click)="registerClick($event)">
    <div class="row app-component__main-menu"
        *ngIf="hasMainMenu()">
      <div class="app-component__main-menu-container">
        <app-main-menu
          [currentStateName]="currentStateName"
          class="app-component__main-menu-content"></app-main-menu>
      </div>
    </div>
    <div class="row app-component__container">

      <div class="app-component__content">
        <section>
          <router-outlet></router-outlet>
        </section>
      </div>

      <app-notifications></app-notifications>

    </div>
  </div>
