<form class="concepts-query" (submit)="submit()">

  <div class="concepts-query__input-container">
    <div class="concepts-query__input-search-icon" *ngIf="!hideIcon">
      <span class="search-icon"><i class="icon-search"></i></span>
    </div>
    <input type="text"
           id="searchQuery"
           autocomplete="off"
           [ngClass]="{'hasSearchIcon': !hideIcon}"
           [(ngModel)]="conceptsContainer.conceptsParams.query"
           [ngModelOptions]="{standalone: true}"
           placeholder="{{'TRANS__ADMIN_CONCEPT__QUERY_PLACEHOLDER' | translate}}">
    <a *ngIf="conceptsContainer.conceptsParams.query"
       (click)="clearSearch()"
       class="concepts-query__clear-input">
      <mat-icon>close</mat-icon>
    </a>
    <a class="concepts-query__search-button text-blue" (click)="submit()">
      <span class="uppercase" translate="TRANS__FILTER__SEARCH_PLACEHOLDER"></span></a>
  </div>
  <div [translate]="queryMessage"></div>
</form>
