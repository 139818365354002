<ng-container *ngIf="operationField">
  <div [ngSwitch]="operationField.input_type">
    <div *ngSwitchCase="FieldInputType.INLINE">
      <app-edit-field-inline-field
        *ngFor="let subField of operationField.sub_fields; let i = index"
        [subFieldIndex]="i"
        [sectionsContainer]="sectionsContainer"
        [object]="operationObject"
        [field]="subField"
      ></app-edit-field-inline-field>
    </div>

    <div *ngSwitchCase="FieldInputType.INLINE_ARRAY">
      <app-edit-field-inline-array
        [sectionsContainer]="sectionsContainer"
        [object]="sectionsContainer.rootObject"
        [field]="operationField"
      ></app-edit-field-inline-array>
    </div>

    <div *ngSwitchDefault>
      <app-edit-field
        [sectionsContainer]="sectionsContainer"
        [object]="sectionsContainer.rootObject"
        [field]="operationField"
      ></app-edit-field>
    </div>
  </div>
</ng-container>
