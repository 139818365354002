import {Injectable} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {ObjectStorageService} from './object-storage.service';
import {FormGroupParams, PrimusFormGroupService} from './primus-form-group.service';
import {SectionsContainer} from './definitions/sections-container';
import {ProgressDialogComponent} from '../shared/progress-dialog/progress-dialog.component';
import {ModelFactoryService} from './model-factory.service';
import {CmsApiService} from './cms-api.service';
import {CopyKeepService} from '../object-edit/copy-keep-checkbox/copy-keep.service';
import {ObjectViewAndData} from './definitions/object-view';
import {MetaField} from './definitions/meta-field';
import {Reference} from './definitions/reference';
import {SuperObjectModel} from './definitions/super-object-model';
import {BaseModel} from './definitions/base-model';
import {SearchParameters} from './definitions/search-parameters';
import {SolrFilterService} from './solr-filter.service';
import {GetArtifactAndOperationsParams} from './get-artifact-and-operations-params';
import {PrimusRouteService} from './primus-route.service';
import {FieldInputType} from "./definitions/field-input-type.enum";
import {SearchService} from "./search.service";
import {SearchReferenceService} from "./search-reference.service";

export interface CreateSectionsContainerParams {
  useExistingObject: boolean;           // Set to true if supposed to create sections containing from existing object,
                                        // set to false if a new object shall be created
  objectType: string;                   // Must be set to a valid object type when creating new objects
  object: SuperObjectModel;             // Must be set to existing object data when creating from existing object,
                                        // unless object id is set
  objectId: string;                     // Must be set to an existing object id unless object is set
  getSourceObject: boolean;             // Set to true to fetch "source" object version from table instead of generating
                                        // complete object from DAO (used when viewing instead of editing object)
  templateGroupId: string;              // Relevant for object types supporting templates
  usePrimeFields: boolean;              // Set to true when prime fields should be displayed
  isCopy: boolean;                      // Set to true if you need to retrieve a copy of the object from the server
  // If set, both object and operations for the object should be loaded at the same time
  operationTarget: string;
}

interface SearchInfo {
  fieldName: string;
  searchParams: SearchParameters;
}


@Injectable({
  providedIn: 'root'
})
export class ObjectEditService {

  currentObject;

  constructor(private primusRoute: PrimusRouteService,
              private objectStorage: ObjectStorageService,
              private formGroupService: PrimusFormGroupService,
              private modalService: MatDialog,
              private modelFactory: ModelFactoryService,
              private cms: CmsApiService,
              private copyKeepService: CopyKeepService,
              private searchService: SearchService,
              private solrFilter: SolrFilterService,
              private searchReferenceService: SearchReferenceService) {
  }

  async loadObjectGetSectionsContainer(objectId: string, getOriginalObject: boolean, templateGroupId?: string): Promise<SectionsContainer> {
    const objectViewAndData = await this.loadObjectViewAndData(objectId, getOriginalObject, templateGroupId);
    const sc = await this.formGroupService.getSectionsAndFormGroup(objectViewAndData.artifact, templateGroupId);
    this.currentObject = sc.rootObject;
    sc.objectView = objectViewAndData.view_data;
    return sc;
  }

  // eslint-disable-next-line max-len
  async loadObjectGetSectionsContainerAndOperations(objectId: string, getOriginalObject: boolean, operationTarget: string, templateGroupId?: string): Promise<SectionsContainer> {
    const getArtifactAndOperationsParams = new GetArtifactAndOperationsParams();
    getArtifactAndOperationsParams.operation_target = operationTarget;
    getArtifactAndOperationsParams.artifact_id = objectId;
    getArtifactAndOperationsParams.get_original_artifact = getOriginalObject;
    getArtifactAndOperationsParams.locale = this.primusRoute.params.lang;
    getArtifactAndOperationsParams.template_group_id = templateGroupId;
    getArtifactAndOperationsParams.timezone_offset = new Date().getTimezoneOffset();
    const objectViewAndData = await this.objectStorage.loadObjectWithViewDataAndOperations(getArtifactAndOperationsParams);
    const sc = await this.formGroupService.getSectionsAndFormGroup(objectViewAndData.artifact, templateGroupId);
    this.currentObject = sc.rootObject;
    sc.objectView = objectViewAndData.view_data;
    sc.operations = objectViewAndData.operations;
    return sc;
  }

  async loadObject(objectId: string): Promise<any> {
    return this.objectStorage.loadObject(objectId);
  }

  async loadObjects(objectIds: Array<string>): Promise<Array<any>> {
    return this.objectStorage.loadObjects(objectIds);
  }

  async setObjectValuesStoreObject(sectionsContainer: SectionsContainer, reloadObject?: boolean): Promise<SuperObjectModel> {
    return new Promise<any>((resolve, reject) => {
      if (sectionsContainer.formGroup.valid) {
        this.formGroupService.setObjectValuesFromForm(sectionsContainer.rootObject, sectionsContainer.formGroup);
        this.copyKeepService.removeNotKeep(sectionsContainer.rootObject);
        this.storeObjectShowProgressModal(sectionsContainer.rootObject, reloadObject).then(
          res => {
            if (reloadObject) {
              sectionsContainer.rootObject = res;
            }
            resolve(res);
          },
          reason => {
            reject(reason);
          }
        );
      }
    });
  }

  storeObjectShowProgressModal(object: BaseModel, reloadObject?: boolean): Promise<SuperObjectModel> {
    return new Promise<any>((resolve, reject) => {
      const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
      this.objectStorage.storeObject(object).then(
        res => {
          progressModal.close();
          if (reloadObject) {
            const objId = res.artifact_id;
            if (objId) {
              this.objectStorage.loadObject(res.artifact_id).then(obj => {
                resolve(obj);
              });
            } else {
              console.error('Re-loaded object had no artifact id!');
              resolve(res);
            }
          } else {
            resolve(res);
          }
        },
        reason => {
          progressModal.close();
          console.error('Store failed with message: ' + reason.message);
          reject(reason);
        }
      );
    });
  }

  deleteObjectShowDialog(object: SuperObjectModel): Promise<void> {
    return new Promise(((resolve, reject) => {
      const progressModal = this.modalService.open(ProgressDialogComponent, {disableClose: true, panelClass: 'progress-modal'});
      this.objectStorage.deleteObject(object).then(
        () => {
          progressModal.close();
          resolve();
        },
        response => {
          progressModal.close(response.error.message);
          reject(response);
        }
      );
    }));
  }

  setNameField(parentRef: Reference, optionData: any, text) {
    let fieldNamePath = parentRef.add_new_params ? parentRef.add_new_params.name_field_path : null;
    if (!fieldNamePath && parentRef.object_type.indexOf('ct_') === 0) {
      // Hardcoding field name path to 'name.name' for concepts
      fieldNamePath = 'name.name';
    }
    if (fieldNamePath) {
      const fieldNameSplit = fieldNamePath.split('.');
      let obj = optionData;
      fieldNameSplit.forEach((fieldPart, index) => {
        if (index < fieldNameSplit.length - 1) {
          obj[fieldPart] = {};
          obj = obj[fieldPart];
        } else {
          obj[fieldPart] = text;
        }
      });
    } else {
      console.warn('The reference parameter "add_new_params.name_field_path" must be set!!');
    }
  }

  async createSectionsContainer(params: CreateSectionsContainerParams): Promise<SectionsContainer> {
    let sectionsContainer: SectionsContainer;
    if (!params.useExistingObject) {
      if (params.usePrimeFields) {
        sectionsContainer = await this.createModelItemGetSectionsContainerForPrimeFields(
          params.objectType, params.object);
      } else {
        sectionsContainer = await this.createModelItemGetSectionsContainer(
          params.objectType, params.object, params.templateGroupId);
      }
    } else if (params.object) {
      if (params.usePrimeFields) {
        sectionsContainer = await this.getSectionsContainerForObjectPrimeFields(params.object);
      } else {
        sectionsContainer = await this.setModelItemGetSectionsContainer(
          params.object, params.templateGroupId, params.isCopy);
      }
    } else if (params.objectId) {
      if (params.operationTarget) {
        sectionsContainer = await this.loadObjectGetSectionsContainerAndOperations(
          params.objectId, !params.getSourceObject, params.operationTarget, params.templateGroupId);
      } else {
        sectionsContainer = await this.loadObjectGetSectionsContainer(
          params.objectId, !params.getSourceObject, params.templateGroupId);
      }
    } else {
      console.warn('Parameter "object" or "objectId", not set');
      return null;
    }
    sectionsContainer.debug = this.primusRoute.params.debug;
    return sectionsContainer;
  }

  async createModelItemGetSectionsContainer(
    modelName, data?: SuperObjectModel, templateGroupId?: string): Promise<SectionsContainer> {
    const item = await this.createModelItem(modelName, data);
    return this.formGroupService.getSectionsAndFormGroup(<SuperObjectModel>item, templateGroupId);
  }

  async createModelItemGetSectionsContainerForPrimeFields(modelName,
                                                          data?: SuperObjectModel,
                                                          noSelectorFields?: boolean): Promise<SectionsContainer> {
    const modelItem = await this.createModelItem(modelName, data);
    const item = <SuperObjectModel>modelItem;
    return this.getSectionsContainerForObjectPrimeFields(item, modelName, noSelectorFields);
  }

  async createModelItem(modelName, data?): Promise<BaseModel> {
    const item = await this.modelFactory.createModelItemAsync(modelName, data);
    return this.setMapValues(item);
  }

  async setModelItemGetSectionsContainer(object, templateGroupId?, isCopy?): Promise<SectionsContainer> {
    const modeItem = <SuperObjectModel>await this.modelFactory.setModelItemAsync(object.object_type, object);
    return this.formGroupService.getSectionsAndFormGroup(modeItem, templateGroupId, isCopy);
  }

  async getSectionsContainerForObjectPrimeFields(object: SuperObjectModel, modelName?: string, noSelectorFields?: boolean): Promise<SectionsContainer> {
    let fields = await this.getPrimeFields(modelName || object.object_type);
    if (noSelectorFields) {
      fields = fields.filter(field => field.input_type !== FieldInputType.SEARCH_SELECTOR_MULTIPLE);
    }
    const formGroup = this.formGroupService.getFormGroup(object, fields, true);
    const res = new SectionsContainer();
    res.rootObject = object;
    res.primeFields = fields;
    res.formGroup = formGroup;
    return res;
  }

  /*
  setSectionFields(object, editFieldsOnly: boolean, requiredOnly: boolean) {
    const sections = object.$$meta.sections;
    if (sections) {
      sections.forEach(section => {
        section.fields = [];
        for (const fieldName in object.$$meta) {
          if (object.$$meta.hasOwnProperty(fieldName)) {
            const fieldMeta = object.$$meta[fieldName];
            if (fieldMeta) {
              const sectionName = fieldMeta.section;
              if (sectionName === section.name) {
                if ((!editFieldsOnly || fieldMeta.edit) && (!requiredOnly || fieldMeta.required)) {
                  section.fields.push(fieldName);
                }
              }
            }
          }
        }
      });
    }
  }*/

  resetSectionsContainerFormGroup(sectionsContainer: SectionsContainer) {
    this.formGroupService.setSectionsContainerFormGroup(sectionsContainer);
  }

  // Useful for objects that have "root model" outside the regular
  // field container regime, in order to add items to the root model
  setTopModel(model, arrayName) {
    model[arrayName].forEach(item => {
      item.$$topModel = model;
      item.$$topModelsArrayName = arrayName;
    });
  }

  async addInlineItem(modelName: string, field: MetaField, sectionsContainer: SectionsContainer) {
    const item = await this.createModelItem(modelName);
    sectionsContainer.rootObject[field.name] = item;
    this.formGroupService.setFormGroupField({
      rootObject: <BaseModel>sectionsContainer.rootObject,
      object: <BaseModel>sectionsContainer.rootObject,
      field: field,
      group: sectionsContainer.formGroup,
      parentKey: field.key
    } as FormGroupParams);
    return item;
  }

  private async setMapValues(modelObject: BaseModel): Promise<BaseModel> {
    const searches: SearchInfo[] = [];
    for (const [fieldName, metaField] of Object.entries(modelObject.$$meta)) {
      const fieldVal = modelObject[fieldName];
      if (fieldVal && metaField.field_type === 'map_id' && metaField.display === 'yes') {
        if (modelObject[fieldName + '_value'] === undefined) {
          const searchInfo = {fieldName: fieldName, searchParams: {}} as SearchInfo;
          const ref = await this.searchReferenceService.getSearchReferenceFromField(metaField);
          this.solrFilter.addFq(searchInfo.searchParams, 'object_type', ref.object_type);
          this.solrFilter.addFq(searchInfo.searchParams, 'artifact_id', fieldVal);
          searches.push(searchInfo);
        }
      }
    }
    if (searches.length) {
      for (let search of searches) {
        const searchRes = await this.searchService.search(search.searchParams);
        const artifacts = searchRes.artifacts;
        if (artifacts && artifacts.length) {
          modelObject[search.fieldName + '_value'] = artifacts[0].artifact_name;
        } else {
          console.warn('Could not find value searching for ' + JSON.stringify(search.searchParams));
        }
      }
    }
    return modelObject;
  }

  private async getPrimeFields(modelName): Promise<Array<any>> {
    return this.cms.getModelOverviewFields({
      modelName: modelName,
      type: 'prime'
    });
  }

  private async loadObjectViewAndData(objectId: string, getOriginalObject: boolean, templateGroupId: string): Promise<ObjectViewAndData> {
    return this.objectStorage.loadObjectWithViewData(objectId, getOriginalObject, templateGroupId);
  }

}
