import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {TranslateModule} from '@ngx-translate/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
// eslint-disable-next-line max-len
import {SharedModule} from '../shared/shared.module';
import {SectionMenuScrollComponent} from './section-menu-scroll/section-menu-scroll.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SectionMenuComponent} from './section-menu/section-menu.component';
import {OrderByTranslatedPipe} from './order-by-translated.pipe';
import {MediaSelectorModule} from '../media-selector/media-selector.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {ImageAnnotationModule} from '../image-annotation/image-annotation.module';
import { ObjectViewAndEditComponent } from './object-view-and-edit/object-view-and-edit.component';
import { ObjectUpdateLogComponent } from './object-information-tabs/object-update-log/object-update-log.component';
import {OverviewFieldsModule} from '../overview-fields/overview-fields.module';
import { ObjectInformationTabsComponent } from './object-information-tabs/object-information-tabs.component';
import { ObjectFoldersComponent } from './object-information-tabs/object-folders/object-folders.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import { SectionPanelComponent } from './section-panel/section-panel.component';
import {RouterModule} from '@angular/router';

@NgModule({
    exports: [
        SectionMenuComponent,
        SectionMenuScrollComponent,
        ObjectViewAndEditComponent,
        ObjectUpdateLogComponent,
        ObjectInformationTabsComponent,
        SectionPanelComponent,
    ],
  imports: [
    FormsModule,
    MatDialogModule,
    CommonModule,
    TranslateModule,
    SharedModule,
    ObjectSearchModule,
    OverviewFieldsModule,
    MediaSelectorModule,
    ObjectEditModule,
    ImageAnnotationModule,
    MatTabsModule,
    MatIconModule,
    MatTableModule,
    MatProgressSpinnerModule,
    RouterModule,
  ],
  declarations: [
    SectionMenuComponent,
    SectionMenuScrollComponent,
    OrderByTranslatedPipe,
    ObjectViewAndEditComponent,
    ObjectUpdateLogComponent,
    ObjectInformationTabsComponent,
    ObjectFoldersComponent,
    SectionPanelComponent
  ]
})
export class ObjectViewModule {
}
