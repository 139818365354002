<section *ngIf="searchContainer && !hideSearchView"
         id="searchResult-main-content"
         class="search-page-component">

  <app-search-view-full
    [searchContainer]="searchContainer"
    [searchViewType]="searchContainer.currentPathView.search_view.search_view_type"
    class="search-page-component__search-view"
  ></app-search-view-full>

</section>
<ng-container *ngIf="hideSearchView">
  <router-outlet></router-outlet>
</ng-container>
