<div class="admin-named-object">
  <app-top-header [parent]="parent"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN_NAMED_OBJECT__INFORMATION_TITLE"
                              description="TRANS__ADMIN_NAMED_OBJECT__INFORMATION_DESCRIPTION">
    <app-admin-list
      *ngIf="conceptsContainer"
      [conceptsContainer]="conceptsContainer"
      [menuTitle]="'TRANS__ADMIN_NAMED_OBJECT__MENU_TITLE'"
    ></app-admin-list>
  </app-header-and-description>
</div>
