import {Injectable} from '@angular/core';
import {Folder} from './definitions/folder';
import {SearchParameters} from './definitions/search-parameters';
import {SuperobjectFolderItem} from './definitions/super-object-folder-item';
import {SolrFilterService} from './solr-filter.service';
import {SearchService} from "./search.service";

@Injectable({
  providedIn: 'root'
})
export class FolderService {

  constructor(private searchService: SearchService,
              private solrFilter: SolrFilterService) {
  }

  async setFolderItemProps(fld: Folder) {
    await this.setFilterItemsOnFolder(fld);
  }

  private async setFilterItemsOnFolder(folder: Folder): Promise<Array<SuperobjectFolderItem>> {
    const params = {fl: ['artifact_id']} as SearchParameters;
    this.solrFilter.addFq(params, 'folders.folder_id', folder.artifact_id);
    const searchResult = await this.searchService.search(params);
    folder.$$folderItems = [];
    searchResult.artifacts.forEach(searchObject => {
      const folderItem = new SuperobjectFolderItem();
      folderItem.folder_id = folder.artifact_id;
      folderItem.context_id = searchObject.artifact_id;
      folder.$$folderItems.push(folderItem);
    });
    return folder.$$folderItems;
  }


}
