<div class="section-view-edit">
  <mat-checkbox
    class="section-view-edit__checkbox"
    *ngIf="sectionsContainer.isCopy"
    [id]="sectionsContainer.rootObject.object_type"
    [checked]="allSections.keep"
    (change)="setAllSectionKeep()">
    <span translate="TRANS__COPY_FIELDS__SELECT_ALL_SECTIONS"></span></mat-checkbox>

  <div *ngFor="let section of sections"
       class="row section-view-edit__section"
       [id]="'section-' + section.name"
       [ngClass]="{'no-padding': hideSectionTitle || section.hide_title}" >

    <div class="section-view-edit__section-content">

      <ng-container *ngIf="!hideSectionTitle && !section.hide_title">
        <app-section-view-title-edit
          [section]="section"
          (updateKeepSection)="onUpdateKeepSection($event)"
          [sectionsContainer]="sectionsContainer"
        ></app-section-view-title-edit>
        <hr>
      </ng-container>

      <div class="subSec" *ngIf="sectionsContainer.isCopy && section.keep">
        <app-edit-fields
          [sectionsContainer]="sectionsContainer"
          [object]="sectionsContainer.rootObject"
          [fields]="section.editFields"
          (closeEdit)="onCloseEdit()"
        ></app-edit-fields>
      </div>
      <div class="subSec" *ngIf="!sectionsContainer.isCopy"
           [ngClass]="{'disable-button': (section.section_visible !== undefined &&
           !section.section_visible) && sectionsContainer.isTemplate}">
        <app-edit-fields
          [sectionsContainer]="sectionsContainer"
          [object]="sectionsContainer.rootObject"
          [fields]="section.editFields"
          (closeEdit)="onCloseEdit()"
        ></app-edit-fields>
      </div>

    </div>

  </div>

</div>
