import {Injectable} from '@angular/core';
import {CmsApiService} from "./cms-api.service";
import {Reference} from "./definitions/reference";
import {LoggerService} from "./logger.service";
import {MetaField} from "./definitions/meta-field";
import {CmsQueueService} from "./cms-queue.service";

@Injectable({
  providedIn: 'root'
})
export class SearchReferenceService {
  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService) {
  }

  async getSearchReferenceFromField(metaField: MetaField): Promise<Reference> {
    return new Promise((resolve, reject) => {
      if (!metaField) {
        resolve(null);
        return;
      }
      // TODO: The following lines are for legacy purposes and may be deleted when all backends are using reference ids
      //  only
      if (metaField.reference && !metaField.reference.reference_id) {
        resolve(metaField.reference);
        return;
      }
      const referenceId = this.getReferenceId(metaField);
      if (!referenceId) {
        resolve(null);
        return;
      }
      this.getSearchReferenceFromReferenceId(referenceId).then(reference => {
          resolve(reference)
        },
        reason => {
          reject(reason)
        })
    });
  }

  async getSearchReferenceFromReferenceId(referenceId: string): Promise<Reference> {
    return new Promise((resolve, reject) => {
      this.cmsQueue.runCmsFnWithQueue(
        this.cms.getSearchReferenceFromReferenceId, {reference_id: referenceId},
        false,
        res => {
          if (!res) {
            this.logger.warn(`Unable to get field reference for ${referenceId}`)
          }
          resolve(res);
        },
        reason => {
          this.logger.error(`Getting reference id failed: ${reason}`);
          reject(reason)
        });

    });
  }

  private getReferenceId(metaField: MetaField) {
    let referenceId = metaField.reference_id;
    if (!referenceId && metaField.reference && metaField.reference.reference_id) {
      // This only happens when field comes from search results
      referenceId = metaField.reference.reference_id;
    }
    if (!referenceId) {
      this.logger.warn(`No reference id found for field ${metaField.field_uuid}`);
    }
    return referenceId;
  }
}
