<div  class="media-carousel-top"
      [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen}">
  <div class="media-carousel-top__button-container media-carousel-top__information"
       *ngIf="showInformationButton && !imageGallery">
    <button mat-button class="media-carousel-top__button"
            *ngIf="mediaElements[mediaIndex]"
            [routerLink]="mediaElements[mediaIndex].mediaObject.$$routerLink"
            [queryParams]="mediaElements[mediaIndex].mediaObject.$$queryParams"
            queryParamsHandling="merge"
            [ngClass]="{'disable-button': isEditing}">
      <mat-icon>more_horiz</mat-icon>
      <span class="media-carousel-top__button-text"
      >{{'TRANS__MEDIA_CAROUSEL__MEDIA_INFORMATION' | translate}}</span></button></div>

  <div class="media-carousel-top__button-container  media-carousel-top__image-zoom">
    <button mat-button class="media-carousel-top__button"
            *ngIf="showZoom"
            [matMenuTriggerFor]="zoomIn"
            #zoomInButton="matMenuTrigger">
      <mat-icon>zoom_in</mat-icon>
      <span class="media-carousel-top__button-text">{{'TRANS__MEDIA_CAROUSEL__ZOOM' | translate}}</span>
    </button>

    <mat-menu #zoomIn="matMenu" [hasBackdrop]="false">
      <div mat-menu-item>
        <mat-slider min="1" max="2" step="0.1" [value]="zoomValue"
                    (change)="onChangedZoomValue($event)"
                    (click)="$event.stopPropagation()"></mat-slider></div>
    </mat-menu>
  </div>

  <div class="media-carousel-top__button-container media-carousel-top__full-screen">
    <button *ngIf="showFullScreenButton"  (click)="openFullScreen()"
            class="media-carousel-top__button"
            [disabled]="isEditing" mat-button>
      <mat-icon>fullscreen</mat-icon>
      <span class="media-carousel-top__button-text"
            >{{'TRANS__MEDIA_CAROUSEL__FULL_SCREEN' | translate}}</span>
    </button>
  </div>

  <div class="media-carousel-top__button-container">
    <app-download-url *ngIf="showDownload"
                      [object]="mediaElements[mediaIndex].mediaObject">
      <div class="app-download-content">
        <button class="media-carousel-top__button" mat-button>
          <mat-icon>get_app</mat-icon>
          <span class="media-carousel-top__button-text"
                >{{'TRANS__MEDIA_CAROUSEL__DOWNLOAD' | translate}}</span>
        </button>
      </div>
    </app-download-url>
  </div>
</div>

