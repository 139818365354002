import {Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild} from '@angular/core';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort} from '@angular/material/sort';
import {SelectionModel} from '@angular/cdk/collections';
import {Subscription} from 'rxjs';
import AdminUsersDataSource from 'src/app/administration/admin-users/admin-users-data-source';

/**
 * Represents a column that should be displayed in the table
 */
export interface TableColumn<T> {
  /**
   * The title of the column.
   * Can be translation string
   */
  title: string;
  /**
   * The property to display
   */
  property: keyof T;
  /**
   * Whether or not the column should be hidden.
   * Default is false
   */
  hidden?: boolean;
  /**
   * Whether or not to display the row as an image.
   * Setting this to a truthy value assumes that T[property] is a valid and absolute URL to the image
   */
  isImage?: boolean;
}

/**
 * Event fired when a selection changes
 */
export interface SelectionChangedEvent<T> {
  /**
   * The row that was (de)selected.
   * NULL if the "(de)select all" was clicked
   */
  row: T | null;
  /**
   * Whether or not the row is currently selected.
   * NULL if the "(de)select all" was clicked
   */
  isRowSelected: boolean | null;
  /**
   * Whether or not all rows are currently selected
   */
  isAllSelected: boolean;
  /**
   * A reference to all the selected rows
   */
  selected: Array<T>;
}

/**
 * @deprecated
 */
@Component({
  selector: 'app-primus-table',
  templateUrl: './primus-table.component.html',
  styleUrls: ['./primus-table.component.scss'],
})
export class PrimusTableComponent<T> implements OnChanges, OnDestroy {

  /**
   * Reference to the sortChange-subscription.
   * Kept to release memory when destroyed or updated
   */
  private sortSub: Subscription;

  /**
   * An internal reference to the current paginator
   * @type {MatPaginator}
   * @private
   */
  private _paginator: MatPaginator;
  /**
   * Sets up pagination when the MatPaginator-component is loaded
   * @param {MatPaginator} paginator
   */
  @ViewChild(MatPaginator) set paginator(paginator: MatPaginator) {
    if (paginator) {
      this._paginator = paginator;
      if (this.dataSource) {
        this.dataSource.paginator = paginator;
      }
      if (this.dataSource.hasOwnProperty('numRecords')) {
        this.availableItems = this.dataSource['numRecords'];
      }
      this._paginator.length = this.availableItems ?? 0;
    }
    this.setupSortChange();
  }

  get paginator() {
    return this._paginator;
  }

  /**
   * Sets up sorting when the MatSort-directive is loaded
   * @param {MatSort} sort
   */
  @ViewChild(MatSort) set sort(sort: MatSort) {
    this.dataSource.sort = sort;
    this.setupSortChange();
  }

  /**
   * An array of options defining the number of rows to display on one page
   * @type {number[]}
   */
  @Input() pageSizeOptions: Array<number>;

  /**
   * The default pagesize
   * @type {number}
   */
  @Input() pageSize: number;

  /**
   * text on action button.
   */
  @Input() actionButtonName: string;

  /**
   * text on action button.
   */
  @Input() actionButtonParent;

  /**
   * text on action button.
   */
  @Input() actionButtonDisabled: boolean;

  /**
   * whether or not the rows are selectable.
   * Defaults to false
   */
  @Input() selectable: boolean;
  /**
   * Whether or not to disable all checkboxes
   */
  @Input() disableSelection: boolean;
  /**
   * Function to determine if a row is selectable.
   * The checkbox will be disabled if false is returned.
   * Defaults to true.
   */
  @Input() rowSelectableFn: (row: T) => boolean;

  /**
   * Whether or not the rows are editable.
   * Defaults to false
   */
  @Input() editable: boolean;
  /**
   * Whether or not the rows are editable.
   * Defaults to false
   */
  @Input() rowActions: boolean;
  /**
   * Whether or not to disable all edit-buttons
   */
  @Input() disableEditing: boolean;
  /**
   * Function to determine if a row can be edited.
   * The button will be disabled if false is returned.
   * Defaults to true.
   */
  @Input() rowEditableFn: (row: T) => boolean;

  /**
   * The columns to display in the table
   */
  @Input() columns: Array<TableColumn<T>>;

  /**
   * If True, columns are sortable asc/desc by click.
   */
  @Input() sortableColumns = true;
  /**
   * The data/rows to display in the table
   */
  @Input() data: Array<T>;
  /**
   * Provide a filter-string to filter the dataset.
   * This will only apply to the visible columns
   */
  @Input() filterString: string;
  /**
   * When true, enables the user to click on a single row (not checkbox or edit-button).
   * Required to make the rowClicked-event fire.
   */
  @Input() enableRowSelection: boolean;
  /**
   * Provide this predicate to override the default filter-function.
   */
  @Input() customFilter: (row: T, filterString: string) => boolean;
  /**
   * set to false to disable the possibility to add items
   * @type {boolean}
   */
  @Input() addable: boolean;
  /**
   * Max number of available items
   * @type {number}
   */
  @Input() availableItems: number;
  /**
   * Event emitted when a row is (de)selected or all rows are (de)selected
   */
  @Output() public readonly selectionChanged: EventEmitter<SelectionChangedEvent<T>>;
  /**
   * Event emitted when a row is edited
   */
  @Output() public readonly editClicked: EventEmitter<T>;
  /**
   * Event emitted when a row is clicked.
   * Requires "enableRowSelection" to be true.
   */
  @Output() public readonly rowClicked: EventEmitter<T>;
  /**
   * Event emitted when adding new row.
   */
  @Output() public readonly addNewRowClicked: EventEmitter<T>;
  /**
   * Emits when the page og pageSize changes
   * @type {EventEmitter<number>}
   */
  @Output() public readonly pageChanged: EventEmitter<PageEvent>;

  @Input() dataSource: MatTableDataSource<T>;

  // public readonly dataSource: MatTableDataSource<T>;
  selectionState: SelectionModel<T>;

  constructor() {
    this.pageSizeOptions = [10, 25, 50, 100, 200];
    this.pageSize = 25;
    this.availableItems = 0;
    this.selectable = false;
    this.disableSelection = false;
    this.rowSelectableFn = () => true;

    this.editable = false;
    this.rowActions = false;
    this.disableEditing = false;
    this.rowEditableFn = () => true;

    this.rowEditableFn = () => true;
    this.columns = [];
    this.data = [];
    this.disableSelection = false;
    this.enableRowSelection = false;
    this.selectionChanged = new EventEmitter<SelectionChangedEvent<T>>();
    this.editClicked = new EventEmitter<T>();
    this.rowClicked = new EventEmitter<T>();
    this.addNewRowClicked = new EventEmitter<T>();

    this.dataSource = new MatTableDataSource<T>();
    this.selectionState = new SelectionModel<T>(true, this.data);

    this.pageChanged = new EventEmitter<PageEvent>();

    this.setupFilter();
  }

  getPaginator() {
    return this._paginator;
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('data')) {
      this.setData(changes.data.currentValue);
      this.dataSource.paginator = this._paginator;
      this.setupSortChange();
    }

    if (changes.hasOwnProperty('columns')) {
      this.columns = changes.columns.currentValue;
      this.setupFilter();
    }

    if (changes.hasOwnProperty('filterString')) {
      this.dataSource.filter = changes.filterString.currentValue;
    }

    if (changes.hasOwnProperty('customFilter')) {
      this.setupFilter();
    }

    if (this._paginator && changes.hasOwnProperty('availableItems')) {
      this._paginator.length = this.availableItems ?? 0;
    }
  }

  public ngOnDestroy(): void {
    if (this.sortSub && !this.sortSub.closed) {
      this.sortSub.unsubscribe();
    }
  }

  public isAllSelected(): boolean {
    if (!this.selectable) {
      return false;
    }
    const numSelected = this.selectionState.selected.length;
    let numRows;
    if (this.dataSource.data) {
      numRows = this.dataSource.data.length;
    } else {
      const ds: AdminUsersDataSource = (this.dataSource as unknown) as AdminUsersDataSource;
      numRows = ds.getAllRows().length;
    }
    return numSelected === numRows;
  }

  public toggleSelectionForAllRows(): void {
    if (!this.selectable) {
      return;
    }
    if (this.isAllSelected()) {
      this.selectionState.clear();
    } else {
      if (this.dataSource.data) {
        this.dataSource.data.forEach(row => this.selectionState.select(row));
      } else {
        const ds: AdminUsersDataSource = (this.dataSource as unknown) as AdminUsersDataSource;
        ds.getAllRows().forEach(row => this.selectionState.select(row));
      }
    }
    this.emitSelectionChanged();
  }

  get displayedColumns(): Array<string> {
    const cols = [];
    if (this.selectable) {
      cols.push('select');
    }

    cols.push(...this.columns.filter(col => !col.hidden).map(col => col.property as string));

    if (this.rowActions) {
      cols.push('rowActions');
    }
    return cols;
  }

  isBool(row: T, col: TableColumn<T>): boolean {
    return typeof row[col?.property] === 'boolean';
  }

  handleEditClicked(row: T) {
    this.editClicked.emit(row);
  }

  handleSelectionChanged(row: T) {
    this.selectionState.toggle(row);
    this.emitSelectionChanged(row);
  }

  handleRowClicked(row: T) {
    if (this.enableRowSelection) {
      this.rowClicked.emit(row);
    }
  }

  private emitSelectionChanged(row?: T): void {
    this.selectionChanged.emit({
      row: row ? row : null,
      isRowSelected: row ? this.selectionState.isSelected(row) : null,
      isAllSelected: this.isAllSelected(),
      selected: this.selectionState.selected
    });
  }

  private setData(data: Array<T>): void {
    if (data && Array.isArray(data) && data.length > 0) {
      this.data = data;
    } else {
      this.data = [];
    }
    this.selectionState = new SelectionModel<T>(true, this.data);
    this.selectionState.clear();
    this.dataSource.data = this.data;
    this.emitSelectionChanged();
  }

  private setupSortChange(): void {
    if (this.dataSource.sort) {
      if (this.sortSub && !this.sortSub.closed) {
        this.sortSub.unsubscribe();
      }
      this.sortSub = this.dataSource.sort.sortChange.subscribe(() => {
        if (this.dataSource.paginator) {
          this.dataSource.paginator.firstPage();
        }
      });
    }
  }

  private setupFilter(): void {
    if (!!this.customFilter) {
      this.dataSource.filterPredicate = this.customFilter;
      return;
    }

    const rowProperties = this.columns.filter(c => !c.isImage).map(c => c.property);
    this.dataSource.filterPredicate = (row: T, filterString: string) =>
      rowProperties.map(prop => String(row[prop] || '').toLowerCase())
        .some(val => val.includes(String(filterString).toLowerCase()));
  }

}
