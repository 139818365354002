<app-edit-field
  *ngIf="canShow()"
  [ngClass]="{'moreThanOneSubField': subFieldIndex > 0}"
  [sectionsContainer]="sectionsContainer"
  [grandParentObject]="grandParentObject"
  [object]="object"
  [field]="field"
  [index]="index"
  [parentIndex]="parentIndex"
></app-edit-field>
