import {Injectable} from '@angular/core';
import {CmsQueueService} from './cms-queue.service';
import {BaseModel} from './definitions/base-model';
import {CmsApiService} from './cms-api.service';
import {SearchParameters} from './definitions/search-parameters';
import {SolrFilterService} from './solr-filter.service';
import {CommonsService} from './commons.service';
import {MetaField} from './definitions/meta-field';
import {LoggerService} from './logger.service';
import {SettingsService} from './settings.service';
import {SuperObjectModel} from './definitions/super-object-model';
import {SearchObject} from './definitions/search-object';
import {SearchService} from "./search.service";

@Injectable({
  providedIn: 'root'
})
export class ModelsService {

  constructor(private logger: LoggerService,
              private cms: CmsApiService,
              private cmsQueue: CmsQueueService,
              private searchService: SearchService,
              private solrFilter: SolrFilterService,
              private commons: CommonsService,
              private settings: SettingsService) {
  }

  models: { [name: string]: BaseModel };
  modelTitles: { [objectType: string]: string } = {};

  getModels(noThrow): { [name: string]: BaseModel } {
    if (this.models) {
      return this.models;
    } else {
      if (!noThrow) {
        this.logger.error('Models not loaded yet!');
      }
    }
  }

  async getModelsAsync(force?): Promise<{ [name: string]: BaseModel }> {
    return new Promise<{ [name: string]: BaseModel }>((resolve, reject) => {
      const models = this.models;
      if (models && !force) {
        resolve(models);
      } else {
        this.cmsQueue.runCmsFnWithQueue(this.cms.getModels, null, force,
          loadedModels => {
            this.models = loadedModels;
            resolve(loadedModels);
          },
          e => {
            reject(e);
          });
      }
    });
  }

  getModelMeta(modelName): { [name: string]: MetaField } {
    let res;
    const model = this.models ? this.models[modelName] : null;
    if (model) {
      res = model.$$meta;
    }
    if (!res) {
      this.logger.warn(`Unable to get meta for ${modelName}`);
    }
    return res;
  }

  getObjectTypesFromSuperObjectTypeIds(superObjectTypeIds: string[]): { [name: string]: string } {
    const res = {};
    const models = this.getModelsFromSuperObjectTypeIds(superObjectTypeIds);
    for (const [superObjectTypeId, model] of Object.entries(models)) {
      res[superObjectTypeId] = model.object_type;
    }
    return res;
  }

  getModelsFromSuperObjectTypeIds(superObjectTypeIds: string[]): { [name: string]: BaseModel } {
    const res = {};
    if (!this.models) {
      this.logger.error('Models not initialized yet!');
      return res;
    }
    const remainingIds = [...superObjectTypeIds];
    for (const model of Object.values(this.models)) {
      const existPos = remainingIds.indexOf(model.superobject_type_id);
      if (existPos !== -1) {
        const superObjectTypeId = remainingIds[existPos];
        res[superObjectTypeId] = model;
        remainingIds.splice(existPos, 1);
        if (!remainingIds.length) {
          break;
        }
      }
    }
    if (remainingIds.length) {
      this.logger.warn(`No model found for super object type ids ${remainingIds}`);
    }
    return res;
  }

  // You must use this method if you are going to make changes to the object!
  getModelCopy(modelName): BaseModel {
    let res;
    const modelOrig = this.models ? this.models[modelName] : null;
    if (modelOrig) {
      res = this.commons.copy(modelOrig);
    }
    return res;
  }

  getModelAsync(modelName): Promise<BaseModel> {
    return new Promise<BaseModel>((resolve, reject) => {
      this.getModelsAsync().then(
        models => {
          resolve(models[modelName]);
        },
        e => {
          reject(e);
        });
    });
  }

  async getModelTitle(objectType): Promise<string> {
    const models = await this.getModelsAsync();
    let title;
    let res;
    const model = models[objectType];
    if (model) {
      title = model.model_title;
      if (title) {
        res = title;
      } else {
        res = await this.getModelTitleFromTypeList(objectType);
      }
    } else {
      this.logger.error('Model ' + objectType + ' not found');
    }
    return res;
  }

  async getModelTitleFromTypeList(objectType): Promise<string> {
    let res = this.modelTitles[objectType];
    if (res) {
      return res;
    }
    const model = this.models[objectType];
    const superobjectTypeId = model.superobject_type_id;
    const searchParams = {} as SearchParameters;
    this.solrFilter.addFq(searchParams, 'artifact_id', superobjectTypeId);
    const data = await this.searchService.search(searchParams);
    if (data.artifacts && data.artifacts.length > 0) {
      res = data.artifacts[0].artifact_name;
      this.modelTitles[objectType] = res;
    } else {
      const err = `No superobject type value found for '${objectType}'`;
      this.logger.error(err);
      throw err;
    }
    return res;
  }

  /**
   * Get a list of superobject concept types from meta types within a field reference.
   * The purpose is to get superobject ids for object types that belong to specific meta types, e.g. if
   * meta type = 'artifact', obtain superobject type for object types 'thing', 'design', 'artwork' etc.
   * @param metaTypes
   */
  async getSuperObjectConceptTypesFromMetaTypes(metaTypes: string[]): Promise<SuperObjectModel[]> {
    let res = [];
    const metaTypeIds = await this.getMetaTypeIds(metaTypes);
    if (metaTypeIds?.length) {
      res = await this.getSelectableSuperObjectTypesFromMetaTypeIds(metaTypeIds);
    }
    return res;
  }

  private async getMetaTypeIds(metaTypes: string[]): Promise<string[]> {
    let res: string[];
    const searchParams = {} as SearchParameters;
    this.solrFilter.addFq(searchParams, 'object_type', this.settings.getClientConfig().CONCEPT_TYPE_META_TYPE);
    this.solrFilter.addFq(searchParams, 'code', metaTypes);
    const searchResult = await this.searchService.search(searchParams);
    if (searchResult.artifacts.length) {
      res = searchResult.artifacts.map(artifact => artifact.artifact_id);
    }
    return res;
  }

  private async getSelectableSuperObjectTypesFromMetaTypeIds(metaTypeIds: string[]): Promise<Array<SuperObjectModel>> {
    const superObjectTypeIds = await this.cms.getSuperObjectTypeIdsFromMetaTypeIds({meta_type_ids: metaTypeIds});
    const params = {} as SearchParameters;
    this.solrFilter.addFq(params, 'artifact_id', superObjectTypeIds);
    const searchRes = await this.searchService.search(params);
    const res = searchRes.artifacts;
    const addItem = new SearchObject();
    addItem.description.description = '';
    addItem.artifact_name = '';
    res.unshift(addItem);
    return res;
  }


}
