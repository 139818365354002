<mat-card class="admin-settings-setting" [ngClass]="{'edit': editing}">
  <mat-card-header class="admin-settings-setting__header">
    <mat-card-title>{{setting.name}}</mat-card-title>
    <button mat-button
            class="admin-settings-setting__edit-button"
       *ngIf="!editing" (click)="editSetting()">
      <i class="icon-edit-alternative"></i>
      <span class="bold" translate="TRANS__ADMIN_SETTINGS__EDIT"></span></button>
    <mat-card-subtitle>{{setting.description}}</mat-card-subtitle>
  </mat-card-header>
  <mat-card-content class="admin-settings-setting__content">
    <div *ngFor="let subSetting of setting.sub_settings" class="admin-settings-setting__content-item">
      <app-admin-settings-field-value
        [subSetting]="subSetting"
        [editing]="editing"
      ></app-admin-settings-field-value>
    </div>
  </mat-card-content>
  <hr *ngIf="editing">
  <mat-card-actions *ngIf="editing" align="end" class="admin-settings-setting__buttons">
    <button mat-flat-button
            color="accent"
            class="bold"
            (click)="storeSetting()" [disabled]="storing">
      <span translate="TRANS__ADMIN_SETTINGS__ACTIONS__STORE"></span>
    </button>
    <button mat-button
            color="accent"
            class="bold"
            (click)="cancelSetting()" [disabled]="storing">
      <span translate="TRANS__ADMIN_SETTINGS__ACTIONS__CANCEL"></span>
    </button>
  </mat-card-actions>
  <mat-card-footer class="admin-settings-setting__footer">
    <span *ngIf="error" [translate]="error"></span>
  </mat-card-footer>
</mat-card>
