export enum MetaTypes {
  ACTIVITY = 'activity',
  ADM_EVENT = 'adm_event',
  ARTIFACT = 'artifact',
  CONCEPT = 'concept',
  CONSTRUCTION = 'construction',
  LOCATION = 'location',
  NAMED = 'named',
  PLACE = 'place',
  SPECTRUM_PROCEDURE = 'spectrum_procedure',
  SUB_MODEL = 'sub_model',
}
