<a class="main-menu-component__user-content"
   (click)="goToCurrentUser()">
  <div class="main-menu-component__user-image">
    <div class="main-menu-component__user-image-content">
      <app-thumbnail *ngIf="userImage" [item]="userImage" [imageIdField]="'image_id'"></app-thumbnail>
      <i class="icon-user"></i>
    </div>
  </div>
  <div class="main-menu-component__user-name">
    <span *ngIf="user && user.name">{{user.name}}</span>
    <span *ngIf="user && !user.name">{{user.username}}</span>
  </div>
</a>
<div class="main-menu-component__user-vertical-line"></div>
<div class="main-menu-component__user-log-out">
  <a (click)="logOut()" translate="TRANS__HEADER__LOG_OUT"></a>
</div>
