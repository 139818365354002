import {Component, OnDestroy, OnInit} from '@angular/core';
import {SearchHandlerService} from '../search-handler.service';
import {AConst} from '../../core/a-const.enum';
import {SearchPageService} from '../search-page.service';
import {SearchScrollService} from '../search-scroll.service';
import {SearchStateService} from '../search-state.service';
import {SearchExecutorService} from '../search-executor.service';
import {SearchContainer} from '../../core/definitions/search-container';
import {ActivatedRoute} from '@angular/router';
import {PrimusStateMapperService} from '../../core/primus-state-mapper.service';
import {PrimusRouterService} from '../../core/primus-router.service';
import {Subscription} from 'rxjs';
import {SearchTemplateGroupService} from '../search-template-group.service';
import {LoggerService} from "../../core/logger.service";

@Component({
  selector: 'app-search-page',
  templateUrl: './search-page.component.html',
  styleUrls: ['./search-page.component.scss']
})
export class SearchPageComponent implements OnInit, OnDestroy {

  searchContainer: SearchContainer;
  AConst = AConst;
  hideSearchView = false;
  private routerSubscription: Subscription;

  constructor(private logger: LoggerService,
              private route: ActivatedRoute,
              private primusStateMapper: PrimusStateMapperService,
              private primusRouter: PrimusRouterService,
              private searchHandler: SearchHandlerService,
              private searchPageService: SearchPageService,
              private searchScrollService: SearchScrollService,
              private searchStateService: SearchStateService,
              private searchExecutorService: SearchExecutorService,
              private searchTemplateGroupService: SearchTemplateGroupService) {
  }


  async ngOnInit() {
    this.searchPageService.addSearchContainerCreationListener(this.searchContainerCreationCallback.bind(this));
    this.routerSubscription = this.primusRouter.navigationHandler(() => {
      if (this.searchContainer) {
        this.searchContainerCreationCallback(this.searchContainer).then();
      }
    });
  }

  ngOnDestroy(): void {
    if (this.routerSubscription) {
      this.routerSubscription.unsubscribe();
    }
  }
  private async searchContainerCreationCallback(searchContainer: SearchContainer): Promise<void> {
    this.hideSearchView = this.primusStateMapper.isObjectState(this.primusRouter.currentState());
    const stateParams = this.route.snapshot.queryParamMap['params'];
    const stateParamsPath = this.searchStateService.getDecodedStateParameter(stateParams.path);
    this.searchContainer = searchContainer;
    const stateTargetId = stateParams.targetId || 'none';
    const searchContainerTargetId = searchContainer.targetId || 'none';
    const oldQuery = searchContainer.queryContainer.query;
    searchContainer.queryContainer.query = this.searchStateService.getDecodedStateParameter(stateParams.query);
    if (!stateParamsPath || searchContainer.path !== stateParamsPath || stateTargetId !== searchContainerTargetId) {
      await this.searchHandler.goSearchMenu(
        {
          path: stateParamsPath || searchContainer.path,
          targetId: stateParams.targetId || searchContainer.targetId,
          objectCount: Number(stateParams.objectCount) || searchContainer.objectCount
        },
        searchContainer);
    } else {
      if (!searchContainer.searchResult || oldQuery !== searchContainer.queryContainer.query) {
        await this.searchExecutorService.runSearch(searchContainer);
      }
      this.searchScrollService.scrollToYPos(searchContainer);
    }
    if (searchContainer.listFieldTemplateId && searchContainer.listFieldTemplateId !== stateParams['list_field_template_id']) {
      await this.searchTemplateGroupService.setListFieldTemplateGroup(searchContainer, stateParams['list_field_template_id']);
      await this.searchExecutorService.runSearch(searchContainer);
    }
  }
}
