import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Moment} from 'moment';
import * as moment from 'moment';
import {QueryField} from '../../../../../core/definitions/advanced-search/query-field';

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss']
})
export class DateInputComponent implements OnChanges {

  @Input() field: QueryField;
  @Input() label: string;
  @Input() fromDatePlaceholder: string;
  @Input() toDatePlaceholder: string;

  @Output() public readonly searchValueChanged: EventEmitter<string | null>;

  fromDate: Moment;
  toDate: Moment;

  constructor() {
    this.searchValueChanged = new EventEmitter<string | null>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hasOwnProperty('field')) {
      this.field = changes.field.currentValue;
      const {fromDate, toDate} = this.field?.inputOptions?.additionalData || {};
      if (fromDate) {
        this.fromDate = moment(fromDate);
      }
      if (toDate) {
        this.toDate = moment(toDate);
      }
    }
  }

  dateChanged(): void {
    const fromDate = this.fromDate?.toISOString() || '*';
    const toDate = this.toDate?.toISOString() || '*';
    this.field.inputOptions.additionalData = {fromDate, toDate};
    this.searchValueChanged.emit(`[${fromDate} TO ${toDate}]`);
  }

}
