<div class="search-filter-menu"
     *ngIf="searchContainer.searchResult"
     [ngClass]="{'sideMenu': sideMenu, 'dropdownMenu': !sideMenu}">

  <div class="search-filter-menu__top" *ngIf="sideMenu">
    <h4>
      <mat-icon class="search-filter-menu__top-icon">filter_alt</mat-icon>
      <span translate="TRANS__SEARCH__LIMIT_RESULT"></span>
      <span class="search-filter-menu__top-count">({{searchContainer.searchResult.search_count}})</span></h4>

    <button mat-button class="search-filter-menu__top-close-side-menu"
            (click)="toggleSideFilterMenuSmallScreen()">
      <mat-icon>close</mat-icon>
    </button>
  </div>

  <div class="search-filter-menu__container"
       [ngClass]="{'home': searchContainer.path === 'home',
       'dropdown-in-selector': !sideMenu && searchContainer.searchResultViewName === SearchResultViewNames.SELECTOR}">

    <app-search-filter-menu-path
      *ngIf="sideMenu"
      class="search-filter-menu__menu-path"
      [sideMenu]="sideMenu"
      [searchContainer]="searchContainer"></app-search-filter-menu-path>

    <div class="search-filter-menu__content">
      <div class="search-filter-menu__inner-content" #searchFilterMenu>
        <ul *ngIf="searchContainer.queryContainer.query || pathView.menus.length > 0"
            class="search-filter-menu__menu-items">
          <li class="search-filter-menu__menu-item"
              *ngFor="let mainMenu of pathView.menus">

            <app-search-filter-menu-item
              class="search-filter-menu__menu-item-content"
              [ngClass]="{
              'removeFilterItem': searchContainer.searchResultViewName ===
              SearchResultViewNames.CONTENT_LIST && mainMenu.count === 0 }"
              [sideMenu]="sideMenu"
              [subMenu]="false"
              [menu]="mainMenu"
              [searchContainer]="searchContainer"
            ></app-search-filter-menu-item>

            <ng-container *ngIf="pathView.menus[0].menus && mainMenu.menus.length > 0 && mainMenu.openItem">
              <ul class="search-filter-menu__menu-item-sub-menu">
                <li class="search-filter-menu__menu-item-sub-menu-item"
                    *ngFor="let subMenu of mainMenu.menus">
                  <app-search-filter-menu-item
                    [menu]="subMenu"
                    [sideMenu]="sideMenu"
                    [subMenu]="true"
                    [searchContainer]="searchContainer"
                  ></app-search-filter-menu-item>
                </li>
              </ul>
            </ng-container>
          </li>
        </ul>

        <div class="search-filter-menu__filter-groups" *ngIf="searchContainer.filtersFacets.hierarchicFilterGroup">
          <app-search-filter-hierarchic
            class="search-filter-menu__filter-groups-item"
            [sideMenu]="sideMenu"
            [searchContainer]="searchContainer"
          ></app-search-filter-hierarchic>
        </div>

        <div class="search-filter-menu__filter-groups"
             *ngFor="let fGroup of filterGroups">
          <app-search-filter-group
            *ngIf="fGroup.checkFilters.length > 0"
            class="search-filter-menu__filter-groups-item"
            [sideMenu]="sideMenu"
            [filterGroup]="fGroup"
            [searchContainer]="searchContainer"
          ></app-search-filter-group>
        </div>

        <div class="search-filter-menu__filter-groups">
          <app-search-facet-range
            *ngFor="let group of searchContainer.filtersFacets.facetRangeGroups"
            [searchContainer]="searchContainer"
            [group]="group"
            [sideMenu]="sideMenu"
            (scrollToBottom)="scrollToBottom()"
          ></app-search-facet-range>
        </div>

      </div>
    </div>

  </div>
</div>
