// TODO: Most of these constants should be replaced with use of enums or classes, please don't add new constants
export enum AConst {
  ANSWER = 'answer',
  ARRAY = 'array',
  ARRAY_LEVEL = 'array_level',
  ARTIFACT = 'artifact',
  ARTIFACT_ID = 'artifact_id',
  ARTIFACT_NAME = 'artifact_name',
  ATTACHMENT_TYPE = 'attachment_type',
  AUTHORITY_MARKED = 'authority_marked',
  CATEGORIES = 'categories',
  CATEGORY_TITLE = 'category_title',
  CONTENT_MENU_OBJECT_SOURCE_LIST = 'content_menu_object_source_list',
  CONTENT_MENU_SEARCH_VIEW = 'content_menu_search_view',
  CONTENT_LIST = 'content_list',
  CONTEXT_ID = 'context_id',
  CONTEXT_ID_VALUE = 'context_id_value',
  CONTEXT_OBJECT_TYPE = 'context_object_type',
  CONTEXTS = 'contexts',
  CREATED_BY_ID = 'created_by_id',
  EDIT = 'edit',
  EDIT_ONCE = 'edit_once',
  EDIT_ONCE_NOT_STORED = 'edit_once_not_stored',
  EDIT_OWNER = 'edit_owner',
  F_PATH = 'f_path',
  FAQ = 'faq',
  FIELD_UUID = 'field_uuid',
  GROUP_NAME = 'group_name',
  ICON_FRAME = 'icon_frame',
  MAP_ID = 'map_id',
  MENUS = 'menus',
  MESSAGE = 'message',
  MESSAGES = 'messages',
  META_TYPE = 'meta_type',
  MAX_FILTER_LENGTH = 10,
  MODEL = 'model',
  NAME = 'name',
  OBJECT_TYPE = 'object_type',
  ORDER = 'order',
  ORDER_NUMBER = 'order_number',
  OVERVIEW_FIELDS = 'overview_fields',
  PARENT_FIELD = 'parent_field',
  PARENT_ID = 'parent_id',
  PARENT_NAME = 'parent_name',
  QUERY_FIELD = 'query_field',
  REG_LEVEL = 'reg_level',
  REG_LEVEL_TYPE_ID = 'reg_level_type_id',
  SOURCE_FIELD = 'source_field',
  SOURCE_NAME = 'source_name',
  STATUS_MSG = 'status_msg',
  SUB_MENUS = 'sub_menus',
  TEMPLATE_GROUP_ID = 'template_group_id',
  TEMPLATE_GROUPS = 'template_groups',
  TEMPLATE_MODELS = 'template_models',
  THING = 'thing',
  USAGE = 'usage',
  USER = 'user',
  WHAT_IS_NEW = 'what_is_new'
}
