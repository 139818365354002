<span class="inline-header-value">
  <span *ngIf="isString()"
        class="inline-header-value__text"
        [ngClass]="{'hasIcon': label.icon === '{conceptIcon}',
        'ellipseTextLabel': label.type === 'text' || label.name === 'url'}">
     <span *ngIf="container.values[label.key].indexOf('TRANS__') === -1"
           [innerHtml]="container.values[label.key]"></span>
    <span *ngIf="container.values[label.key].indexOf('TRANS__') !== -1"
          [translate]="container.values[label.key]"></span>
  </span>
  <span *ngIf="label.sRef" class="object-url__object-link">
    <!--<a *ngIf="!edit" [uiSref]="label.sRef.stateName"-->
       <!--[uiParams]="label.sRef.param"-->
       <!--[uiOptions]="{reload: true}"-->
       <!--[tabindex]="-1">-->
      <!--<span class="text-link"-->
            <!--[innerHtml]="container.values[label.key]"></span>-->
    <!--</a>-->
    <span [innerHtml]="container.values[label.key]"></span>
  </span>

</span>
