<div class="admin-about">
  <app-top-header [parent]="parent"
                  class="admin-header-container"></app-top-header>

  <app-header-and-description heading="TRANS__ABOUT_PRIMUS__INFORMATION_TITLE"
                              description="TRANS__ABOUT_PRIMUS__INFORMATION_DESCRIPTION">
    <div class="admin-row">
      <mat-card class="admin-column admin-card">
        <mat-card-header class="admin-card__header">
          <mat-card-title translate="TRANS__ABOUT_PRIMUS__INFORMATION__TITLE"></mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-list>
            <mat-list-item>
              <span class="admin-about__information-item-title" translate="TRANS__ABOUT_PRIMUS__VERSION"></span>
              <span class="">{{version}}</span></mat-list-item>

            <mat-list-item>
              <span class="admin-about__information-item-title" translate="TRANS__ABOUT_PRIMUS__EDITION"></span>
              <span [translate]="edition.title"></span></mat-list-item>

            <mat-list-item class="admin-about__user-manual-item">
              <span class="admin-about__user-manual-item-header" translate="TRANS__ABOUT_PRIMUS__USER_MANUAL_HEADLINE"></span>
              <ng-container *ngIf="userManual">
                <div class="admin-about__user-manual-item-button-container"
                     *ngFor="let manual of userManual.manuals">
                  <a mat-button
                     class="text-blue"
                     (click)="logDownloadManual(manual.title)"
                     [href]="manual.href" target="_blank">
                    <i class="icon-file-pdf"></i>
                    <span [translate]="manual.title"></span></a></div>
              </ng-container>
            </mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>

      <mat-card class="admin-column admin-card">
        <mat-card-header class="admin-card__header">
          <mat-card-title translate="TRANS__ADMIN__HELP__TITLE"></mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-list>
            <mat-list-item><span class="bold" translate="TRANS__ADMIN__HELP__CONTACT"></span></mat-list-item>
            <mat-list-item><span translate="TRANS__ADMIN__HELP__NUMBER"></span></mat-list-item>
            <mat-list-item><span translate="TRANS__ADMIN__HELP__MAIL"></span>
              <a class="admin-email text-blue-hover"
                 href="mailto:support@kulturit.no">support@kulturit.no</a></mat-list-item>
          </mat-list>
        </mat-card-content>
      </mat-card>
    </div>

    <div class="admin-row">
      <mat-card class="admin-column admin-card">
        <mat-card-header class="admin-card__header">
          <mat-card-title translate="TRANS__ABOUT_PRIMUS__PREVIOUS_CHANGES__TITLE"></mat-card-title></mat-card-header>

        <mat-card-content class="admin-card__content">
          <mat-tab-group  (selectedTabChange)="tabButton($event)">
            <mat-tab *ngFor="let tab of changeList; index as i"
                     [label]="tab.version">

              <div *ngIf="changeList.length" class="admin-about__change-content">
                <div class="what-is-new__table">
                  <div class="what-is-new__table__row what-is-new__table__row-header">
                    <div class="what-is-new__table__row-item">
                      <span translate="TRANS__ABOUT_PRIMUS__CHANGES__VERSION" class="bold"
                            [translateParams]="{version: changeList[currentChangeIndex].version}"></span></div>
                    <div class="what-is-new__table__row-item"
                         translate="TRANS__EDITION__SMALL"></div>
                    <div class="what-is-new__table__row-item"
                         translate="TRANS__EDITION__LARGE"></div>
                    <div class="what-is-new__table__row-item"
                         translate="TRANS__EDITION__MEDIUM"></div></div>
                  <div class="what-is-new__table__row what-is-new__table__row-content"
                       *ngFor="let change of changeList[currentChangeIndex].value">
                    <div class="what-is-new__table__row-item">
                      <span [innerHTML]="change.description"></span></div>
                    <div *ngFor="let edition of change.edition"
                         class="what-is-new__table__row-item {{edition}}">
                      <mat-icon *ngIf="edition === 'small' || edition === 'large' || edition === 'medium'">check</mat-icon></div>
                  </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </mat-card-content>
      </mat-card>
    </div>
  </app-header-and-description>
</div>
