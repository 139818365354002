<div class="overview-title" *ngIf="objectView">
  <div *ngIf="objectView && objectView.authority_id"
       class="authority-ribbon-container">
    <div class="authority-ribbon"
         [ngClass]="{
         'situla': objectView.authority_id === clientConfig.AUTHORITY_ARCHAEOLOGY_ID,
         'ribbon-culture-hub': objectView.authority_id === clientConfig.AUTHORITY_KULTURNAV_ID}">
      <span>{{objectView.authority_id_value}}</span></div>
  </div>
  <div *ngIf="!noTitle">

    <h1 *ngIf="!titleAsLink">
      <i class="list-item-icon object-icon {{objectView.icon}}"
         [class.text-blue]="objectView.meta_type === MetaTypes.ADM_EVENT"></i>
      <span [ngClass]="{'overview-title__headline': !smallTit, 'overview-title__headline-small': smallTit}">{{title}}</span>
    </h1>

    <span *ngIf="titleAsLink"
          [ngClass]="{'overview-title__headline': !smallTit, 'overview-title__headline-small': smallTit, 'bold': smallTit}">
      <app-object-url [object]="objectView">
        <span class="object-url-content">
          <i class="list-item-icon object-icon {{objectView.icon}}"
             [class.text-blue]="objectView.meta_type === MetaTypes.ADM_EVENT"></i> {{title}}</span>
      </app-object-url>
    </span>

  </div>

</div>
