<div *ngFor="let mediaElement of mediaType.mediaElements; let i = index"
     class="thumbnail-stack"
     (click)="setMedia(i)"
     [attr.data-index]="i"
     [ngClass]="{
     'large': mediaContainer.thumbWidth === 'large',
     'medium': mediaContainer.thumbWidth === 'medium',
      'small': mediaContainer.thumbWidth === 'small'}">
  <div class="thumbnail-stack__img-container"
       [ngClass]="{'selected border-blue': i == mediaType.currentMediaIndex &&
       mediaType.mediaType == mediaContainer.currentMediaType.mediaType}">
    <div class="thumbnail-stack__img-content">
      <img *ngIf="!invalidSrc[i]"
           id='thumb-{{i}}'
           [alt]="mediaElement.mediaObject.artifact_name"
           [src]="mediaElement.mediaObject.$$thumbUrl"
           (error)="setInvalidSrc(i)">
      <i *ngIf="invalidSrc[i]" class="icon-image-attention"></i>
    </div>
    <div class="thumbnail-stack__media-type">
      <i class="icon-{{mediaElement.mediaObject.object_type.toLowerCase()}}"></i>
    </div>
  </div>
</div>
