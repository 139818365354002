import {Component, Input, OnChanges} from '@angular/core';
import {SearchHandlerService} from '../../search-handler.service';
import {FocusServiceImplementation, SearchFocusService} from '../../search-focus.service';
import {SearchViewMenu} from '../../../core/definitions/search-objects';
import {SearchFilterService} from '../../search-filter.service';
import {SearchContainer} from '../../../core/definitions/search-container';
import {PrimusStateMapperService} from '../../../core/primus-state-mapper.service';

@Component({
  selector: 'app-search-filter-menu-item',
  templateUrl: './search-filter-menu-item.component.html',
  styleUrls: ['./search-filter-menu-item.component.scss']
})
export class SearchFilterMenuItemComponent implements OnChanges {

  @Input() menu: SearchViewMenu;
  @Input() sideMenu;
  @Input() subMenu;
  @Input() searchContainer: SearchContainer;

  private fsi: FocusServiceImplementation;

  constructor(public primusStateMapper: PrimusStateMapperService,
              private searchHandler: SearchHandlerService,
              private searchFocusService: SearchFocusService,
              private searchFilterService: SearchFilterService) {
  }

  ngOnChanges() {
    if (this.menu) {
      if (this.menu.menus && this.menu.order === 0) {
        this.setOpenItem(this.menu);
      }
    }
  }

  openFilterItem(menu) {
    this.setOpenItem(menu);
  }

  async checkMenuFilter($event) {
    $event.preventDefault();
    await this.searchFilterService.checkMenuFilter(this.menu, this.searchContainer);
  }

  async goToMenuPath(): Promise<void> {
    this.fsi = this.searchFocusService.createFSI(this.searchContainer);
    if (this.searchContainer.focus.curFocus && this.searchContainer.focus.curFocus.focus.stored) {
      const exists = this.searchFocusService.checkPathParamsExists(this.menu.path, this.searchContainer);
      if (!exists) {
        await this.fsi.createOrUpdateFocus(this.searchContainer.focus.curFocus);
      }
    }
    this.searchContainer.objectCount = 0;
    this.searchContainer.path = this.menu.path;
    await this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer);
  }

  private setOpenItem(menu: SearchViewMenu) {
    if (this.searchContainer.currentPathView.search_view.menus) {
      this.searchContainer.currentPathView.search_view.menus.forEach(mainMenu => {
        if (menu.facet_values === mainMenu.facet_values) {
          menu.openItem = !menu.openItem;
        } else {
          mainMenu.openItem = false;
        }
      });
    }
  }

}
