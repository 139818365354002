import {Component, Input, OnChanges} from '@angular/core';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {SearchObject} from '../../core/definitions/search-object';
import {SearchParameters} from '../../core/definitions/search-parameters';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {SolrFilterService} from '../../core/solr-filter.service';
import {SearchService} from "../../core/search.service";
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-reference-usage',
  templateUrl: './reference-usage.component.html',
  styleUrls: ['./reference-usage.component.scss']
})
export class ReferenceUsageComponent implements OnChanges {

  @Input() fieldParameters: FieldParameters;
  @Input() parentObject: SuperObjectModel;
  @Input() showReferencesAsLink: boolean;

  constructor(private searchService: SearchService,
              private solrFilter: SolrFilterService,
              private searchReferenceService: SearchReferenceService) {
  }

  usage: SearchObject[] = [];
  displayUsage = false;

  ngOnChanges(): void {
    this.getReferenceUsage(this.fieldParameters).then(usage => {
      this.usage = usage;
    });
  }


  toggleDisplayUsage() {
    this.displayUsage = !this.displayUsage;
  }

  private async getReferenceUsage(fieldParameters: FieldParameters): Promise<SearchObject[]> {
    let res: SearchObject[] = [];
    const ref = await this.searchReferenceService.getSearchReferenceFromField(fieldParameters.field);
    if (ref?.show_usage && fieldParameters.object) {
      const usageObjectId = fieldParameters.object.artifact_id;
      const solrField = `${fieldParameters.field.name}.${ref.ref_prop}`;
      const searchParams = {} as SearchParameters;
      this.solrFilter.addFq(searchParams, solrField, usageObjectId);
      if (this.parentObject) {
        this.solrFilter.addFq(searchParams, '-artifact_id', this.parentObject.artifact_id);
      }
      const searchRes = await this.searchService.search(searchParams);
      res = searchRes.artifacts || [];
    }
    return res;
  }

}
