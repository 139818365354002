import {Component, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import {ObjectEditService} from '../../core/object-edit.service';
import {SearchSelectorService} from '../../core/search-selector.service';
import {FieldValueService} from '../../core/field-value.service';
import {CmsApiService} from '../../core/cms-api.service';
import {FieldMetaHandlerService} from '../../core/field-meta-handler.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {AConst} from '../../core/a-const.enum';
import {MetaField} from '../../core/definitions/meta-field';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {TemplateGroupSelector} from '../../core/definitions/template-group-selector';
import {SelectorContainer} from '../../core/definitions/selector-container';
import {UserCacheService} from '../../core/user-cache.service';
import {SelectorCreationParams} from '../../core/definitions/selector-creation-params';
import {SearchObject} from '../../core/definitions/search-object';
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-template-group-select-button',
  templateUrl: './template-group-select-button.component.html',
  styleUrls: ['./template-group-select-button.component.scss']
})
export class TemplateGroupSelectButtonComponent implements OnChanges {
  @Input() object: SuperObjectModel;
  @Input() templateGroupId: string;
  @Input() noPreselect: boolean;
  @Input() disabled: boolean;
  @Input() simple: boolean;
  @Input() collapseOnSmallScreen: boolean;
  @Output() templateSelectorCreated = new EventEmitter<any>();
  @Output() changeTemplateGroup = new EventEmitter<string>();

  private model: TemplateGroupSelector;
  private fieldParameters: FieldParameters;
  templateSelectorContainer: SelectorContainer;
  locked: boolean;

  constructor(private objectEditService: ObjectEditService,
              private selectorHandler: SearchSelectorService,
              private fieldValueService: FieldValueService,
              private cms: CmsApiService,
              private fieldMetaHandler: FieldMetaHandlerService,
              private userCacheService: UserCacheService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnChanges() {
    this.initSelector().then();
  }

  enableTemplateSelector() {
    if (this.templateSelectorContainer && this.templateSelectorContainer.selectorEnabled) {
      this.selectorHandler.disableSelector(this.templateSelectorContainer);
    } else {
      this.getListSearchContainer().then();
    }
  }

  private async initSelector() {
    if (!this.noPreselect && !this.templateGroupId) {
      const info = await this.userCacheService.getUserTemplateGroupInfo();
      if (info) {
        this.doChangeTemplateGroup(info.template_group_id);
        this.templateGroupId = info.template_group_id;
        this.locked = info.locked;
      }
    }
    await this.createTemplateSelector();
  }

  private async createTemplateSelector() {
    let modelTypeId;
    if (this.object) {
      modelTypeId = this.object.superobject_type_id;
    }
    const found = await this.checkTemplateGroupExists();
    if (!found) {
      this.templateGroupId = null;
    }
    const mod = await this.objectEditService.createModelItem(
      'TemplateGroupSelector',
      {
        model_type_id: modelTypeId,
        template_group_id: this.templateGroupId
      }
    );
    this.model = <TemplateGroupSelector>mod;
    this.fieldParameters = new FieldParameters();
    this.fieldParameters.rootObject = this.model;
    this.fieldParameters.object = this.model;
    this.fieldParameters.field = {} as MetaField;
    this.fieldParameters.field.name = AConst.TEMPLATE_GROUP_ID;
  }

  private async checkTemplateGroupExists(): Promise<boolean> {
    let groupFound = false;
    if (this.templateGroupId) {
      const templateGroups = await this.userCacheService.getTemplateGroups();
      for (const group of templateGroups) {
        if (group.template_group_id === this.templateGroupId) {
          groupFound = true;
          break;
        }
      }
    }
    return groupFound;
  }

  private getSelectedValues(): SearchObject[] {
    const res = [];
    const value = this.fieldValueService.getFieldValue(this.fieldParameters.object, AConst.TEMPLATE_GROUP_ID);
    if (value) {
      res.push({
        artifact_id: value
      } as SearchObject);
    }
    return res;
  }

  private doChangeTemplateGroup(newGroupId) {
    if (newGroupId !== this.templateGroupId) {
      this.changeTemplateGroup.emit(newGroupId);
    }
  }

  private async getListSearchContainer() {
    let selector;
    if (this.fieldParameters) {
      const meta = this.fieldMetaHandler.getMetaParamsFromFieldParameters(this.fieldParameters, 'current', true);
      const reference = await this.searchReferenceService.getSearchReferenceFromField(meta);
      selector =reference.selector;
      const selected = this.getSelectedValues();
      const params = {
        selected: selected
      } as SelectorCreationParams;
      this.templateSelectorContainer = this.selectorHandler.createSelectorContainer(null);

      this.templateSelectorCreated.emit(this.templateSelectorContainer);

      this.selectorHandler.enableSelector(
        selector, this.templateSelectorContainer, params,
        {
          searchContainerCreated: (/*sc*/) => {
          },
          selectorCallback: (selectedObj) => {
            if (selectedObj.length > 0) {
              this.model.template_group_id = selectedObj[0].template_group_id;
            } else {
              this.model.template_group_id = undefined;
            }
            this.doChangeTemplateGroup(this.model.template_group_id);
            this.selectorHandler.disableSelector(this.templateSelectorContainer);
          }
        }
      );
    }
  }

}
