import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  ViewChild
} from '@angular/core';
import {MediaHelperService} from '../../core/media-helper.service';
import {CommonsService} from '../../core/commons.service';
import {UiToolsService} from '../../core/ui-tools.service';
import {AnnotationHandler} from '../../image-annotation/annotation-handler';
import {AnnotationService} from '../../image-annotation/annotation.service';
import {Image} from '../../core/definitions/image';
import {ImageItem} from '../../core/definitions/image-item';
import {ObjectMediaContainer, ObjectMediaType} from '../../core/definitions/object-media-container';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {MediaTypeChanges} from '../media-type-changes';

@Component({
  selector: 'app-media-carousel',
  templateUrl: './media-carousel.component.html',
  styleUrls: ['./media-carousel.component.scss']
})
export class MediaCarouselComponent implements AfterViewInit, OnChanges, OnDestroy {
  zoomValue = 1;
  container;
  collapseZoomInButton = false;
  showAnnotationToggle = false;
  toggleAnnotations = false;
  resizeTimeOut;
  imageGallery = false;
  showMediaOrder = false;
  disableNextButton = false;
  stopSizeWatch;
  mediumScreen;
  setSmallThumbSize = false;
  thumbSize = {
    small: 'TRANS__MEDIA_CAROUSEL__SIZE_BUTTON_SMALL',
    medium: 'TRANS__MEDIA_CAROUSEL__SIZE_BUTTON_MEDIUM',
    large: 'TRANS__MEDIA_CAROUSEL__SIZE_BUTTON_LARGE'
  };
  mediumScreenThumbnailWidth = 150;
  thumbWidthKeys = Object.keys(this.thumbSize);

  @ViewChild('container') containerElement: ElementRef;
  @ViewChild('mediaElementContainer') mediaElementContainer: ElementRef;
  @Input() mediaContainer: ObjectMediaContainer;
  @Input() startMediaId;
  @Input() sectionsContainer: SectionsContainer;
  @Input() curAnn: AnnotationHandler;
  @Input() isEditing;
  @Output() mediaUpdated = new EventEmitter<string>();
  @Output() openImageFullScreen = new EventEmitter<object>();
  @Output() objectRefresh = new EventEmitter<object>();

  constructor(private mediaHelper: MediaHelperService,
              private elementRef: ElementRef,
              private commons: CommonsService,
              private uiTools: UiToolsService,
              private annotationService: AnnotationService) {
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    // wait for resize to be finished before doing stuff
    if (this.resizeTimeOut) {
      clearTimeout(this.resizeTimeOut);
    }
    this.resizeTimeOut = setTimeout((() => {
      this.resize();
    }), 500);
  }

  ngOnChanges() {
    if (this.sectionsContainer?.rootObject) {
      this.showAnnotationToggle = this.sectionsContainer.rootObject.meta_type !== 'media';
      this.imageGallery = this.sectionsContainer.rootObject.meta_type === 'media';
    }
    this.mediumScreen = this.uiTools.setMediumScreenSizeOnLoad(this.mediumScreen);
    this.setThumbWidthFromWindowSize();
    this.setSmallThumbSize = window.innerHeight < 900 && !this.mediumScreen;
    this.setCurrentMediaFromStartMediaId();
    this.stopSizeWatch = this.uiTools.addWindowSizeListener(
      (newVal) => {
        this.mediumScreen = newVal.width < 1025;
        // this.smallScreen = newVal.width < 642;
      }
    );
  }

  ngAfterViewInit() {
    this.container = this.containerElement.nativeElement;
  }

  ngOnDestroy() {
    if (this.stopSizeWatch) {
      this.uiTools.removeWindowSizeListener(this.stopSizeWatch);
    }
  }

  private setCurrentMediaFromStartMediaId() {
    let indexSet = false;
    const mustSetIndex = this.startMediaId !== null;
    if (mustSetIndex) {
      for (const mediaType of this.mediaContainer.mediaTypes) {
        for (let i = 0; i < mediaType.mediaElements.length; i++) {
          if (mustSetIndex && mediaType.mediaElements[i].mediaObject.artifact_id === this.startMediaId) {
            indexSet = true;
            this.setCurrentMedia(mediaType, i).then();
            break;
          }
        }
        if (indexSet) {
          break;
        }
      }
    }
    if (!indexSet && this.mediaContainer.mediaTypes.length) {
      this.setCurrentMedia(this.mediaContainer.mediaTypes[0], 0).then();
    }
  }

  private setMediaTypeCanAnnotate() {
    const mediaType = this.mediaContainer.currentMediaType;
    if (mediaType.mediaType === 'Image' && this.showAnnotationToggle && this.sectionsContainer?.rootObject) {
      this.annotationService.canAnnotate(this.sectionsContainer.rootObject).then(res => {
        mediaType.canAnnotate = res;
      });
    } else {
      mediaType.canAnnotate = false;
    }
  }

  async toggleAnnotation() {
    this.toggleAnnotations = !this.toggleAnnotations;
    this.collapseZoomInButton = true;
    this.onChangeZoomValue(1);
    if (this.toggleAnnotations) {
      this.setCurrentAnnotation().then();
    }
    setTimeout(() => this.collapseZoomInButton = false, 1000);
  }

  onSetActiveMedia(mediaType: ObjectMediaType, index, direction?) {
    this.collapseZoomInButton = true;

    if (this.mediumScreen) {
      if (index.nextPrevMediaType) {
        const currentMedia = this.mediaContainer.mediaTypes.indexOf(mediaType);
        const nextMedia = this.mediaContainer.mediaTypes[currentMedia + 1];
        const prevMedia = this.mediaContainer.mediaTypes[currentMedia - 1];
        const hasNextMedia = this.mediaContainer.mediaTypes.indexOf(nextMedia);

        if (direction === 'next' && nextMedia) {
          mediaType = nextMedia;
          if (hasNextMedia === this.mediaContainer.mediaTypes.length - 1) {
            this.disableNextButton = true;
          }
        }
        if (direction === 'prev' && prevMedia) {
          mediaType = prevMedia;
          this.disableNextButton = false;
          index.index =  mediaType.mediaElements.length - 1;
        }
      }
      if (direction) {
        const scrollAmount = 150 * ((direction === 'prev') ? -1 : 1);
        this.mediaElementContainer.nativeElement.scrollLeft += scrollAmount;
      }
    }


    this.setCurrentMedia(mediaType, index.index).then(() => {
      const currentMediaId = mediaType.mediaElements[index.index].mediaObject.artifact_id;
      this.mediaUpdated.emit(currentMediaId);
      this.onChangeZoomValue(1);

      if (this.mediumScreen && direction === undefined) {
        this.scrollToSelectedElementOnResizeOrClick();
        const currentMediaIndex = this.mediaContainer.mediaTypes.indexOf(mediaType);
        this.disableNextButton = currentMediaIndex === this.mediaContainer.mediaTypes.length - 1;
      }
      setTimeout(() => this.collapseZoomInButton = false, 1000);
    });
  }

  onToggleChangeMediaOrder(mediaTypeChangeList?: MediaTypeChanges[]) {
    this.showMediaOrder = !this.showMediaOrder;
    if (this.showMediaOrder) {
      document.getElementsByTagName('body')[0].classList.add('selector-open');
      document.getElementsByTagName('html')[0].classList.add('selector-open');
    } else {
      document.getElementsByTagName('body')[0].classList.remove('selector-open');
      document.getElementsByTagName('html')[0].classList.remove('selector-open');
    }

    if (mediaTypeChangeList) {
      // Need to sort the mediaElements based on the new sort order for meta items from "change media order" component,
      // also need to set the new order numbers on the existing items
      mediaTypeChangeList.forEach(mediaTypeChanges => {
        for (const mediaType of this.mediaContainer.mediaTypes) {
          if (mediaTypeChanges.objectMediaType.mediaType === mediaType.mediaType) {
            const elementsWithNewOrder = [];
            mediaType.mediaElements = elementsWithNewOrder;
            for (const mediaItemChanged of mediaTypeChanges.mediaItems) {
              for (const mediaElement of mediaType.mediaElements) {
                if (mediaElement.mediaItem.$$mediaId === mediaItemChanged.$$mediaId) {
                  mediaElement.mediaItem.order_number = mediaItemChanged.order_number;
                  elementsWithNewOrder.push(mediaElement);
                  break;
                }
              }
            }
          }
        }
      });
      this.objectRefresh.emit({});
    }
  }

  onChangeZoomValue(zoom) {
    this.zoomValue = zoom.value ? zoom.value : zoom;
  }

  setThumbWidth(width) {
    this.mediaContainer.thumbWidth = width;
  }

  canAnnotate() {
    return this.mediaContainer.currentMediaType?.canAnnotate;
  }

  private resize() {
    this.onChangeZoomValue(this.zoomValue);
    this.mediumScreen ? this.scrollToSelectedElementOnResizeOrClick() : this.setThumbWidthFromWindowSize();
  }

  private scrollToSelectedElementOnResizeOrClick() {
    const el = document.getElementById(
      'thumb-' + this.mediaContainer.currentMediaType.mediaType + '-' +
      this.mediaContainer.currentMediaType.currentMediaIndex);
    const scrollTo = (el.getBoundingClientRect().left + this.mediaElementContainer.nativeElement.scrollLeft) - 85;
    this.mediaElementContainer.nativeElement.scrollTo(scrollTo, 0);
  }

  private setThumbWidthFromWindowSize() {
    this.setSmallThumbSize = window.innerHeight < 900 && !this.mediumScreen;
    if (this.setSmallThumbSize) {
      this.mediaContainer.thumbWidth = 'small';
    } else {
      this.mediaContainer.thumbWidth = this.mediaContainer.thumbWidth ?? 'medium';
    }
  }

  private async setCurrentMedia(mediaType: ObjectMediaType, mediaIndex: number): Promise<void> {
    this.mediaContainer.currentMediaType = mediaType;
    mediaType.currentMediaIndex = mediaIndex;
    this.setMediaTypeCanAnnotate();
    if (this.toggleAnnotations) {
      await this.setCurrentAnnotation();
    }
  }

  private async setCurrentAnnotation(): Promise<void> {
    const mediaType = this.mediaContainer.currentMediaType;
    if (mediaType.mediaType === 'Image' && this.curAnn) {
      const image = new ImageItem();
      image.image_id = mediaType.mediaElements[mediaType.currentMediaIndex].mediaObject.artifact_id;
      await this.annotationService.setCurAnnotation(
        this.curAnn,
        this.sectionsContainer.rootObject,
        image,
        this.sectionsContainer.rootObject,
        null,
        true).then();
      if (this.curAnn.selectedAnnotation) {
        this.curAnn.readOnly = true;
      }
    }
  }

  onOpenImageFullScreen(activeImage) {
    this.openImageFullScreen.emit(activeImage);
  }

}
