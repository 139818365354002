<div class="object-navigator"
     *ngIf="!currentObjectId || (searchMode && rootObjId === currentObjectId)">

  <button class="object-navigator__prevNext-button"
          mat-button
          type="button"
          [ngClass]="{'disable-button': !canGoPrev || isEditing}">
    <app-object-url
      *ngIf="prevItem"
      [object]="prevItem"
      [searchContainer]="searchContainer"
      [rootObjId]="prevItem.artifact_id"
      [rootObjType]="prevItem.object_type"
      [searchItemIndex]="searchContainer.searchItemIndex - 1"
      [listName]="prevItemListName">
      <div class="object-url-content text-blue-hover">
        <mat-icon>navigate_before</mat-icon>
        <span class="underline-first-letter"
              >{{'TRANS__SEARCH_NAVIGATION__PREVIOUS' | translate}}</span></div>
    </app-object-url>
  </button>

  <button class="object-navigator__to-search-button text-blue-hover"
          mat-button
          type="button"
          (click)="goToSearch()"
          [disabled]="isEditing">
    <mat-icon>manage_search</mat-icon>
    <span class="object-navigator__to-search-button-text">
      <span>{{'TRANS__SEARCH_NAVIGATION__TO' | translate}}</span>
      <span class="underline-first-letter"
            >{{'TRANS__SEARCH_NAVIGATION__THE_SEARCH' | translate}}</span></span>
  </button>

  <button
    class="object-navigator__prevNext-button"
    mat-button
    type="button"
    [ngClass]="{'disable-button': !canGoNext || isEditing}">
    <app-object-url
      [object]="nextItem"
      [searchContainer]="searchContainer"
      [rootObjId]="nextItem.artifact_id"
      [rootObjType]="nextItem.object_type"
      [searchItemIndex]="searchContainer.searchItemIndex + 1"
      [listName]="nextItemListName">
      <div class="object-url-content text-blue-hover">
        <span class="underline-first-letter"
              >{{'TRANS__SEARCH_NAVIGATION__NEXT' | translate}}</span>
        <mat-icon>navigate_next</mat-icon></div>
    </app-object-url>
  </button>
</div>

<div class="object-navigator"
     *ngIf="currentObject && rootObjId && rootObjId !== currentObjectId">
  <button class="backToRoot-button"
          mat-button
          type="button"
          [ngClass]="{'disable-button': isEditing}">
      <app-object-url
        [object]="rootItem"
        [searchContainer]="searchContainer"
        [rootObjId]="rootObjId"
        [rootObjType]="rootObjType"
        [searchItemIndex]="searchContainer.searchItemIndex">
        <div class="object-url-content text-blue-hover">
          <mat-icon>navigate_before</mat-icon>
          <span class="underline-first-letter"
                >{{'TRANS__SEARCH_NAVIGATION__BACK_TO' | translate}}</span>:
          <app-object-name
            class="backToRoot-button__name"
            [object]="rootItem"
            [stylingClass]="'search-navigator-name'"></app-object-name></div>
      </app-object-url>
  </button>
</div>

<div class="object-navigator" *ngIf="!rootObjId || (rootObjId && !searchMode)">
  <button class="object-navigator__to-search-button text-blue-hover"
          [disabled]="isEditing"
          mat-button
          type="button"
          (click)="goBack()"
          routerLink="/search"
          queryParamsHandling="merge">
    <mat-icon>manage_search</mat-icon>
    <span class="object-navigator__to-search-button-text">
      <span class="underline-first-letter"
            >{{'TRANS__SEARCH_NAVIGATION__GO_BACK' | translate}}</span></span>
  </button>
</div>
