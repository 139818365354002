<div class="recto-verso-buttons" *ngIf="hasRectoVerso">
  <button mat-stroked-button color="primary"
          class="recto-verso-buttons__button"
          [disabled]="isRecto"
          [ngClass]="{'active': isRecto}" (click)="goToSearchItem(rectoObject)">
    <mat-icon *ngIf="isRecto" class="recto-verso-buttons__icon">visibility</mat-icon>
    <span class="bold uppercase">Recto</span>
  </button>
  <button mat-stroked-button color="primary"
          class="recto-verso-buttons__button"
          [disabled]="isVerso"
          [ngClass]="{'active': isVerso}" (click)="goToSearchItem(versoObject)">
    <mat-icon *ngIf="isVerso" class="recto-verso-buttons__icon">visibility</mat-icon>
    <span class="bold uppercase">Verso</span>
  </button>
</div>
