<div *ngIf="contextTypes.length > 0" class="edit-field-context dialog-border-color">
  <span class="bold edit-field-context__headline"
        [translate]="field.title || 'TRANS__MODAL_DIALOG__YOU_HAVE_CHOSEN'"></span>

  <button mat-stroked-button color="primary"
          *ngFor="let contextType of contextTypes"
          (click)="toggleContext(contextType)"
          class="edit-field-context__button"
          type="button"
          [ngClass]="{'active': contextType.showItems}">
    <mat-icon>{{contextType.showItems ? 'expand_less' : 'expand_more'}}</mat-icon>
    <span class="edit-field-context__button-text">{{contextType.superobjectType}} ({{contextType.count}})</span>
  </button>

  <mat-chip-list *ngIf="currentConcept && currentConcept.showItems"
                 class="edit-field-context__objects">
    <mat-chip class="edit-field-context__object"
              *ngFor="let ob of currentConcept.items"> {{ob.identifier}} </mat-chip></mat-chip-list>
</div>
