<div *ngIf="usage.length" class="reference-usage dialog-border-color">
  <button mat-stroked-button
          color="primary"
          type="button"
          class="reference-usage__button"
          (click)="toggleDisplayUsage()"
          [ngClass]="{'active': displayUsage}">
    <span class="bold" translate="TRANS__USAGE_COUNT"></span>
    <span class="bold">{{usage.length}}</span>
    <mat-icon>{{displayUsage ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>
  <mat-chip-list *ngIf="displayUsage" class="reference-usage__objects">
    <mat-chip *ngFor="let usageObj of usage"
              class="reference-usage__object">
      <app-object-url *ngIf="showReferencesAsLink" [object]="usageObj">
        <span class="object-url-content">{{usageObj.artifact_name}}</span>
      </app-object-url>
      <span *ngIf="!showReferencesAsLink">{{usageObj.artifact_name}}</span>
    </mat-chip>
  </mat-chip-list>
</div>
