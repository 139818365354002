import {Component, Inject, OnInit} from '@angular/core';
import {SectionsContainer} from "../../core/definitions/sections-container";
import {Reference} from "../../core/definitions/reference";
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialogRef as MatDialogRef
} from "@angular/material/legacy-dialog";
import {EditObjectDialogData} from "../edit-object-dialog-data";
import {ObjectEditService} from "../../core/object-edit.service";
import {EditObjectDialogService} from "../edit-object-dialog.service";
import {UiToolsService} from "../../core/ui-tools.service";
import {SearchReferenceService} from "../../core/search-reference.service";

@Component({
  selector: 'app-edit-object-child-dialog',
  templateUrl: './edit-object-child-dialog.component.html',
  styleUrls: ['./edit-object-child-dialog.component.scss']
})
export class EditObjectChildDialogComponent implements OnInit {
  sectionsContainer: SectionsContainer = new SectionsContainer();
  private fieldReference: Reference;
  primeFields;
  storeStatus = {
    failed: false,
    message: '',
    serverMsg: ''
  };

  constructor(public dialogRef: MatDialogRef<EditObjectChildDialogComponent>,
              @Inject(MAT_DIALOG_DATA) public data: EditObjectDialogData,
              private objectEditService: ObjectEditService,
              private editObjectDialogService: EditObjectDialogService,
              private uiTools: UiToolsService,
              private searchReferenceService: SearchReferenceService) {
  }

  ngOnInit() {
    this.initObject().then();
  }

  onSubmit() {
    this.objectEditService.setObjectValuesStoreObject(this.sectionsContainer, true).then(
      value => {
        this.dialogRef.close(value);
      },
      reason => {
        this.storeStatus.failed = true;
        this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__STORE_FAILED';
        this.storeStatus.serverMsg = reason.error.message;
      }
    );
  }

  get isValid(): boolean {
    return this.sectionsContainer.formGroup.valid;
  }

  private async initObject() {
    const fieldParams = this.data.fieldParameters;
    this.fieldReference = await this.searchReferenceService.getSearchReferenceFromField(fieldParams.field);
    this.sectionsContainer = await this.editObjectDialogService.createOptionFromFieldParameters(
      fieldParams, this.data, this.fieldReference, this.data.createText, this.data.parentId);
    if (!this.sectionsContainer) {
      this.storeStatus.failed = true;
      this.storeStatus.message = 'TRANS__EDIT_OBJECT_DIALOG__NO_ACCESS';
    } else {
      this.primeFields = this.sectionsContainer.primeFields;
    }
  }

}
