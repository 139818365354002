import {Reference} from './reference';
import {Inline} from './inline';
import {OptionInfo} from './option-info';
import {FieldIf} from './field-if';
import {FieldValidation} from './field-validation';
import {FieldAction} from './field-action';
import {RefData} from '../ref.service';
import {DateInfo} from './date-info';
import {InlineViewItem} from "./inline-view-item";


export class FieldDisplayLabel extends InlineViewItem {
  type: string;
  title: string;
  name: string;
  key: string;
  path: string;
  sRef: RefData;
}

export interface MetaField {
  actions: Array<FieldAction>;
  actions_id: string;
  admin_title: string;
  conditions_id: string;
  date_info: DateInfo;
  date_info_id: string;
  display: string;
  edit: string;
  field_uuid: string;
  meta_field_id: string;
  meta_field_id_value: string;
  field_name: string;
  field_ifs: Array<FieldIf>;
  title: string;
  ge: number;
  field_type: string;
  inline: Inline;
  inline_fields: Array<MetaField>;
  input_type: string;
  is_array: boolean;
  isSortable: boolean;
  key: string;
  le: number;
  max_length: number;
  min_length: number;
  name: string;
  option_info: OptionInfo;
  order: number;
  parent_id: string;
  parent_ids: Array<string>;
  parent_name: string;
  path: string;
  placeholder: string;
  reference: Reference;
  reference_id: string;
  regex: string;
  is_required: string;
  sub_fields: Array<MetaField>;
  sub_order: number;
  template_group_id: string;
  validation: FieldValidation;
  value_option_id: string;
  value: any;

  $$fieldTitle;
  $$hide: boolean;
  $$inlineView: InlineViewItem;
}
