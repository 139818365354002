<div class="media-carousel"
     [ngClass]="{'smallCarousel': mediumScreen, 'largeCarousel': !mediumScreen}">
  <div class="media-carousel__container"
       *ngIf="mediaContainer.currentMediaType"
       [ngClass]="{'can-annotate': canAnnotate()}">
    <div class="media-carousel__top">
      <app-media-carousel-top-menu
        [zoomValue]="zoomValue"
        [isEditing]="isEditing"
        [mediaElements]="mediaContainer.currentMediaType.mediaElements"
        [imageGallery]="imageGallery"
        [mediaIndex]="mediaContainer.currentMediaType.currentMediaIndex"
        [toggleAnnotations]="toggleAnnotations"
        [collapseZoomIn]="collapseZoomInButton"
        [mediumScreen]="mediumScreen"
        (changedZoomValue)="onChangeZoomValue($event)"
        (openImageFullScreen)="onOpenImageFullScreen($event)">
      </app-media-carousel-top-menu>
    </div>
    <div class="media-carousel__active-image" #container
         *ngIf="mediaContainer.currentMediaType.currentMediaIndex >= 0">
      <app-active-media
        *ngIf="!toggleAnnotations && container"
        [activeMedia]="mediaContainer.currentMediaType.mediaElements[mediaContainer.currentMediaType.currentMediaIndex].mediaObject"
        [zoomValue]="zoomValue"
        [container]="container"></app-active-media>
      <app-active-annotate-image
        *ngIf="toggleAnnotations && curAnn && curAnn.annotateImage"
        [curAnn]="curAnn"
        [imgIndex]="mediaContainer.currentMediaType.currentMediaIndex"
        [zoomValue]="zoomValue"
        [lockHeight]="false"
        [container]="container"
        [edit]="false"></app-active-annotate-image>
    </div>
  </div>

  <div class="media-carousel__annotation" *ngIf="canAnnotate()">
    <mat-slide-toggle (change)="toggleAnnotation()"
                      [disabled]="isEditing"
                      class="media-carousel__annotation-toggle-button">
      <span translate="TRANS__IMAGE_CAROUSEL__SHOW_ANNOTATION"></span></mat-slide-toggle>
    <app-image-carousel-annotation-points
      *ngIf="toggleAnnotations && curAnn && curAnn.annotateImage"
      [mediaType]="mediaContainer.currentMediaType"
      [curAnn]="curAnn"
      [mediumScreen]="mediumScreen"
      [readonly]="sectionsContainer?.rootObject.read_only">
      </app-image-carousel-annotation-points>
  </div>

  <div class="media-carousel__thumbnails-container">
    <div class="media-carousel__buttons-container" *ngIf="!mediumScreen">
      <button [disabled]="isEditing || imageGallery"
              type="button"
              class="media-carousel__change-media-order-button"
              mat-stroked-button color="primary"
              (click)="onToggleChangeMediaOrder()">
        <mat-icon>unfold_more</mat-icon>
        <span class="bold" translate="TRANS__MEDIA_CAROUSEL__CHANGE_ORDER"></span></button>

      <button mat-stroked-button
              class="media-carousel__size-buttons"
              type="button"
              *ngFor="let key of thumbWidthKeys"
              (click)="setThumbWidth(key)"
              [ngClass]="{'active': mediaContainer.thumbWidth === key}">
        <span class="bold" [translate]="thumbSize[key]"></span></button>
    </div>

    <div *ngIf="!mediumScreen" class="media-carousel__thumbnail-stack">
      <ng-container *ngFor="let mediaType of mediaContainer.mediaTypes">
        <app-thumbnail-stack
          [mediaContainer]="mediaContainer"
          [mediaType]="mediaType"
          [isEditing]="isEditing"
          [imageGallery]="imageGallery"
          (setActiveImg)="onSetActiveMedia(mediaType, $event)"
        ></app-thumbnail-stack></ng-container></div>

    <div *ngIf="mediumScreen" class="media-carousel__thumbnail-carousel-container">
      <app-thumbnail-carousel-nav-button
        class="media-carousel__carousel-nav-button-left"
        [direction]="'prev'"
        [disable]="mediaContainer.mediaTypes[0].mediaType === mediaContainer.currentMediaType.mediaType
        && mediaContainer.currentMediaType.currentMediaIndex < 1"
        [mediaType]="mediaContainer.currentMediaType"
        (setActiveMediaIndex)="onSetActiveMedia( mediaContainer.currentMediaType, $event, 'prev')"
      ></app-thumbnail-carousel-nav-button>

      <div class="media-carousel__thumbnail-carousel" #mediaElementContainer>
        <app-thumbnail-carousel
          *ngFor="let mediaType of mediaContainer.mediaTypes"
          [ngStyle]="{'width': (mediumScreenThumbnailWidth * mediaType.mediaElements.length) + 'px'}"
          class="media-carousel__thumbnail-carousel-media-type"
          [media]="mediaType.mediaElements"
          [mediaIndex]="mediaContainer.currentMediaType.currentMediaIndex"
          [currentMediaType]="mediaContainer.currentMediaType.mediaType"
          [thumbnailWidth]="mediumScreenThumbnailWidth"
          (setActiveImg)="onSetActiveMedia(mediaType, $event)"></app-thumbnail-carousel></div>

      <app-thumbnail-carousel-nav-button
        class="media-carousel__carousel-nav-button-right"
        [direction]="'next'"
        [disable]="disableNextButton"
        [mediaType]="mediaContainer.currentMediaType"
        (setActiveMediaIndex)="onSetActiveMedia(mediaContainer.currentMediaType, $event, 'next')"
      ></app-thumbnail-carousel-nav-button>
    </div>
  </div>

  <div *ngIf="showMediaOrder" class="overlay"></div>

  <div *ngIf="showMediaOrder" class="side-selector change-img-order-selector media-carousel__selector">
    <app-change-media-order
      [mediaContainer]="mediaContainer"
      [sectionsContainer]="sectionsContainer"
      (toggleChangeMediaOrder)="onToggleChangeMediaOrder($event)"></app-change-media-order>
  </div>
</div>
