import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import {TranslateModule} from '@ngx-translate/core';
import {FieldTitleComponent} from './field-title/field-title.component';
import {FieldToolTipComponent} from './field-tool-tip/field-tool-tip.component';
import {FieldActionButtonComponent} from './field-action-button/field-action-button.component';
import {ObjectIconComponent} from './object-icon/object-icon.component';
import {ObjectNameComponent} from './object-name/object-name.component';
import {CultureHubSearchDialogComponent} from './culture-hub-search-dialog/culture-hub-search-dialog.component';
import {ProgressDialogComponent} from './progress-dialog/progress-dialog.component';
import {AlertDialogComponent} from './alert-dialog/alert-dialog.component';
// eslint-disable-next-line max-len
import {TemplateGroupSelectButtonComponent} from './template-group-select-button/template-group-select-button.component';
import {DropdownMenuComponent} from './dropdown-menu/dropdown-menu.component';
import {DeleteItemButtonComponent} from './delete-item-button/delete-item-button.component';
import {ObjectUsageDialogComponent} from './object-usage-dialog/object-usage-dialog.component';
import {DropdownMenuItemComponent} from './dropdown-menu/dropdown-menu-item/dropdown-menu-item.component';
import {DropdownMenuHrComponent} from './dropdown-menu/dropdown-menu-hr/dropdown-menu-hr.component';
import {ObjectUrlComponent} from './object-url/object-url.component';
import {ThumbnailComponent} from './thumbnail/thumbnail.component';
import {DownloadUrlComponent} from './download-url/download-url.component';
import {NotificationsComponent} from './notifications/notifications.component';
import {ObjectViewComponent} from './object-view/object-view.component';
import {DisplayFieldTitleComponent} from './display-field-title/display-field-title.component';
import {ObjectViewFieldValuesComponent} from './object-view-field-values/object-view-field-values.component';
import {ObjectViewFieldValueComponent} from './object-view-field-value/object-view-field-value.component';
import {ObjectViewFieldCheckboxComponent} from './object-view-field-checkbox/object-view-field-checkbox.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {DropdownMenuAnimatedComponent} from './dropdown-menu-animated/dropdown-menu-animated.component';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {TopHeaderComponent} from './top-header/top-header.component';
import { HeadingAndDescriptionComponent } from './heading-and-description/heading-and-description.component';
import {PrimusTableComponent} from './primus-tables/primus-table/primus-table.component';
import {MatLegacyTableModule as MatTableModule} from '@angular/material/legacy-table';
import {MatSortModule} from '@angular/material/sort';
import {MatLegacyPaginatorModule as MatPaginatorModule} from '@angular/material/legacy-paginator';
import {MatLegacyCheckboxModule as MatCheckboxModule} from '@angular/material/legacy-checkbox';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyFormFieldModule as MatFormFieldModule} from '@angular/material/legacy-form-field';
import {MatLegacyInputModule as MatInputModule} from '@angular/material/legacy-input';
import {MatLegacyTooltipModule as MatTooltipModule} from '@angular/material/legacy-tooltip';
import {MatLegacySelectModule as MatSelectModule} from '@angular/material/legacy-select';
import {MatDatepickerModule} from '@angular/material/datepicker';
import { PrimusTreeTableComponent } from './primus-tables/primus-tree-table/primus-tree-table.component';
import {MatTreeModule} from '@angular/material/tree';
import { PrimusBreadcrumbComponent } from './primus-tables/primus-breadcrumb/primus-breadcrumb.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import { PrimusTableFilterComponent } from './primus-tables/primus-table-filter/primus-table-filter.component';
import { PrimusMenuButtonComponent } from './primus-menu-button/primus-menu-button.component';
import { PrimusMenuButtonItemComponent } from './primus-menu-button/primus-menu-button-item/primus-menu-button-item.component';
import { RectoVersoButtonsComponent } from './recto-verso-buttons/recto-verso-buttons.component';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {ChooseBackendApiComponent} from './choose-backend-api/choose-backend-api.component';
import {MatLegacyListModule as MatListModule} from '@angular/material/legacy-list';
import { PrimusSolrDataTableComponent } from './primus-tables/primus-solr-data-table/primus-solr-data-table.component';
import {MatLegacyProgressBarModule as MatProgressBarModule} from '@angular/material/legacy-progress-bar';
import { PrimusSolrDataTableFilterComponent } from './primus-tables/primus-solr-data-table-filter/primus-solr-data-table-filter.component';
import { PrimusSolrTreeViewComponent } from './primus-solr-tree-view/primus-solr-tree-view.component';
import { TreeNodeComponent } from './primus-solr-tree-view/tree-node/tree-node.component';
import {MatLegacyTabsModule as MatTabsModule} from '@angular/material/legacy-tabs';
import {CultureHubImporterDialogComponent} from './culture-hub-importer-dialog/culture-hub-importer-dialog.component';
import {CultureHubImporterItemsComponent} from './culture-hub-importer-items/culture-hub-importer-items.component';
import {MatLegacyAutocompleteModule as MatAutocompleteModule} from '@angular/material/legacy-autocomplete';
import {MatLegacyChipsModule as MatChipsModule} from '@angular/material/legacy-chips';
import { ObjectViewTabbedSectionsComponent } from './object-view-tabbed-sections/object-view-tabbed-sections.component';
import {RouterModule} from '@angular/router';
import { ObjectUsageFieldComponent } from './object-usage-field/object-usage-field.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { AutoSaveSnackBarComponent } from './auto-save-snack-bar/auto-save-snack-bar.component';
import {ImageFullScreenComponent} from './image-full-screen/image-full-screen.component';
import {MatLegacySliderModule as MatSliderModule} from '@angular/material/legacy-slider';
import {ActiveMediaComponent} from './active-media/active-media.component';
import {ActiveAnnotateImageComponent} from './active-annotate-image/active-annotate-image.component';
import { CultureHubImporterFoldersComponent } from './culture-hub-importer-folders/culture-hub-importer-folders.component';
import { CultureHubFolderFilterPipe } from './culture-hub-importer-folders/culture-hub-folder-filter.pipe';
import { ExternalUrlDirective } from './external-url.directive';


@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    TranslateModule,
    MatDialogModule,
    FormsModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    MatCheckboxModule,
    MatIconModule,
    MatFormFieldModule,
    MatInputModule,
    ReactiveFormsModule,
    MatTooltipModule,
    MatSelectModule,
    MatDatepickerModule,
    MatTreeModule,
    MatMenuModule,
    MatProgressSpinnerModule,
    MatListModule,
    MatProgressBarModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatChipsModule,
    RouterModule,
    MatExpansionModule,
    MatSliderModule
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ChooseBackendApiComponent,
    FieldTitleComponent,
    FieldActionButtonComponent,
    FieldToolTipComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    CultureHubSearchDialogComponent,
    AlertDialogComponent,
    ObjectUrlComponent,
    DeleteItemButtonComponent,
    DropdownMenuComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    DropdownMenuAnimatedComponent,
    TemplateGroupSelectButtonComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent,
    TopHeaderComponent,
    HeadingAndDescriptionComponent,
    PrimusTableComponent,
    PrimusTreeTableComponent,
    PrimusBreadcrumbComponent,
    PrimusTableFilterComponent,
    PrimusMenuButtonComponent,
    RectoVersoButtonsComponent,
    PrimusSolrDataTableComponent,
    PrimusSolrDataTableFilterComponent,
    PrimusSolrTreeViewComponent,
    CultureHubImporterDialogComponent,
    CultureHubImporterItemsComponent,
    ObjectViewTabbedSectionsComponent,
    ObjectUsageFieldComponent,
    ImageFullScreenComponent,
    ActiveMediaComponent,
    ActiveAnnotateImageComponent
  ],
  declarations: [
    ChooseBackendApiComponent,
    FieldTitleComponent,
    FieldToolTipComponent,
    FieldActionButtonComponent,
    ObjectIconComponent,
    ObjectNameComponent,
    CultureHubSearchDialogComponent,
    ProgressDialogComponent,
    AlertDialogComponent,
    TemplateGroupSelectButtonComponent,
    DropdownMenuComponent,
    DeleteItemButtonComponent,
    ObjectUsageDialogComponent,
    DropdownMenuItemComponent,
    DropdownMenuHrComponent,
    DropdownMenuAnimatedComponent,
    ObjectUrlComponent,
    ThumbnailComponent,
    DownloadUrlComponent,
    NotificationsComponent,
    ObjectViewComponent,
    DisplayFieldTitleComponent,
    ObjectViewFieldValuesComponent,
    ObjectViewFieldValueComponent,
    ObjectViewFieldCheckboxComponent,
    TopHeaderComponent,
    HeadingAndDescriptionComponent,
    PrimusTableComponent,
    PrimusTreeTableComponent,
    PrimusBreadcrumbComponent,
    PrimusTableFilterComponent,
    PrimusMenuButtonComponent,
    PrimusMenuButtonItemComponent,
    RectoVersoButtonsComponent,
    PrimusSolrDataTableComponent,
    PrimusSolrDataTableFilterComponent,
    PrimusSolrTreeViewComponent,
    TreeNodeComponent,
    CultureHubImporterDialogComponent,
    CultureHubImporterItemsComponent,
    ObjectViewTabbedSectionsComponent,
    ObjectUsageFieldComponent,
    AutoSaveSnackBarComponent,
    ImageFullScreenComponent,
    ActiveMediaComponent,
    ActiveAnnotateImageComponent,
    CultureHubImporterFoldersComponent,
    CultureHubFolderFilterPipe,
    ExternalUrlDirective
  ]
})
export class SharedModule {
}
