import {Injectable} from '@angular/core';
import {CmsApiService} from './cms-api.service';
import {ConceptType, ConceptTypes} from './definitions/concept-types';
import {
  Concept,
  ConceptListItem,
  SearchConcepts,
  ConceptsParams,
  SortDir,
  ConceptHierarchic
} from './definitions/concepts';
import {ConceptsContainer} from './definitions/concepts-container';
import {ModelsService} from './models.service';
import {TranslateService} from '@ngx-translate/core';
import {ModelFactoryService} from './model-factory.service';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';
import {ConceptDataSource} from '../administration/admin-concept-lists/concept-data-source';
import {CheckFilterGroup} from './definitions/search-objects';

export interface ConceptDialogData {
  concept: Concept;
  concept_id: string;
  conceptType: ConceptType;
  conceptsParams: ConceptsParams;
  conceptList: ConceptListItem[];
  conceptDataSource: ConceptDataSource;
  index: number;
}

export interface AdminConceptJoinConceptsData {
  conceptsContainer: ConceptsContainer;
  callback;
}

@Injectable({
  providedIn: 'root'
})
export class ConceptService {

  private conceptFieldUsage;

  constructor(private translate: TranslateService,
              private modalService: MatDialog,
              private cms: CmsApiService,
              private modelsService: ModelsService,
              private modelFactoryService: ModelFactoryService) {
  }

  async getConceptTypes(nonSystemOnly?: boolean): Promise<ConceptTypes> {
    return new Promise<ConceptTypes>(resolve => {
      this.cms.getConceptTypes({non_system_only: nonSystemOnly}).then(conceptTypes => {
        resolve(conceptTypes);
      });
    });
  }

  async getConceptType(conceptTypeId): Promise<ConceptType> {
    return new Promise<ConceptType>(resolve => {
      this.cms.getConceptType({concept_type_id: conceptTypeId}).then(conceptType => {
        resolve(conceptType);
      });
    });
  }

  async getConcepts(conceptsParams: ConceptsParams): Promise<SearchConcepts> {
    return this.cms.getConcepts(conceptsParams);
  }

  async setConceptsContainer(conceptType: ConceptType): Promise<ConceptsContainer> {
    const conceptsContainer = new ConceptsContainer(this.cms);
    const conceptsParams = new ConceptsParams();
    conceptsParams.concept_type_id = conceptType.concept_type_id;
    conceptsParams.meta_type = conceptType.meta_type;
    conceptsParams.rows = 20;
    conceptsParams.query = '';
    conceptsParams.sort = 'artifact_name';
    conceptsParams.sort_dir = SortDir.Asc;
    if (conceptsContainer.conceptDataSource.sort) {
      conceptsContainer.conceptDataSource.sort.active = 'name';
      conceptsContainer.conceptDataSource.sort.start = 'asc';
    }
    conceptsContainer.conceptsParams = conceptsParams;
    conceptsContainer.conceptType = conceptType;
    conceptsContainer.conceptType.minLevel = -1;
    conceptsContainer.reload = Math.random();
    conceptsContainer.filterGroups = await this.getFilterGroupsForType(conceptsContainer.conceptType);
    this.setConceptParamsFilters(conceptsContainer);
    return conceptsContainer;
  }

  async setConceptList(conceptsContainer: ConceptsContainer, resetStart: boolean, goBack?: boolean, pageIndex?: number) {
    if (resetStart) {
      this.setPageIndex(conceptsContainer, goBack, pageIndex);
      this.setPaginatorIndex(conceptsContainer, goBack, pageIndex);
    }
    await conceptsContainer.conceptDataSource.loadConcepts(conceptsContainer);

    this.checkSetMinLevel(conceptsContainer);
  }

  private setPageIndex(conceptsContainer: ConceptsContainer, goBack?: boolean, pageIndex?: number) {
    if (goBack) {
      conceptsContainer.pageIndex = pageIndex ? pageIndex : 0;
    } else {
      conceptsContainer.pageIndex = 0;
    }
  }

  private setPaginatorIndex(conceptsContainer: ConceptsContainer, goBack?: boolean, pageIndex?: number) {
    if (conceptsContainer.conceptDataSource.paginator) {
      if (goBack) {
        conceptsContainer.conceptDataSource.paginator.pageIndex = pageIndex ? pageIndex : 0;
      } else {
        conceptsContainer.conceptDataSource.paginator.pageIndex = 0;
      }
    }
  }

  private async getFilterGroupsForType(conceptType: ConceptType): Promise<CheckFilterGroup[]> {
    const filterGroupData = await this.cms.getObjectSearchFilters(conceptType.meta_type, conceptType.concept_type_id);
    if (filterGroupData.error_message) {
      console.error(
        `An error occurred getting filter groups for ${conceptType.concept_type_id}: ${filterGroupData.error_message}`);
    }
    return filterGroupData.filter_groups;
  }

  private setConceptParamsFilters(conceptsContainer: ConceptsContainer) {
    for (const filterGroup of conceptsContainer.filterGroups) {
      for (const filter of filterGroup.filters) {
        if (filter.checked_value) {
          conceptsContainer.conceptsParams[filter.name] = filter.value;
          filter.checked = true;
        }
      }
    }
  }

  private checkSetMinLevel(conceptsContainer: ConceptsContainer) {
    if (conceptsContainer.conceptType.is_hierarchic &&
      conceptsContainer.conceptType.minLevel === -1 &&
      conceptsContainer.conceptDataSource.data.length) {
      conceptsContainer.conceptType.minLevel = conceptsContainer.conceptDataSource.data[0].level;
    }
  }

  getConceptListItemFromId(conceptList: ConceptListItem[], conceptId): ConceptListItem {
    let concept: ConceptListItem;
    if (conceptList.length) {
      for (const conceptListItem of conceptList) {
        if (conceptListItem.artifact_id === conceptId) {
          concept = conceptListItem;
          break;
        }
      }
    }
    return concept;
  }

  async setConceptUsage(concepts: Array<any>) {
    const conceptIds = concepts.map(concept => concept.artifact_id);
    const conceptUsages = await this.cms.getConceptUsage({
      artifact_ids: conceptIds, writable_usages_only: false});
    concepts.forEach(concept => {
      concept.$$usage = conceptUsages[concept.artifact_id];
    });
  }

  async getConceptFieldUsage(conceptTypeId?: string) {
    if (!this.conceptFieldUsage) {
      this.conceptFieldUsage = this.cms.getConceptFieldUsage({writable_usages_only: 'false'});
    }
    return conceptTypeId ? this.conceptFieldUsage[conceptTypeId] : this.conceptFieldUsage;
  }

  getParentConcept(conceptsContainer: ConceptsContainer, offset?: number): ConceptListItem {
    let parentConcept: ConceptListItem;
    offset = offset || 0;
    const parentConcepts = conceptsContainer.parentConcepts;
    if (parentConcepts.length > offset) {
      parentConcept = parentConcepts[parentConcepts.length - (1 + offset)];
    }
    return parentConcept;
  }

  async createChildConcept(conceptsContainer: ConceptsContainer, parentConcept?: ConceptListItem): Promise<Concept> {
    const conceptData = new ConceptHierarchic();
    if (!parentConcept) {
      parentConcept = this.getParentConcept(conceptsContainer);
    }
    if (parentConcept) {
      conceptData.level = parentConcept.level + 1;
      conceptData.parent_id = parentConcept.artifact_id;
      conceptData.parent_id_value = parentConcept.artifact_name;
      conceptData.parent_path = parentConcept.parent_path ? parentConcept.parent_path + ' > ...' : '';
      parentConcept.is_leaf = false;
    } else {
      conceptData.level = 1;
    }
    return <Concept>await this.modelFactoryService.createModelItemAsync(
      conceptsContainer.conceptType.concept_type_id, conceptData);
  }

  selectAll(conceptsContainer: ConceptsContainer) {
    this.clearSelected(conceptsContainer);
    conceptsContainer.conceptDataSource.data.forEach(concept => {
      concept.$$selected = conceptsContainer.allSelected;
      if (conceptsContainer.allSelected) {
        conceptsContainer.selected.push(concept);
      }
    });
  }

  clearSelected(conceptsContainer: ConceptsContainer) {
    conceptsContainer.conceptDataSource.setSelected(conceptsContainer, false);
    conceptsContainer.selected = [];
  }

  selectConcept(concept: ConceptListItem, conceptsContainer: ConceptsContainer) {
    if (concept.$$selected) {
      conceptsContainer.selected.push(concept);
    } else {
      const existingIndex = conceptsContainer.selected.indexOf(concept);
      if (existingIndex > -1) {
        conceptsContainer.selected.splice(existingIndex, 1);
      }
    }
  }

}
