import {
  Component, ElementRef,
  EventEmitter,
  forwardRef,
  Input, OnChanges, OnDestroy, OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {AConst} from '../../core/a-const.enum';
import {OptionsService, SelectFieldOptionContainer} from '../../core/options.service';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {FieldStateService} from '../../core/field-state.service';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {EditFieldSelectOptionsComponent} from './edit-field-select-options/edit-field-select-options.component';
import {EditFieldSelectQueryComponent, ShowOptions} from './edit-field-select-query/edit-field-select-query.component';
import {InFocus} from '../in-focus';
import {CurrentQuery} from '../../core/definitions/current-query';
import {Reference} from '../../core/definitions/reference';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldValueService} from '../../core/field-value.service';
import {DragulaService} from 'ng2-dragula';
import {VirtualScrollEditDataSourceList} from '../virtual-scroll-edit-data-source-list';
import {SearchParams} from '../../core/search-params';
import {Subject} from 'rxjs';
import {SearchReferenceService} from "../../core/search-reference.service";
import {OptionsDialogService} from "../options-dialog.service";

@Component({
  selector: 'app-edit-field-select',
  templateUrl: './edit-field-select.component.html',
  styleUrls: ['./edit-field-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => EditFieldSelectComponent)
    }
  ]
})
export class EditFieldSelectComponent implements OnInit, OnChanges, ControlValueAccessor, OnDestroy {
  reference: Reference = new Reference();
  showOptions = {show: false, initialized: false} as ShowOptions;
  AConst = AConst;
  query: CurrentQuery = new CurrentQuery();
  onChange;
  fieldKey;
  selectedRow = 0;
  isArray = false;
  fullSearch = {value: false};
  cachedParams: SelectFieldOptionContainer;
  ds: VirtualScrollEditDataSourceList;
  searchParams: SearchParams;
  keyPressed: Subject<any> = new Subject<any>();
  private openModal = false;


  @Input() fieldParameters: FieldParameters;
  @Input() inFocus: InFocus;
  @Input() forceUpdate: boolean;
  @Output() fieldFocus = new EventEmitter<object>();
  @Output() fieldBlur = new EventEmitter<object>();
  @Output() optionsSelect = new EventEmitter<any>();
  @Output() selectOption = new EventEmitter<any>();


  @ViewChild(EditFieldSelectQueryComponent) editFieldSelectQuery;
  @ViewChild('addNewOption') addNewOption: ElementRef;
  @ViewChild(EditFieldSelectOptionsComponent) editFieldSelectOptions;


  constructor(private optionsService: OptionsService,
              private optionsDialogService: OptionsDialogService,
              private dragService: DragulaService,
              private fieldState: FieldStateService,
              private fieldValueService: FieldValueService,
              private searchReferenceService: SearchReferenceService) {
    dragService.dropModel().subscribe((value) => {
      this.updateOrder(value);
    });
  }

  ngOnInit() {
    this.searchParams = {
      metaField: this.fieldParameters.field,
      fieldParameters: this.fieldParameters,
      query: '',
    };
    this.ds = new VirtualScrollEditDataSourceList(this.optionsService, this.searchParams);
  }

  ngOnChanges() {
    this.getSetCachedParams().then(() => {
      this.optionsService.initSelect(this.cachedParams, this.reference).then()
    });
  }

  async getParams() {
    const cachedParams = await this.getSetCachedParams();
    if (!cachedParams.editFieldSelectQuery) {
      cachedParams.editFieldSelectQuery = this.editFieldSelectQuery;
    }
    return cachedParams;
  }

  private async getSetCachedParams(): Promise<SelectFieldOptionContainer> {
    const metaField: MetaField = this.fieldParameters.field;
    this.reference = await this.searchReferenceService.getSearchReferenceFromField(metaField);
    this.fieldParameters.canAddNew = await this.optionsService.getCanAddNew(this.reference);
    this.isArray = this.getIsArray();
    this.fieldKey = this.fieldState.getFieldKeyWhileDrawingInputs(metaField, this.fieldParameters.index, this.fieldParameters.parentIndex);
    this.cachedParams = new SelectFieldOptionContainer();
    this.cachedParams.fieldParameters = this.fieldParameters;
    this.cachedParams.fieldKey = this.fieldKey;
    this.cachedParams.refProp = this.reference.ref_prop || AConst.ARTIFACT_ID;
    this.cachedParams.editFieldSelectQuery = this.editFieldSelectQuery;
    this.cachedParams.myFormControl = this.myFormControl;
    this.cachedParams.query = this.query;

    return this.cachedParams;
  }

  private tabToNextField() {
    if (this.showOptions.show) {
      if ((this.fieldParameters.canAddNew || this.reference.search_kulturnav) && this.query.value !== '') {
        setTimeout(() => {
          this.setNewOptionFocus();
        }, 100);
      } else {
        this.toggleShowOptions(false);
      }
    } else {
      this.fieldSelectBlur();
    }
  }

  onKeyEventsInShowOptions(value) {
    if (value.row) {
      this.updateSelectedRow(value.row);
    }
    if (value.setFocus) {
      this.editFieldSelectQuery.setInputToFocus();
    }

    if (value.tabbing) {
      this.tabToNextField();
    }
  }

  private updateSelectedRow(row) {
    this.selectedRow = row;
  }

  async onOptionsSelected(options) {
    if (this.optionsSelect) {
      this.optionsSelect.emit(options);
    }
    const params = await this.getParams();
    const value = await this.optionsService.optionsSelected(options, params, this.reference);
    this.myFormControl.markAsDirty();
    if (this.onChange) {
      this.onChange(value);
    } else {
      console.warn('No "onChange" function set for ' + this.fieldKey);
    }
  }

  async onOptionUnchecked(option) {
    const params = await this.getParams();
    this.optionsService.optionUnchecked(option, params);
  }

  async onChildEvent(event) {
    const params = await this.getParams();
    const eventType = event.type;
    switch (eventType) {
      case 'optionSelected':
        this.onOptionsSelected(event.data).then();
        break;
      case 'optionUnChecked':
        await this.onOptionUnchecked(event.data);
        break;
      case 'setTabToNextField':
        this.tabToNextField();
        break;
      case 'setQueryValue':
        this.optionsService.setQueryValue(event.data, params);
        break;
      case 'setClearField':
        this.optionsService.clearField(params);
        break;
      case 'fieldBlur':
        this.fieldSelectBlur();
        break;
      case 'fieldFocus':
        this.onFieldSelectFocus();
        break;
      case 'setOptionsToFocus':
        this.editFieldSelectOptions.setOptionsToFocus(event.data);
        break;
      case 'toggleShowOptions':
        this.toggleShowOptions(event.data);
        break;
      case 'setInputToFocus':
        this.editFieldSelectQuery.setInputToFocus();
        break;
      case 'keyPressed':
        this.keyPressed.next(event.data);
        break;
      default:
        console.warn('Unknown event type: ' + eventType);
    }
  }

  toggleShowOptions(toggle) {
    this.editFieldSelectQuery.toggleShowOptions(toggle);
  }

  get isSingleItemArray() {
    return this.fieldValueService.isSingleItemArray(this.fieldParameters);
  }

  private fieldSelectBlur() {
    setTimeout(() => {
      if (!this.showOptions.show) {
        this.fieldBlur.emit();
      }
    }, 300);
  }

  private onFieldSelectFocus() {
    this.fieldFocus.emit();
  }

  private get myFormControl() {
    const res = this.fieldParameters.sectionsContainer.formGroup.controls[this.fieldKey];
    if (!res) {
      console.warn('No form control for key "' + this.fieldKey + '" yet');
    }
    return res;
  }

  private getIsArray() {
    return this.fieldParameters.field.field_type === AConst.ARRAY &&
      !this.fieldValueService.isSingleItemArray(this.fieldParameters);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(/*fn: any*/): void {
    // No implementation necessary yet
  }

  setDisabledState(/*isDisabled: boolean*/): void {
    // No implementation necessary yet
  }

  writeValue(/*obj: any*/): void {
    // No implementation necessary yet
  }

  private updateOrder(value) {
    if (this.fieldParameters.object[this.fieldParameters.field.name] &&
      value.targetModel[0].object_type === this.fieldParameters.object[this.fieldParameters.field.name][0].object_type) {
      this.fieldParameters.object[this.fieldParameters.field.name] = value.targetModel;
      this.fieldParameters.object[this.fieldParameters.field.name].forEach((item, index) => {
        item.order_number = index;
      });
      this.myFormControl.markAsDirty();
    }
  }

  // Unsubscribe dataSource on destroy
  ngOnDestroy() {
    this.ds.unsubscribe();
  }

  createOptionWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.createOption();
    }
  }
  createOption() {
    this.openModal = true;
    this.fieldParameters.sectionsContainer.isSecondDialog = this.fieldParameters.sectionsContainer.isDialog;
    this.fieldParameters.sectionsContainer.isDialog = true;
    this.optionsDialogService.createOption(this.fieldParameters).then(option => {
      this.onOptionsSelected([option]).then();
      if (this.isArray && !this.isSingleItemArray) {
        this.setQueryValue('');
        this.setInputToFocus();
      }
    }, () => {
      this.openModal = false;
    });
  }

  createOptionBlur() {
    if (!this.openModal && !this.reference.search_kulturnav) {
      this.toggleShowOptions(false);
    }
  }

  searchCultureHubBlur() {
    if (!this.openModal) {
      this.toggleShowOptions(false);
    }
  }

  searchCultureHub() {
    this.openModal = true;
    this.optionsDialogService.searchCultureHub(
      this.fieldParameters.field,
      this.isArray && !this.isSingleItemArray,
      this.query?.value).then(options => {
      this.onOptionsSelected(options).then();
    }, () => {
      this.openModal = false;
    });
  }

  searchCultureHubWithKey(event) {
    if (event.key === 'Enter') { // enter and space
      // event.preventDefault();
      event.target.focus();
      this.searchCultureHub();
    }
  }

  setNewOptionFocus() {
    this.addNewOption.nativeElement.focus();
  }

  private setQueryValue(value) {
    this.onChildEvent({type: 'setQueryValue', data: value}).then();
  }

  private setInputToFocus() {
    this.onChildEvent({type: 'setInputToFocus'}).then();
  }

}
