import {Injectable} from '@angular/core';
import {CommonsService} from './commons.service';
import {ChangeTrackerService} from './change-tracker.service';
import {FieldStateService} from './field-state.service';
import {ObjectFieldTraverseService} from './object-field-traverse.service';
import {FieldParameters} from './definitions/field-parameters';
import {CmsQueueService} from './cms-queue.service';
import {MetaField} from './definitions/meta-field';
import {IfCondition, FieldIf, IfThenType, IfType} from './definitions/field-if';
import {UserData} from './definitions/user-data';
import {BaseModel} from './definitions/base-model';
import {ModelsService} from './models.service';
import {CmsApiService} from './cms-api.service';
import {UserCacheService} from './user-cache.service';
import {LoggerService} from './logger.service';
import {FieldValueService} from './field-value.service';
import {UserPrivilege} from '../administration/admin-users/User';
import {FieldType} from "./definitions/field-type.enum";

export interface DisabledResult {
  disabled: boolean;
  reason: string;
}

export interface RunIfResult {
  result: boolean;
  resultText: string;
  hadIfType: boolean;
}

enum ConditionType {
  CHECK_WITH_BACKEND = 'check_with_backend',
  // COMPARE_FIELD_VALUE = 'compare_field_value',
  COMPARE_WITH_OTHER_FIELD = 'compare_with_other_field',
  EDITING = 'editing',
  EDITION = 'edition',
  // FIELD_VALUE_IN_VALUES = 'field_value_in_values',
  IS_ADMIN_USER = 'is_admin_user',
  NOT_EDITING = 'not_editing',
  OBJECT_HAS_CHANGES = 'object_has_changes',
  OBJECT_HAS_NO_CHANGES = 'object_has_no_changes',
}

@Injectable({
  providedIn: 'root'
})
export class FieldConditionService {

  myUserData: UserData;
  mySettings;
  gettingSettings = false;
  fieldConditions: { [id: string]: FieldIf[] }

  private backendAnswers = {};
  private backendAnswerPurgeTime = {};


  constructor(
    private logger: LoggerService,
    private fieldStateSvc: FieldStateService,
    private changeTracker: ChangeTrackerService,
    private cms: CmsApiService,
    private cmsQueue: CmsQueueService,
    private commons: CommonsService,
    private objectFieldTraverse: ObjectFieldTraverseService,
    private modelsService: ModelsService,
    private userCacheService: UserCacheService,
    private fieldValueService: FieldValueService) {
  }

  setFieldConditions() {
    this.cms.getFieldConditions().then(fieldConditions => {
      this.fieldConditions = fieldConditions
    });
  }

  runIf(ifType, fieldParameters: FieldParameters, setValueCallback?): RunIfResult {
    const ifItem = this.getIfByType(fieldParameters, ifType);
    let res;
    let resultText = '';
    if (ifItem) {
      res = this.runIfItem(ifItem, fieldParameters);
      if (res && ifItem.field_warning_text) {
        resultText = ifItem.field_warning_text;
      }
    } else {
      res = this.getDefaultRes(ifType);
    }

    if (ifItem) {
      this.runIfThenElse(res, ifItem, fieldParameters, setValueCallback);
    }
    return {
      result: res,
      resultText: resultText,
      hadIfType: !!ifItem
    };
  }

  runIfItem(ifItem: FieldIf, fieldParameters: FieldParameters) {
    let t, conditions: Array<IfCondition>, cond: IfCondition, res;
    conditions = ifItem.conditions || [];
    for (t = 0; t < conditions.length; t++) {
      cond = conditions[t];
      res = this.runCompare(cond, fieldParameters);
      if (res && ifItem.operator === 'or') {
        break;
      }
      if (!res && ifItem.operator !== 'or') {
        break;
      }
    }
    return res;
  }

  runIfDisabled(fieldParameters: FieldParameters): DisabledResult {
    let reason = '';
    let isDisabled = false;
    const ifItem = this.getIfByType(fieldParameters, IfType.DISABLE);
    if (ifItem) {
      this.runIfItem(ifItem, fieldParameters);
      isDisabled = this.runIf(IfType.DISABLE, fieldParameters).result;
      if (isDisabled && ifItem.reason_field) {
        reason = this.getCompareFieldValue(ifItem.reason_field, fieldParameters);
      }
    }
    return {
      reason: reason,
      disabled: isDisabled
    };
  }

  getFieldConditions(field: MetaField): FieldIf[] {
    if (!field) {
      return [];
    }
    let res;
    if (field.field_ifs) {
      res = field.field_ifs;
    } else if (field.conditions_id) {
      res = this.fieldConditions[field.conditions_id];
    }
    return res;
  }

  private getCompareValue(value) {
    let res = value;
    if (typeof res === 'string' && res.indexOf('::') !== -1) {
      res = this.getSpecialFieldValue(res);
    }
    if (res === undefined) {
      res = null;
    }
    return res;
  }

  private getSpecialFieldValue(sourceField) {
    let res = null;
    const specialInfo = [
      {prefix: 'user::', specialObject: this.userData},
      {prefix: 'settings::', specialObject: this.settings}
    ];
    for (const comp of specialInfo) {
      if (sourceField.indexOf(comp.prefix) === 0) {
        if (comp.specialObject) {
          res = this.getSpecialFieldValueFromObject(sourceField, comp.specialObject);
        } else {
          this.logger.warn(`Special object not set yet: ${comp.prefix}`);
        }
        break;
      }
    }
    return res;
  }

  private getSpecialFieldValueFromObject(fields: string, specialObject) {
    const fieldsSplit = fields.split('::')[1].split('.');
    let obj = specialObject;
    for (const field of fieldsSplit) {
      obj = obj[field];
    }
    return obj;
  }

  private getValueFromObject(fieldName: string, fieldParameters: FieldParameters, object: BaseModel) {
    const res = {
      found: false,
      value: null
    };
    if (!object) {
      return res;
    }
    const objectAndMetaFieldRes = this.getObjectAndMetaField(object, fieldName);
    let metaField = objectAndMetaFieldRes.metaField;
    let foundObj = objectAndMetaFieldRes.object;
    fieldName = objectAndMetaFieldRes.fieldName;
    let parentMetaField: MetaField = null;
    if (!metaField && fieldParameters.field.path) {
      const fieldPathSplit = fieldParameters.field.path.split('.');
      for (const element of fieldPathSplit) {
        const objAndParentMetaRes = this.getObjectAndParentMetaField(fieldParameters, foundObj, element);
        foundObj = objAndParentMetaRes.object;
        parentMetaField = objAndParentMetaRes.parentMetaField;
        if (objAndParentMetaRes.breakIt) {
          break;
        }
      }
      if (parentMetaField && foundObj) {
        metaField = this.getMetaField(foundObj, fieldName);
      }
    }
    this.setResultOfGetValueFromObject(res, metaField, foundObj, fieldName);
    return res;
  }

  private getObjectAndMetaField(object, fieldName) {
    let metaField: MetaField;
    let foundObj: any = object;
    if (fieldName.indexOf('.') === -1) {
      metaField = this.getMetaField(object, fieldName);
    } else {
      const nameSplit = fieldName.split('.');
      metaField = this.getMetaField(object, nameSplit[0]);
      if (metaField) {
        foundObj = object[nameSplit[0]];
        fieldName = nameSplit[1];
      }
    }
    return {
      object: foundObj,
      metaField: metaField,
      fieldName: fieldName
    }
  }

  private getObjectAndParentMetaField(fieldParameters: FieldParameters, object, element) {
    let parentMetaField: MetaField;
    object = this.checkGetArrayObject(fieldParameters, object);
    parentMetaField = this.getMetaField(object, element);
    let breakIt = false;
    if (!parentMetaField) {
      breakIt = true
    } else {
      object = object[element];
      if (!object) {
        breakIt = true
      } else if (typeof object !== 'object') {
        parentMetaField = null;
        breakIt = true
      }
    }
    return {
      object: object,
      parentMetaField: parentMetaField,
      breakIt: breakIt
    }
  }

  private setResultOfGetValueFromObject(res, metaField, foundObj, fieldName) {
    if (metaField) {
      res.found = true;
      if (Array.isArray(foundObj)) {
        foundObj = foundObj.length ? foundObj[0] : {};
      }
      res.value = foundObj[fieldName];
    }
  }

  private checkGetArrayObject(fieldParameters, obj) {
    if (Array.isArray(obj)) {
      if (fieldParameters.index || fieldParameters.index === 0) {
        obj = obj[fieldParameters.index];
      } else {
        this.logger.error('Unable to get array element');
      }
    }
    return obj
  }

  private getCompareFieldValue(fieldName: string, fieldParameters: FieldParameters) {
    let res = null;
    let valueInfo;
    const objects = [fieldParameters.object, fieldParameters.grandParentObject, fieldParameters.rootObject];
    for (const element of objects) {
      valueInfo = this.getValueFromObject(fieldName, fieldParameters, element);
      if (valueInfo.found) {
        res = valueInfo.value;
        break;
      }
    }
    if (valueInfo && !valueInfo.found && fieldName !== 'create_multiple') {
      this.logger.warn(`Unable to find compare value for ${fieldName}`);
    }
    return res;
  }

  private runCompare(cond: IfCondition, fieldParameters: FieldParameters) {
    let res, otherValue = cond.value;
    const edit = fieldParameters.edit || false;

    const fieldVal = this.getConditionFieldVal(cond, fieldParameters);
    if (cond.compare_field) {
      otherValue = this.getFieldValue(cond.compare_field, fieldParameters);
    }
    if (cond.cond_type === ConditionType.EDITING) {
      res = edit;
    } else if (cond.cond_type === ConditionType.NOT_EDITING) {
      res = !edit;
    } else if (cond.cond_type === ConditionType.OBJECT_HAS_CHANGES) {
      res = this.changeTracker.objectHasChanges(fieldParameters.rootObject);
    } else if (cond.cond_type === ConditionType.OBJECT_HAS_NO_CHANGES) {
      res = !this.changeTracker.objectHasChanges(fieldParameters.rootObject);
    } else if (cond.cond_type === ConditionType.EDITION) {
      res = this.compareEdition(cond);
    } else if (cond.cond_type === ConditionType.COMPARE_WITH_OTHER_FIELD) {
      res = this.compareValue(cond, cond.value, otherValue);
    } else if (cond.cond_type === ConditionType.IS_ADMIN_USER) {
      res = this.compareIsAdminUser(cond);
    } else if (cond.cond_type === ConditionType.CHECK_WITH_BACKEND) {
      res = this.checkBackendCondition(cond);
    } else if (cond.values) {
      res = this.compareValues(cond, fieldVal);
    } else {
      res = this.compareValue(cond, fieldVal, otherValue);
    }
    return res;
  }

  private getConditionFieldVal(cond: IfCondition, fieldParameters: FieldParameters) {
    let fieldVal;
    if (cond.field) {
      if (cond.field.indexOf('::') === -1) {
        fieldVal = this.getCompareFieldValue(cond.field, fieldParameters);
      } else {
        fieldVal = this.getFieldValue(cond.field, fieldParameters);
      }
    }
    return fieldVal;
  }

  private compareEdition(cond: IfCondition) {
    let res = false;
    if (cond.values) {
      res = this.compareValues(cond, this.userData?.edition?.toUpperCase());
    } else if (cond.value) {
      res = this.compareValue(cond, cond.value, this.userData?.edition?.toUpperCase());
    } else {
      this.logger.warn('Do not know what to do here 😬')
    }
    return res;
  }

  private compareIsAdminUser(condition: IfCondition) {
    const isAdminUser = this.userData?.rights_level as UserPrivilege === UserPrivilege.ADMIN;
    const compareValue = true;
    return this.commons.compareValues(isAdminUser, condition.comparator, compareValue);
  }

  private compareValue(condition: IfCondition, value, otherValue) {
    const compValue1 = this.getCompareValue(value);
    const compValue2 = this.getCompareValue(otherValue);
    return this.commons.compareValues(compValue1, condition.comparator, compValue2);
  }

  private compareValues(condition: IfCondition, fieldValueIn) {
    let res;
    const fieldValues = Array.isArray(fieldValueIn) ? fieldValueIn : [fieldValueIn];
    let fulfilled = false;
    for (const conditionValue of condition.values) {
      for (const fieldValue of fieldValues) {
        res = this.compareValue(condition, conditionValue, fieldValue);
        fulfilled = this.checkConditionFulfilled(res, condition);
        if (fulfilled) {
          break;
        }
      }
      if (fulfilled) {
        break;
      }
    }
    return res;
  }

  private checkConditionFulfilled(result, condition) {
    let fulfilled = false;
    if (condition.comparator !== '!=') {
      if (result) {
        fulfilled = true;
      }
    } else {
      if (!result) {
        fulfilled = true;
      }
    }
    return fulfilled;
  }

  private getIfByType(fieldParameters: FieldParameters, ifType): FieldIf {
    let res;
    if (fieldParameters.field['$$hasIf'] && fieldParameters.field['$$hasIf'][ifType] !== undefined) {
      res = fieldParameters.field['$$hasIf'][ifType];
    } else {
      this.setDefaultHasIf(fieldParameters);
      const field = this.getFieldWithConditions(fieldParameters);
      const ifs = this.getFieldConditions(field);
      if (!ifs || !ifs.length) {
        return fieldParameters.field['$$hasIf'][ifType];
      }
      for (const ifItem of ifs || []) {
        fieldParameters.field['$$hasIf'][ifItem.if_type] = ifItem;
        if (ifItem.if_type === ifType) {
          res = ifItem
        }
      }
    }
    return res;
  }

  private getFieldWithConditions(fieldParameters: FieldParameters): MetaField {
    let res;
    if (fieldParameters.field.conditions_id) {
      res = fieldParameters.field;
    } else if (fieldParameters.field.parent_name) {
      const parentField = this.getMetaField(
        fieldParameters.grandParentObject || fieldParameters.object,
        fieldParameters.field.parent_name);
      if (parentField.conditions_id && parentField.field_type !== FieldType.ARRAY) {
        res = parentField;
      }
    }
    return res;
  }

  private setDefaultHasIf(fieldParameters: FieldParameters) {
    if (!fieldParameters.field['$$hasIf']) {
      fieldParameters.field['$$hasIf'] = {
        show: false,
        edit: false,
        disable: false,
        field_warning: false
      }
    }
  }

  private getFieldValue(fieldIn, fieldParameters: FieldParameters) {
    let fieldVal;
    const field = this.getReplaceField(fieldIn, fieldParameters);
    if (field === null) {
      return null;
    }
    if (field.indexOf('::') === -1) {
      fieldVal = this.getFieldValueFromModels(field, fieldParameters);
    } else {
      fieldVal = this.getSpecialFieldValue(field);
    }
    return fieldVal;
  }

  private getReplaceField(field, fieldParameters: FieldParameters) {
    let res = field, searchField, start, end, replaceField;
    start = field.indexOf('{');
    if (start > -1) {
      end = field.indexOf('}');
      if (end > start) {
        searchField = field.substring(start + 1, end);
        replaceField = this.getFieldValue(searchField, fieldParameters);
        if (replaceField !== null) {
          res = field.substring(0, start) + replaceField +
            field.substring(end + 1);
        } else {
          res = null;
        }
      }
    }
    return res;
  }

  private getFieldPath(fieldName, fieldParameters: FieldParameters) {
    let fieldKey = '';
    const field = this.getFieldKeyInfo(fieldName, fieldParameters);
    if (field) {
      fieldKey = this.fieldStateSvc.getFieldKeyWhileDrawingInputs(field, fieldParameters.index, fieldParameters.parentIndex);
      if (fieldKey.indexOf(fieldName) === -1) {
        // Field name is a compare field. Need to exchange the last part of the field key with the compare field name
        const lastPathSep = fieldKey.lastIndexOf('.');
        if (lastPathSep !== -1) {
          fieldKey = fieldKey.substring(0, lastPathSep + 1) + fieldName;
        }
      }
    } else {
      this.logger.warn('Field info not found for ' + fieldName);
    }
    return fieldKey;
  }

  private getFieldKeyInfo(fieldName, fieldParameters: FieldParameters) {
    let field;
    if (fieldParameters.field.path) {
      const traverseRes = this.objectFieldTraverse.traverseObjectByPath(
        fieldParameters.rootObject, fieldParameters.field.path, 0);
      const parentObject = traverseRes.subObject;
      if (parentObject) {
        const fieldMeta = this.getMetaField(parentObject, fieldName);
        if (fieldMeta) {
          field = {key: traverseRes.parentKey + '.' + fieldName};
        } else {
          this.logger.warn('Field ' + fieldName + ' not found in path ' + fieldParameters.field.path);
        }
      } else {
        this.logger.warn('No sub model found for path ' + fieldParameters.field.path);
      }
    } else {
      const fieldMeta = this.getMetaField(fieldParameters.rootObject, fieldName);
      if (fieldMeta) {
        field = {key: fieldName};
      } else {
        this.logger.warn('Field ' + fieldName + ' not found in root object');
      }
    }
    return field;
  }

  private getMetaField(object, fieldName): MetaField {
    let res = null;
    let meta = object.$$meta;
    if (!meta) {
      if (object.object_type) {
        meta = this.modelsService.getModelMeta(object.object_type);
        if (!meta) {
          this.logger.error('Unable to find meta for ' + object.object_type);
        }
      }
    }
    if (meta) {
      res = meta[fieldName];
    }
    return res;
  }

  private getFieldValueFromModels(fieldName: string, fieldParameters: FieldParameters) {
    let traverseRes = this.objectFieldTraverse.traverseObjectByPath(fieldParameters.rootObject, fieldName, 0);
    if (traverseRes.subObject === undefined) {
      const fieldPath = this.getFieldPath(fieldName, fieldParameters);
      traverseRes = this.objectFieldTraverse.traverseObjectByPath(fieldParameters.rootObject, fieldPath, 0);
    }
    return traverseRes.subObject;
  }

  private getDefaultRes(ifType) {
    const res = {
      disable: false,
      field_warning: false
    }[ifType];
    return res !== undefined ? res : true;
  }

  private runIfThenElse(res: boolean, ifItem: FieldIf, fieldParameters: FieldParameters, setValueCallback?) {
    if (ifItem.if_then && res) {
      for (const ifThen of ifItem.if_then) {
        this.runIfThenElseClause(ifThen.if_then_type, ifThen.value, fieldParameters, setValueCallback);
      }
    } else if (ifItem.if_else) {
      for (const ifElse of ifItem.if_else) {
        this.runIfThenElseClause(ifElse.if_else_type, ifElse.value, fieldParameters, setValueCallback);
      }
    }
  }

  private runIfThenElseClause(ifThenType: string, value: any, fieldParameters: FieldParameters, setValueCallback?) {
    if (ifThenType === IfThenType.SET_DEFAULT_VAL) {
      const fieldName = fieldParameters.field.name;
      if (!fieldParameters.object[fieldName]) {
        this.fieldValueService.setFieldValueAndControlValue(fieldParameters, fieldParameters.object, fieldName, value).then(() => {
          if (setValueCallback) {
            setValueCallback();
          }
        });
      }
    }
  }

  // This special condition functionality is used for requesting specific backend APIs for information that
  // cannot be provided in any other way. The condition type must be set to "check_with_backend" and the
  // API to be requested must be indicated using the "backend_api" attribute, which is the "question".
  // The API must always return a json object using the following format:
  // { "question": true/false }
  // In the case of the 'global_template_exists' question, the API will return:
  // { "global_template_exists": true/false }
  private checkBackendCondition(cond: IfCondition) {
    let question = cond.backend_api;
    let answer = this.backendAnswers[question];
    if (answer === undefined || this.backendAnswerPurgeTime[question] < (new Date()).getTime()) {
      if (answer === undefined) {
        this.backendAnswers[question] = false;
      }
      this.backendAnswerPurgeTime[question] = new Date().getTime() + 2000;
      if (question === 'global_template_exists') {
        this.cms.globalTemplateExists().then(noGlobalExistsRes => {
          this.backendAnswers[question] = noGlobalExistsRes[question];
        });
      }
    }
    return this.backendAnswers[question] === cond.value;
  }

  private get userData() {
    if (!this.myUserData) {
      this.userCacheService.getUserData().then(userData => {
        this.myUserData = userData;
      });
    }
    return this.myUserData;
  }

  private get settings() {
    if (!this.mySettings && !this.gettingSettings) {
      this.gettingSettings = true;
      this.cmsQueue.runCmsFnWithQueue(this.cms.getClientConfig, undefined, false,
        (data) => {
          this.mySettings = data;
          this.gettingSettings = false;
        },
        e => {
          this.logger.error(`Error getting settings: ${e}`);
        }
      ).then();
    }
    return this.mySettings;
  }
}
