<div [formGroup]="sectionsContainer.formGroup" class="edit-field" *ngIf="generalFieldType">

  <div class="edit-field__field-title">
    <app-field-title
      [field]="field"
      [rootObject]="sectionsContainer.rootObject"
      [edit]="true"
      [editable]="isEditable"
      [showToolTip]="true"
    ></app-field-title>
  </div>

  <div class="edit-field__field-content"
       [ngClass]="{'no-field-title': !field.title,
        'padding-top':
       field.input_type === 'checkbox' ||
       field.input_type === 'radio_option' ||
       field.input_type === 'check_array',
        'noCopy': sectionsContainer.isCopy &&
        object['$$keep'] &&  object['$$keep'][field.name] &&
      !fieldParameters.object['$$keep'][field.name]['keep']}">

    <div class="clearInputContainer"
         [ngClass]="sectionsContainer.isDialog ?
     (sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name"
         [class.show-options-in-modal]="sectionsContainer.isDialog">
      <app-edit-field-input
        [fieldParameters]="fieldParameters"
      ></app-edit-field-input>
    </div>

  </div>

</div>
