import {Component, Input, OnChanges, OnDestroy} from '@angular/core';
import {ToolbarAction} from '../../core/definitions/toolbar-action';
import {OperationStep} from '../../core/definitions/operation-step';
import {OperationToolbarServiceService} from '../operation-toolbar-service.service';
import {TranslateService} from '@ngx-translate/core';
import {OperationContainer} from '../../core/definitions/operation-container';
import {OperationStepExecutionResult} from '../../core/definitions/operation-step-execution-result';

@Component({
  selector: 'app-operation-toolbar',
  templateUrl: './operation-toolbar.component.html',
  styleUrls: ['./operation-toolbar.component.scss']
})
export class OperationToolbarComponent implements OnChanges, OnDestroy {

  @Input() operationContainer: OperationContainer;
  @Input() operationStep: OperationStep;
  @Input() selectorEnabled: boolean;
  @Input() stylingClass: string;
  @Input() disabled: boolean;

  toolbarActions: ToolbarAction[] = [];
  splitActions: ToolbarAction[] = [];
  currentButton = new ToolbarAction();
  index = -1;
  private registerHasBeenRun = false;
  private lastOperationStepExecutionResult: OperationStepExecutionResult;

  constructor(
    private operationToolbarService: OperationToolbarServiceService,
    private translateService: TranslateService) {
  }

  ngOnChanges(): void {
    this.toolbarActions = this.operationStep.toolbar_actions || [];
    this.splitActions = this.toolbarActions.filter(item => {
      return (item.button_class === 'register-button');
    });

    const registerButton = this.splitActions.filter(item => {
      return (item.action_type === 'register');
    });

    if (this.index === -1) {
      this.index = this.splitActions.indexOf(registerButton[0]);
      this.currentButton = this.index !== -1 ? this.splitActions[this.index] : this.splitActions[0];
    }
  }

  ngOnDestroy() {
    this.operationToolbarService.clearScrollTimeout();
  }

  get isInvalid() {
    return this.operationToolbarService.getIsInvalid(this.operationContainer, this.registerHasBeenRun);
  }

  getTooltipText(action: ToolbarAction) {
    return this.operationToolbarService.getTooltipText(this.selectorEnabled, action);
  }

  isDisabled(action: ToolbarAction): boolean {
    return this.disabled || this.operationToolbarService.isDisabled(
      this.operationContainer, action, this.registerHasBeenRun);
  }

  async runAction(action: ToolbarAction) {
    const runActionResult = await this.operationToolbarService.runAction(
      this.operationContainer, action, true, this.lastOperationStepExecutionResult);
    this.registerHasBeenRun = runActionResult.registerHasBeenRun;
    this.lastOperationStepExecutionResult = runActionResult.operationStepExecutionResult;
  }

  get errorCount() {
    return this.operationToolbarService.getErrorCount(this.operationContainer);
  }

  nextError() {
    this.operationToolbarService.nextError(this.operationContainer);
  }

  setCurrentButton(index) {
    const buttonConfig = this.splitActions[index] as ToolbarAction;
    this.index = index;
    buttonConfig.button_text = this.translateService.instant(buttonConfig.button_text);
    this.currentButton = buttonConfig;
  }

}
