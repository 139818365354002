import { Injectable } from '@angular/core';
import {QueryMenu} from '../core/definitions/search-objects';
import {SearchContainer} from '../core/definitions/search-container';
import {TranslateService} from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class QueryFieldMenuService {

  constructor(private translateService: TranslateService) { }

  hasQueryField(searchContainer: SearchContainer): QueryMenu {
    let res = null, qm;
    const qf = searchContainer.queryContainer.queryField;
    if (qf) {
      qm = searchContainer.currentPathView.search_view.query_menus;
      if (qm) {
        qm.menus.forEach((menu) => {
          if (menu.query_field === qf) {
            res = menu;
          }
        });
      }
    }
    return res;
  }

  setSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const qf = searchContainer.queryContainer.queryField;
    const qm = searchContainer.currentPathView.search_view.query_menus;

    if (this.hasQueryField(searchContainer)) {
      qm.menus.forEach((menu) => {
        menu.selected = menu.query_field === qf;
        if (menu.selected) {
          searchContainer.queryContainer.selectedQueryMenu = menu;
          searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
          this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
        }
      });
    } else {
      this.setDefaultSelectedQueryFieldMenu(searchContainer);
    }
  }

  private setDefaultSelectedQueryFieldMenu(searchContainer: SearchContainer) {
    const qm = searchContainer.currentPathView.search_view.query_menus;
    if (qm) {
      qm.menus.forEach((menu) => {
        if (menu.selected) {
          if (!searchContainer?.searchCategory) {
            searchContainer.queryContainer.selectedQueryMenu = menu;
          } else {
            menu.selected = false;
          }
        }
      });
      if (!searchContainer.queryContainer.selectedQueryMenu) {
        searchContainer.queryContainer.selectedQueryMenu = qm[0];
      }
      if (!searchContainer?.searchCategory) {
        searchContainer.queryContainer.queryPlaceholder = searchContainer.queryContainer.selectedQueryMenu.description;
        this.setCurrentQueryPlaceholder(searchContainer, searchContainer.queryContainer.queryPlaceholder);
      }
    }
  }

  private setCurrentQueryPlaceholder(searchContainer: SearchContainer, placeholder: string) {
    this.translateService.get(placeholder).subscribe(value => {
      searchContainer.queryContainer.currentQueryPlaceholder = value;
    });
  }

}
