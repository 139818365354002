import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {ObjectPageComponent} from './object-page.component';
import {Observable} from 'rxjs';
import {PrimusRouterService} from '../../core/primus-router.service';

@Injectable()
export class CanDeactivateObjectPage implements CanDeactivate<ObjectPageComponent> {
  constructor(private primusRouter: PrimusRouterService) {}

  canDeactivate(
    component: ObjectPageComponent,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState: RouterStateSnapshot
  ): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    return this.primusRouter.checkCancel();
  }
}


