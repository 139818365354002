import {Injectable} from '@angular/core';
import {SearchResult} from '../core/definitions/search-result';
import {SearchObject} from '../core/definitions/search-object';
import {SearchParameterService} from './search-parameter.service';
import {SearchContainer} from '../core/definitions/search-container';
import {ContentListSourceContainer} from '../core/definitions/object-content-tab/content-list-source-container';
import {BaseModel} from '../core/definitions/base-model';
import {SearchService} from "../core/search.service";

@Injectable({
  providedIn: 'root'
})
export class SearchResultSelectionsService {

  constructor(private searchParameterService: SearchParameterService,
              private searchService: SearchService) {
  }

  selectionsChanged(searchContainer: SearchContainer) {
    let res = false, t;
    const originalItems = searchContainer.selections.originalItems;
    const selected = searchContainer.selections.selectedItems;

    if (searchContainer.selections.singleSelect) {
      res = selected.length !== 0;
    } else {
      if (originalItems.length !== selected.length) {
        res = true;
      } else {
        for (t = 0; t < originalItems.length; t++) {
          if (selected.indexOf(originalItems[t]) === -1) {
            res = true;
            break;
          }
        }
      }
    }
    return res;
  }

  async selectAll(searchContainer: SearchContainer, contentListSource?: ContentListSourceContainer) {
    let newItems;
    if (!contentListSource) {
      newItems = await this.getAllArtifactsFromSearch(searchContainer);
      searchContainer.searchResult.artifacts = newItems;
    } else {
      newItems = contentListSource.items.map(item => item.object);
    }
    if (searchContainer.selections.allSelected) {
      for (const newItem of newItems) {
        if (this.selectedIndexOf(searchContainer, newItem) === -1) {
          searchContainer.selections.selectedItems.push(newItem);
        }
      }
    } else {
      for (const newItem of newItems) {
        this.removeItemIdById(searchContainer, newItem, true);
      }
    }
    if (!contentListSource) {
      this.toggleSelectedItems(searchContainer);
    } else {
      this.toggleSelectedItemsContentListSource(searchContainer, contentListSource);
    }
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  selectSearchResultItem(obj: SearchObject, searchContainer: SearchContainer, shiftKey: boolean) {
    if (this.checkSelectRange(obj, searchContainer, shiftKey)) {
      return;
    }
    const existPos = this.selectedIndexOf(searchContainer, obj);
    let selected: boolean;
    if (!searchContainer.selections.singleSelect) {
      selected = existPos === -1;
    } else {
      selected = !(searchContainer.selections.selectedItems.length &&
        this.getItemId(obj) === this.getItemId(searchContainer.selections.selectedItems[0]));
    }
    this.setSelected(searchContainer, obj, selected, existPos);
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  setSelectedUsed(searchContainer: SearchContainer, searchRes: SearchResult) {
    const sel = searchContainer.selections.selectedItems.map(item => item.artifact_id);
    const used = searchContainer.used;
    if (sel.length > 0 || used.length > 0) {
      for (const art of searchRes.artifacts) {
        this.checkItemInArray(searchContainer, sel, art, 'selected');
        this.checkItemInArray(searchContainer, used, art, 'used');
      }
    }
  }

  toggleSelectedItems(searchContainer: SearchContainer) {
    const sc = searchContainer;
    const itemIds = sc.selections.selectedItems.map(selectedItem => this.getItemId(selectedItem));
    for (const item of sc.searchResult.artifacts) {
      const itemId = this.getItemId(item);
      searchContainer.selections.selected[itemId] = false;
      if (itemIds.length) {
        const index = itemIds.indexOf(itemId);
        if (index !== -1) {
          searchContainer.selections.selected[itemId] = true;
          itemIds.splice(index, 1);
        }
      }
    }
  }

  toggleSelectedItemsContentListSource(searchContainer: SearchContainer, contentListSource: ContentListSourceContainer) {
    const itemIds = this.getSelectedItemIds(searchContainer);
    for (const pageItem of contentListSource.pageItems) {
      pageItem.checked = false;
      if (itemIds.length) {
        const index = itemIds.indexOf(this.getItemId(pageItem.listItem.object));
        if (index !== -1) {
          pageItem.checked = true;
          itemIds.splice(index, 1);
        }
      }
    }
  }

  clearSelections(searchContainer: SearchContainer) {
    this.clearAllSelections(searchContainer);
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  setSelections(searchContainer: SearchContainer, items: SearchObject[]) {
    searchContainer.selections.originalItems = [...items];
    if (items && items.length) {
      for (const item of items) {
        this.addItem(searchContainer, item);
      }
    }
  }

  removeItemIdById(searchContainer: SearchContainer, deleteItem: SearchObject, skipCallback?: boolean) {
    let deleteIndex = -1;
    const deleteItemId = this.getItemId(deleteItem);
    searchContainer.selections.selectedItems.forEach((selectedItem, index) => {
      if (deleteItemId === this.getItemId(selectedItem)) {
        deleteIndex = index;
      }
    });
    this.removeItemIdByIndex(searchContainer, deleteIndex);
    if (searchContainer.selections.selectItemCallback && !skipCallback) {
      searchContainer.selections.selectItemCallback();
    }
  }

  removeItemIdByIndex(searchContainer: SearchContainer, deleteIndex) {
    if (deleteIndex !== -1) {
      searchContainer.selections.selectedItems.splice(deleteIndex, 1);
    }
  }

  getItemId(obj: BaseModel): string {
    let res;
    const relationIdFields = ['relation_id', 'image_id', 'video_id', 'attachment_id', 'text_block_id'];
    if (obj.artifact_id) {
      res = obj.artifact_id;
    } else {
      for (const relationIdField of relationIdFields) {
        res = obj[relationIdField];
        if (res) {
          break;
        }
      }
    }
    if (!res) {
      console.warn('Unable to get an item id from ' + JSON.stringify(obj));
    }
    return res;
  }

  getSelectedItemIds(searchContainer: SearchContainer) {
    return searchContainer.selections.selectedItems.map(selectedItem => this.getItemId(selectedItem));
  }

  // Get items without $$ props
  getCleanItems(searchContainer: SearchContainer): SearchObject[] {
    return searchContainer.selections.selectedItems.map(item => this.cleanItem(item));
  }

  private setSelected(searchContainer: SearchContainer, searchObject: SearchObject, selected: boolean, existPos: number) {
    if (selected) {
      if (searchContainer.selections.singleSelect) {
        this.clearAllSelections(searchContainer);
        this.addItem(searchContainer, searchObject);
        this.setSelectedUsed(searchContainer, searchContainer.searchResult);
      } else {
        this.addItem(searchContainer, searchObject);
      }
    } else {
      this.removeItemIdByIndex(searchContainer, existPos);
    }
    this.setSelectedDelayed(searchContainer, searchObject, selected);
  }

  private setSelectedDelayed(searchContainer: SearchContainer, searchObject: SearchObject, selected: boolean) {
    setTimeout(() => {
      // Delay required for setting selected, else GUI will not register the change
      searchContainer.selections.selected[this.getItemId(searchObject)] = selected;
    }, 100);
  }

  private checkSelectRange(obj: SearchObject, searchContainer: SearchContainer, shiftKey: boolean): boolean {
    if (!shiftKey || !searchContainer.selections.firstSelectedObject) {
      searchContainer.selections.firstSelectedObject = obj;
      return false;
    }
    // Necessary in order to register change when selecting already selected item in range
    this.setSelectedDelayed(searchContainer, obj, false);
    let searchObjects: SearchObject[] = [];
    if (searchContainer.cachedScrollDataColumns?.length) {
      const rows = searchContainer.cachedScrollDataColumns.filter(row => row !== undefined);
      for (const row of rows) {
        searchObjects = searchObjects.concat(row.columns.filter(column => column !== undefined));
      }
    } else if (searchContainer.cachedScrollDataList?.length) {
      searchObjects = searchContainer.cachedScrollDataList.filter(item => item !== undefined);
    } else {
      searchObjects = searchContainer.searchResult.artifacts;
    }
    this.selectRange(obj, searchContainer, searchObjects);
    if (searchContainer.selections.selectItemCallback) {
      searchContainer.selections.selectItemCallback();
    }
    // Necessary in order to register change when selecting already selected item in range
    this.setSelectedDelayed(searchContainer, obj, true);
    return true;
  }

  private selectRange(obj: SearchObject, searchContainer: SearchContainer, searchObjects: SearchObject[]) {
    let loopStart, loopEnd;
    const searchIndex = searchObjects.findIndex(item => this.getItemId(item) === this.getItemId(obj));
    const firstSelectedIndex = searchObjects.findIndex(
      item => this.getItemId(item) === this.getItemId(searchContainer.selections.firstSelectedObject));
    if (searchIndex === -1 || firstSelectedIndex === -1) {
      // Add item if searchIndex !== -1, to prevent empty marking.
      if (searchIndex !== -1) {
        const existPos = this.selectedIndexOf(searchContainer, searchObjects[searchIndex]);
        if (existPos === -1) {
          this.setSelected(searchContainer, searchObjects[searchIndex], true, -1);
        }
      }
      console.warn('Unable to find item');
      return false;
    }
    if (firstSelectedIndex < searchIndex) {
      loopStart = firstSelectedIndex;
      loopEnd = searchIndex;
    } else {
      loopStart = searchIndex;
      loopEnd = firstSelectedIndex;
    }
    for (let t = loopStart; t <= loopEnd; t++) {
      const searchObj = searchObjects[t];
      const existPos = this.selectedIndexOf(searchContainer, searchObj);
      if (existPos === -1) {
        this.setSelected(searchContainer, searchObj, true, -1);
      }
    }
  }

  private clearAllSelections(searchContainer: SearchContainer) {
    searchContainer.selections.allSelected = false;
    searchContainer.selections.selected = {};
    searchContainer.selections.selectedItems = [];
  }

  private addItem(searchContainer: SearchContainer, item: SearchObject) {
    searchContainer.selections.selectedItems.push(item);
  }

  private async getAllArtifactsFromSearch(searchContainer: SearchContainer): Promise<Array<SearchObject>> {
    const searchParams = await this.searchParameterService.getSearchParams(searchContainer);
    searchParams.rows = searchContainer.searchResult.search_count || searchParams.rows;
    searchParams.start = 0;

    const searchRes = await this.searchService.searchWithOverview(searchParams);
    // filter to just artifact ids
    return searchRes.artifacts;
  }

  private checkItemInArray(searchContainer: SearchContainer, arr: string[], art: SearchObject, setProp) {
    const itemId = this.getItemId(art);
    const index = arr.indexOf(itemId);
    const found = index !== -1;
    switch (setProp) {
      case 'selected':
        searchContainer.selections.selected[itemId] = found;
        break;
      case 'used':
        if (found) {
          searchContainer.selections.selected[itemId] = true;
        }
        art.$$used = found;
        break;
      default:
        console.warn('Unknown prop ' + setProp);
    }
  }

  private selectedIndexOf(searchContainer: SearchContainer, item: SearchObject): number {
    let res = -1;
    const itemId = this.getItemId(item);
    for (let index = 0; index < searchContainer.selections.selectedItems.length; index++) {
      const selectedItem = searchContainer.selections.selectedItems[index];
      if (this.getItemId(selectedItem) === itemId) {
        res = index;
        break;
      }
    }
    return res;
  }

  private cleanItem(item: SearchObject): SearchObject {
    const res = {} as SearchObject;
    for (const itemProp in item) {
      if (itemProp.indexOf('$$') !== 0) {
        res[itemProp] = item[itemProp];
      }
    }
    return res;
  }
}
