<div class="dropdown-menu-animated {{menuContainer.buttonClass}} {{menuContainer.view.name}}">
  <button class="dropdown-menu-animated__button"
          [disabled]="menuContainer.disableContainer && menuContainer.disableContainer[menuContainer.disableProp]"
          [ngClass]="{'active': menuToggled}"
          [matMenuTriggerFor]="dropdownMenu"
          [matMenuTriggerRestoreFocus]="false"
          (menuOpened)="menuToggled = true;"
          (menuClosed)="menuToggled = false;"
          mat-stroked-button>
    <mat-icon class="dropdown-menu-animated__button-icon">{{menuContainer.view.icon}}</mat-icon>
    <span class="dropdown-menu-animated__button-text">{{buttonLabel}}</span>
    <mat-icon *ngIf="menuContainer.buttonClass !== 'old-button'"
              class="dropdown-menu-animated__button-arrow">{{menuToggled ? 'expand_less' : 'expand_more'}}</mat-icon>
  </button>

<!--  *ngIf="menuToggled && (!menuContainer.disableContainer || !menuContainer.disableContainer[menuContainer.disableProp])"-->
  <mat-menu #dropdownMenu="matMenu"
            class="dropdown-menu-animated__dropdown-menu removePaddingInMatMenu"
            [ngClass]="{'content-list': menuContainer.contentList}"
            [overlapTrigger]="false"
            (closed)="menuToggled = false;">
    <ng-content></ng-content>
  </mat-menu>
</div>

