<!-- Content -->
<div mat-dialog-content
     class="modal-body modal-body-edit-fields culture-hub-fields-container">
  <div class="modal-main-content">

    <!-- SEARCH -->
    <form class="culture-hub-fields__search-field ">
      <mat-form-field appearance="outline">
        <mat-label translate="TRANS__FILTER__SEARCH_PLACEHOLDER"></mat-label>
        <input matInput
               [(ngModel)]="search"
               name="search"
               placeholder="{{'TRANS__CULTURE_HUB_IMPORTER__SEARCH_PLACEHOLDER' | translate}}">
        <mat-icon matPrefix color="accent">search</mat-icon>
      </mat-form-field>
    </form>

    <!-- TABLE -->
    <div class="culture-hub-table-container">
      <div class="md-table admin-scroll-layout culture-hub-table">
        <table (matSortChange)="sortData($event)" [dataSource]="sortedDataSource | cultureHubFolderFilter: search"
               mat-table matSort>

          <!-- Selected Checkbox -->
          <ng-container matColumnDef="selected">
            <th mat-header-cell *matHeaderCellDef mat-sort-header=""></th>
            <td mat-cell *matCellDef="let folder">
              <mat-checkbox [checked]="selectedMap.has(folder.artifact_id)"
                            (change)="selectFolder(folder)"></mat-checkbox>
            </td>
          </ng-container>

          <!-- Folder Name, on click open a new tab to kulturnav.org/${folder.uuid} -->
          <ng-container matColumnDef="folderName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__FOLDER_NAME"></span>
            </th>
            <td mat-cell *matCellDef="let folder">
              <a href="https://kulturnav.org/{{folder.uuid}}" target="_blank">{{folder.name?.name}}</a>
            </td>
          </ng-container>

          <!-- Local Name -->
          <ng-container matColumnDef="remoteListName">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__LOCAL_NAME"></span>
            </th>
            <td mat-cell *matCellDef="let folder">{{folder.remote_list.remote_list_name}}</td>
          </ng-container>

          <!-- Source Updated Date -->
          <ng-container matColumnDef="sourceUpdatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__SOURCE_UPDATED_AT"></span>
            </th>
            <td mat-cell *matCellDef="let folder">{{getDateFromIso(folder.source_updated_at)}}</td>
          </ng-container>

          <!-- Updated -->
          <ng-container matColumnDef="updated">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__UPDATED"></span>
            </th>
            <td mat-cell *matCellDef="let folder" style="text-align: center; vertical-align: middle">
              <mat-checkbox [(ngModel)]="!!folder.imported_date" disabled></mat-checkbox>
            </td>
          </ng-container>

          <!-- Local Updated Date -->
          <ng-container matColumnDef="localUpdatedAt">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__LOCAL_UPDATED_AT"></span>
            </th>
            <td mat-cell
                *matCellDef="let folder"
                [ngClass]="{'expired': isBefore(folder)}">
              {{getDateFromIso(folder.imported_date)}}
            </td>
          </ng-container>

          <!-- Owner Type Name -->
          <ng-container matColumnDef="ownerType">
            <th mat-header-cell *matHeaderCellDef mat-sort-header="">
              <span translate="TRANS__CULTURE_HUB_IMPORTER__OWNER_TYPE"></span>
            </th>
            <td mat-cell *matCellDef="let folder">{{folder.folder_type.folder_type_id_value}}</td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

          <tr class="mat-row culture-hub-table__no-data" *matNoDataRow>
            <td class="mat-cell" colspan="7">

              <!-- SEARCHING -->
              <span *ngIf="!sortedDataSource?.length > 0 && importing" class="culture-hub-table__no-data-spinner">
                <mat-spinner color="accent" diameter="16"></mat-spinner>
                <span class="culture-hub-table__no-data-spinner-text"
                      translate="TRANS__CULTURE_HUB_IMPORTER__LOADING"></span>
              </span>

              <!-- NO OPTIONS AVAILABLE -->
              <span *ngIf="!sortedDataSource?.length > 0 && !importing"
                    class="tone-down-color" translate="TRANS__CULTURE_HUB_IMPORTER__NO_AVAILABLE_OPTIONS">
              </span>

              <!-- NO HITS ON SEARCH -->
              <span
                *ngIf="sortedDataSource?.length > 0 && !(sortedDataSource | cultureHubFolderFilter: search)?.length > 0"
                class="tone-down-color"
                translate="TRANS__CULTURE_HUB_IMPORTER__NO_SEARCH_HITS"></span>
            </td>
          </tr>

        </table>
      </div>
    </div>

    <!-- CHIPS -->
    <div class="culture-hub-fields__selected-terms">
      <span class="bold" translate="TRANS__MODAL_DIALOG__YOU_HAVE_CHOSEN"></span>
      <mat-chip-list *ngIf="selectedMap.size > 0">
        <mat-chip *ngFor="let selected of selectedMap | keyvalue"
                  (removed)="selectFolder(selected.value)">
          {{selected.value.name.name}}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>

  </div>
</div>

<!-- Culture Hub Footer -->
<div mat-dialog-actions class="modal-footer dialog-border-color culture-hub-footer-container">

    <span class="culture-hub-footer-container__feedback">
    <ng-container *ngFor="let error of errors">
      <span *ngIf="errors.length" class="error-warn-color bold" [translate]="error"></span></ng-container>
    </span>

    <button mat-flat-button
            [disabled]="importing || !selectedMap.size"
            color="accent"
            type="submit"
            (click)="runImport()">
      <span *ngIf="!importing" class="bold" translate="TRANS__CULTURE_HUB_IMPORTER__IMPORT_FOLDERS"></span>
      <span *ngIf="importing" class="culture-hub-footer-container__downloading">
      <mat-spinner diameter="16" id="culture-hub-downloading"></mat-spinner>
      <span class="bold" translate="TRANS__CULTURE_HUB_SEARCH__IMPORTING"></span></span>
    </button>
    <button mat-button
            type="button"
            color="accent"
            (click)="closeImport()">
      <span class="bold" translate="TRANS__BUTTON__CLOSE"></span></button>

  <!-- Progressbar -->
  <div *ngIf="progress" class="row" style="margin-top: 8px">
    <mat-progress-bar
      color="accent"
      [value]="progress">
    </mat-progress-bar>
  </div>
</div>
