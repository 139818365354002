<mat-form-field appearance="outline"
                class="hierarchic-search-panel__search-field">
  <mat-label>
    <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__SEARCH_FOR"></span>&nbsp;<span
    [translate]="fieldParameters.field.title"></span>
  </mat-label>
  <input #queryInput matInput type="text" [(ngModel)]="query.value" (ngModelChange)="queryChanged()">
  <button mat-button type="button" *ngIf="query.value" matSuffix mat-icon-button aria-label="Clear"
          (click)="clearQuery()">
    <mat-icon>close</mat-icon>
  </button>
</mat-form-field>
<div class="hierarchic-search-panel__content"
     [ngClass]="{'create': fieldParameters.canAddNew}">
  <div *ngIf="searching" class="hierarchic-search-panel__searching">
    <mat-progress-spinner mode="indeterminate"
                          color="accent"
                          [diameter]="16"></mat-progress-spinner>
    <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__SEARCHING"></span></div>
  <mat-tree *ngIf="!searching" [dataSource]="dataSource"
             [treeControl]="treeControl" class="hierarchic-tree">
    <!-- This is the tree node template for leaf nodes -->
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle>
        <!-- use a disabled button to provide padding for tree leaf (does not work for some reason) -->
        <button mat-icon-button disabled>
          <mat-icon class="mat-icon-rtl-mirror">chevron_right</mat-icon>
        </button>
        <mat-checkbox *ngIf="isArray" [checked]="node['$$isSelected']" (change)="onNodeSelected(node, true)"></mat-checkbox>
        <button mat-button type="button"
                class="hierarchic-search-panel__node-text"
                [title]="node['$$name']"
                (click)="onNodeSelected(node, true)">
          <span>{{node['$$name']}}</span>
          <span *ngIf="node['authority_id_value'] === 'KulturNav'"
                class="concept-authority"></span>
        </button>
        <button *ngIf="node['description.description']"
                (click)="openDescription(node)"
                mat-icon-button
                type="button"
                class="hierarchic-search-panel__node-description">
          <mat-icon>info</mat-icon></button>
    </mat-tree-node>
    <!-- This is the tree node template for expandable nodes -->
    <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
      <li>
        <div class="mat-tree-node">
          <button mat-icon-button matTreeNodeToggle (click)="onNodeOpened(node)"
                  type="button"
                  [attr.aria-label]="'Toggle ' + node['$$name']">
            <mat-icon class="mat-icon-rtl-mirror">
              {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
            </mat-icon>
          </button>
          <mat-checkbox *ngIf="isArray" [checked]="node['$$isSelected']" (change)="onNodeSelected(node, false)"></mat-checkbox>
          <button mat-button type="button"
                  class="hierarchic-search-panel__node-text"
                  [title]="node['$$name']"
                  (click)="onNodeSelected(node, false)">
            <span>{{node['$$name']}}</span>
            <span *ngIf="node['authority_id_value'] === 'KulturNav'"
                  class="concept-authority"></span>
          </button>
          <button *ngIf="node['description.description']"
                  (click)="openDescription(node)"
                  mat-icon-button
                  type="button"
                  class="hierarchic-search-panel__node-description">
            <mat-icon>info</mat-icon></button>
        </div>
        <ul class="hierarchic-search-panel__node-children"
            [class.hierarchic-tree-invisible]="!treeControl.isExpanded(node)">
          <ng-container matTreeNodeOutlet></ng-container>
        </ul>
      </li>
    </mat-nested-tree-node>
  </mat-tree>
  <div *ngIf="!query.value" class="hierarchic-search-panel__feedback">
    <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__NO_SEARCH"></span>
  </div>
  <div *ngIf="!searching && dataSource.data.length === 0 && query.value"
       class="hierarchic-search-panel__feedback">
    <span translate="TRANS__HIERARCHIC_SEARCH_PANEL__NO_SEARCH_HITS"></span></div>
</div>
