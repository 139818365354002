import {Component, Input, OnChanges,} from '@angular/core';
import {SectionsContainer} from '../../core/definitions/sections-container';
import {LoggerService} from '../../core/logger.service';
import {MetaField} from '../../core/definitions/meta-field';
import {FieldParameters} from '../../core/definitions/field-parameters';
import {InlineViewService} from "../../core/inline-view.service";

@Component({
  selector: 'app-section-panel',
  templateUrl: './section-panel.component.html',
  styleUrls: ['./section-panel.component.scss']
})
export class SectionPanelComponent implements OnChanges {

  @Input() sectionsContainer: SectionsContainer;
  @Input() sectionName: string;
  @Input() edit: boolean;
  @Input() styleClass: string;

  fieldParameterList: FieldParameters[] = [];

  constructor(private logger: LoggerService,
              private inlineViewService: InlineViewService) {
  }

  ngOnChanges(): void {
    let fields: MetaField[];
    if (this.sectionsContainer) {
      for (const section of this.sectionsContainer.sections) {
        if (section.name === this.sectionName) {
          fields = section.fields.filter(field => {
            return !!field.title;
          }).sort((field1, field2) => {
            return Number(field1.order) - Number(field2.order);
          });
          break;
        }
      }
      if (fields) {
        this.fieldParameterList = fields.map(field => {
          this.inlineViewService.getInlineViewForField(this.sectionsContainer.rootObject.object_type, field).then(
            inlineViewItem => {
              field.$$inlineView = inlineViewItem;
            })
          return {
            sectionsContainer: this.sectionsContainer,
            field: field,
            rootObject: this.sectionsContainer.rootObject,
            grandParentObject: undefined,
            object: this.sectionsContainer.rootObject,
            edit: this.edit,
            index: undefined,
            parentIndex: undefined,
            prevObject: undefined,
            useMultipleSetter: undefined,
            canAddNew: undefined,
            canDelete: undefined
          } as FieldParameters;
        });
      } else {
        this.logger.warn(`Edit section named ${this.sectionName} not found!`);
      }
    }
  }
}
