<div class="admin-location-wrapper">
  <app-top-header [parent]="parentNavigationView"
                  class="admin-header-container"></app-top-header>
  <app-header-and-description heading="TRANS__ADMIN__LOCATION__HEADER"
                              description="TRANS__ADMIN__LOCATION__DESCRIPTION">
    <div class="content-wrapper">
      <app-primus-solr-tree-view class="hierarchy-navigator"
                                 [tabName]="'TRANS__SOLR_TREE_VIEW__LOCATIONS_TAB_NAME'"
                                 [topNodeLabel]="topNodeLabel"
                                 searchInputLabel="TRANS__ADMIN__LOCATION__SEARCH_INPUT_LABEL"
                                 query="-valid:false AND object_type:Location"
                                 secondaryLabelProp="location_type_id_value"
                                 [labelProp]="parentLabelProp"
                                 [parentIdProp]="parentIdProp"
                                 [selectedNode]="selectedParentNode"
                                 (selectionChanged)="selectParent($event)">
      </app-primus-solr-tree-view>
      <div class="admin-location-wrapper__content">
        <app-admin-location-filter [parentName]="selectedParentNode?.label || topNodeLabel"
                                   [selectedLocations]="selectedLocations"
                                   (filterChanged)="setFilterQuery($event)"
                                   (filterStringChanged)="currentFilterString = $event"
                                   (actionPerformed)="onActionPerformed()">
        </app-admin-location-filter>
        <app-primus-solr-data-table addRowButtonLabel="TRANS__ADMIN__LOCATION__ADD_CHILD_BUTTON_LABEL"
                                    class="admin-location-wrapper__solr-data-table"
                                    [solrQuery]="currentQuery"
                                    [columnLabels]="columnLabels"
                                    [columns]="columns"
                                    [filterString]="currentFilterString"
                                    [rowValueTransformFn]="rowTransformer"
                                    [enableAddRowButton]="true"
                                    [enableEditing]="true"
                                    [enableMultiSelection]="true"
                                    (selectionChanged)="handleSelectionChanged($event)"
                                    (editRowClicked)="handleEditOrCreateLocationClicked($event.refreshTable, $event.row?.artifact_id)"
                                    (addRowClicked)="handleEditOrCreateLocationClicked($event.refreshTable)">
        </app-primus-solr-data-table>
      </div>
    </div>

  </app-header-and-description>
</div>

