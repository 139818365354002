import { Component } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
// This component has no real value except being used as a component in the externalRedirect route due to component
// being required
export class NotFoundComponent {

}
