import { Injectable } from '@angular/core';
import {SearchParameters, SearchParametersForOverview} from "./definitions/search-parameters";
import {CmsApiService} from "./cms-api.service";

@Injectable({
  providedIn: 'root'
})
export class SearchService {

  constructor(private cms: CmsApiService) { }

  async search(searchParams: SearchParameters) {
    searchParams['q.op'] = searchParams['q.op'] || 'AND';
    return this.cms.search(searchParams);
  }

  async searchWithOverview(params: SearchParametersForOverview) {
    params['q.op'] = params['q.op'] || 'AND';
    return this.cms.searchWithOverview(params);
  }
}
