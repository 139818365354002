<div [formGroup]="sectionsContainer.formGroup"
     class="edit-fields">

  <ng-container *ngFor="let field of orderedFields">

    <div *ngIf="canShow(field)"
         [id]="field.key"
         class="{{field.$$inlineView && field.$$inlineView.css_class_edit}}"
         [ngClass]="{'edit-fields__field': field.input_type !== 'action_button',
         'action-buttons': field.input_type === 'action_button',
         'sub': !field.display || field.$$inlineView && !field.$$inlineView.css_class_edit,
         'precisionDateRange': (field.input_type === FieldInputType.DATE_ISO || field.input_type === FieldInputType.DATE_TIME_ISO)
         && (allDateInfo[field.date_info_id]?.is_to_date_field)}">
      <app-edit-field-switch
        [sectionsContainer]="sectionsContainer"
        [field]="field"
        [grandParentObject]="grandParentObject"
        [object]="object"
        [index]="index"
        [parentIndex]="parentIndex"
        (closeEdit)="onCloseEdit()"
      ></app-edit-field-switch>

    </div>

  </ng-container>

</div>
