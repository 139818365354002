<div *ngIf="canShow" class="field-action-button">
  <button mat-stroked-button
          *ngIf="inlineView?.css_class_edit === 'set-button' ||
          inlineView?.css_class_edit === 'edit-button'"
          (click)="runAction()"
          [disabled]="isDisabled">
    <mat-icon *ngIf="inlineView?.icon">{{inlineView?.icon}}</mat-icon>
    <span *ngIf="fieldTitle"
          [translate]="fieldTitle"></span>
    <span *ngIf="!fieldTitle"
          [translate]="field.title"></span></button>

  <button mat-button
          *ngIf="inlineView?.css_class_edit === 'cancel-button'"
          (click)="runAction()"
          [disabled]="isDisabled">
    <span *ngIf="fieldTitle"
          [translate]="fieldTitle"></span>
    <span *ngIf="!fieldTitle"
          [translate]="field.title"></span></button>

  <button mat-flat-button
          *ngIf="inlineView?.css_class_edit === 'store-button'"
          (click)="runAction()"
          [disabled]="isDisabled"
          color="accent">
    <span *ngIf="fieldTitle"
          [translate]="fieldTitle"></span>
    <span *ngIf="!fieldTitle"
          [translate]="field.title"></span></button>

  <button mat-stroked-button
          type="button"
          *ngIf="inlineView?.css_class_edit === 'add-coordinate'"
          (click)="runAction()"
          class="field-action-button__coordination-button"
          [disabled]="isDisabled">
    <mat-icon *ngIf="inlineView?.icon">{{inlineView?.icon}}</mat-icon>
    <span *ngIf="fieldTitle"
          [translate]="fieldTitle"></span>
    <span *ngIf="!fieldTitle"
          [translate]="field.title"></span></button>
</div>
