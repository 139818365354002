<ng-container [formGroup]="fieldParameters.sectionsContainer.formGroup">
  <mat-radio-group
    [formControlName]="formControlName"
    (change)="onChange($event)">
    <mat-radio-button
      *ngFor="let opt of getFieldOptions()"
      [value]="opt.value"
      [checked]="opt.value === fieldParameters.object[fieldParameters.field.name]"
      class="radio-option">
      {{opt.label | translate}}
    </mat-radio-button>
  </mat-radio-group>
</ng-container>
