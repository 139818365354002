<div class="edit-field-inline-array-item" [id]="arrayItemFieldId"
     [ngClass]="{'content-with-copy': sectionsContainer.isCopy,
     'noCopy': sectionsContainer.isCopy &&
     object.$$keep && !object.$$keep.fields[field.name]?.indexes[arrayIndex]?.canKeep}">
  <div class="field-level-{{field[AConst.ARRAY_LEVEL]}}">
    <app-edit-field-inline-array-item-top-bar
      [fieldParameters]="fieldParameters"
      (setFirstInputFocus)="onSetFirstInputFocus()"
      class="edit-field-inline-array-item__top_bar"
      [ngClass]="{
      'active': arrayItemIsOpen(),
      'disable-button': item.$$disabled}"
    ></app-edit-field-inline-array-item-top-bar>

    <div *ngIf="arrayItemIsOpen()"
         class="edit-field-inline-array-item__content"
         [id]="field.name + arrayIndex + '-open'"  #fieldInlineArrayItem>

      <app-edit-field-inline-array-fields
        [sectionsContainer]="sectionsContainer"
        [fields]="fields"
        [object]="item"
        [grandParentObject]="object"
        [index]="arrayIndex"
        [parentIndex]="parentIndex"
      ></app-edit-field-inline-array-fields>

    </div>
  </div>

</div>
<div *ngIf="sectionsContainer.isCopy" class="copy-keep-checkbox">
  <app-copy-keep-checkbox
    [sectionsContainer]="sectionsContainer"
    [object]="object"
    [field]="field"
    [index]="arrayIndex"
    [parentIndex]="parentIndex"
    [item]="item"
  ></app-copy-keep-checkbox>
</div>
