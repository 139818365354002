import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {ObjectViewModule} from '../object-view/object-view.module';
import {ProcedureComponent} from './procedure/procedure.component';
import {ProcedureContentListComponent} from './procedure-content-list/procedure-content-list.component';
import {ProcedureStepperComponent} from './procedure-stepper/procedure-stepper.component';
import {ProcedureViewAndEditComponent} from './procedure-view-and-edit/procedure-view-and-edit.component';
import {ObjectSearchModule} from '../object-search/object-search.module';
import {SharedModule} from '../shared/shared.module';
import {ObjectEditModule} from '../object-edit/object-edit.module';
import {MediaCarouselModule} from '../media-carousel/media-carousel.module';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from '@angular/material/legacy-progress-spinner';
import {TranslateModule} from '@ngx-translate/core';
import {OperationsModule} from '../operations/operations.module';
import {MatLegacyButtonModule as MatButtonModule} from '@angular/material/legacy-button';
import {MatIconModule} from '@angular/material/icon';
import {MatLegacyDialogModule as MatDialogModule} from '@angular/material/legacy-dialog';
import { ProcedureContentListSourceComponent } from './procedure-content-list-source/procedure-content-list-source.component';
import { ProcedureContentViewSelectorComponent } from './procedure-content-view-selector/procedure-content-view-selector.component';
import { MatLegacyCheckboxModule as MatCheckboxModule } from '@angular/material/legacy-checkbox';
import {ObjectContentTabModule} from '../object-content-tab/object-content-tab.module';
import {RouterModule} from '@angular/router';



@NgModule({
  declarations: [
    ProcedureComponent,
    ProcedureContentListComponent,
    ProcedureStepperComponent,
    ProcedureViewAndEditComponent,
    ProcedureContentListSourceComponent,
    ProcedureContentViewSelectorComponent,
  ],
  imports: [
    TranslateModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatIconModule,
    MatDialogModule,
    MatCheckboxModule,
    CommonModule,
    SharedModule,
    ObjectViewModule,
    ObjectSearchModule,
    ObjectEditModule,
    MediaCarouselModule,
    OperationsModule,
    ObjectContentTabModule,
    RouterModule
  ],
  exports: [
    ProcedureComponent
  ]
})
export class SpectrumProcedureModule { }
