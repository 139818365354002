<div *ngIf="objectViewData" class="object-view">
  <div *ngFor="let section of objectViewData.sections"
       class="object-view__section"
       id="section-{{section.name}}"
       [ngClass]="{'withSection': !hideSectionTitle,
       'add-frame': !hideSectionTitle}">

    <div class="object-view__section-content">
      <div class="object-view__section-title"
           *ngIf="!hideSectionTitle">
        <h4 class="bold"
            [translate]="section.title"></h4>
      </div>
      <hr class="object-view__hr" *ngIf="!hideSectionTitle">
      <div class="object-view__section-field-container">
        <div *ngFor="let sectionField of section.section_fields"
             class="object-view__section-field"
             [ngClass]="{'object-view__action-buttons': sectionField.action_button}">
          <div class="object-view__field">
            <div class="">
              <app-display-field-title
                *ngIf="!sectionField.action_button"
                [titleClass]="section.name === 'user_rights' ? 'user-rights': ''"
                [field]="sectionField"></app-display-field-title>
              <div *ngIf="sectionField.items">
                <div *ngFor="let item of sectionField.items"
                     class="object-view__field-content"
                     [ngClass]="{'user-rights': section.name === 'user_rights',
                       'level-1': item.header_values && item.header_values.level === 1,
                       'level-2': item.header_values && item.header_values.level === 2,
                       'new-array-item': item.is_new_array_item}">
                  <div *ngIf="item.header_values"
                       class="item-header"
                       [ngClass]="{
                       'bold': item.header_values.level === 1}">
                    <app-object-view-field-values
                      [fieldValues]="item.header_values"></app-object-view-field-values>
                  </div>

                  <app-object-view-field-checkbox
                    [sectionsContainer]="sectionsContainer"
                    [checkboxField]="item.checkbox_field">

                    <app-object-view-field-values
                      *ngIf="item.field_values"
                      [fieldValues]="item.field_values">
                    </app-object-view-field-values>

                  </app-object-view-field-checkbox>
                </div>
              </div>
              <div *ngIf="sectionField.action_button">
                <app-field-action-button
                  class="section-field__action-button"
                  [sectionsContainer]="sectionsContainer"
                  [fieldTitle]="sectionField.title"
                  [field]="sectionField.action_button"
                ></app-field-action-button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
