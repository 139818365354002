import {Component, Inject, OnInit} from '@angular/core';
import {SuperObjectModel} from '../../core/definitions/super-object-model';
import {MAT_LEGACY_SNACK_BAR_DATA as MAT_SNACK_BAR_DATA} from '@angular/material/legacy-snack-bar';
import {DateToolsService} from '../../core/date-tools.service';

@Component({
  selector: 'app-auto-save-snack-bar',
  templateUrl: './auto-save-snack-bar.component.html',
  styleUrls: ['./auto-save-snack-bar.component.scss']
})
export class AutoSaveSnackBarComponent implements OnInit {
  updatedAt = '';

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: SuperObjectModel,
              private dateToolsService: DateToolsService) { }

  ngOnInit(): void {
    this.updatedAt = this.dateToolsService.isoDateToString(this.data.updated_at, 'datetime');
  }

}
