<div class="dialog">
  <div mat-dialog-title class="modal-header">
    <h1 class="modal-title">
      <span>{{data.modalTitle | translate}}</span></h1>
    <button type="button"
            mat-icon-button
            class="close"
            [tabindex]="-1"
            aria-label="close dialog"
            mat-dialog-close="">
      <mat-icon>close</mat-icon></button>
  </div>

  <div mat-dialog-content class="modal-body">
    <div class="modal-main-content">
      <span>{{data.modalContent | translate}}</span></div></div>

  <div mat-dialog-actions class="modal-footer">
    <button type="submit"
            mat-flat-button
            color="accent"
            (click)="dialogRef.close(true)">
      <span class="bold" translate="TRANS__CONFIRM_DIALOG__SUBMIT"></span></button>
    <button type="button"
            mat-button
            color="accent"
            (click)="dialogRef.close(false)">
      <span class="bold" translate="TRANS__CONFIRM_DIALOG__CANCEL"></span></button>
  </div>
</div>


