<div class="job-status" >
  <!-- Status icon, shown in the top bar -->
  <div class="job-status__button-container" (click)="togglePanel()">
    <button mat-button class="job-status__button">
      <i class="icon-box job-status__button-box"></i>

      <span *ngIf="lastStatusIcon && lastStatusIcon != 'finished' && lastStatusIcon != 'failed'"
           class="job-status__button-icon-bg small-icon-bg"
           [ngClass]="lastStatusIcon">
        <i class="icon-spinner job-status__spinner-icon"></i>
      </span>

      <span *ngIf="lastStatusIcon == 'finished' || lastStatusIcon == 'failed'"
           class="job-status__button-icon-bg small-icon-bg"
           [ngClass]="lastStatusIcon">
        <mat-icon *ngIf="lastStatusIcon == 'finished'"
                  class="job-status__finished-icon">done</mat-icon>
        <mat-icon *ngIf="lastStatusIcon == 'failed'"
                  class="job-status__failed-icon color-white">close</mat-icon>
      </span>
    </button>
    <div *ngIf="lastStatusIcon == 'active'" class="job-status__button-text">
      <span>{{'TRANS__JOB_STATUS__ONGOING' | translate}}</span></div>
  </div>

  <!-- Overlay, active when clicked -->
  <ng-container *ngIf="clicked">
    <div class="job-status__dropdown" [ngClass]="{'ongoing': lastStatusIcon == 'active'}">
      <div class="job-status__dropdown-inner-container">

        <!-- Tabs: start -->
        <mat-tab-group mat-align-tabs="start">
          <!-- Current user -->
          <mat-tab [label]="'TRANS__JOB_STATUS__YOUR_OPERATIONS' | translate">
            <div *ngFor="let m of getMessages(true)">
            <ng-container>
              <app-job-status-message-list
                [message]="m"
                [afterExpand]="temporaryCancelJobSubscriber"
                [afterCollapse]="initJobSubscriber"
                (closePanel)="togglePanel()"
                (showError)="showError($event)"
                [downloadReport]="downloadReport">
              </app-job-status-message-list>
            </ng-container>
            </div>
          </mat-tab>

          <ng-container *ngIf="adminUser">
            <!-- Admin: All users -->

            <mat-tab [label]="'TRANS__JOB_STATUS__ALL_OPERATIONS' | translate">
              <div *ngFor="let m of getMessages(false)">
                <ng-container>
                  <app-job-status-message-list
                  [message]="m"
                  [afterExpand]="temporaryCancelJobSubscriber"
                  [afterCollapse]="initJobSubscriber"
                  (closePanel)="togglePanel()"
                  (showError)="showError($event)"
                  [downloadReport]="downloadReport">
                  </app-job-status-message-list>
                </ng-container>
              </div>
            </mat-tab>
          </ng-container>
        </mat-tab-group>
        <!-- Tabs: end -->

      </div>
    </div>
  </ng-container>
</div>

<!-- Overlay displayed when a job has just finished -->
<ng-container *ngIf="this.finishedJob && !this.clicked">
  <div class="job-status__alert-container">
      <div class="job-status__alert">
        <div class="job-status__alert-icon">
          <div class="large-icon-bg finished">
            <mat-icon  class="job-status__alert-finished-icon color-white">done</mat-icon></div></div>
        <div class="bold"><span>{{'TRANS__JOB_STATUS__FINISHED' | translate}}</span>: <span>{{finishedJob.task_name | translate}}</span></div>
        <div *ngIf="finishedJob.task_type == 'reports'" class="job-status__alert-download-button">
          <button class="job-status__download-button"
                  mat-stroked-button
                  color="primary"
                  (click)="downloadReport(finishedJob.queue_id, finishedJob.filetype)">
            <mat-icon>print</mat-icon>
            <span class="bold">{{'TRANS__JOB_STATUS__DOWNLOAD' | translate}}</span></button>
        </div>
        <div *ngIf="finishedJob.ref" class="job-status__alert-download-button">
          <a mat-stroked-button color="primary"
             [routerLink]="primusStateMapper.routerLinkFromState(finishedJob.ref)"
             [queryParams]="primusStateMapper.queryParams(finishedJob.refParams)"
             queryParamsHandling="merge">
            <span>{{'TRANS__JOB_STATUS__OPEN' | translate}}</span>
          </a>
        </div>

      </div>
  </div>
</ng-container>

<!-- Overlay displayed when job is activated -->
<div class="job-status__alert-container" *ngIf="getShowActiveDialog()">
  <div class="job-status__alert job-status__activated">
    <div class="job-status__alert-icon">
      <div (click)="clicked = true; hideActiveDialog();"
           class="job-status__alert-icon-container large-icon-bg active">
        <i class="icon-spinner job-status__activated-spinner-icon color-white"></i>
      </div>
    </div>
    <div><span class="bold">{{'TRANS__JOB_STATUS__OPERATION_STARTED' | translate}}</span></div>
    <div class="tone-down-color"><span>{{'TRANS__JOB_STATUS__MORE_INFORMATION' | translate}}</span></div>
  </div>
</div>
<div class="job-status__dropdown-overlay"
     *ngIf="clicked"
     (click)="togglePanel()"></div>

