<div class="object-page object-and-media-page" id="artifactCtrl"
     [ngClass]="{'edit': isEditing,
     'imageFullScreenOpen': imageFullScreen}">
  <div class="object-page__container object-and-media-page__container">
    <div class="object-page__content object-and-media-page__content row" id="artCon">

      <app-section-menu
        [sectionMenuContainer]="sectionMenuContainer"
      ></app-section-menu>

      <app-object-search-menu
        [object]="art"
        [isEditing]="isEditing"
        [stylingClass]="'object-page'"
      ></app-object-search-menu>

      <div id="artifactImgContainer"
           class="small-12 medium-12 large-4 large-push-8 columns object-page__media-carousel
           object-and-media-page__media-carousel"
           [ngClass]="{'artifactImgContainerEdit': isEditing}">

        <div class="object-page__media-carousel-template object-and-media-page__template">
          <div class="object-page__media-carousel-template-content">
            <app-recto-verso-buttons *ngIf="art" [object]="art"></app-recto-verso-buttons>

            <app-template-group-select-button
              *ngIf="contentInfo.artifact"
              [object]="contentInfo.artifact"
              [collapseOnSmallScreen]="false"
              [templateGroupId]="contentInfo.templateGroupId"
              (templateSelectorCreated)="onTemplateSelectorCreated($event)"
              (changeTemplateGroup)="onChangeTemplateGroup($event)"
              [disabled]="isEditing"
            ></app-template-group-select-button>
          </div>
        </div>

        <div class="object-page__media-carousel__content object-and-media-page__media-carousel-content"
             [ngClass]="{'fillEmptySpace': scrollContent}">
          <div class="showImages" *ngIf="art?.$$mediaContainer?.mediaTypes.length"
               [ngClass]="{'selector-enabled': operationContainer?.selectorEnabled}">
            <app-media-carousel
              [startMediaId]="art.$$mediaContainer.mediaTypes[0].mediaElements[0].mediaObject.artifact_id"
              [mediaContainer]="art.$$mediaContainer"
              [sectionsContainer]="sectionsContainer"
              [curAnn]="currentObjectService.curAnn"
              [isEditing]="isEditing"
              (openImageFullScreen)="openImageFullScreen($event)"
              (objectRefresh)="objectRefresh($event)"
            ></app-media-carousel>
          </div>

          <app-search-view-selector
            *ngIf="operationContainer?.selectorEnabled"
            [selectorContainer]="operationContainer"
          ></app-search-view-selector>

          <app-search-view-selector
            *ngIf="templateSelectorContainer.selectorEnabled"
            [selectorContainer]="templateSelectorContainer"
          ></app-search-view-selector>
        </div>
      </div>
      <div class="small-12 medium-12 large-5 large-pull-1 columns
      object-menus-container object-page__object-menus landing-page-top landing-page-top__action-menu">
        <div class="object-page__object-menus__content"
             *ngIf="operationContainer?.art">
          <app-operation-menus-object
            *ngIf="!smallObjectMenus && operationContainer"
            [operationContainer]="operationContainer"
          ></app-operation-menus-object>
          <app-operation-menus-object-small
            *ngIf="smallObjectMenus && operationContainer"
            [operationContainer]="operationContainer"
          ></app-operation-menus-object-small>
        </div>

      </div>
      <div id="scrollToThisFromCopyCallback"
           class="small-12 medium-12 large-8 large-pull-4 columns landing-page-main-content">
        <div class="overlay"
             *ngIf="operationContainer?.selectorEnabled"
             (click)="selectorOverlay()"></div>
        <div class="landing-page-main-content__content" id="innerCon">

          <div class="object-page__object-menu-current"
               [ngClass]="{'isEditing': isEditing}"
               *ngIf="operationContainer?.currentOperation">
            <app-operation-view-object
              [operationContainer]="operationContainer"
            ></app-operation-view-object>
          </div>

          <div class="landing-page-main-content__overview-fields">

            <div *ngIf="!sectionsContainer"
                 class="loading-object-overview-fields"
                 [ngClass]="{'isEditing': isEditing}">
              <div class="loading-object-overview-fields__header"></div>
              <div class="loading-object-overview-fields__line"></div>
              <div class="loading-object-overview-fields__line"></div>
              <div class="loading-object-overview-fields__line"></div>
              <div class="loading-object-overview-fields__line"></div></div>

            <app-object-overview-fields
              *ngIf="sectionsContainer"
              [objectView]="sectionsContainer.objectView"
              class="landing-page-main-content__overview-fields-item"
              [isEditing]="isEditing"
            ></app-object-overview-fields>
          </div>

          <app-section-menu-scroll
            [sectionMenuContainer]="sectionMenuContainer"
            class="landing-page-lower"
            id="artifactLower">
            <div class="landing-page-lower__container">
              <div class="landing-page-lower__content-tabs-container"
                   [ngClass]="{'isEditing': isEditing}">

                <div  *ngIf="!finishedLoading" class="loading-object-content-tabs">
                  <div class="loading-object-content-tabs__selected-first-menu"
                       [ngClass]="{'isEditing': isEditing}">
                    <span class="bold text-blue" translate="TRANS__OVERVIEW_MENU__OVERVIEW"></span>
                    <mat-icon class="text-blue">expand_more</mat-icon></div></div>

                <app-object-content-tabs
                  class="landing-page-lower__content-tabs"
                  *ngIf="contentInfo?.artifact"
                  [isEditing]="isEditing"
                  (finishedLoading)="isFinishedLoading()"
                  [contentInfo]="contentInfo"
                ></app-object-content-tabs>
              </div>

              <div class="landing-page-lower__content">
                <div *ngIf="loadFailed"
                     class="landing-page-lower__failed" translate="TRANS__OBJECT_PAGE__LOAD_FAILED"></div>

                <div *ngIf="contentInfo.curListName === 'overview' && sectionsContainer"
                     class="landing-page-lower__overview">
                  <app-object-view-and-edit
                    [isEditing]="isEditing"
                    [sectionsContainer]="sectionsContainer"
                    [templateGroupId]="contentInfo.templateGroupId"
                  ></app-object-view-and-edit>
                </div>

                <div *ngIf="!isEditing && contentInfo.curListName === 'overview' && art"
                     class="object-page__information-tabs">
                  <app-object-information-tabs [object]="art"></app-object-information-tabs>
                </div>

                <div *ngIf="contentInfo.curListName !== 'overview'"
                     class="landing-page-lower__content-list">
                  <div class="large-12 columns landing-page-lower__content-list__container">
                    <app-object-content-list
                      [contentInfo]="contentInfo"
                      (objectRefresh)="objectRefresh($event)"
                    ></app-object-content-list>
                  </div>
                </div>

              </div>
            </div>
          </app-section-menu-scroll>
        </div>

      </div>
    </div>
    <div class="object-page__action-toolbar" *ngIf="operationContainer?.currentOperation">
      <app-operation-toolbar
        *ngIf="operationContainer.currentOperation.$$currentStep"
        [operationContainer]="operationContainer"
        [operationStep]="operationContainer.currentOperation.$$currentStep"
        [selectorEnabled]="operationContainer.selectorEnabled || templateSelectorContainer.selectorEnabled"
        [stylingClass]="'object-page'"
      ></app-operation-toolbar>
    </div>
  </div>
</div>
<app-image-full-screen
  *ngIf="imageFullScreen"
  class="image-full-screen-container"
  [mediaContainer]="art.$$mediaContainer"
  (closeImageFullScreen)="closeImageFullScreen()"
  [curAnn]="currentObjectService.curAnn"
  [toggleAnnotations]="toggleAnnotations"></app-image-full-screen>
