import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {DragulaService} from 'ng2-dragula';
import {MediaItem} from '../../core/definitions/media-item';
import {CommonsService} from '../../core/commons.service';
import {ObjectMediaType} from '../../core/definitions/object-media-container';
import {MediaTypeChanges} from '../media-type-changes';

@Component({
  selector: 'app-change-media-order-media-type',
  templateUrl: './change-media-order-media-type.component.html',
  styleUrls: ['./change-media-order-media-type.component.scss']
})
export class ChangeMediaOrderMediaTypeComponent implements OnInit {

  constructor(private dragService: DragulaService,
              private commons: CommonsService) {

    dragService.dropModel().subscribe((value) => {
      this.updateOrder(value);
    });
    dragService.drag().subscribe((value) => {
      document.onmousemove = e => {
        const elementHeight = value.el.getBoundingClientRect().height;
        if (value.source.parentElement.clientHeight <
          (this.objectMediaType.mediaElements.length / 3) * elementHeight) {
          const mouseY = e['pageY'] - this.mediaElement.nativeElement.offsetTop;
          const scrollTop = this.mediaElement.nativeElement.scrollTop;
          const scrollBottom = this.mediaElement.nativeElement.offsetHeight - scrollTop;

          if (mouseY - elementHeight / 2 < scrollTop) {
            this.mediaElement.nativeElement.scrollBy(0, -15);
          } else if (mouseY + elementHeight > scrollBottom) {
            this.mediaElement.nativeElement.scrollBy(0, 15);
          }
        }
      };
    });

    dragService.dragend().subscribe(() => {
      document.onmousemove = null;
    });

  }

  @Input() objectMediaType: ObjectMediaType;
  @Output() mediaOrderChanges = new EventEmitter<MediaTypeChanges>();
  @ViewChild('mediaElement', {static: true}) mediaElement: ElementRef;

  mediaItems: MediaItem[];

  private static copyItem(origItem) {
    const newItem = {};
    let key;
    for (key in origItem) {
      if (!origItem.hasOwnProperty(key)) {
        continue;
      }
      newItem[key] = origItem[key];
    }
    return newItem;
  }

  ngOnInit(): void {
    const orderField = 'order_number';
    const mediaItems = this.objectMediaType.mediaElements.map(mediaElement =>
      ChangeMediaOrderMediaTypeComponent.copyItem(mediaElement.mediaItem));
    this.mediaItems = this.commons.sortArray(mediaItems, orderField);
  }

  private setChanged() {
    this.mediaOrderChanges.emit({
      objectMediaType: this.objectMediaType,
      mediaItems: this.mediaItems
    });
  }

  private updateOrder(value) {
    // The following check is necessary due to Dragula sending drag event to all components
    if (value.targetModel[0].object_type === this.mediaItems[0].object_type) {
      this.mediaItems = value.targetModel;
      this.mediaItems.forEach((mediaItem, index) => {
        mediaItem.order_number = index;
      });
      this.setChanged();
    }
  }

}
