<div class="active-media" [ngClass]="{'draggable': zoomValue > 1}">
  <div class="active-media__container">
    <img *ngIf="!playMedia && validSrc && activeMedia && activeMedia.$$imageUrl"
         [src]="activeMedia.$$imageUrl"
         [alt]="'TRANS__ACTIVE_MEDIA__ACTIVE_MEDIA' | translate"
         id="mediaItem-{{activeMedia.artifact_id}}"
         (error)="setDefaultImg()">

    <div *ngIf="!validSrc && activeMedia && activeMedia.object_type === 'Attachment'"
         class="default-image">
      <i class="icon-image-attention"></i>
      <span translate="TRANS__ACTIVE_MEDIA__NO_ATTACHMENT_IMAGE"></span>
    </div>

    <a (click)="onPlayMedia()"
       *ngIf="showPlayback && !playMedia"
       class="playButton"><i class="icon-play2"></i></a>
    <div *ngIf="showPlayback && playMedia">
      <div *ngIf="status === 'done'">
        <video *ngIf="activeMedia.object_type === 'Video'"
               width="{{this.container.offsetWidth}}"
               height="{{this.container.offsetHeight}}"
               controls autoplay>
          <source *ngFor="let pu of activeMedia.$$mediaPlaybackUrls"
                  [src]="pu.url" type="{{pu.type}}">
        </video>
        <audio *ngIf="activeMedia.object_type === 'Audio'"
               controls autoplay>
          <source *ngFor="let pu of activeMedia.$$mediaPlaybackUrls"
                  [src]="pu.url" type="{{pu.type}}">
        ></audio>
      </div>
      <div *ngIf="status !== 'done'" class="default-image">
        <i class="icon-hour-glass"></i>
        <span translate="TRANS__ACTIVE_MEDIA__MEDIA_NOT_READY"></span>
      </div>
    </div>
  </div>
</div>
