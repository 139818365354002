import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {VirtualScrollSearchDataSourceColumns} from '../../virtual-scroll-search-data-source-columns';
import {SearchExecutorService} from '../../search-executor.service';
import {SearchStateService} from '../../search-state.service';
import {SearchResultSelectionsService} from '../../search-result-selections.service';
import {SearchHandlerService} from '../../search-handler.service';
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling';
import {SearchContainer} from '../../../core/definitions/search-container';
import {MetaTypes} from '../../../core/definitions/meta-types';
import {SettingsService} from '../../../core/settings.service';
import {UiToolsService} from '../../../core/ui-tools.service';
import {ClientConfig} from "../../../core/definitions/client-config";

@Component({
  selector: 'app-search-result-view-thumbnail',
  templateUrl: './search-result-view-thumbnail.component.html',
  styleUrls: ['./search-result-view-thumbnail.component.scss']
})
export class SearchResultViewThumbnailComponent implements OnInit {
  @ViewChild(CdkVirtualScrollViewport) virtualScroll: CdkVirtualScrollViewport;
  @Input() searchContainer: SearchContainer;
  @Input() width;

  MetaTypes = MetaTypes;
  columns;
  ds: VirtualScrollSearchDataSourceColumns;
  clientConfig: ClientConfig;

  constructor(private searchExecutorService: SearchExecutorService,
              private searchStateService: SearchStateService,
              private searchResultSelectionsService: SearchResultSelectionsService,
              private searchHandler: SearchHandlerService,
              private settingsService: SettingsService,
              private uiTools: UiToolsService) {
  }

  ngOnInit(): void {
    this.clientConfig = this.settingsService.getClientConfig();
    this.ds = new VirtualScrollSearchDataSourceColumns(this.searchExecutorService, this.searchContainer);
    this.getColumns();
    if (this.searchContainer.virtualScrollViewOffset) {
      setTimeout(() => {
        this.virtualScroll.scrollToOffset(this.searchContainer.virtualScrollViewOffset);
      }, 2000);
    }
  }

  async select(obj, event) {
    this.searchResultSelectionsService.selectSearchResultItem(obj, this.searchContainer, event.shiftKey);
    await this.searchStateService.setState(this.searchContainer);
  }

  setSearchItemIndex(searchItemIndex) {
    if (this.searchContainer && searchItemIndex !== undefined) {
      this.searchContainer.virtualScrollViewOffset = this.virtualScroll.getOffsetToRenderedContentStart();
      this.searchHandler.setSearchItemIndex(this.searchContainer, searchItemIndex);
    }
  }
  getColumns() {
    const windowWidth = this.uiTools.windowSize.width;
    const width =  windowWidth > 1025 ? windowWidth - 270 : windowWidth;
    this.columns = Math.floor(width / 202);
  }
}
