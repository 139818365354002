<div class="annotation-color-menu" [ngClass]="{'isDialog': isDialog}">
  <div class="medium-12 columns annotation-color-menu__button-container">
    <mat-form-field appearance="outline" class="annotation-color-menu__select">
      <span matPrefix class="annotation-color-menu__colorBox"
            [ngStyle]="{'background-color': curAnn.state.edit ? curAnn.currentColor : '#666'}"></span>
      <mat-select [disabled]="!curAnn.state.edit"
                  [matTooltip]="'TRANS__ANNOTATE_MENU__DISABLED_TOOLTIP' | translate"
                  [matTooltipDisabled]="curAnn.state.edit">
        <mat-option *ngFor="let col of curAnn.colors"
                    (click)="curAnn.setColor(col)"
                    [ngStyle]="{'background-color': col}">
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div class="medium-12 columns annotation-color-menu__text-container">
    <span class="annotation-color-menu__text" translate="TRANS__ANNOTATE_MENU__COLOR"></span>
  </div>
</div>
