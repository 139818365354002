import { Injectable } from '@angular/core';
import {FieldValueService} from './field-value.service';
import {FieldParameters} from './definitions/field-parameters';
import {SearchFilters} from './definitions/search-filters';
import {UserCacheService} from './user-cache.service';
import {ReferenceFilter} from './definitions/reference';
import {LoggerService} from './logger.service';
import {SearchReferenceService} from "./search-reference.service";

@Injectable({
  providedIn: 'root'
})
export class ReferenceFilterService {

  constructor(private logger: LoggerService,
              private fieldValueSvc: FieldValueService,
              private userCacheService: UserCacheService,
              private searchReferenceService: SearchReferenceService) { }

  /* Used for setting search filter or create new item */
  async generateRefFilterData(refFilters: ReferenceFilter[], isTargetArray, fieldParameters: FieldParameters): Promise<SearchFilters> {
    const target = new SearchFilters();
    refFilters = refFilters || [];
    refFilters = Array.isArray(refFilters) ? refFilters : [refFilters];
    for (const rFilter of refFilters) {
      const name = rFilter.filter_field;
      const values = rFilter.values;
      if (name && Array.isArray(values)) {
        await this.loopFilterValuesSetTargetSearchFilters(fieldParameters, name, values, target, isTargetArray)
      }
      if (rFilter.fq) {
        const fq = await this.getCheckSubstituteValue(rFilter.fq, fieldParameters);
        target.fq = target.fq || [];
        target.fq.push(fq);
      }
    }
    return target;
  }

  private async loopFilterValuesSetTargetSearchFilters(
    fieldParameters: FieldParameters,
    name: string,
    values: string[],
    target: SearchFilters,
    isTargetArray: boolean) {
    for (const [index, value] of values.entries()) {
      let targetVal = value;
      if (typeof value === 'object') {
        targetVal = this.getSourceFieldVal(value, fieldParameters);
      } else if (typeof value === 'string') {
        targetVal = await this.getCheckSubstituteValue(value, fieldParameters);
      }
      if (targetVal !== null && targetVal !== undefined) {
        if (isTargetArray) {
          this.setArrayTargetFilters(target, name, targetVal);
        } else if (index === 0) {
          target[name] = targetVal;
        }
      }
    }
  }

  private setArrayTargetFilters(target: SearchFilters, name, targetVal) {
    if (Array.isArray(targetVal)) {
      if (targetVal.length) {
        target[name] = target[name] || [];
        target[name] = target[name].concat(targetVal);
      }
    } else {
      target[name] = target[name] || [];
      target[name].push(targetVal);
    }
  }

  private async getCheckSubstituteValue(value: string, fieldParameters: FieldParameters): Promise<string> {
    let res = value;
    if (value.indexOf('{') !== -1) {
      const substStr = this.fieldValueSvc.getSubstituteString(value, '{', '}');
      let substVal;
      if (substStr.indexOf('user.') === 0) {
        const userField = substStr.split('.')[1];
        const userData = await this.userCacheService.getUserData();
        substVal = userData[userField];
      } else {
        substVal = await this.getSuperobjectTypeFilterFromDialogParent(substStr, fieldParameters);
        if (!substVal) {
          substVal = this.fieldValueSvc.getFieldValFromFieldParameters(fieldParameters, substStr);
        }
      }
      res = value.replace('{' + substStr + '}', substVal || '');
      if (!substVal) {
        this.logger.warn(`Unable to find substitute value for ${substStr}, this may happen for field meta operations`);
      }
    }
    return res;
  }

  // If the concept field is placed in an edit dialog, e.g. when creating a new Designation while editing an object,
  // then the 'main type' selector must get the superobject type filter from the parent object. This filter is located
  // in the 'filters'-field in the rootObject (the root object is the new designation object)
  private async getSuperobjectTypeFilterFromDialogParent(substStr: string, fieldParameters: FieldParameters) {
    let substVal;
    const reference = await this.searchReferenceService.getSearchReferenceFromField(fieldParameters.field);
    if (substStr === 'superobject_type_id' &&
      fieldParameters.rootObject.filters &&
      fieldParameters.rootObject.object_type === reference.object_type) {
      const superobjectTypeFilter = fieldParameters.rootObject.filters.find(filter => filter.superobject_type_id !== undefined);
      if (superobjectTypeFilter) {
        substVal = superobjectTypeFilter.superobject_type_id;
      }
    }
    return substVal;
  }

  private getSourceFieldVal(sourceInfo, fieldParameters: FieldParameters) {
    let sourceVal = null;
    const sourceField = sourceInfo.source_field;
    if (sourceField) {
      if (sourceInfo.source_object) {
        if (sourceInfo.source_object === 'parent_object') {
          const parentObject = fieldParameters.sectionsContainer.parentObject;
          if (parentObject) {
            sourceVal = parentObject[sourceField];
          } else {
            this.logger.warn('Parent object not set');
          }
        } else {
          this.logger.warn('Source object not supported: ' + sourceInfo.source_object);
        }
      } else {
        sourceVal = this.fieldValueSvc.getFieldValFromFieldParameters(fieldParameters, sourceField);
      }
    }
    return sourceVal;
  }

}
