<div class="field-select-input"
     [class.fieldSelectOpen]="inFocus.value">
  <label class="clearInputContainer"
         [ngClass]="fieldParameters.sectionsContainer.isDialog ?
     (fieldParameters.sectionsContainer.isSecondDialog ? 'ignoreClicksSecondModal-' + fieldParameters.field.name :
     'ignoreClicksModal-' + fieldParameters.field.name) :
     'ignoreClicks-' + fieldParameters.field.name">
    <input type="text"
           [id]="fieldKey"
           [name]="fieldKey"
           [placeholder]="'&#xe62c;&nbsp;' + placeHolderSearchFor + placeholder"
           [(ngModel)]="query.value"
           [ngModelOptions]="{standalone: true}"
           [disabled]="isDisabled"
           (keyup)="onInputKey($event)"
           (keydown)="onInputKeyDown($event)"
           (click)="refreshAndShowOptions()"
           (focus)="onFieldSelectFocus()"
           [ngClass]="{'errorInputFrame': !showOptions.show && fieldValidation.isFieldInvalid(sectionsContainer, fieldKey)}"
           tabindex="0"
           autocomplete="off" #fieldSelectInput>
    <a *ngIf="query.value && !reference.no_clear"
       class="clearInputField"
       (click)="clearField()"
       type="button">
      <mat-icon>close</mat-icon>
    </a>
    <span *ngIf="!isArray && query.valueWithTag && query.valueWithTag === textValue"
          class="selected-concept-in-input"> <!-- If single select -->
        <span *ngIf="!fieldParameters.object[temporaryFieldValueName]">
          {{textValue}}</span>
         <span *ngIf="fieldParameters.object[temporaryFieldValueName]"
               [ngClass]="{'withAuthority': fieldParameters.object[temporaryFieldValueName].indexOf('concept-name') !== -1}"
               [innerHtml]="fieldParameters.object[temporaryFieldValueName]"></span></span>

    <span *ngIf="isArray && isSingleItemArray && query.valueWithTag === textValue"
          class="selected-concept-in-input"> <!-- If isSingleItemArray  -->
        <span *ngIf="!fieldArray[fieldArray.length -1][temporaryFieldValueName]">
          {{textValue}}</span>
         <span *ngIf="fieldArray[fieldArray.length -1][temporaryFieldValueName]"
               [innerHtml]="fieldArray[fieldArray.length -1][temporaryFieldValueName]"></span></span>
  </label>
  <span class="field-select-input__dropdown-button"
        [ngClass]="{'open': showOptions.show}"
        (click)="toggleShowOptions(undefined)">
        <span class="vertical-line"></span>
       <mat-icon>expand_more</mat-icon>
  </span>
</div>
