<div *ngIf="!someAreUsed"  class="object-usage-field__object-unused">
  <mat-icon>check_circle</mat-icon>
  <span translate="TRANS__OBJECT_USAGE__NONE_ARE_USED"></span>
</div>

<div *ngIf="someAreUsed">
  <ng-container *ngFor="let objectUsage of objectUsages">
    <ng-container *ngIf="objectUsage.objectsUsing.length">
      <button mat-stroked-button
              color="primary"
              type="button"
              class="object-usage-field__button"
              (click)="toggleShowUsage(objectUsage)"
              [ngClass]="{'active': objectUsage.showItems}">
        <span class="object-usage-field__button-text bold">
          <span>{{objectUsage.objectName}}</span>
          <span>({{objectUsage.objectsUsing.length}})</span>
        </span>
        <mat-icon>{{objectUsage.showItems ? 'expand_less' : 'expand_more'}}</mat-icon>
      </button>
    </ng-container>
  </ng-container>
  <mat-chip-list *ngIf="lastShowUsage?.showItems"
                 class="object-usage-field__objects">
    <mat-chip *ngFor="let objectUsing of lastShowUsage.objectsUsing"
              class="object-usage-field__object">{{objectUsing.artifact_name}}</mat-chip>
  </mat-chip-list>
</div>
