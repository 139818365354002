<div *ngIf="openSelection"
     (click)="toggleSelection()"
     class="selection-basket-overlay"></div>
<div class="search-menus">
  <div class="search-menus__button">
    <ul class="searchTopMenu">

      <li class="search-menus__selection-basket">
        <button class="search-menus__selection-basket-button" (click)="toggleSelection()">
          <span class="search-menus__selection-basket-count-bg-container">
            <span class="search-menus__selection-basket-count-bg">
              <span>{{selectedCount}}</span></span>
            <i class="icon-box"></i></span>
          <span class="search-menus__selection-basket-button-text"
                translate="TRANS__SEARCH_MENU__SELECTION"></span></button></li>

      <li class="search-top-menu-buttons search-menus__button__search-action"
          [ngClass]="{
        'disable': actionMenuDisabled, 'active':searchContainer.toggleActionMenu}">
        <app-search-menu-select-all
          class="markAllContainer"
          [searchContainer]="searchContainer"
        ></app-search-menu-select-all>

        <app-search-menu-actions
          class="search-menus__button__search-action-menu"
          [searchContainer]="searchContainer"
          [operations]="searchContainer.operationContainer?.operations"
          [disabled]="actionMenuDisabled"
          [reportContainer]="searchContainer.reportContainer"
          (operationPerformed)="onOperationPerformed($event)"
        ></app-search-menu-actions>
      </li>

      <li class="search-top-menu-buttons search-menus__button__view">
        <app-dropdown-menu-animated
          class="search-menus__button__content"
          [menuContainer]="{
          view: currentResultView,
          buttonClass : 'new-button',
          disableContainer: searchContainer.reportContainer,
          disableProp: 'show'}">

          <app-search-menu-view-selector
            class="search-menus__button__content"
            [searchContainer]="searchContainer"
            [currentResultView]="currentResultView"
          ></app-search-menu-view-selector>

        </app-dropdown-menu-animated>
      </li>

      <li class="search-top-menu-buttons search-menus__button__sort">
        <app-dropdown-menu-animated
          class="search-menus__button__content"
          [menuContainer]="{
          view: { description: 'TRANS__SEARCH__ORDER_ON',  icon: 'sort', name: searchContainer.searchResultViewName},
          disableContainer: searchContainer.reportContainer,
          buttonClass : 'new-button',
          disableProp: 'show'}">

          <app-search-menu-sort
            [searchContainer]="searchContainer"
          ></app-search-menu-sort>
        </app-dropdown-menu-animated>
      </li>
      <li *ngIf="searchContainer.path === 'home/folders/folder'"
          class="search-top-menu-buttons search-menus__button__folder-settings">
        <app-search-menu-folder-settings
          class="search-menus__button__content"
          [searchContainer]="searchContainer"
          (operationPerformed)="onOperationPerformed($event)"
        ></app-search-menu-folder-settings></li>

      <li *ngIf="openSelection" class="search-menus__selection-basket-dropdown">
        <app-search-selection-basket
          (removeSelectionItem)="remove($event)"
          (removeAllItems)="removeAll()"
          [searchContainer]="searchContainer"
          [selectedCount]="selectedCount"
        ></app-search-selection-basket></li>

    </ul>
  </div>
</div>
