import {Injectable} from '@angular/core';
import {FieldValueService} from '../../core/field-value.service';
import {ChangeTrackerService} from '../../core/change-tracker.service';
import {ModelFactoryService} from '../../core/model-factory.service';
import {FieldMetaService} from '../../core/field-meta.service';
import {InlineArrayItemService} from '../../core/inline-array-item.service';
import {MetaField} from '../../core/definitions/meta-field';
import {BaseModel} from '../../core/definitions/base-model';
import {KeepObject} from '../../core/definitions/keep-object';
import {FieldParameters} from '../../core/definitions/field-parameters';

export class CopyKeepServiceParams {
  object: BaseModel;
  field: MetaField;
  index: number;
  fieldChangeCallback;

  constructor(object: BaseModel, field: MetaField, index: number, fieldChangeCallback?) {
    this.object = object;
    this.field = field;
    this.index = index;
    this.fieldChangeCallback = fieldChangeCallback;
  }
}

@Injectable({
  providedIn: 'root'
})
export class CopyKeepService {

  constructor(private readonly fieldValueSvc: FieldValueService,
              private readonly inlineArrayItemSvc: InlineArrayItemService,
              private readonly changeTracker: ChangeTrackerService,
              private readonly fieldMetaSvc: FieldMetaService,
              private readonly modelFactory: ModelFactoryService) {
  }

  getKeepProps(params: CopyKeepServiceParams): KeepObject {
    let keepProps: KeepObject;
    if (params.object && params.object.$$keep) {
      keepProps = params.object.$$keep.fields[params.field.name];
      if (keepProps && params.index !== undefined) {
        keepProps = keepProps.indexes[params.index];
      }
    }
    return keepProps;
  }

  moveKeepObjects(fieldParameters: FieldParameters, previousIndex: number, currentIndex: number) {
    if (!fieldParameters.object.$$keep) {
      return;
    }
    const indexes = fieldParameters.object.$$keep.fields[fieldParameters.field.name].indexes;
    const moved = indexes[previousIndex];
    if (previousIndex > currentIndex) {
      // Item has been moved up
      for (let t = previousIndex ; t > currentIndex ; t--) {
        indexes[t] = indexes[t - 1];
      }
    } else {
      // Item has been moved down
      for (let t = previousIndex ; t < currentIndex ; t++) {
        indexes[t] = indexes[t + 1];
      }
    }
    indexes[currentIndex] = moved;
  }

  /**
   * The following conditions shall prevent the "keep" checkbox
   * from being displayed next to a field:
   *  - Copied fields missing values
   *  - Copied fields that are required
   *  - New array items, added after the copy was created
   *  - Fields within a newly created array item object
   *
   * @param keep, boolean, whether field shall be default kept or not
   * @param params containing model information
   * @param params.object, the parent model of the field to be checked
   * @param params.fieldName, the name of the field to be checked
   * @param params.index, if set: the index of the array item to be checked
   */
  setKeep(keep, params: CopyKeepServiceParams) {
    const res = this.checkCanKeep(params);
    if (res) {
      const keepProps = this.getSetKeepProps(params);
      if (keepProps) {
        keepProps.canKeep = keep;
      }
    }
    return res;
  }


  hasChanges(params: CopyKeepServiceParams) {
    return this.changeTracker.checkFieldValueChanged(params.object, params.field.name);
  }

  removeNotKeep(object) {
    this.recursiveRemoveKeep(object);
  }

  setKeepSection(model, section, keep) {
    model.$$keepSection = model.$$keepSection || {};
    model.$$keepSection[section.name] =
      model.$$keepSection[section.name] || {};
    model.$$keepSection[section.name].keep = keep;
    this.setKeepSectionFields(model, section, keep);
  }

  private checkCanKeep(params: CopyKeepServiceParams) {
    let res = true, arrayItem;
    if (params.object) {
      if (params.index !== undefined) {
        arrayItem = params.object[params.field.name][params.index];
        if (arrayItem !== undefined) {
          res = !arrayItem._create;
        } else {
          console.warn('No item ' + params.index + ' in ' + params.field.name);
        }
      }
      if (res) {
        res = this.fieldValueSvc.hasValue(params.field, params.object) &&
          !params.object['_create'] && !params.field.is_required;
      }
      if (res) {
        res = !this.hasChanges(params);
      }
    }
    return res;
  }

  private getSetKeepProps(params: CopyKeepServiceParams): KeepObject {
    let keepProps: KeepObject;
    if (params.object) {
      params.object.$$keep = params.object.$$keep || new KeepObject();
      params.object.$$keep.fields[params.field.name] =
        params.object.$$keep.fields[params.field.name] || new KeepObject();
      keepProps = params.object.$$keep.fields[params.field.name];
      if (params.index !== undefined) {
        keepProps.indexes[params.index] = keepProps.indexes[params.index] || new KeepObject();
        keepProps = keepProps.indexes[params.index];
      }
      if (params.fieldChangeCallback) {
        this.changeTracker.setFieldChangeCallback(params.object, params.field.name, params.fieldChangeCallback);
      }
    }
    return keepProps;
  }

  private recursiveRemoveKeep(obj: BaseModel) {
    let keepObject: KeepObject = null;
    if (obj) {
      keepObject = obj.$$keep;
    } else {
      console.warn('No object!');
    }
    if (!keepObject) {
      return;
    }
    for (const [fieldName, keepProps] of Object.entries(keepObject.fields)) {
      if (keepProps.canKeep === false) {
        obj[fieldName] = null;
      }
      if (!(keepProps.indexes && obj[fieldName])) {
        continue;
      }
      this.loopSortedIndexRemoveKeep(obj, fieldName, this.getReverseSortedIndexes(keepProps.indexes));
    }
    this.loopRemoveKeepFromSubModels(obj)
  }

  private loopSortedIndexRemoveKeep(obj: BaseModel, fieldName, sortedIndex) {
    for (const [indexStr, keepPropsNdx] of Object.entries(sortedIndex)) {
      const index = Number.parseInt(indexStr, 10);
      // 'keep props' will not exist for new elements added after copy
      if (keepPropsNdx) {
        if (keepPropsNdx['canKeep'] === false) {
          obj[fieldName].splice(index, 1);
        } else {
          this.recursiveRemoveKeep(obj[fieldName][index]);
        }
      }
    }
  }

  private loopRemoveKeepFromSubModels(obj: BaseModel) {
    for (const subObj of Object.values(obj)) {
      if (subObj && subObj.$$keep) {
        this.recursiveRemoveKeep(subObj);
      }
    }
  }

  private getReverseSortedIndexes(indexes) {
    return Object.keys(indexes).sort((index1, index2) => {
      let res = 0;
      if (Number(index1) < Number(index2)) {
        res = 1;
      } else if (Number(index1) > Number(index2)) {
        res = -1;
      }
      return res;
    });
  }

  private setKeepSectionFields(model, section, keep) {
    for (const secField of section.fields) {
      this.setKeepSectionField(keep, model, secField);
    }
  }

  private setKeepSectionField(keep, model, sectionField) {
    let secModel = model;
    const path = <string>sectionField.path;
    if (path) {
      for (const s of path.split('.')) {
        secModel = secModel[s];
      }
    }
    if (secModel) {
      this.recursiveSetKeep(keep, secModel, sectionField);
    } else {
      console.warn('Model not found for ' + sectionField.path);
    }
  }

  private recursiveSetKeep(keep, object: BaseModel, field) {
    this.modelFactory.traverseModelField((model, fieldName, index) => {
      const fieldRes = model.$$meta[fieldName];
      this.setKeep(keep, new CopyKeepServiceParams(model, fieldRes, index));
    }, object, field.name);
  }

}
