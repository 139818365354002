import {Component, Input, OnChanges} from '@angular/core';
import {SearchContainer} from '../../../core/definitions/search-container';
import {SearchHierarchicBreadcrumbService} from '../../search-hierarchic-breadcrumb.service';
import {SearchHandlerService} from '../../search-handler.service';

@Component({
  selector: 'app-search-hierarchic-bread-crumbs',
  templateUrl: './search-hierarchic-bread-crumbs.component.html',
  styleUrls: ['./search-hierarchic-bread-crumbs.component.scss']
})
export class SearchHierarchicBreadCrumbsComponent implements OnChanges {
  @Input() searchContainer: SearchContainer;
  @Input() targetId: string;

  constructor(private searchHierarchicBreadcrumbService: SearchHierarchicBreadcrumbService,
              private searchHandler: SearchHandlerService) { }

  showMoreButton = false;
  finishedLoading = false;

  ngOnChanges(): void {
    if (this.targetId && this.targetId !== 'none') {
      this.searchHierarchicBreadcrumbService.setBreadcrumbsFromSearch(this.searchContainer, this.targetId).then(() => {
        this.showMoreButton = this.searchContainer.breadcrumbs.length - 1 > 3;
        this.finishedLoading = true;
      });
    }
  }

  showAllItems() {
    this.showMoreButton = false;
  }

  goToMenuPath() {
    this.searchContainer.objectCount = 0;
    this.searchContainer.targetId = 'none';
    this.searchContainer.path = 'home/folders';
    this.searchContainer.breadcrumbs = [];
    this.searchHandler.goPathView(this.searchContainer.path, this.searchContainer).then();
  }
}
