<ng-container *ngFor="let select of arrayObject[arrayName]">
  <li class="edit-field-selected-options" *ngIf="!select._destroy">
    <span *ngIf="select[valueProp]"
          [innerHtml]="select[valueProp]"></span>
    <a (click)="unCheckOption(select)">
      <mat-icon>close</mat-icon>
    </a>
    <div class="button-overlay"></div>
  </li>
</ng-container>
