export class GetArtifactParams {
  artifact_id: string;
  suppressErrHandler: boolean;
}

export class GetArtifactViewParams {
  artifact_id: string;
  template_group_id: string;
  timezone_offset = new Date().getTimezoneOffset();
}


export class GetArtifactViewAndDataParams {
  artifact_id: string;
  get_original_artifact: boolean;
  template_group_id: string;
  timezone_offset = new Date().getTimezoneOffset();
}
