import {Component, Inject, OnInit} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {AdminConceptJoinConceptsData, ConceptService} from '../../../core/concept.service';
import {ConceptListItem} from '../../../core/definitions/concepts';
import {ConceptJoinInfo, ConceptJoinResult} from '../../../core/definitions/concept-join-info';
import {DateToolsService} from '../../../core/date-tools.service';

@Component({
  selector: 'app-admin-concept-join-concepts',
  templateUrl: './admin-concept-join-concepts.component.html',
  styleUrls: ['./admin-concept-join-concepts.component.scss']
})
export class AdminConceptJoinConceptsComponent implements OnInit {

  joinToConcept: ConceptListItem;
  concepts: Array<ConceptListItem> = [];
  conceptJoinResult: ConceptJoinResult = new ConceptJoinResult();

  private findIndex = 0;
  private similarNamesFound = [];
  private hadSelections = false;
  private selectedAuthCount = 0;

  constructor(
    public dialogRef: MatDialogRef<AdminConceptJoinConceptsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AdminConceptJoinConceptsData,
    private conceptService: ConceptService,
    private dateToolsService: DateToolsService) {
  }

  ngOnInit(): void {
    this.clearConceptJoinResult();
    this.setConcepts().then();
    this.getInvalidJoinConcepts();
    this.hadSelections = !!this.data.conceptsContainer.selected.length;
  }

  async joinConcepts() {
    const conceptJoinInfo = new ConceptJoinInfo();
    conceptJoinInfo.winner = this.joinToConcept.artifact_id;
    conceptJoinInfo.losers = this.concepts.map(concept => concept.artifact_id).filter(
      concept_id => concept_id !== this.joinToConcept.artifact_id);
    try {
      this.data.callback(conceptJoinInfo);
    } catch (e) {
      this.conceptJoinResult.message = e.error.message;
      this.conceptJoinResult.status = 'failed';
    }
    if (this.conceptJoinResult.status === 'ok') {
      for (const concept of this.data.conceptsContainer.conceptDataSource.data) {
        for (const conceptInJoin of this.concepts) {
          if (concept.artifact_id === conceptInJoin.artifact_id &&
            conceptInJoin.artifact_id !== this.joinToConcept.artifact_id) {
            concept.$$deleted = true;
          }
        }
      }
      this.conceptService.clearSelected(this.data.conceptsContainer);
      this.data.conceptsContainer.conceptDataSource.refreshVisibleConcepts();
      this.conceptJoinResult.status = 'ok';
      this.conceptJoinResult.message = 'TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__JOIN_SUCCESS';
      setTimeout(() => {
        this.closeDialog();
      }, 3000);
    }
  }

  closeDialog() {
    this.dialogRef.close();
  }

  getInvalidJoinConcepts() {
    let res = false;
    if (this.conceptJoinResult.status === 'no_more_similar') {
      return true;
    }
    if (this.selectedAuthCount > 1) {
      this.conceptJoinResult.status = 'invalid';
      this.conceptJoinResult.message = 'TRANS__ADMIN_CONCEPT__INVALID__CANNOT_JOIN_CULTURE_HUB_CONCEPTS';
      res = true;
    } else if (this.data.conceptsContainer.conceptType.is_hierarchic && this.concepts.find(concept => !concept.is_leaf)) {
      res = true;
      this.conceptJoinResult.status = 'invalid';
      this.conceptJoinResult.message = 'TRANS__ADMIN_CONCEPT__INVALID__JOIN_HAS_PARENT_CONCEPTS';
    } else if (this.concepts.length < 2) {
      res = true;
      this.conceptJoinResult.status = 'invalid';
      this.conceptJoinResult.message = 'TRANS__ADMIN_CONCEPT__INVALID__TOO_FEW_CONCEPTS_IN_JOIN';
    }
    if (!res && this.conceptJoinResult.status === 'invalid') {
      this.clearConceptJoinResult();
    }
    return res;
  }

  dropConcept(dropConcept: ConceptListItem) {
    const dropIndex = this.concepts.findIndex(concept => concept.artifact_id === dropConcept.artifact_id);
    if (dropIndex !== -1) {
      this.concepts.splice(dropIndex, 1);
      dropConcept.$$selected = false;
      this.conceptService.selectConcept(dropConcept, this.data.conceptsContainer);
      this.setDefaultJoinToConcept(this.concepts);
    }
  }

  private async setConcepts(findNext?: boolean): Promise<void> {
    let concepts: Array<ConceptListItem>;
    if (!findNext && this.data.conceptsContainer.selected.length) {
      concepts = [...this.data.conceptsContainer.selected];
    } else {
      concepts = this.findNextSimilar();
    }
    if (concepts.length) {
      await this.conceptService.setConceptUsage(concepts);
      this.setDefaultJoinToConcept(concepts);
      for (const concept of concepts) {
        concept.$$updatedAt = this.dateToolsService.isoDateToString(concept.updated_at);
      }
      this.concepts = concepts;
    } else {
      this.conceptJoinResult.status = 'no_more_similar';
      this.conceptJoinResult.message = 'TRANS__ADMIN_CONCEPT__JOIN_CONCEPTS__NO_SIMILAR_CONCEPTS_FOUND';
    }
  }

  private setDefaultJoinToConcept(concepts: Array<ConceptListItem>) {
    this.joinToConcept = concepts[0];
    let authFound = false;
    let biggest = 0;
    this.selectedAuthCount = 0;
    for (const concept of concepts) {
      if (concept.authority_id) {
        this.joinToConcept = concept;
        authFound = true;
        this.selectedAuthCount++;
      }
      if (!authFound && concept.$$usage.length > biggest) {
        this.joinToConcept = concept;
        biggest = concept.$$usage.length;
      }
    }
  }

  private clearConceptJoinResult() {
    this.conceptJoinResult.status = 'ok';
    this.conceptJoinResult.message = '';
  }

  private findNextSimilar() {
    const res = [];
    let found = false;
    const concepts = this.data.conceptsContainer.conceptDataSource.data;

    do {
      let checkConcept: ConceptListItem;
      let checkConceptName: string;
      let nextCheckSet = true;
      do {
        checkConcept = concepts[this.findIndex];
        checkConceptName = this.getTransformedName(checkConcept);
        if (this.similarNamesFound.length && this.similarNamesFound.indexOf(checkConceptName) !== -1) {
          nextCheckSet = false;
          this.findIndex++;
        } else {
          nextCheckSet = true;
        }
      } while (!nextCheckSet && this.findIndex < concepts.length);
      if (this.findIndex >= concepts.length) {
        return res;
      }
      for (let t = this.findIndex + 1; t < concepts.length; t++) {
        const checkSimilarConcept = concepts[t];
        const checkSimilarName = this.getTransformedName(checkSimilarConcept);
        const sameFilters = this.data.conceptsContainer.conceptType.has_filters ?
          checkConcept.filters === checkSimilarConcept.filters : true;
        if (checkConceptName === checkSimilarName && sameFilters) {
          if (!found) {
            found = true;
            res.push(checkConcept);
            this.similarNamesFound.push(checkConceptName);
          }
          res.push(checkSimilarConcept);
        }
      }
      this.findIndex++;
    } while (!found && this.findIndex < concepts.length);
    return res;
  }

  private getTransformedName(concept: ConceptListItem) {
    const conceptNameLower = concept.name.toLowerCase();
    let transformedName = '';
    for (let t = 0; t < conceptNameLower.length; t++) {
      const char = conceptNameLower[t];
      if (' -_'.indexOf(char) === -1) {
        transformedName += char;
      }
    }
    return transformedName;
  }
}
